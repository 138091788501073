<template>

    <div class="w-100 h-100">

        <div class="officine-Convenzionate-filtri form-row no-gutters">
            <div class="col-md-2 d-flex align-items-center justify-content-end">
                <div class="pt-4">Filtra la lista per: </div>
            </div>
           
            <div class="form-group col-md-4 mt-4" style="position: relative">
                <label class="text-black" style="font-weight: 700;">BRAND</label>
                <multiselect
                        :options="brands"
                        @input="e => { brandSelected = e; filterpartnerLocation();}"
                        placeholder=" "
                        label="value"
                        track-by="value"
                        :searchable="true"
                        :showNoResults="false"
                        :max-height="250"
                        ref="brandsSelect"
                        selected-label=" "
                        select-label=" "
                        deselectLabel=" "
                        v-model="brandSelected"
                        :loading="brandLoading"
                        >
                </multiselect>
            </div>

            <div class="form-group col-md-4 mt-4" style="position: relative">
                <label class="text-black" style="font-weight: 700;">PROVINCIA</label>
                <multiselect
                        id="provincesSelect"
                        v-if="provinces"
                        :options="provinces"
                        @input="e=>{ provinceSelected = e; filterpartnerLocation();}"
                        placeholder=" "
                        label="label"
                        track-by="label"
                        :searchable="true"
                        :showNoResults="false"
                        :max-height="250"
                        ref="provincesSelect"
                        selected-label=" "
                        select-label=" "
                        deselectLabel=" "
                        v-model="provinceSelected"
                        :loading="provincesLoading">
                </multiselect>
            </div>

            <div class="col-md-2 d-none d-md-flex align-items-center">
                <a @click="onLocalizeClick"
                    class="pt-4">
                    Localizzami
                    <i class="fal fa-location fa-lg clickable"></i>
                </a>

            </div>
            
        </div>

        <div v-if="!error">
            <div class="d-flex justify-content-between align-items-center p-2 p-md-3">
                <span class="font-weight-bold">Risultati</span>
            </div>
            <div class="row no-gutters row-eq-height border rounded overflow-hidden">
                <div class="col-12 col-md-5 order-2 order-md-1">
                    <adaptive-spinner
                            class="p-5"
                            v-if="partnerLocationLoading"/>
                    <div v-else
                         @scroll="onInfiniteScroll"
                         class="overflow-auto scrollableContainer"
                         ref="scrollableContainer">
                    
                        <the-officine-convenzionate-item
                            v-for="(item, index) in partnerLocationListFiltered"
                            :partnerLocation="item"
                            :currentUficialbrand="brandSelected?.value"
                            @onPartnerLocationSelected="onPartnerLocationSelected(index)"
                            :selected="partnerLocationSelected == index"
                            :ref="index"
                            :index="index"
                            :id="index"/>
                        <div class="p-3" v-if="!this.brandSelected && partnerLocationListFiltered.length === 0">
                            <i>Seleziona Brand e Provincia per vedere i risultati</i>
                        </div>
                        <div class="p-3" v-if="this.brandSelected && partnerLocationListFiltered.length === 0">
                            <i>Non sono presenti delle officine convenzionate per il brand e la provincia selezionati. Prova a selezionare un'altra provincia vicino a te.</i>
                            <p class="mt-2 font-weight-bolder">Ricordati che se scegli WeRepair ma ti rechi presso un riparatore non convenzionato, la Compagnia ti rimborserà i costi di riparazione, previa applicazione di una penale del 30%, fino ad un massimo di 1.000€.</p>
                        </div>

                    </div>
                </div>
                <div class="col-12 col-md-7 order-1 order-md-2">
                    <adaptive-spinner
                            class="p-5"
                            v-if="partnerLocationLoading"/>
                        
                    <the-officine-convenzionate-map :partnerLocation="partnerLocationListFiltered" v-else/>
                          
                </div>
            </div>
            
        </div>
        <div v-if="error" class="p-5 m-5 d-flex justify-content-center align-items-center">
            <i class="fal fa-times fa-4x text-danger"></i>
        </div>
    </div>

</template>

<script>
import {gmapApi} from 'gmap-vue';
import ArApiService from "../AreaRiservata/AreaRiservata.service";
import Multiselect from "vue-multiselect";
import TheOfficineConvenzionateItem from "./TheOfficineConvenzionateItem";
import TheOfficineConvenzionateMap from "./TheOfficineConvenzionateMap";
import AdaptiveSpinner from "../Common/AdaptiveSpinner";
import {bus} from "../Preventivo/_Common/EventBus";

export default {
    name: "TheOfficineConvenzionate",
    components: {
        Multiselect,
        TheOfficineConvenzionateItem,
        TheOfficineConvenzionateMap,
        AdaptiveSpinner
    },
    data() {
        return {
            brands: [],
            provinces: [],
            partnerLocationList: null,
            partnerLocationListFiltered: [],
            brandLoading: true,
            provincesLoading: true,
            partnerLocationLoading: true,
            provinceSelected: null,
            brandSelected: null,
            error: false,
            partnerLocationSelected: null,
            userCenter: null
        }
    },

    mounted() {
        ArApiService.getPartnerBrands().then( (res)=>{
            this.brands = res.data.brands.map( item => ({label: item, value: item}) );
            this.brandLoading = false;
        });

        ArApiService.getPartnerProvinces().then( (res)=>{
            const items = res.data.provinces;
            let keys = Object.keys(items);
            this.provinces = keys.map(item => ({label: items[item], value: item}));
            this.provincesLoading = false;
        });

        ArApiService.getPartnerLocation({}).then( (res)=>{
           
            this.partnerLocationList = res.data.partners;
            //this.partnerLocationListFiltered = this.partnerLocationList;
            this.partnerLocationLoading = false;
            
            this.$nextTick(() => {
                bus.$on('scrollToLocation', (index) => {
                    this.partnerLocationSelected = index
                    const el = this.$refs[index]
                    const top = el[0].$el.offsetTop
                    this.$refs.scrollableContainer.scrollTo(0, top)
                })
            })
        });
        

    },
    computed: {

    },
    methods: {
        async onInfiniteScroll(e) {
            const scrollEnd = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight;
            
            /* if (scrollEnd && !this.scrollcomponentLoading) {
                this.scrollcomponentLoading = true
                this.skip = this.skip + 10
                await this.onInstallerSearch(true)
                setTimeout(() => this.scrollcomponentLoading = false, 1000);
            } */
        },
        filterpartnerLocation(){
            this.partnerLocationSelected = null;

            let partnerLocationToOrder= this.partnerLocationList.filter((item)=> {
                    if(!this.brandSelected) return true;
                    if(item.isUniversalRepairer) return true;
                    return item.officialBrands.map(item => item.toLowerCase()).includes(this.brandSelected.value.toLowerCase()) ||
                            item.otherBrands.map(item => item.toLowerCase()).includes(this.brandSelected.value.toLowerCase());
                })
                .filter((item)=> {
                    if(!this.provinceSelected) return true;
                    return item.administrativeCode === this.provinceSelected.value;
                });

                const self = this;    
                if(partnerLocationToOrder.length > 0) {
                    partnerLocationToOrder.sort(function(a,b){
                        return a.officialBrands.map(item => item.toLowerCase()).includes(self.brandSelected.value.toLowerCase()) ? -1 : 1;
                    });

            }
            this.partnerLocationListFiltered = partnerLocationToOrder;

            bus.$emit('filterpartnerLocation', true)
        },
        onPartnerLocationSelected(index){
            this.partnerLocationSelected = index;
        },
        async onLocalizeClick() {
            this.loading = true
            
            if ('geolocation' in navigator) {
                try {
                    const userLocation = await this.getPosition()
                    console.log(userLocation.coords)
                    bus.$emit('onUserNewLocation', userLocation.coords)
                    this.loading = false
                } catch (e) {
                    this.geolocationSupported = false
                    this.loading = false
                    console.error(e)
                }
            } else {
                // Il browser non supporta la geolocalizzazione
                this.geolocationSupported = false
            }
        },
        getPosition(options) {
            return new Promise((resolve, reject) =>
                navigator.geolocation.getCurrentPosition(resolve, reject, options)
            );
        }
        
    },
    beforeDestroy() {
        bus.$off('scrollToLocation')
    },

}

</script>

<style scoped lang="scss">
.scrollableContainer {
  min-height: 400px;  
  max-height: 550px;
}
</style>
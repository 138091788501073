<template>
    <div class="w-100 form-placehoder-loading">
        <i class="fal fa-spin fa-circle-notch text-muted"></i>
    </div>
</template>

<script>

    export default {
        name: "FormPlaceholderLoading",
    }
</script>



<template>
    <div class="alert-container container-fluid fixed-top shadow border-bottom py-3" :class="alertClass">
        <div class="mx-3 p-0">
            <div class="d-flex justify-content-start align-items-center">
                <div class="p-0">
                    <i class="p-3 fal fa-2x text-white" :class="alertIcon"></i>
                </div>
                <div class="flex-grow-1">
                    <p class="text-white m-2 ml-3" style="line-height: 1.2em">
                        <b>{{GET_ALERT_BANNER.title}}</b><br/>
                        {{GET_ALERT_BANNER.message}}
                    </p>

                </div>
                <a v-if="GET_ALERT_BANNER.url"
                   class="btn btn-sm btn-outline-light mr-0"
                   @click.prevent="onBannerCtaClick" v-loading-button="ux.loading > 0">
                    {{GET_ALERT_BANNER.cta}}</a>

                <i class="fal fa-lg fa-times clickable text-light alert-dismiss shadow-sm m-3"
                   v-if="GET_ALERT_BANNER.dismiss"
                   @click="onBannerDismiss"></i>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

    export default {
        name: 'BannerAlert',
        computed: {
            ...mapState(['ux']),
            ...mapGetters(['GET_ALERT_BANNER']),
            alertClass() {
                return `alert-${this.GET_ALERT_BANNER.kind}`
            },
            alertIcon() {
                switch (this.GET_ALERT_BANNER.kind) {
                    case 'info':
                        return 'fa-info-circle';
                    case 'success':
                        return 'fa-grin';
                    case 'danger':
                        return 'fa-frown';
                    case 'warning':
                        return 'fa-surprise';

                }

            }
        },
        methods: {
            ...mapMutations(['REMOVE_BANNER']),
            ...mapActions(['DD_RESTORE_QUOTATION', 'DD_GET_QUOTATION']),

            onBannerDismiss() {
                this.REMOVE_BANNER();
            },
            onBannerCtaClick() {
                this.$router.go(this.GET_ALERT_BANNER.url);
                this.REMOVE_BANNER();
            }
        }

    }
</script>

import axios from "axios"
import {API_URL} from "../../../Common/constants"
import Vue from 'vue';
import store from '../../../Store';

// Necessario per gestire il prodotto dottorpet in caso di nuova polizza e dottordog in caso di rinnovo.
let getProductApiUrl = () =>{
    if((store.state.ux.currentProduct === 'dottordog' || Vue.currentProduct === 'dottordog') && !store.state.ux.isInAr){
        return 'dottorpet';
    }

    return store.state.ux.currentProduct || Vue.currentProduct;
}

// Quando viene richiamato un preventivatore la sua root dell'app
// ha una proprietà data che ci dice in che prodotto siamo
const GlobalApiService = {

    formConfiguration: (_quotationId) => {

        return axios.get(`${API_URL}/${getProductApiUrl()}/formConfig`, {
            params: {
                quotationId: _quotationId
            },

        }).catch(error => {
            throw new Error(error);
        });
    },

    // Recupera l'anagrafica di un utente, necessaria per riempire l'anagrafica quando un utente è loggato
    getAnagrafica: function () {

        return axios.get(`${API_URL}/quotation/getRegistryUserData`).catch(error => {
            throw new Error(error);
        });
    },

// Recupera i metodi di pagamento disponibili per l'id preventivo
    getPayMethods: function (_quotationId) {


        return axios.get(`${API_URL}/quotation/checkoutConfig`, {
            params: {
                quotationId: _quotationId,
                saveccalias: false
            },
        })
    },

    checkOut: function (_data) {
        return axios.post(`${API_URL}/${getProductApiUrl()}/checkout`,
            {...(_data)}).catch(error => {
            throw new Error(error);
        });
    },
    
    getOtpConfig: function (_quotationId) {

        return axios.get(`${API_URL}/otp/config`, {
            params: {
                QuotationId: _quotationId
            },
        })
    },

    editOtpEmail: function (_data) {

        return axios.post(`${API_URL}/otp/editEmail`,
            {...(_data)}).catch(error => {
                throw new Error(error);
        })
    },

    editOtpPhone: function (_data) {

        return axios.post(`${API_URL}/otp/editPhone`,
            {...(_data)}).catch(error => {
                throw new Error(error);
        })
    },

    sendOtp: function (_data) {
        
        return axios.post(`${API_URL}/otp/send`,
            {...(_data)}).catch(error => {
                throw new Error(error);
        })
                   
    },

    remindmelaterOtp: function (_data) {
        
        return axios.post(`${API_URL}/otp/remindmelater`,
            {...(_data)}).catch(error => {
                throw new Error(error);
        })
                   
    },

    verifyOtp: function (_data) {

        return axios.post(`${API_URL}/otp/verify`,
            {...(_data)}).catch(error => {
                throw new Error(error);
        })
    },

    getFlags: (_quotationId) => {

        return axios.get(`${API_URL}/account/getFlags`, {
            params: {
                quotationId: _quotationId
            },

        }).catch(error => {
            throw new Error(error);
        });
    },

    // Conferma l'indirizzo email usando il parametro nella mail ricevuta dopo aver compilato l'anagrafica in caso di utente non loggato
    confirmEmail: function (_token) {

        return axios.post(`${API_URL}/${getProductApiUrl()}/confirmEmailFromQuotation`, {
                token: _token
            }
        ).catch(error => {
            throw new Error(error);
        });

    },

    // Recupera il quotation ID usando il parametro nella mail di "Salva preventivo"
    getSavedQuotationId: function (_token) {

        return axios.post(`${API_URL}/${getProductApiUrl()}/confirmSaveEmail`, {
                token: _token
            }
        ).catch(error => {
            throw new Error(error);
        });

    },

    saveQuotation: function (_data) {
        return axios.post(`${API_URL}/${getProductApiUrl()}/saveQuotation`,
            {...(_data)}).catch(error => {
            throw new Error(error);
        });

    },
    getDealerEmails: function (_data) {

        return axios.get(`${API_URL}/quotation/GetDealerEmails`, {
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });
    },
   
    setDealerEmail: function (_data) {
        return axios.post(`${API_URL}/quotation/setDealerEmails`,
            {...(_data)}).catch(error => {
            throw new Error(error);
        });

    }
};

export default GlobalApiService;

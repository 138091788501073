import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../Store';
import SelligentPusher from "../Common/selligentPusher";


const ArBase = () => import("./ArBase")
const ArPolicy = () => import("./Components/Polizza/ArPolicy")
const RcRefund = () => import("./Components/Polizza/RcRefund/RcRefund")
const RcRefundIban = () => import("./Components/Polizza/RcRefund/RcRefundIban")
const RcRefundDocuments = () => import("./Components/Polizza/RcRefund/RcRefundDocuments")
const RcRefundThankyou = () => import("./Components/Polizza/RcRefund/RcRefundThankyou")
const LostPlate = () => import("./Components/Polizza/LostPlate/LostPlate")
const LostPlateDocuments = () => import("./Components/Polizza/LostPlate/LostPlateDocuments")
const LostPlateCheckout = () => import("./Components/Polizza/LostPlate/LostPlateCheckout")
const LostPlateThankyou = () => import("./Components/Polizza/LostPlate/LostPlateThankyou")
const ArUser = () => import("./Components/User/ArUser")
const ArReactivate = () => import("./Components/Polizza/ReactivatePolicy/ArReactivate")
const ArReactivateThankyou = () => import("./Components/Polizza/ReactivatePolicy/ArReactivateThankyou")
const ArSuspend = () => import("./Components/Polizza/SuspendPolicy/ArSuspend")
const ArSuspendDocuments = () => import("./Components/Polizza/SuspendPolicy/ArSuspendDocuments")
const ArSuspendConfirm = () => import("./Components/Polizza/SuspendPolicy/ArSuspendConfirm")
const ArSuspendThankyou = () => import("./Components/Polizza/SuspendPolicy/ArSuspendThankyou")
const ArAddGuarantees = () => import("./Components/Polizza/AddGuarantees/ArAddGuarantees")
const ArRighttoreconsider = () => import("./Components/Polizza/RightToReconsider/ArRighttoreconsider")
const ArChangeVehicle = () => import("./Components/Polizza/ChangeVehicle/ArChangeVehicle")
const ArChangeVehicleData = () => import("./Components/Polizza/ChangeVehicle/ArChangeVehicleData")
const ArChangeVehicleDocuments = () => import("./Components/Polizza/ChangeVehicle/ArChangeVehicleDocuments")
const ArChangeVehicleCheckout = () => import("./Components/Polizza/ChangeVehicle/ArChangeVehicleCheckout")
const ArChangeVehicleThankyou = () => import("./Components/Polizza/ChangeVehicle/ArChangeVehicleThankyou")
const ArChangeOwner = () => import("./Components/Polizza/ChangeOwner/ArChangeOwner")
const ArChangeOwnerOwner = () => import("./Components/Polizza/ChangeOwner/ArChangeOwnerOwner")
const ArChangeOwnerBundles = () => import("./Components/Polizza/ChangeOwner/ArChangeOwnerBundles")
const ArChangeOwnerAddons = () => import("./Components/Polizza/ChangeOwner/ArChangeOwnerAddons")
const ArChangeOwnerDataOwner = () => import("./Components/Polizza/ChangeOwner/ArChangeOwnerDataOwner")
const ArChangeOwnerDataEmail = () => import("./Components/Polizza/ChangeOwner/ArChangeOwnerDataEmail")
const ArChangeOwnerDocuments = () => import("./Components/Polizza/ChangeOwner/ArChangeOwnerDocuments")
const ArChangeOwnerCheckout = () => import("./Components/Polizza/ChangeOwner/ArChangeOwnerCheckout")
const ArChangeOwnerThankyou = () => import("./Components/Polizza/ChangeOwner/ArChangeOwnerThankyou")
const ArChangeVehicleBundles = () => import("./Components/Polizza/ChangeVehicle/ArChangeVehicleBundles")
const ArChangeVehicleAddons = () => import("./Components/Polizza/ChangeVehicle/ArChangeVehicleAddons")
const ArUserAddressAlert = () => import("./Components/User/Components/ArUserAddressAlert")
const ArUserAddressDocuments = () => import("./Components/User/Components/ArUserAddressDocuments")
const ArUserAddressCheckout = () => import("./Components/User/Components/ArUserAddressCheckout")
const ArUserAddressThankyou = () => import("./Components/User/Components/ArUserAddressThankyou")
const ArAddGuaranteesCheckout = () => import("./Components/Polizza/AddGuarantees/ArAddGuaranteesCheckout")
const ArAddGuaranteesThankyou = () => import("./Components/Polizza/AddGuarantees/ArAddGuaranteesThankyou")
const ArPendingOperations = () => import("./Components/ArPendingOperations/ArPendingOperations")
const ArPendingOperationsDocuments = () => import("./Components/ArPendingOperations/ArPendingOperationsDocuments")
const ArPendingOperationsCheckout = () => import("./Components/ArPendingOperations/ArPendingOperationsCheckout")
const ArPendingOperationsThankyou = () => import("./Components/ArPendingOperations/ArPendingOperationsThankyou")
const ArAddGuaranteesDrivingKind = () => import("./Components/Polizza/AddGuarantees/ArAddGuaranteesDrivingKind")
const ArAddGuaranteesBundle = () => import("./Components/Polizza/AddGuarantees/ArAddGuaranteesBundle")
const ArAgentBoxAftertought = () => import("./Components/Polizza/AgentBoxAftertought/ArAgentBoxAftertought")
const ArAgentBoxAftertougthDocuments = () => import("./Components/Polizza/AgentBoxAftertought/ArAgentBoxAftertougthDocuments")
const ArAgentBoxAftertoughtThankyou = () => import("./Components/Polizza/AgentBoxAftertought/ArAgentBoxAftertoughtThankyou")
const ArSalesupdate = () => import("./Components/ArSalesupdate/ArSalesupdate")
const ArQuotations = () => import("@/AreaRiservata/Components/ArQuotations/ArQuotations")
const ArQuotationsList = () => import("@/AreaRiservata/Components/ArQuotations/ArQuotationsList")
const ArQuotationsSearch = () => import("@/AreaRiservata/Components/ArQuotations/ArQuotationsSearch")


Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: '/auth',
            component: ArBase,
            meta: {
                title: 'Area riservata',
                backButtonEnabled: true
            },
            children: [
                {
                    path: 'sales-update',
                    name: 'sales-update',
                    component: ArSalesupdate,
                    meta: {
                        title: `Dati di vendita`,
                        description: 'Aggiorna e consulta i dati di vendita dei tuoi veicoli nuovi ed usati',
                        backButtonEnabled: false,
                        titleBarDisabled: true
                    }
                },
                {
                    path: 'quotations',
                    name: 'quotations',
                    component: ArQuotations,
                    meta: {
                        title: `Preventivi`,
                        description: 'Cerca i preventivi dei tuoi clienti e i preventivi web',
                        backButtonEnabled: false,
                        titleBarDisabled: true
                    },
                    children:[
                        {
                            path: 'search',
                            name: 'search',
                            component: ArQuotationsList,
                            meta: {
                                backButtonEnabled: false,
                                titleBarDisabled: true
                            }
                        },
                        {
                            path: 'acquire',
                            name: 'acquire',
                            component: ArQuotationsSearch,
                            meta: {
                                backButtonEnabled: false,
                                titleBarDisabled: true
                            }
                        },
                    ]
                },
                {
                    path: 'polizza/:policyId',
                    name: 'polizza',
                    component: ArPolicy,
                    meta: {
                        title: `Calcola il prezzo dell'assicurazione`,
                        backButtonEnabled: true,
                        titleBarDisabled: true
                    },
                    children: [
                        {
                            path: 'cambio-veicolo',
                            name: 'cambio-veicolo',
                            component: ArChangeVehicle,
                            meta: {
                                title: `Sostituisci veicolo`,
                                backButtonEnabled: false,
                            },
                        },
                        {
                            path: 'cambio-veicolo-dati',
                            name: 'cambio-veicolo-dati',
                            component: ArChangeVehicleData,
                            meta: {
                                title: `Sostituisci veicolo`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'cambio-veicolo-pacchetti',
                            name: 'cambio-veicolo-pacchetti',
                            component: ArChangeVehicleBundles,
                            meta: {
                                title: `Sostituisci veicolo`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'cambio-veicolo-garanzie',
                            name: 'cambio-veicolo-garanzie',
                            component: ArChangeVehicleAddons,
                            meta: {
                                title: `Sostituisci veicolo`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'cambio-veicolo-documenti',
                            name: 'cambio-veicolo-documenti',
                            component: ArChangeVehicleDocuments,
                            meta: {
                                title: `Sostituisci veicolo`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'cambio-veicolo-checkout',
                            name: 'cambio-veicolo-checkout',
                            component: ArChangeVehicleCheckout,
                            meta: {
                                title: `Sostituisci veicolo`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'cambio-veicolo-grazie',
                            name: 'cambio-veicolo-grazie',
                            component: ArChangeVehicleThankyou,
                            meta: {
                                title: `Sostituisci veicolo`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'cambio-proprietario',
                            name: 'cambio-proprietario',
                            component: ArChangeOwner,
                            meta: {
                                title: `Cambia proprietario`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'cambio-proprietario-dati',
                            name: 'cambio-proprietario-dati',
                            component: ArChangeOwnerOwner,
                            meta: {
                                title: `Cambia proprietario`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'cambio-proprietario-pacchetti',
                            name: 'cambio-proprietario-pacchetti',
                            component: ArChangeOwnerBundles,
                            meta: {
                                title: `Cambia proprietario`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'cambio-proprietario-garanzie',
                            name: 'cambio-proprietario-garanzie',
                            component: ArChangeOwnerAddons,
                            meta: {
                                title: `Cambia proprietario`,
                                backButtonEnabled: true
                            },
                        },

                        {
                            path: 'cambio-proprietario-anagrafica',
                            name: 'cambio-proprietario-anagrafica',
                            component: ArChangeOwnerDataOwner,
                            meta: {
                                title: `Cambia proprietario`,
                                backButtonEnabled: true
                            },
                        },

                        {
                            path: 'cambio-proprietario-email',
                            name: 'cambio-proprietario-email',
                            component: ArChangeOwnerDataEmail,
                            meta: {
                                title: `Cambia proprietario`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'cambio-proprietario-documenti',
                            name: 'cambio-proprietario-documenti',
                            component: ArChangeOwnerDocuments,
                            meta: {
                                title: `Cambia proprietario`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'cambio-proprietario-checkout',
                            name: 'cambio-proprietario-checkout',
                            component: ArChangeOwnerCheckout,
                            meta: {
                                title: `Cambia proprietario`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'cambio-proprietario-grazie',
                            name: 'cambio-proprietario-grazie',
                            component: ArChangeOwnerThankyou,
                            meta: {
                                title: `Cambia proprietario`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'rimborso-rc',
                            name: 'rimborso-rc',
                            component: RcRefund,
                            meta: {
                                title: `Interrompi polizza`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'rimborso-rc-iban',
                            name: 'rimborso-rc-iban',
                            component: RcRefundIban,
                            meta: {
                                title: `Interrompi polizza`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'rimborso-rc-documenti',
                            name: 'rimborso-rc-documenti',
                            component: RcRefundDocuments,
                            meta: {
                                title: `Interrompi polizza`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'rimborso-rc-grazie',
                            name: 'rimborso-rc-grazie',
                            component: RcRefundThankyou,
                            meta: {
                                title: `Interrompi polizza`,
                                backButtonEnabled: true
                            },
                        },

                        // Targa smarrita
                        {
                            path: 'targa-smarrita',
                            name: 'targa-smarrita',
                            component: LostPlate,
                            meta: {
                                title: `Cambia targa per smarrimento`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'targa-smarrita-documenti',
                            name: 'targa-smarrita-documenti',
                            component: LostPlateDocuments,
                            meta: {
                                title: `Cambia targa per smarrimento`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'targa-smarrita-checkout',
                            name: 'targa-smarrita-checkout',
                            component: LostPlateCheckout,
                            meta: {
                                title: `Cambia targa per smarrimento`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'targa-smarrita-grazie',
                            name: 'targa-smarrita-grazie',
                            component: LostPlateThankyou,
                            meta: {
                                title: `Cambia targa per smarrimento`,
                                backButtonEnabled: true
                            },
                        },
                        // Sospendi polizza
                        {
                            path: 'sospendi',
                            name: 'sospendi',
                            component: ArSuspend,
                            meta: {
                                title: `Sospendi polizza`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'sospendi-documenti',
                            name: 'sospendi-documenti',
                            component: ArSuspendDocuments,
                            meta: {
                                title: `Sospendi polizza`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'sospendi-conferma',
                            name: 'sospendi-conferma',
                            component: ArSuspendConfirm,
                            meta: {
                                title: `Sospendi polizza`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'sospendi-grazie',
                            name: 'sospendi-grazie',
                            component: ArSuspendThankyou,
                            meta: {
                                title: `Sospendi polizza`,
                                backButtonEnabled: true
                            },
                        },

                        // riattiva polizza
                        {
                            path: 'riattiva',
                            name: 'riattiva',
                            component: ArReactivate,
                            meta: {
                                title: `Riattiva polizza`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'riattiva-grazie',
                            name: 'riattiva-grazie',
                            component: ArReactivateThankyou,
                            meta: {
                                title: `Riattiva polizza`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'garanzie-guida',
                            name: 'garanzie-guida',
                            component: ArAddGuaranteesDrivingKind,
                            meta: {
                                title: `Cambia tipo di guida/aggiungi garanzie`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'garanzie-pacchetto',
                            name: 'garanzie-pacchetto',
                            component: ArAddGuaranteesBundle,
                            meta: {
                                title: `Aggiungi garanzie`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'garanzie',
                            name: 'garanzie',
                            component: ArAddGuarantees,
                            meta: {
                                title: `Aggiungi garanzie`,
                                backButtonEnabled: true
                            },
                        },

                        {
                            path: 'garanzie-checkout',
                            name: 'garanzie-checkout',
                            component: ArAddGuaranteesCheckout,
                            meta: {
                                title: `Aggiungi garanzie`,
                                backButtonEnabled: true
                            },
                        },

                        {
                            path: 'garanzie-grazie',
                            name: 'garanzie-grazie',
                            component: ArAddGuaranteesThankyou,
                            meta: {
                                title: `Aggiungi garanzie`,
                                backButtonEnabled: true
                            },
                        },

                        {
                            path: 'ripensamento',
                            name: 'ripensamento',
                            component: ArRighttoreconsider,
                            meta: {
                                title: `Rimborso RC per diritto di ripensamento`,
                                backButtonEnabled: false
                            },
                        },

                        // AGENTE recesso box
                        // Sospendi polizza
                        {
                            path: 'agente-recesso-box',
                            name: 'agente-recesso-box',
                            component: ArAgentBoxAftertought,
                            meta: {
                                title: `Diritto recesso Livello Adventure MOTO.APP`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'agente-recesso-documenti',
                            name: 'agente-recesso-documenti',
                            component: ArAgentBoxAftertougthDocuments,
                            meta: {
                                title: `Recesso box`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'agente-recesso-grazie',
                            name: 'agente-recesso-grazie',
                            component: ArAgentBoxAftertoughtThankyou,
                            meta: {
                                title: `Diritto di recesso box`,
                                backButtonEnabled: false
                            },
                        },
                    ]
                },
                {
                    path: 'user',
                    name: 'user',
                    component: ArUser,
                    meta: {
                        title: `Calcola il prezzo dell'assicurazione`,
                        backButtonEnabled: true
                    },
                    children: [
                        {
                            path: 'residenza-alert',
                            name: 'residenza-alert',
                            component: ArUserAddressAlert,
                            meta: {
                                title: `Cambio residenza`,
                                backButtonEnabled: false
                            },
                        },
                        {
                            path: 'residenza-documenti',
                            name: 'residenza-documenti',
                            component: ArUserAddressDocuments,
                            meta: {
                                title: `Cambio residenza`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'residenza-checkout',
                            name: 'residenza-checkout',
                            component: ArUserAddressCheckout,
                            meta: {
                                title: `Cambio residenza`,
                                backButtonEnabled: true
                            },
                        },
                        {
                            path: 'residenza-grazie',
                            name: 'residenza-grazie',
                            component: ArUserAddressThankyou,
                            meta: {
                                title: `Cambio residenza`,
                                backButtonEnabled: true
                            },
                        },
                    ]
                },
                {
                    path: 'operazioni-in-sospeso',
                    name: 'operazioni-in-sospeso',
                    component: ArPendingOperations,
                    meta: {
                        title: `Operazioni in sospeso`,
                        backButtonEnabled: false,
                        closeButtonDisabled: true
                    },
                    children: [
                        {
                            path: 'documenti',
                            name: 'operazioni-in-sospeso-documenti',
                            component: ArPendingOperationsDocuments,
                            meta: {
                                title: `Operazioni in sospeso`,
                                backButtonEnabled: true,
                                  closeButtonDisabled: true
                            },
                        },
                        {
                            path: 'checkout',
                            name: 'operazioni-in-sospeso-checkout',
                            component: ArPendingOperationsCheckout,
                            meta: {
                                title: `Operazioni in sospeso`,
                                backButtonEnabled: true,
                                  closeButtonDisabled: true
                            },
                        },
                        {
                            path: 'grazie',
                            name: 'operazioni-in-sospeso-grazie',
                            component: ArPendingOperationsThankyou,
                            meta: {
                                title: `Operazioni in sospeso`,
                                backButtonEnabled: false,
                                  closeButtonDisabled: true
                            },
                        },
                    ]
                },
            ]
        },
    ],

});

// Imposto il titolo della pagina e l'impostazione del pulsante indietro
router.beforeEach((to, from, next) => {
    // Serve per ora andrà tolto quando facciamo l'AR per tutti i prodotti
    store.dispatch('SET_CURRENT_PRODUCT', 'motoplatinum');
    store.dispatch('SET_VIEW_TITLE', to.meta.title);
    store.dispatch('SET_BACK_BUTTON_STATE', to.meta.backButtonEnabled);
    store.dispatch('SET_AR_STATE', to.path.includes('/auth/'));
    next();
});


// Controllo se l'utente puo entrare o meno in determinate pagine e da li faccio i redirect necessari
router.beforeEach((to, from, next) => {
    const _vuexStore = JSON.parse(window.sessionStorage.getItem('vuex'));
    if (_vuexStore && !_vuexStore.areariservata.actions.policyId && !to.params.policyId) {
        // window.location.href = "/Website/RicercaPolizze.aspx"
        next();
    } else {
        next();
    }
});

// Segnalo a selligent ogni cambio di pagina
router.afterEach((to, from)=>{
    try{
        let pusher = new SelligentPusher()
        pusher.add('page', window.location.pathname + '/#' + to.fullPath)
        pusher.push()
    } catch (e) {
    }
})

export {router}

import Vue from 'vue';
import Vuex from 'vuex';
import ux from './Modules/ux.store';
import bikeplatinum from '../Preventivo/Bikeplatinum/BikeplatinumQuotation.store';
import dottordog from '../Preventivo/Dottordog/DottordogQuotation.store';
import motoplatinum from '../Preventivo/Motoplatinum/MotoplatinumQuotation.store';
import multimobility from '../Preventivo/Multimobility/MultimobilityQuotation.store';
import anagrafica from './Modules/anagrafica.store';
import checkout from './Modules/checkout.store';
import quotation from './Modules/quotation.store';
import areariservata from './Modules/areariservata.store';
import arQuotations from '@/AreaRiservata/Components/ArQuotations/quotations.store'
import agente from './Modules/agente.store';
import createLogger from 'vuex/dist/logger';
import createPersistedState from 'vuex-persistedstate';
import * as numeral from 'numeral';
import * as moment from "moment";
import {MONTHS} from "@/Common/constants";


Vue.use(Vuex);

Vue.directive('uppercase', {
    update(el) {
        if (el.value) el.value = el.value.toUpperCase()
    },
});

Vue.directive('remove-spaces', {
    update(el) {
        el.value = el.value.trim()
    },
});

Vue.directive('capitalize', {
    update(el) {
        el.value = el.value.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        });
    },
});

Vue.directive('loading-button', {
    update: function (el, binding, vnode) {
        //do nothing if value not changed
        if (binding.oldValue === binding.value) {
            return;
        }

        if (binding.value === true) {
            if ($(el).find('.fa-spin').length === 0) {
                $(el).append('<i class="fa fa-circle-notch fa-spin"></i>');
            }
            $(el).attr('disabled','disabled');
        } else {
            $(el).find('.fa-spin').remove();
            $(el).removeAttr('disabled');
        }
    }
});

Vue.filter('currency', function (value) {
    const _value = typeof value === 'string' ? parseFloat(value) : value;
    if (!_value || _value === 0) return '0,00€';
    return `${numeral(_value).format('0.0,[00]')}€`;
});

Vue.filter('readableDate', function (value) {
    return value ? moment(value).format('DD/MM/YYYY') : "";
});

Vue.filter('readableMonth', function (value) {
    const found = MONTHS.find((el)=>{
        return el.key == value
    })
    return value ? found.value : "";
});

Vue.filter('readableDateTime', function (value) {
    return value ? moment(value).format('dddd, MMMM Do YYYY, h:mm:ss') : "";
});

// Aggiunge l'animazione ai messaggi di errore
Vue.directive('error-animation', {
    bind(el) {
        el.value = el.value.toUpperCase();
        // Wrappo l'elemento con l'animazione di vue
        const wrapper = document.createElement('animation');
        wrapper.setAttribute('name', 'jump');
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    },
});

const logger = createLogger({
    collapsed: true,
    logActions: true,
});

const _returnPlugins = () => {
    if (process.env.NODE_ENV !== 'production') {
        return [logger,
            createPersistedState({
                storage: {
                    getItem: key => window.sessionStorage.getItem(key),
                    setItem: (key, value) => window.sessionStorage.setItem(key, value),
                    removeItem: key => window.sessionStorage.removeItem(key)
                }
            })]
    } else {
        return [createPersistedState({
            storage: {
                getItem: key => window.sessionStorage.getItem(key),
                setItem: (key, value) => window.sessionStorage.setItem(key, value),
                removeItem: key => window.sessionStorage.removeItem(key)
            }
        })]
    }
};

export default new Vuex.Store({
    modules: {
        ux,
        quotation,
        bikeplatinum,
        dottordog,
        motoplatinum,
        multimobility,
        anagrafica,
        checkout,
        areariservata,
        agente,
        arQuotations
    },
    actions: {
        GLOBAL_FULL_RESET({state, commit}) {
            //commit('RESET_UX_STATE');
            commit('REMOVE_ERROR');
            commit('RESET_QUOTATION_STATE');
            commit('RESET_BIKEPLATINUM_STATE');
            commit('RESET_DOTTORDOG_STATE');
            commit('RESET_MOTOPLATINUM_STATE');
            commit('RESET_ANAGRAFICA_STATE');
            commit('RESET_CHECKOUT_STATE');
            commit('RESET_AGENTE_STATE');
        },

        GLOBAL_QUOTATION_RESET({state, commit}) {
            commit('REMOVE_ERROR');
            commit('RESET_QUOTATION_STATE');
            commit('RESET_BIKEPLATINUM_STATE');
            commit('RESET_DOTTORDOG_STATE');
            commit('RESET_MOTOPLATINUM_STATE');
            commit('RESET_ANAGRAFICA_STATE');
            commit('RESET_CHECKOUT_STATE');
        },
    },
    getters: {
        IS_IFRAMED() {
            return window.self !== window.top;
        }
    },
    plugins: _returnPlugins()

});




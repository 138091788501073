import * as moment from 'moment';

export default function blogHandler() {

    const dates = document.querySelectorAll('.blog-date');
    dates.forEach(e => {
        e.innerText = moment(e.innerText, "DD/MM/YYYY HH:mm:ss").fromNow();
    })

    const date = document.querySelectorAll('.blog-date-extended');
    date.forEach(e => {
        e.innerText = moment(e.innerText, "DD/MM/YYYY HH:mm:ss").format("LL");
    })

    //////
    const blogSidebarToggle = document.querySelector('#blogSidebarToggle');
    const blogSidebarContainer = document.querySelector('#blogSidebarContainer');

    blogSidebarToggle && blogSidebarContainer ? blogSidebarToggle.onclick = () => blogSidebarContainer.classList.toggle('d-none') : null;

    // Riordino gli articoli del blog per aggirare la disposizione da css delle cards di bootstrap
    // per visualizzare gli articoli in ordine cronologico da left to right da top to bottom invece
    // che da top to bottom e left to right
    const articles = document.querySelectorAll('.blog-card');
    const columns = 3;

    if (articles.length > 0) {
        let cardContainer = document.querySelector('.blog-card-columns');
       if(cardContainer){
           const reordered = reorder()
           while (cardContainer.firstChild) {
               cardContainer.removeChild(cardContainer.lastChild);
           }
           for (let i = 0; i < reordered.length; i++) {
               cardContainer.appendChild(reordered[i]);
           }
           cardContainer.classList.remove('sr-only');
       }
    }

    function reorder() {
        const _reordered = []
        let col = 0;
        while (col < columns) {
            for (let i = 0; i < articles.length; i += columns) {
                let _val = articles[i + col];
                if (_val != undefined) {
                    _reordered.push(_val);
                }
            }
            col++;
        }

        return _reordered;
    }
}

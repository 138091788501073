import axios from 'axios';
import {API_URL} from '../../Common/constants';



const MyApiService = {

    getQuotation: function (_data) {

        return axios.get(`${API_URL}/multimobility/getQuotation`, {
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });

    },

    getQuotationData: function (_quotationId) {

        return axios.get(`${API_URL}/multimobility/getQuotationData`, {
            params: {
                quotationId: _quotationId,
            },

        }).catch(error => {
            throw new Error(error);
        });

    },

    confirmQuotation: function (_data) {

        return axios.post(`${API_URL}/multimobility/confirmQuotation`,
            {
                ...(_data)
            }).catch(error => {
            throw new Error(error);
        });

    },

    confirmRegistration: function (_data) {
        return axios.post(`${API_URL}/multimobility/confirmRegistration`,
            {...(_data)}).catch(error => {
            throw new Error(error);
        });

    },

};

export default MyApiService;


export default function careersHandler() {

    // rendiamo globali le funzioni
    window.filterCareer = filterCareer;
    window.filterSelectCareer = filterSelectCareer;

}

const departments = document.querySelectorAll('.pill-filter');
const NoCareersToggle = ( bool ) => {

    const NoCareers = document.getElementById('NoCareers');
    if(NoCareers){
        NoCareers.style.display = bool ?'flex': 'none';
    }

};

export function filterCareer( el ) {

    const careers = document.querySelectorAll('.row-career');
    const department =  el;
    const className = 'active';
    const select = document.getElementById('select-careers');
    let careerssLength = 0;

    if( department == null){
        Array.prototype.map.call( careers,( c ) => { c.style.display = 'flex'; });
        Array.prototype.map.call( departments,( d ) => {
            d.classList.remove( className );
        });
        NoCareersToggle(false);
        return false;
    }

    if( department.classList.contains( className ) ){

        department.classList.remove( className );
        Array.prototype.map.call( careers,( c ) => { c.style.display = 'flex'; });
        select.value = '';
        NoCareersToggle(false);
    } else {

        // Rimuove la classe 'active' a tutti i  department
        Array.prototype.map.call( departments,( d ) => {
            d.classList.remove( className );
        });
        // Aggiungi la classe 'active' department corrente
        department.classList.add( className );

        // mostra i lavoriri filtrati
        Array.prototype.map.call( careers,( c ) => {
            c.style.display = c.dataset.careerDepartment == department.dataset.department ? 'flex' : 'none';
            careerssLength = c.style.display == 'flex' ? ++careerssLength : careerssLength;
        });

        if( careerssLength == 0 ){
            NoCareersToggle(true);
        } else {
            NoCareersToggle(false);
        }
        select.value = department.dataset.department;

    }

    filterSelectCareer( select , true);

}

export function filterSelectCareer( el, flag ){
    if( flag === undefined ){ flag = false;}

    const icon = document.getElementById('ico-careers-filter');
    let department = null;
    const toggleIcoFilter = ( bool) => document.getElementById('IcoFilter').style.display = bool ? 'inline-block': 'none';

    icon.className = '';
    toggleIcoFilter(true);

    switch ( el.value ) {
        case 'support':
            icon.className = 'fal fa-user-headset';
            break;
        case 'claims':
            icon.className = 'fal fa-balance-scale';
            break;
        case 'hr':
            icon.className = 'fal fa-users';
            break;
        case 'it':
            icon.className = 'fal fa-server';
            break;
        case 'insurance':
            icon.className = 'fal fa-chart-line';
            break;
        case 'organisation':
            icon.className = 'fal fa-sliders-v';
            break;
        case 'marketing':
            icon.className = 'fal fa-megaphone';
            break;
        case 'sales':
            icon.className = 'fal fa-handshake';
            break;
        case 'telematics':
            icon.className = 'fal fa-car-battery';
            break;
        case 'datalab':
            icon.className = 'fal fa-brain';
            break;
        case 'accounting':
            icon.className = 'fal fa-abacus';
            break;
        case 'antifraud-legal-compliance':
            icon.className = 'fal fa-gavel';
            break;
        case 'strategy':
            icon.className = 'far fa-chess-knight';
            break;
        default:
            toggleIcoFilter(false);
            break;
    }

    Array.prototype.map.call( departments,( d ) => {
        if(d.dataset.department == el.value) {
            department = d;
        }
    });

    !flag ? filterCareer(department) : null;

}


//Seleziona vantaggio
$(document).ready(function () {
    $(".lista-vantaggi .vantaggio").on("click", function () {
        if (!$(this).hasClass("active")) {
            $(this).each(function () {
                $(".lista-vantaggi .vantaggio").removeClass("active");
                $(".lista-vantaggi .vantaggio .info p").slideUp("100");
            });

            $(this).addClass("active");
            $(".lista-vantaggi .vantaggio.active .info p").slideDown("100");
        } else {
            $(this).removeClass("active");
            $(".lista-vantaggi .vantaggio .info p").slideUp("100");
        } 

    });
});
// Selelziona team
$(document).ready(function () {
    $("#PosizioniAperte .pill-filter").on("click", function () {

        var team = $(this).attr("data-department");

        if ($(this).hasClass("active")) {
            $(".description-team .team").each(function () {
                $(this).removeClass("active");
            });

            $(".description-team ." + team + "").addClass("active");
        } else {
            $(".description-team ." + team + "").removeClass("active");
        }

    });

    //MOBILE --------------------------------------------

    $("#select-careers").on("click", function () {

        var team = $(this).val();

        $(".description-team .team").each(function () {
            $(this).removeClass("active");
        });

        $(".description-team ." + team + "").addClass("active");

    });
});
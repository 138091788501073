<template>
    <transition name="fade" appear mode="out-in">
        <div class="quote-summary sticky-top overflow-hidden">

            <transition name="fade">
                <div class="card rounded bg-transparent border-secondary" v-if="DD_SELECTED_BUNDLE">
                    <div class="card-header">
                        <p class="h4 text-secondary mb-0">
                            <i class="fal fa-file-certificate"></i> La polizza
                            <a v-if="$route.name !== 'thank-you' && $route.name !== 'quotazione' && DD_TOKEN_DATA.urlprod !== 'facileit'"
                               @click="goTo('quotazione')"
                               class="edit-link">Modifica</a>

                        </p>
                    </div>
                    <div class="card-body d-flex justify-content-between border border-bottom">
                        <p>
                            <span class="h4 mb-0">Totale</span><br/>
                            <span class="text-secondary font-weight-bold">per un anno</span>
                        </p>
                        <p class="d-flex align-items-center mb-0">
                            <span class="h3 text-secondary font-weight-bold mb-0"
                              :class="{'discounted' : QUOTATION_DATA.discountCodeIsValid && isDiscounted }">
                              {{DD_TOTAL_GROSS_AMOUNT | currency }}
                            </span>
                        </p>
                    </div>
                    <div class="card-body d-flex justify-content-between align-items-center border border-bottom"
                         v-if="QUOTATION_DATA.discountCodeIsValid">
                        <p class="lead mb-0">
                            <span v-if="isDiscounted">Con sconto</span><br v-if="isDiscounted"/> <b>"{{QUOTATION_DATA.discountCodeDescription}}"</b>
                        </p>
                        <span v-if="isDiscounted" class="h3 text-primary font-weight-bold mb-0">
                                {{DD_TOTAL_AMOUNT | currency }}
                        </span>
                    </div>
                    <div class="card-body">
                       <small class="">Include:</small>
                        <dl class="pl-3 ml-1" >
                            <template>
                                <dt class="p-0" style="line-height: 1;">
                                    <small class="text-secondary font-weight-bold">- Dottorpet {{ DD_SELECTED_BUNDLE.title }}</small>
                                </dt>
                            </template>
                            <template >
                                <dt v-for="addon in DD_SELECTEDS_ADDONS" class="p-0" style="line-height: 1;">
                                    <small class="text-secondary font-weight-bold">- {{ addon.title }}</small>
                                </dt>
                            </template>
                            <template>
                                <div class="ml-1">
                                    <small class="font-weight-bold">Garanzie</small><br/>
                                    <dt v-for="(garanzia, index) in DD_SELECTED_BUNDLE.garanzie">
                                        <small class="d-block text-muted" v-if="garanzia != 'No'">- {{DD_VERSIONS_LABELS[index].name}}</small>
                                    </dt>

                                </div>
                            </template>
                        </dl>
                    </div>
                </div>
            </transition>


            <div class="summary-header mt-0" @click="onpetDataSectionClick" :class="{'clickable' : DD_PET_DATA}">
                <span class="lead m-0" :class="{'text-secondary' : DD_PET_DATA.sex}">
                  <i class="fal paw-alt fa-paw-alt"></i>
                  Il pet
                </span>
                <i class="fas fa-chevron-down text-primary"
                   :class="{'fa-chevron-up' : summary.petDetailsSectionOpen}"></i>
            </div>

            <template v-if="summary.petDetailsSectionOpen && DD_PET_DATA">
                <div class="d-flex justify-content-between summary-table-row" v-if="DD_PET_DATA.sex">
                    <small class="summary-value-label">Sesso</small>
                    <small class="summary-value">{{DD_PET_DATA.sex === 'M' ? 'Maschio' : 'Femmina'}}</small>
                </div>

                <div class="d-flex justify-content-between summary-table-row" v-if="DD_BREED_NAME && DD_PET_KIND != 'coniglio'">
                    <small class="summary-value-label">Razza</small>
                    <small class="summary-value">{{DD_BREED_NAME}}</small>
                </div>

                <div class="d-flex justify-content-between summary-table-row" v-if="DD_PET_DATA.petBirth">
                    <small class="summary-value-label">Data di nascita</small>
                    <small class="summary-value">{{DD_PET_DATA.petBirth}}</small>
                </div>

                <div class="d-flex justify-content-between summary-table-row" v-if="DD_PET_DATA.petName">
                    <small class="summary-value-label">Nome</small>
                    <small class="summary-value">{{DD_PET_DATA.petName}}</small>
                </div>

                <div class="d-flex justify-content-end" v-if="DD_PET_DATA.petName">
                    <a v-if="$route.name !== 'thank-you'"
                       @click="goTo('pet')"
                       class="edit-link">Modifica</a>
                </div>

            </template>


            <div class="overflow-hidden">
                <transition name="fade">
                    <div>
                        <div class="summary-header" @click="onAnagraficaSectionClick"
                             :class="{'clickable' : GLOBAL_ANAGRAFICA_CONTRACTOR}">
                            <span class="lead m-0 text-secondary"
                                  :class="{'text-muted' : !GLOBAL_ANAGRAFICA_CONTRACTOR}">
                              <i class="fal fa-id-card"></i>
                              Dati anagrafici
                            </span><i class="fa fa-chevron-down primary"
                                      :class="{'fa-chevron-up' : summary.anagraficaSectionOpen}"></i>
                        </div>
                        <template
                                v-if="summary.anagraficaSectionOpen && GLOBAL_ANAGRAFICA_CONTRACTOR.name || summary.anagraficaSectionOpen && GLOBAL_ANAGRAFICA_CONTRACTOR.surname">
                            <p class="summary-subtitle">Il contraente</p>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_CONTRACTOR.name">
                                <small class="summary-value-label">Nome</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_CONTRACTOR.name}}</small>
                            </div>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_CONTRACTOR.surname">
                                <small class="summary-value-label">{{!GLOBAL_ANAGRAFICA_CONTRACTOR.isCompany ? 'Cognome'
                                    : 'Ragione sociale'}}</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_CONTRACTOR.surname}}</small>
                            </div>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_CONTRACTOR.fiscalCode">
                                <small class="summary-value-label">Codice fiscale</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_CONTRACTOR.fiscalCode}}</small>
                            </div>
                            <div class="d-flex justify-content-end" v-if="!GLOBAL_LOGGED_USER">
                                <a v-if="$route.name !== 'thank-you'"
                                   @click="goTo('anagrafica/contraente')"
                                   class="edit-link">Modifica</a>
                            </div>
                        </template>
                        <template v-if="GLOBAL_ANAGRAFICA_OWNER.name && !anagrafica.isOwner">
                            <p class="summary-subtitle">Il proprietario</p>
                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_OWNER.name">
                                <small class="summary-value-label">Nome</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_OWNER.name}}</small>
                            </div>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_OWNER.surname">
                                <small class="summary-value-label">{{!GLOBAL_ANAGRAFICA_OWNER.isCompany ? 'Cognome'
                                    : 'Ragione sociale'}}</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_OWNER.surname}}</small>
                            </div>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_OWNER.fiscalCode">
                                <small class="summary-value-label">Codice fiscale</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_OWNER.fiscalCode}}</small>
                            </div>

                            <div class="d-flex justify-content-end">
                                <a v-if="$route.name !== 'thank-you'"
                                   @click="goTo('anagrafica/proprietario')"
                                   class="edit-link">Modifica</a>
                            </div>
                        </template>
                    </div>
                </transition>
            </div>

        </div>

    </transition>
</template>

<script>
    import {mapGetters, mapState} from 'vuex'
    import {GaEvents} from "../../../Common/AnalyticsMethods";

    export default {
        name: 'dd-summary',
        data() {
            return {
                summary: {
                    petDetailsSectionOpen: true,
                    anagraficaSectionOpen: true,

                }
            }
        },
        computed: {
            ...mapGetters([
                'DD_PET_DATA',
                'QUOTATION_DATA',
                'DD_BREED_NAME',
                'DD_SELECTED_BUNDLE',
                'DD_PET_KIND',
                'GLOBAL_ANAGRAFICA_CONTRACTOR',
                'GLOBAL_ANAGRAFICA_OWNER',
                'DD_ROUTER',
                'GLOBAL_LOGGED_USER',
                'DD_TOKEN_DATA',
                'DD_TOTAL_AMOUNT',
                'DD_TOTAL_GROSS_AMOUNT',
                'DD_SELECTEDS_ADDONS',
                'DD_VERSIONS_LABELS',
            ]),

            ...mapState(['anagrafica']),

            isDiscounted(){
                return this.DD_TOTAL_AMOUNT !== this.DD_TOTAL_GROSS_AMOUNT
            }
        },
        methods: {
            onpetDataSectionClick() {
                this.summary.petDetailsSectionOpen = this.DD_PET_DATA ? !this.summary.petDetailsSectionOpen : false;
            },
            onAnagraficaSectionClick() {
                this.summary.anagraficaSectionOpen = this.GLOBAL_ANAGRAFICA_CONTRACTOR ? !this.summary.anagraficaSectionOpen : false;
            },
            goTo(_routeName) {
                GaEvents.modify('DD');
                this.$router.replace('/' + _routeName);
            }
        }
    }
</script>

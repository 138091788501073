<template>
    <div class="container-fluid m-0 p-0 pb-5">
        <the-progressbar-main-for-old-site v-if="fromNewSite"></the-progressbar-main-for-old-site>
        <progressbar-main v-else></progressbar-main>
        <div class="container p-0 overflow-hidden-desktop mt-0 mt-md-3"
        :class="{'min-vh-100 pb-5' : !IS_IFRAMED}">
            <div class="row no-gutters" :class="{'mb-5 pb-5' : IS_IFRAMED}">
                <div class="col-12">
                    <transition
                            name="slideLeft"
                            :duration="200"
                            mode="out-in">
                        <router-view/>
                    </transition>

                </div>
            </div>
        </div>
    </div> 

</template>

<script>

    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    // Tutte le funzioni comuni di init del preventivatore si trovano qui
    import {BootstrapOperations} from '../_Common/Mixins/CommonInitFunctions.mixin'
    import ProgressbarMain from "./Components/ProgressbarMain";
    import TheProgressbarMainForOldSite from "./Components/TheProgressbarMainForOldSite";

    export default {
        components: {
            ProgressbarMain,
            TheProgressbarMainForOldSite,
        },
        mixins: [BootstrapOperations],

        data() {
            return {
                currentProduct: 'motoplatinum',
                fromNewSite:false
            }
        },
        computed: {
            ...mapState(['ux', 'motoplatinum']),
            ...mapGetters([
                'GLOBAL_LOGGED_USER',
                'IS_IFRAMED'
            ])
        },
        mounted() {
            this.fromNewSite = this.$route.query.w && this.$route.query.type;
        },
        methods: {
            ...mapActions([
                'MP_GET_QUOTATION',
                'GLOBAL_GET_FORMS_CONFIGURATION',
                'GLOBAL_RESTORE_ANAGRAFICA'
            ]),
            ...mapMutations([
                'RESET_LOADING',
                'SET_BANNER', 'REMOVE_BANNER',
                'SET_URLPROD',
                'FULL_RESET'
            ]),
        }
    }


</script>



import {defineStore} from 'pinia'
import WebOrderService from "@/services/weborder.service";
import {INSUREE_MODEL, REGISTRY_MODEL} from "@/Common/constants";


export const useWebOrderStore = defineStore({
    id: 'weborder',
    state: () => ({
        quotation: {},
        registryModel: REGISTRY_MODEL,
        paymentConfig: {},
        webOrderLoading: false,
        errorState: {
            active: false,
            message: '',
            code: ''
        }
    }),
    getters: {
        webOrder(state) {
            return state.quotation.webOrder
        },
        isQuotationValid(state) {
            if (!state.quotation.webOrder) return false
            // Verifico ci siano tutte e 4 le opzioni richieste e la data di inizio
            return state.quotation.webOrder.insuranceStartTime.value
        },
        optionsIndexes(state) {
            let indexes = {...state.quotation.product.options}
            delete indexes['$id']
            Object.keys(indexes).forEach((key, index)=> {
                indexes[key] = index;
            });
            return indexes
        },
        error(state){
            return state.errorState;
        },
        currentUrlProd(state) {
            return state.quotation.webOrder.urlProd.toLocaleLowerCase();
        }
    },
    actions: {
        /**
         * Crea un nuovo webOrder
         * @param {string} product
         * @param {string} locale
         */
        async createNewWebOrder(product, locale) {
            this.webOrderLoading = true
            try {
                const response = await WebOrderService.new({
                    productName: product,
                    locale: locale
                })
                this.quotation = response.data
                await this.setWebOrder()
                this.webOrderLoading = false
                return this.quotation
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        /**
         * Aggiunge una nuova anagrafica al webOrder,
         * se viene passato un registry popola il nuovo registry coi valori passati
         * @param {array} roles i ruoli che vanno impostati pr ogni registry nuova
         * @param {array} options Le opzioni scelte dall'utente in fase di quotazione es.:[KID,ADULT, DAY, SINGLE, GROUP]
         * @param {object} [registry] Se si ha gia un'anagrafica da impostare (es.: Utente loggato) passarla qui
         */
        addNewRegistry(roles, options, registry) {

            // Recupero un nuovo model pulito, se viene passato invece uso il registry che viene passato
            const newRegistry = !registry ? JSON.parse(JSON.stringify(this.registryModel.value)) : registry

            // Gli assegno i ruoli passati
            roles.forEach(el => newRegistry.roles.push(el))

            // Gli assegno le opzioni passate
            newRegistry.options = options
            //@TODO: verificare dopo aver aggiunto solo bambini aggiungo un adulto verifico che sia il primo elemento
            /*  if(!this.quotation.registries[0].options.includes('ADULT') && newRegistry.options.includes('ADULT')){
                 [newRegistry].concat(this.quotation.registries)
             }
             else{

             } */

            this.quotation.registries.push(newRegistry)
            // lo aggiungo all'array delle anagrafiche


        },

        /**
         * Aggiunge un nuovo soggetto assicurato al webOrder, usando il modello custom per prodotto
         * @param {string} product Il prodotto in cui ci troviamo, dal momento che il model degli insuree è custom dobbiamo sapere di quale prodotto prendere il model
         * @param {array} options Le opzioni scelte dall'utente in fase di quotazione es.:[KID,ADULT, DAY, SINGLE, GROUP]
         * @param {number} [index] m Permette di scegliere la posizione della lista registry dove inserirlo
         **/

        addNewInsuree(product, options, index) {
            // Recupero un nuovo model pulito
            const newInsuree = JSON.parse(JSON.stringify(INSUREE_MODEL[product].value))

            // Gli assegno il ruolo di INSUREE
            newInsuree.roles.push('INSUREE')

            // Gli assegno le opzioni passate
            newInsuree.options = options

            // lo aggiungo all'array delle anagrafiche
            if (index) this.quotation.registries.splice(index, 0, newInsuree)
            else this.quotation.registries.push(newInsuree)


        },

        /**
         * Rimuove un elemento dai registries all'indice passato
         * @param {number} index Il prodotto in cui ci troviamo, dal momento che il model degli insuree è custom dobbiamo sapere di quale prodotto prendere il model
         **/
        removeRegistryElement(index) {
            this.quotation.registries.splice(index, 1)
        },

        /**
         * Rimuove l'ultimo elemento
         **/
        removeLastRegistryElement() {
            this.quotation.registries.splice(this.quotation.registries.length, 1)
        },

        /**
         * Fa un ciclo in tutte le anagrafiche presenti nell'array dei registry nel weborder per sostituire le opzioni in caso l'utente le cambi dopo aver selezionato il numero dei componenti
         * @param {string} oldOption
         * @param {string} newOption
         **/

        changeRegistryOptions(oldOption, newOption) {
            this.quotation.registries.forEach(registry => {
                registry.options = registry.options.map(options => {
                    return options.replace(oldOption, newOption);
                });
            })
        },

        /**
         * Ottiene la configurazione del checkout
         * Non c'è stato tempo d'integrare i
         * metodi di checkout nella nuova API
         * Quindi qui stiamo ancora chiamando i
         * metodi del preventivatore 2.0
         * @param {string} quotationReference il valore quotationId presente nel webOrder
         */

        async getPaymentConfig(quotationReference) {
            try {
                this.webOrderLoading = true
                const response = await WebOrderService.getPaymentConfig({quotationid: quotationReference})
                this.paymentConfig = response.data
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        async executePayment() {
            try {
                this.webOrderLoading = true
                const response = await WebOrderService.buy(this.quotation)
                this.paymentConfig = response.data
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        /**
         * Ottiene un webOrder
         * @param {string} webOrderID
         */

        async getWebOrder(webOrderID) {
            try {
                this.webOrderLoading = true
                const response = await WebOrderService.get({webOrderID: webOrderID})
                this.quotation = response.data
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        /**
         * Salva i dati del weborder
         */
        async setWebOrder() {
            try {
                this.webOrderLoading = true
                const response = await WebOrderService.set(this.quotation)
                this.quotation = response.data
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        /**
         * Salva i dati del weborder e in più conferma quando
         * l'utente ha completato l'anagrafica, ha gli stessi
         * effetti della setWeborder ma server side fa scattare
         * diverse logiche es.: l'invio delle email all'utente
         */
        async confirmWebOrder() {
            try {
                this.webOrderLoading = true
                const response = await WebOrderService.confirm(this.quotation)
                this.quotation = response.data
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },
        //salva il preventivo legato a una mail
        async sendSaveQuotation() {
            try {
                this.webOrderLoading = true
                const response = await WebOrderService.sendSaveQuotation(this.quotation)
                this.quotation = response.data
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },
        //salvataggio errori chiamata
        setError(payload){
            this.errorState.active = true;
            this.errorState.message = payload.friendlyErrorDescription;
            this.errorState.code = payload.errorDescription;
        },
        removeError() {
            this.errorState.active = false;
            this.errorState.message = '';
            this.errorState.code = '';
        }
    },
})

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../../Store';
import SelligentPusher from "../../Common/selligentPusher";


const App = () => import('./Motoplatinum')
const CustomerDetail = () => import('./02_CustomerDetail/CustomerDetail')
const CustomerDetailContraente = () => import('./02_CustomerDetail/CustomerDetailContraente')
const CustomerDetailProprietario = () => import('./02_CustomerDetail/CustomerDetailProprietario')
const Acquisto = () => import('./03_Checkout/Acquisto')
const ThankYouPage = () => import('./03_Checkout/ThankYouPage')
const EmailConfirmation = () => import('./02_CustomerDetail/EmailConfirmation')
const Quotation = () => import("./01_Quotation/Quotation")
const QuotationVehicle = () => import("./01_Quotation/QuotationVehicle")
const QuotationOwner = () => import("./01_Quotation/QuotationOwner")
const QuotationInsurance = () => import("./01_Quotation/QuotationInsurance")
const QuotationBundles = () => import("./01_Quotation/QuotationBundles")
const QuotationAddons = () => import("./01_Quotation/QuotationAddons")
const QuotationFastquote = () => import("./01_Quotation/QuotationFastquote")
const CustomerDetailStart = () => import("./02_CustomerDetail/CustomerDetailStart")
const CustomerDetailBoxData = () => import("./02_CustomerDetail/CustomerDetailBoxData")
const CustomerDetailDocumentUpload = () => import("./02_CustomerDetail/CustomerDetailDocumentUpload")
const QuotationAlertData = () => import("./01_Quotation/QuotationAlertData")
const QuotationAlertDocumentUpload = () => import("./01_Quotation/QuotationAlertDocumentUpload")
const QuotationAlertDocumentOk = () => import("./01_Quotation/QuotationAlertDocumentOk")
const Booking = () => import("./04_Booking/Booking")
const BookingDate = () => import("./04_Booking/BookingDate")
const BookingThankyou = () => import("./04_Booking/BookingThankyou")
const BookingPlace = () => import("./04_Booking/BookingPlace")
const OtpPage = () => import('./03_Checkout/OtpPage')

Vue.use(VueRouter);

/** Scrolls the element into view
 * Manually created since Safari does not support the native one inside an iframe
 */
const scrollElementIntoView = (element, behavior) => {

    let scrollTop = window.scrollY || element.scrollTop

    const finalOffset = element.getBoundingClientRect().top + scrollTop

    window.parent.scrollTo({
        top: finalOffset,
        behavior: behavior || 'auto'
    })
}

const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: App,
            meta: {
                title: 'Preventivo',
                backButtonEnabled: true
            },
            children: [
                {
                    path: 'quotazione',
                    name: 'quotation',
                    component: Quotation,
                    meta: {
                        title: `Calcola il prezzo dell'assicurazione`,
                        backButtonEnabled: false,
                        quotationIdRequired: false,
                    },
                    children: [

                        {
                            path: 'fastquote',
                            name: 'fastquote',
                            component: QuotationFastquote,
                            meta: {
                                title: `Fast quote`,
                                backButtonEnabled: false,
                                quotationIdRequired: false,
                            },
                        },
                        {
                            path: 'documentazione',
                            name: 'documentazione',
                            component: QuotationAlertData,
                            meta: {
                                title: `Documentazione richiesta`,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                        {
                            path: 'upload',
                            name: 'upload',
                            component: QuotationAlertDocumentUpload,
                            meta: {
                                title: `Caricamento documenti`,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                        {
                            path: 'upload-ok',
                            name: 'upload-ok',
                            component: QuotationAlertDocumentOk,
                            meta: {
                                title: `Caricamento documenti`,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                        {
                            path: 'veicolo',
                            name: 'veicolo',
                            component: QuotationVehicle,
                            meta: {
                                title: `Dati del veicolo`,
                                backButtonEnabled: true,
                                backButtonUrl: '/quotazione/fastquote',
                                quotationIdRequired: false,
                                microStepNumber: 1,
                            },
                        },
                        {
                            path: 'proprietario',
                            name: 'proprietario',
                            component: QuotationOwner,
                            meta: {
                                title: `Dati del proprietario`,
                                backButtonEnabled: true,
                                backButtonUrl: '/quotazione/veicolo',
                                quotationIdRequired: false,
                                microStepNumber: 2
                            },
                        },
                        {
                            path: 'datiassicurativi',
                            name: 'datiassicurativi',
                            component: QuotationInsurance,
                            meta: {
                                title: `Dati assicurativi`,
                                backButtonEnabled: true,
                                backButtonUrl: '/quotazione/proprietario',
                                quotationIdRequired: false,
                                microStepNumber: 3
                            },
                        },
                        {
                            path: 'pacchetti',
                            name: 'pacchetti',
                            component: QuotationBundles,
                            meta: {
                                title: `Pacchetti`,
                                backButtonEnabled: true,
                                backButtonUrl: '/quotazione/datiassicurativi',
                                quotationIdRequired: false,
                                microStepNumber: 4
                            },
                        },
                        {
                            path: 'garanzie',
                            name: 'garanzie',
                            component: QuotationAddons,
                            meta: {
                                title: `Garanzie`,
                                backButtonEnabled: true,
                                backButtonUrl: '/quotazione/pacchetti',
                                quotationIdRequired: false,
                                microStepNumber: 5
                            },
                        },
                    ]
                },
                {
                    path: 'anagrafica',
                    component: CustomerDetail,
                    name: 'anagraficaParent',
                    meta: {
                        backButtonEnabled: true,
                        quotationIdRequired: true
                    },
                    children: [
                        {
                            path: 'start',
                            name: 'anagraficaStart',
                            component: CustomerDetailStart,
                            meta: {
                                title: 'Dati anagrafici',
                                backButtonEnabled: true,
                                backButtonUrl: '/quotazione/garanzie',
                                quotationIdRequired: true
                            }
                        },
                        {
                            path: 'proprietario',
                            name: 'anagraficaProprietario',
                            component: CustomerDetailProprietario,
                            meta: {
                                title: 'Inserisci i dati del proprietario',
                                backButtonEnabled: true,
                                backButtonUrl: '/anagrafica/start',
                                quotationIdRequired: true
                            }
                        },
                        {
                            path: 'contraente',
                            name: 'anagraficaContraente',
                            component: CustomerDetailContraente,
                            meta: {
                                title: 'Inserisci i dati del contraente',
                                backButtonEnabled: true,
                                backButtonUrl: '/anagrafica/proprietario',
                                quotationIdRequired: true
                            }
                        },
                        {
                            path: 'motobox',
                            name: 'anagraficaBoxData',
                            component: CustomerDetailBoxData,
                            meta: {
                                title: 'Dati MOTO.BOX',
                                backButtonEnabled: true,
                                quotationIdRequired: true
                            }
                        },
                        {
                            path: 'conferma',
                            name: 'conferma',
                            component: EmailConfirmation,
                            meta: {
                                title: 'Conferma la tua email',
                                backButtonEnabled: false,
                                quotationIdRequired: false
                            }
                        }, {
                            path: 'documenti',
                            name: 'documenti',
                            component: CustomerDetailDocumentUpload,
                            meta: {
                                title: 'Caricamento documenti',
                                backButtonEnabled: false,
                                quotationIdRequired: false
                            }
                        }],
                },
                {
                    path: 'acquista',
                    name: 'acquista',
                    component: Acquisto,
                    meta: {
                        title: 'Dati di pagamento',
                        backButtonEnabled: false,
                        quotationIdRequired: true
                    }
                },
                {
                    path: 'thank-you',
                    name: 'thank-you',
                    component: ThankYouPage,
                    meta: {
                        title: ``,
                        backButtonEnabled: false,
                        quotationIdRequired: false
                    }
                },
                {
                    path: 'otp',
                    name: 'otp',
                    component: OtpPage,
                    meta: {
                        title: ``,
                        backButtonEnabled: false,
                        quotationIdRequired: false
                    }
                },
                {
                    path: 'prenotazione',
                    name: 'prenotazione',
                    component: Booking,
                    meta: {
                        title: `Installazione`,
                        backButtonEnabled: false,
                        quotationIdRequired: true
                    },
                    children: [

                        {
                            path: ':contractid/mappa',
                            name: 'Scegli installatori',
                            component: BookingPlace,
                            meta: {
                                title: `Mappa`,
                                backButtonEnabled: false,
                                quotationIdRequired: false
                            }
                        },
                        {
                            path: ':contractid/appuntamento',
                            name: 'Appuntamento',
                            component: BookingDate,
                            meta: {
                                title: `Appuntamento`,
                                backButtonEnabled: true,
                                quotationIdRequired: false
                            }
                        },
                        {
                            path: 'thank-you',
                            name: 'booking-thank-you',
                            component: BookingThankyou,
                            meta: {
                                title: `Grazie`,
                                backButtonEnabled: false,
                                quotationIdRequired: false
                            }
                        },
                    ]
                },


            ]
        },
        {
            path: '*',
            component: App,
        }
    ],

});


// Imposto il titolo della pagina e l'impostazione del pulsante indietro e se ci troviamo in AR oppure no
router.beforeEach((to, from, next) => {
    store.dispatch('SET_VIEW_TITLE', to.meta.title);
    store.dispatch('SET_BACK_BUTTON_STATE', to.meta.backButtonEnabled);
    store.dispatch('SET_CURRENT_PRODUCT', 'motoplatinum');
    store.dispatch('SET_AR_STATE', to.path.includes('/auth/'));
    next();
});

// Controlla che l'utente non possa fare danni passando da una route a un'altra,
// fa in modo che esca un avviso se la navigazione puo causare un ricalcolo della tariffa
router.beforeEach((to, from, next) => {
    const routes = router.options.routes[0].children
    const quotationRoutes = routes.find(route => {
        return route.name === 'quotation'
    }).children.map(r => r.name)

    const anagraficaRoutes = routes.find(route => {
        return route.name === 'anagraficaParent'
    }).children.map(r => r.name)

    if (anagraficaRoutes.includes(from.name) && quotationRoutes.includes(to.name) || from.name === 'acquista' && quotationRoutes.includes(to.name)) {
        let confirm = window.confirm('Attenzione! Abbandonando questa pagina, il tuo prezzo finale potrebbe cambiare.')
        if (confirm) next()
    } else next();
});


// Controllo se l'utente puo entrare o meno in determinate pagine e da li faccio i redirect necessari
router.beforeEach((to, from, next) => {
    const _vuexStore = JSON.parse(window.sessionStorage.getItem('vuex'));
    if (to.meta.quotationIdRequired &&
        _vuexStore && !_vuexStore.quotation.quotationData.quotationId && !to.query.q) {
        next('/quotazione/fastquote');
    } else {
        next();
    }
});

// Segnalo a selligent ogni cambio di pagina
router.afterEach((to, from) => {
    try {
        let pusher = new SelligentPusher()
        pusher.add('page', window.location.pathname + '/#' + to.fullPath)
        pusher.push()
    } catch (e) {
    }
})

router.afterEach((to, from) => {
    if (to.path === from.path) return
    // element which needs to be scrolled to
    const element = document.querySelector("#scrollReference");
// scroll to element
    scrollElementIntoView(element)
})


export {router}

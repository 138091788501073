// Invia a GA un evento Custom, lo usiamo
// principalmente per tracciare gli obiettivi di conversione
// _action, _category, _label sono obbligatori,
// _value è solo per tracciare gli acquisti

export class GaEvents {

    static async quotation(_productShortname) {
        this.sendEvent('Quotation', 'Preventivatore', _productShortname);
    }

    static async registration(_productShortname) {
        this.sendEvent('Registration', 'Preventivatore', _productShortname);
    }

    static async sale(_productShortname, _transactionAmount) {
        this.sendEvent('Sale', 'Preventivatore', _productShortname, parseFloat(_transactionAmount));
    }

    static async modify(_productShortname) {
        this.sendEvent('Modifica', 'Preventivatore', _productShortname);
    }

    static async block(_productShortname) {
        this.sendEvent('Blocca', 'Preventivatore', _productShortname);
    }

    static async print(_productShortname) {
        this.sendEvent('Stampa', 'Preventivatore', _productShortname);
    }

    static async quotationError(_errorDescription, _productShortname) {
        this.sendEvent(_errorDescription, 'Errore preventivatore', _productShortname);
    }


// Invia a GA e selligent una transazione Ecommerce.
// Questo deve essere l'aspetto dell'oggetto
    static async transaction(transactionId, affiliationId, value, sku, productName, productCategory) {
        try {
            const _ecommerceData = {
                'transactionId': transactionId || null,
                'transactionAffiliation': affiliationId || null,
                'transactionTotal': value || null,
                'transactionsProducts': [{
                    'sku': sku || null,
                    'name': productName || null,
                    'category': productCategory || null
                }]
            };

            await dataLayer.push(_ecommerceData);
        } catch (e) {

        }

        try {
            // Evento ecommerce per Selligent
            trackdata.push('aOcGfliaI');
            trackdata.push(sku);
            trackdata.push(1);
            trackdata.push(value);
            trackdata.push('');
            trackdata.push(productCategory);
            trackdata.push(transactionId);
            trackdata.push('');
            trackdata.push('EUR');

            (function (d, s, id) {
                var js, sjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "//24hassistance.slgnt.eu/optiext/webtracker.dll";
                sjs.parentNode.insertBefore(js, sjs);
            }(document, 'script', 'webtracker'));
        } catch (e) {
        }
    }


    static async sendEvent(_action, _category, _label, _value) {
        try {
            const _data = {
                event: 'customEvent',
                ...(_action ? {action: _action} : {}),
                ...(_category ? {category: _category} : {}),
                ...(_label ? {label: _label} : {}),
                ...(_value ? {value: _value} : {}),
            };
            await dataLayer.push(_data);
            // Tracciamenti aggiunti su richesta di Pwc
            if(_label === 'MP'){
                if(_action === 'Quotation') await _conv_q.push(["triggerConversion", "100228016"]);
                if(_action === 'Sale') await _conv_q.push(["triggerConversion", "100228018"]);
            }
            return true;

        } catch (e) {
            console.error(e);
        }
    }
}

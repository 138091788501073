<template>
    <div>

        <p v-if="uploader.categories === null"
        class="d-flex justify-content-between align-items-center border border-success p-3 mt-3 rounded font-weight-bold lead text-success">
            <span>Non ci sono documenti da caricare</span>
            <i class="fal fa-check fa-2x"></i>
        </p>
        <div v-if="step == 0" class="pb-4 position-relative">

            <div class="row align-items-center mx-0">
                <div class="col mt-3">
                    <b>Carica i documenti richiesti per proseguire</b><br>
                    (puoi caricare anche più di un file per documento)
                </div>
                <div class="col-md-5 mt-3 text-center text-md-right" v-if="!callbackOverride">
                    <button class="btn btn-outline-medium-gray px-2 d-none d-md-inline-block" @click="showModal();">Caricali
                        con lo smartphone<i class="fas fa-qrcode ml-2"></i></button>
                </div>

            </div>


            <div class="loader-container" v-show="loading != 0">
                <div class="loader-shadow">

                </div>
                <div class="loader"></div>
            </div>

            <div class="doc-container mx-3"
                v-for="( category, index) in uploader.categories"
                :key="index"
                :class="{ 'doc-red' : categoriesErrors[index].fileErrors.length > 0,
                            'doc-green' : category.completed && categoriesErrors[index].fileErrors.length == 0 }">

                <div class="d-flex p-3 align-items-center cursor"
                    @click="indexDoc = index == indexDoc ? -1: index">
                    <div class="start-icon">
                        <i class="fa-2x ico-doc" :class="category.icon"></i>
                    </div>

                    <div class="mr-4">
                        <b>{{ category.title }}</b><br>
                        <small class="text-muted">{{ category.description }} <span v-if="!category.optional"
                                                                                class="text-mandatory">(Obbligatorio)</span></small>
                    </div>
                    <i class=" fa-2x ico-doc ml-auto"
                    :class="{ 'fal fa-file-upload' : !category.completed && categoriesErrors[index].fileErrors.length == 0,
                                    'fal fa-check-circle': category.completed && categoriesErrors[index].fileErrors.length == 0,
                                    'fal fa-times-circle' : categoriesErrors[index].fileErrors.length > 0
                                }">
                    </i>
                </div>

                <template v-if="index == indexDoc">

                    <hr class="m-0">
                    <!--  FILE LOADED VIEWS -->
                    <div class="d-flex border-bottom p-3 align-items-center"
                        v-for="( file, index) in category.files">

                        <div class="start-icon">
                            <i class="fa-1x"
                            :class="{ 'fas fa-file-pdf' : file.fileType == '.pdf',
                                            'fas fa-file-image': file.fileType != '.pdf' }">
                            </i>
                        </div>


                        <div class="pr-4">
                            {{ file.name }}
                        </div>
                        <img :src="file.base64" class="img-file ml-auto"/>
                    </div>
                    <!--  END FILE LOADED VIEWS -->


                    <!--  ERRORS VIEWS -->
                    <div class="d-flex border-bottom p-3 align-items-center text-error"
                        v-for="( fileError , indexError ) in categoriesErrors[index].fileErrors">

                        <div class="start-icon">
                            <i class="fal fa-file-times text-red fa-1x"></i>
                        </div>
                        <div class="pr-3">
                            {{ fileError.name }}<br/>
                            <span class="error-text">{{ fileError.error }}</span>
                        </div>
                        <i class="far fa-times fa-2x text-red ml-auto" @click="removefileError( index, indexError )"></i>

                    </div>
                    <!--  END ERRORS VIEWS -->

                    <!--  DROP LOADER  -->
                    <div class="m-3 text-center">

                        <div ref="HelperFile"
                            class="doc-helper cursor"
                            @click="setCategory(category.id, index); $refs.InputFile.click();"
                            v-cloak @drop.prevent="setCategory(category.id, index); handleDropFileSelect($event);"
                            @dragover.prevent
                        >
                            Trascina qui oppure clicca per caricare i file<br/>

                            <i class="fal fa-file-upload fa-2x mt-2"></i>
                        </div>

                    </div>
                    <!--  END DROP LOADER  -->

                    <div class="text-right mx-3 mb-3" v-if="category.completed">
                        <button class="btn btn-outline-secondary"
                                @click="indexDoc = index == indexDoc ? -1: index">
                            FATTO
                        </button>
                    </div>

                </template>


            </div>

            <div class="text-right mx-3 my-3" v-if="source != 'qrcode' && uploader.completed == true">
                <a  id="cta_document_uploader_continue_1"
                    data-type="CTA"
                    @click="onConfirmClick" class="btn btn-primary rounded-pill">
                    PROSEGUI
                </a>
            </div>

            <div class="text-right mx-3 my-3" v-if="source == 'qrcode' && uploader.completed == true">
                <button id="cta_document_uploader_continue_2"
                        data-type="CTA"
                        @click="nextStep();" class="btn btn-primary rounded-pill">
                    PROSEGUI
                </button>
            </div>


            <input class="d-none" ref="InputFile" type="file" @change="handleFileSelect($event)">
        </div>

        <div v-if="step == 1" class="pb-4 position-relative">
            <div class="row align-items-center mx-0">
                <div class="col-12 text-center p-5">
                    <i class="far fa-check fa-5x text-primary ml-3 mb-3"></i>
                    <p class="text-secondary" style="font-size: 1.5rem;">
                        <b>Ok!</b><br>
                        Il caricamento è andato a buon fine.<br>
                        Torna al pc: clicca su PROSEGUI e completa l'acquisto della tua polizza.
                    </p>

                </div>
            </div>

        </div>


        <!-- QRCODE MODAL-->
        <div id="qrcode-modal" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="form-container">
                    <div class="modal-header">
                    <span class="form-container-title">
                        Carica da smartphone
                    </span>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i class="far fa-times"></i></span>
                        </button>
                    </div>
                    <div id="" class="modal-body">
                        <div class="mt-3 text-center">
                            Inquadra il QR Code con la fotocamera del tuo smartphone qrcode
                        </div>
                        <img class="img-fluid py-3 px-5" :src="uploader.qrCode">
                    </div>
                </div>
            </div>
        </div>

        <div>
            <b-modal ref="qrcodeModal" id="qrcodeModal" title=" Carica da smartphone" :hide-footer="true">
                <div class="row pb-5 justify-content-center align-items-center d-flex">
                    <div class="col-sm-12 col-md-10">
                        <div id="" class="modal-body">
                            <div class="text-center">
                                Inquadra il QR Code con la fotocamera del tuo smartphone qrcode
                            </div>
                            <img class="img-fluid py-3 px-5" :src="uploader.qrCode">
                        </div>
                    </div>
                </div>

            </b-modal>
        </div>

        <!-- END QRCODE MODAL -->

    </div>

</template>

<script>

    import axios from 'axios';
    import {API_URL} from "../../../../Common/constants";
    import {BModal, VBModal} from 'bootstrap-vue';

    export default {
        name: "DocumentUploader",
        created() {

            this.getDocumentOptions();

        },
        mounted() {
            console.log('source',this.source);
            const self = this;
            $('#qrcode-modal').on('shown.bs.modal', function (e) {
                self.refreshData = setInterval(function () {
                    self.getDocumentOptions();
                }, 6000)
            });
        },
        destroyed() {
            clearInterval(this.refreshData);
        },
        data() {
            return {
                uploader: {},
                indexDoc: -1,
                fileSizeLimit: null, //5MB
                categoryFiles: [],
                categoryId: null,
                categoryIndex: null,
                categoriesErrors: [],
                loading: 0,
                step: 0,
                refreshData: null
            }
        },
        props: [
            'operationid',
            'action',
            'source',
            'nextUrl',
            'callbackOverride',
        ],
        components: {
            'b-modal': BModal,
        },
        directives: {
            // Note that Vue automatically prefixes directive names with `v-`
            'b-modal': VBModal
        },
        computed: {},
        methods: {
            activeLoading() {
                this.loading++;
            },
            disactiveLoading() {
                this.loading--;
            },
            addFile(files) {

                Array.prototype.map.call(files, (file, index) => {

                    const fileType = file.type != '' ? file.type.split('/')[1] : '';
                    const reader = new FileReader();
                    const maxSize = parseInt(`${this.fileSizeLimit}000000`);

                    if (fileType != 'pdf' && fileType != 'jpeg' && fileType != 'png') {

                        const fileErrors = {
                            name: file.name,
                            error: 'Formato file non valido: puoi caricare file in formato .pdf, .png, .jpg'
                        };
                        
                        this.categoriesErrors[this.categoryIndex].fileErrors = [...this.categoriesErrors[this.categoryIndex].fileErrors, fileErrors];

                    } else if (file.size > maxSize) {
                        const fileErrors = {
                            name: file.name,
                            error: `I file non devono superare i ${this.fileSizeLimit}MB, carica un file più piccolo.`
                        };
                        
                        this.categoriesErrors[this.categoryIndex].fileErrors = [...this.categoriesErrors[this.categoryIndex].fileErrors, fileErrors];
                    } else {
                        reader.onload = (readerEvt) => {
                            const newFile = {
                                id: 0,
                                base64: readerEvt.target.result,
                                name: file.name,
                                idCategory: this.categoryId
                            };

                            this.addDocument(newFile);

                        };

                        reader.readAsDataURL(file);

                    }


                });

            },
            removefileError(index, indexError) {
                const file = this.categoriesErrors[index].fileErrors;
                this.categoriesErrors[index].fileErrors = [...file.slice(0, indexError), ...file.slice(indexError + 1)];

            },
            handleFileSelect(e) {
                const files = e.target.files;
                this.addFile(files);
            },
            handleDropFileSelect(e) {

                const files = e.dataTransfer.files;
                this.addFile(files);

            },
            onResetClick() {

            },
            getDocumentOptions() {
                
                axios.get(`${API_URL}/documentupload/getoptions`, {
                    params: {
                        operationid: this.operationid,
                        action: this.action
                    }
                })
                .then((res) => {
                    this.uploader = res.data;
                    this.fileSizeLimit = this.uploader.maxSizeUpload;

                    if (this.uploader.categories.length != this.categoriesErrors.length) {
                        
                        this.uploader.categories.forEach(() => {
                            const f = {
                                fileErrors: []
                            };

                            this.categoriesErrors = [...this.categoriesErrors, f];
                        });
                    }

                    if (this.uploader.completed == true) {
                        this.hideModal();
                    }

                });

            },
            addDocument(file) {
                this.activeLoading();
                const obj = {
                    operationid: this.operationid,
                    action: parseInt(this.action),
                    files: [file]
                };

                axios.post(`${API_URL}/documentupload/AddDocument`, obj)
                    .then((res) => {

                        this.uploader = res.data;
                        this.disactiveLoading();

                        this.$refs["InputFile"].value = "";

                    }).catch((e) => {
                    this.disactiveLoading();
                    console.error(e);
                    if (e && e.status == 400) {
                        this.$noty.error('Ops, si è verificato un errore imprevisto. Riprova!');
                    }

                });

            },
            setCategory(id, index) {
                this.categoryId = id;
                this.categoryIndex = index;
            },
            showModal() {
                this.$refs.qrcodeModal.show();
            },
            hideModal() {
               this.$refs.qrcodeModal.hide();
            },
            nextStep() {
                this.step = 1;
            },
            onConfirmClick() {
                if (!this.callbackOverride) this.$router.push(this.uploader.nextUrl)
                else this.$emit('documentUploadCompleted');
            }

        }
    }
</script>


import SelligentPusher from "./selligentPusher";

const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};


const AnalyticsInit = () => {
    const _gtmData = document.getElementById('gtmDataLayer') ? JSON.parse(document.getElementById('gtmDataLayer').text) : null;
    const _urlProd = getCookie('cookiePartner24hAssistance');
    const _userId = getCookie('cookieGa24hAssistance');
    const _adv = getCookie('cookieAdvertising24hAssistance');
    const _partnerKind = _gtmData ? _gtmData.Utente.TipoPartner : null;
    let _kind = null;

    if (_userId && _userId.length > 0) {
        try {
            dataLayer.push({
                'userId': _userId
            });
        } catch (e) {
        }
    }


    //Direct free
    if (!_urlProd && !_userId && !_adv) {
        _kind = 'Direct'
    }

    //Direct PAid ADV
    if (_urlProd && _adv) {
        _kind = 'Direct Paid ADV'
    }

    //Direct Paid Partner
    if (_urlProd && !_adv) {
        _kind = 'Direct Paid Partner web'
    }

    //Partner
    if (_partnerKind) {
        if (_partnerKind === 'T') {
            _kind = 'Partner Traditional'
        }

        if (_partnerKind === 'NT') {
            _kind = 'Partner NotTraditional'
        }

    }

    try {
        dataLayer.push({
            'userKind': _kind,
            'isLogged': !!_userId
        })

    } catch (e) {
    }

};

const SelligentInit = ()=>{
    let pusher = new SelligentPusher()
    pusher.add('page', window.location.pathname)
    pusher.push()
}

export {AnalyticsInit, SelligentInit}

<template>
    <figure v-lazyload class="image__wrapper">
        <img
                class="image__item"
                :data-url="source"
                :alt="imgAlt"
                src="/images/loading.gif"
        />
    </figure>
</template>


<script>
export default {
    name: "TheLazyImage",
    props: {
        source: {
            type: String,
            required: true
        },
        imgAlt: {
            type: String,
            required: true
        },

    }
};
</script>

<style scoped>

.image__item{
    width: 100%;
    max-height: 150px;
}

</style>

import {defineStore} from 'pinia'
import * as Cookies from "js-cookie";
import UserService from "@/services/user.service";
import {useWebOrderStore} from "@/stores/weborder.store"
import {REGISTRY_DATA_MATCHING} from "@/Common/constants"

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        userRegistry: {},
        userConfig: {},
    }),
    getters: {
        user: (state) => {
            if (!window.globalConfigVariables.token) return false
            const _isAgent = !!Cookies.get('IUN24hAssistance')
            return {
                config: state.userConfig,
                registry: state.userRegistry,
                isAgent: _isAgent
            }
        },
        globalIsAgent: () => {
            return process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? process.env.IUN_TOKEN || Cookies.get('IUN24hAssistance') : Cookies.get('IUN24hAssistance');
        },
    },
    actions: {
        /**
         * Ottiene il modello del registry (anagrafica) e i dati dell'utente loggato (tipo di utente, segmento, ecc..)
         */
        async collectUserData() {
            const webOrder = useWebOrderStore()

            // Verifico se l'utente è loggato
            if (!window.globalConfigVariables.token) return

            // Recupero il registry
            const registryResponse = await UserService.getUserRegistry()

            // Recupero gli altri dati dell'utente (es.: Tipologia, segmento)
            const configResponse = await UserService.getUserConfig()

            // Clono un model pulito dell'anagrafica
            const newRegistry = JSON.parse(JSON.stringify(webOrder.registryModel.value));

            newRegistry.name.value = registryResponse.data.name
            newRegistry.surname.value = !registryResponse.data.isCompany ? registryResponse.data.surname : null
            newRegistry.companyName.value = registryResponse.data.isCompany ? registryResponse.data.surname : null
            newRegistry.email.value = registryResponse.data.email
            newRegistry.phone.value = registryResponse.data.cellular
            newRegistry.nationality.value = 'it'
            newRegistry.taxID.value = !registryResponse.data.isCompany ? registryResponse.data.fiscalCode : null
            newRegistry.vatID.value = registryResponse.data.isCompany ? registryResponse.data.fiscalCode : null
            newRegistry.jobName.value = REGISTRY_DATA_MATCHING.professione.values.find(el => el.key = registryResponse.data.professionId).value
            newRegistry.address.streetAddress.value = `${registryResponse.data.address.street}, ${registryResponse.data.address.number}`
            newRegistry.address.townName.value = registryResponse.data.address.location
            newRegistry.address.zipCode.value = registryResponse.data.address.zipCode

            //const foundProvince =
            newRegistry.address.administrativeCode.value = REGISTRY_DATA_MATCHING.provincia.values.find(el => el.key === registryResponse.data.address.provinceId).value
            newRegistry.address.stateCode.value = REGISTRY_DATA_MATCHING.stato.values.find(el => el.key = registryResponse.data.address.countryId).value

            // Assegno alla tipologia di utente un tipo più leggibile
            configResponse.data.userType = configResponse.data.userType === 0 || configResponse.data.userType === 1 ? configResponse.data.userType = 'C' : configResponse.data.userType = 'P'
            this.userConfig = configResponse.data
            this.userRegistry = newRegistry
            return this.userRegistry
        },
    }
})

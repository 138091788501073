

<template>

<div class="fixed-top"        
        id="sticky-fast-quote-multimobility"
        style="min-height: 1px;"
        data-section="sticky_fastquote"
        v-view="handleFastQuoteVisibility">
        
        <div class="sticky-fast-quote bg-gradient-dark-blue-sticky position-fixed" v-if="stickyIsVisible">
            <div class="container py-4 py-md-4 my-md-2">
                <div class="row d-flex justify-content-between align-items-center">
                    <div class="col-md-7 col-lg-8 d-none d-md-block">
                        <div class="d-flex justify-content-start">
                            <img class="img-fluid"
                                    src="/images/24hassistance_logo_white.png"
                                    srcset="/images/24hassistance_logo_white@2x.png 2x,
                                            /images/24hassistance_logo_white@3x.png 3x,
                                        /images/24hassistance_logo_white@4x.png 4x"
                                    style="max-height:40px"/>
                                    <p style="padding-top:13px"
                                    class="lead text-uppercase text-white m-0 ml-2">Assicurazione mobilità urbana
                                    </p>

                                    
                        </div>
                    </div>

                    <div class="col-12 col-md-5 col-lg-4 p-sm-0 px-md-3">
                        <p class="font-weight-bold text-white mb-2" style="border-bottom: 1px solid rgb(255, 255, 255);">
                        Assicura te e la tua famiglia da 39€ all'anno<br/>
                        <!-- Chi vuoi assicurare? -->
                        </p>
                        <div class="d-flex justify-content-around align-items-center">
                            <div class="col px-0 mr-1">
                                <a class="btn btn-sm bg-white text-seconday btn-block rounded-pill"
                                    id="cta_my_sticky_fastquote_1"
                                    href="javascript:void(0)"
                                    data-type="CTA"
                                    onclick="window.fastquoteEventHandler(event, '/multimobility/preventivo#/quotazione?insurancetype=individual')">Individuale</a>
                            </div>
                            
                            <div class="col px-0">
                                <a class="btn btn-sm bg-white text-seconday btn-block rounded-pill"
                                    id="cta_my_sticky_fastquote_2"
                                    href="javascript:void(0)"
                                    data-type="CTA"
                                    onclick="window.fastquoteEventHandler(event, '/multimobility/preventivo#/quotazione?insurancetype=family')">Familiare</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import FastquoteMotoplatinum from "./FastquoteMotoplatinum";
    import {bus} from "../EventBus";

    export default {
        name: "StickyFastquoteMultimobility",

        components: {
            'fastquote-motoplatinum': FastquoteMotoplatinum
        },
        props: ['title', 'url', 'onlyMobile' ],
        
        data: function () {
            return {
                stickyIsVisible: false
            }
        },

        created() {
            const _this = this;
            // Attende l'evento inviato dal fastquote quando è in
            // modalità sticky ed entra ed esce dal viewport
            bus.$on('onShowStickyFastquote', (_v) => {                
                _this.stickyIsVisible = _v;
            });

        },
        methods: {

            handleFastQuoteVisibility: function (e) {
                try {
                    if (e.scrollPercent > 0.05) {
                        bus.$emit('onShowStickyFastquote', true);
                    } else if (e.scrollPercent < 0.05) {
                        bus.$emit('onShowStickyFastquote', false);
                    }
                } catch (e) {
                }
                

            },
        }
    }
</script>



<template>
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
      <!--   <i class="fal fa-4x fa-spin fa-circle-notch text-primary"></i> -->
        <div class="loader-start"></div>
    </div>
</template>

<script>
// Questo spinner si adatta e si centra nel suo contenitore, non ha bordi
export default {
    name: "AdaptiveSpinner"
}
</script>

<template>
    <div class="bg-light-gray">
        <div id="iframe-container" style="display: none;">
            <a href="https://wa.me/message/TVAVKRRM37Q4H1" target="_blank" class="whatsapp-button">
                <i class="fab fa-whatsapp fa-lg" style="color:#ffffff;"></i> <!-- Icona di WhatsApp -->
            </a>
        </div>
        <div v-if="preloading" class="text-center">
            <i class="fal fa-spin fa-circle-notch text-primary fa-4x"></i>
        </div>
        <div v-if="error" class="text-center">
            <i class="fal  fa-times text-danger fa-4x"></i>
            <p class="text-muted">Si è verificato un errore</p>
        </div>
        <div v-if="!preloading && !error" class="container-fluid p-0 m-0">
            <transition name="fade" mode="out-in" duration="150">
                <router-view></router-view>
            </transition>
        </div>
        
    </div>
</template>

<script>

    export default {
        data() {
            return {
                preloading: true,
                error: false
            }
        },
        name: "ArBase",
        created() {
            this.$store.commit('RESET_LOADING');
            this.$store.commit('SET_SCREEN_WIDTH', window.innerWidth);
            window.addEventListener(
                'resize', () => {
                    this.$store.commit('SET_SCREEN_WIDTH', window.innerWidth);
                })
            // Verifico se è presente il parametro con lo user id per effettuare il login automatico
            if (this.$route.query.uid) this.loginUser(this.$route.query.uid)
            else this.preloading = false
        },
        methods: {
            async loginUser(q) {
                try {
                    await this.$store.dispatch('AGENTE_LOGIN_AS', q)
                    const callback = this.$route.query.callback
                    let query = Object.assign({}, this.$route.query);
                    delete query.uid;
                    this.$router.replace({query});
                    if(callback) window.location = callback
                    else window.location = `/?callback=${encodeURIComponent(window.location.pathname + window.location.hash)}&noredirect=true`
                } catch (e) {
                    console.error(e)
                    this.error = true
                }
            }
        }
    }
</script>

<style type="text/css">
    .whatsapp-button {
        background-color: #25D366; /* Colore verde WhatsApp */
        padding: 12px 12px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 24px; /* Aumenta la dimensione dell'icona */
        border-radius: 50%; /* Rende il bottone rotondo */
        cursor: pointer;
        border: none;
        transition: background-color 0.3s;
        width: 50px;
        height: 50px;
    }
    .whatsapp-button:hover {
        background-color: #1ebe5f; /* Un verde leggermente più scuro per l'hover */
    }
    #iframe-container{ 
        position: fixed;
        right: 1.5rem;
        bottom: 1.25rem;
        z-index: 99;
        
    }
</style>



<template>
    <div class="d-flex justify-content-end align-items-center">
        <select class="px-3 bg-white font-size-16 font-weight-500"
                ref="langSelector"
                v-model="$i18n.locale"
                style="border: none !important;">
            <option
                    v-for="(lang, i) in langs" :key="`Lang${i}`"
                    :value="lang.locale">
                {{ lang.locale.toUpperCase() }} {{ lang.emoji }}
            </option>
        </select>
    </div>
</template>

<script>

import {bus} from "@/Preventivo/_Common/EventBus";

export default {
    name: "TheLocaleSelect",
    mounted() {
        this.$refs.langSelector.value = this.$i18n.locale
    },
    data() {
        return {
            langs: [{
                locale: 'it',
                emoji: '🇮🇹'
            }, {
                locale: 'en',
                emoji: '🇬🇧'
            }, {
                locale: 'de',
                emoji: '🇩🇪'
            }]
        }
    },
    watch: {
        '$i18n.locale': {
            handler(value) {
                this.$moment.locale(value)
                bus.$emit('onLocaleChange')
            }
        }
    }

}
</script>

<style lang="scss" scoped>

.dropdown-menu {
  left: -80px;
}

</style>

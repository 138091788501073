<template>
    <div class="hide-print container-fluid border-bottom py-3">
        <div class="container px-0 d-flex justify-content-center justify-content-lg-between justify-content-md-between align-items-center">
            <div class="breadcrumbs d-flex justify-content-start align-items-center">
                <span class="mp-progressbar-item"
                      :class="getColorClass(1)">Preventivo
                    <i v-if="MP_CONFIG.validatedGlobalSteps >= 1"
                       class="fas float-right fa-check-circle"/>
                </span>

                <i class="m-1 fal fa-horizontal-rule"
                   :class="getColorClass(2)"/>

                <span class="mp-progressbar-item"
                      :class="getColorClass(2)">Anagrafica
                 <i v-if="MP_CONFIG.validatedGlobalSteps >= 2"
                    class="fas float-right fa-check-circle"/>
                </span>

                <i class="m-1 fal fa-horizontal-rule"
                   :class="getColorClass(4)"/>

                <span class="mp-progressbar-item"
                      :class="getColorClass(4)">Acquista
                 <i v-if="MP_CONFIG.validatedGlobalSteps >= 4"
                    class="fas float-right fa-check-circle"/>
                </span>
            </div>
            <div class="d-flex justify-content-end">
                <!-- <mp-agent-alertdata/>
                <button id="cta_mp_new_quotation"
                        dataType="CTA"
                        type="button"
                        v-if="GLOBAL_IS_AGENT"
                        @click="goToFasquote()"
                        class="btn btn-xs btn-cta btn-outline-secondary">Nuovo preventivo <i class="fas fa-sync"></i>
                </button> -->
                <img
                     class="d-none d-md-block"
                     style="max-height:40px"
                     src="/images/motoplatinum_logo.png">
            </div>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "TheProgressbarMainForOldSite",
        computed: {
            ...mapGetters(['MP_CONFIG', 'GLOBAL_IS_AGENT']),
        },
        methods: {
            getColorClass(_step) {
                if (_step > this.MP_CONFIG.validatedGlobalSteps + 1) return 'text-muted';
                if (_step === this.MP_CONFIG.validatedGlobalSteps + 1) return 'text-secondary font-weight-bold';
                if (_step <= this.MP_CONFIG.validatedGlobalSteps) return 'text-success font-weight-bold';
            },
            goToFasquote() {
                window.location.href = '/motoplatinum/v2#/quotation/fastquote'
            }

        }
    }
</script>

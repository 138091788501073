<template>
    <div class="quotation-loading">
        <div class="row h-100 align-content-center justify-content-center">
            <div class="col-6 text-center">
                <img
                        class="img-fluid product-logo mb-3"
                        style="max-width:11rem"
                        src="/images/bikeplatinum-logo-white.png"
                        srcset="/images/bikeplatinum-logo-white@2x.png 2x,
                       /images/bikeplatinum-logo-white@4x.png 4x"/>
                <progress-bar
                        size="tiny"
                        bg-color="#FFC820"
                        bar-color="#022D53"
                        text-fg-color="#0761af"
                        :val="increasing_pct"></progress-bar>
                <transition name="fade">
                    <p>{{displayMessage}}</p>
                </transition>
                <i class="fas fa-circle-o-notch fa-spin fa-2x text-primary"></i>
            </div>
        </div>
    </div>
</template>

<script>
    import ProgressBar from 'vue-simple-progress'

    export default {
        name: 'UxLoadingQuotation',
        components: {ProgressBar},
        data() {
            return {
                increasing_pct: 0,
                progressMessages: [
                    'Caricamento dei dati dalla banca dati di Ciclo Registro',
                    'Calcolo del tuo preventivo']
            }
        },
        mounted() {
            setInterval(() => {
                if (this.is_paused)
                    return;
                this.increasing_pct = Math.min(this.increasing_pct + 2, 100);
            }, 90);
        },
        computed: {
            displayMessage() {
                return this.increasing_pct > 45 ? this.progressMessages[1] : this.progressMessages[0]
            }
        }
    }
</script>


import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../../Store';
import SelligentPusher from "../../Common/selligentPusher";

const App = () => import('./Bikeplatinum')
const BpBundles = () => import('./01_Quotation/BpBundles')
const BpQuotation = () => import('./01_Quotation/BpQuotation')
const BpVehicle = () => import('./01_Quotation/BpVehicle')
const BpStart = () => import('./01_Quotation/BpStart')
const CustomerDetail = () => import('./02_CustomerDetail/CustomerDetail')
const CustomerDetailContraente = () => import('./02_CustomerDetail/CustomerDetailContraente')
const CustomerDetailProprietario = () => import('./02_CustomerDetail/CustomerDetailProprietario')
const Acquisto = () => import('./03_Checkout/Acquisto')
const ThankYouPage = () => import('./03_Checkout/ThankYouPage')
const EmailConfirmation = () => import('./02_CustomerDetail/EmailConfirmation')
const OtpPage = () => import('./03_Checkout/OtpPage')
const VinPage = () => import('./02_CustomerDetail/VinPage')


Vue.use(VueRouter);

const dynamicBackButtonUrl = () => {
    return store.state.anagrafica.customerDetails.isOwner ? '/anagrafica/proprietario' : '/anagrafica/contraente'
};

/** Scrolls the element into view
 * Manually created since Safari does not support the native one inside an iframe
 */
const scrollElementIntoView = (element, behavior) => {

    let scrollTop = window.scrollY || element.scrollTop

    const finalOffset = element.getBoundingClientRect().top + scrollTop

    window.parent.scrollTo({
        top: finalOffset,
        behavior: behavior || 'auto'
    })
}

const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: App,
            meta: {
                title: 'Preventivo',
                backButtonEnabled: true
            },
            children: [
                {
                    path: 'inizio',
                    name: 'inizio',
                    component: BpStart,
                    meta: {
                        title: `Calcola il prezzo dell'assicurazione bici`,
                        backButtonEnabled: false,
                        quotationIdRequired: false,
                    },
                },
                {
                    path: 'veicolo',
                    name: 'veicolo',
                    component: BpVehicle,
                    meta: {
                        backButtonEnabled: false,
                        quotationIdRequired: false,
                        //backButtonUrl: '/inizio'
                    },
                },
                {
                    path: 'quotation',
                    name: 'quotation',
                    component: BpQuotation,
                    meta: {
                        title: `Calcola il prezzo dell'assicurazione bici`,
                        backButtonEnabled: false,
                        quotationIdRequired: false,
                    },
                },
                {
                    path: 'pacchetti',
                    name: 'pacchetti',
                    component: BpBundles,
                    meta: {
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                        backButtonUrl: '/veicolo'
                    }
                },
                {
                    path: 'telaio',
                    name: 'telaio',
                    component: VinPage,
                    meta: {
                        title: 'Inserisi i dati della bici',
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                        backButtonUrl: '/pacchetti'
                    }
                },
                {
                    path: 'anagrafica',
                    component: CustomerDetail,
                    meta: {
                        backButtonEnabled: true,
                        quotationIdRequired: true
                    },
                    children: [
                        {
                            path: 'contraente',
                            name: 'anagraficaContraente',
                            component: CustomerDetailContraente,
                            meta: {
                                title: 'Inserisci i dati del contraente',
                                backButtonEnabled: true,
                                //quotationIdRequired: true,
                                backButtonUrl: '/telaio'
                            }
                        },
                        {
                            path: 'proprietario',
                            name: 'anagraficaProprietario',
                            component: CustomerDetailProprietario,
                            meta: {
                                title: 'Inserisci i dati del proprietario',
                                backButtonEnabled: true,
                                quotationIdRequired: true,
                                backButtonUrl: '/anagrafica/contraente'
                            }
                        },
                        {
                            path: 'conferma',
                            name: 'conferma',
                            component: EmailConfirmation,
                            meta: {
                                title: 'Conferma la tua email',
                                backButtonEnabled: false,
                                quotationIdRequired: false
                            }
                        }]
                },
                {
                    path: 'acquista',
                    name: 'acquista',
                    component: Acquisto,
                    meta: {
                        title: 'Dati di pagamento',
                        backButtonEnabled: false,
                        quotationIdRequired: true
                    }
                },
                {
                    path: 'thank-you',
                    name: 'thank-you',
                    component: ThankYouPage,
                    meta: {
                        title: null,
                        backButtonEnabled: false,
                        quotationIdRequired: false
                    }
                },
                {
                    path: 'otp',
                    name: 'otp',
                    component: OtpPage,
                    meta: {
                        title: null,
                        backButtonEnabled: false,
                        quotationIdRequired: false
                    }
                },

            ]
        },
        {
            path: '*',
            component: App,
        }
    ],

});

// Imposto il titolo della pagina e l'impostazione del pulsante indietro
router.beforeEach((to, from, next) => {
    store.dispatch('SET_VIEW_TITLE', to.meta.title);
    store.dispatch('SET_BACK_BUTTON_STATE', to.meta.backButtonEnabled);
    store.dispatch('SET_CURRENT_PRODUCT', 'bikeplatinum');
    store.dispatch('SET_AR_STATE', to.path.includes('/auth/'));
    next();
});


// Controllo se l'utente puo entrare o meno in determinate pagine e da li faccio i redirect necessari
router.beforeEach((to, from, next) => {    const _vuexStore = JSON.parse(window.sessionStorage.getItem('vuex'));
    if (to.meta.quotationIdRequired &&
        _vuexStore && !_vuexStore.quotation.quotationData.quotationId && !to.query.q) {
        next('/inizio');
    } else {
        next();
    }
});

// Segnalo a selligent ogni cambio di pagina
router.afterEach((to, from)=>{
    try{
        let pusher = new SelligentPusher()
        pusher.add('page', window.location.pathname + '/#' + to.fullPath)
        pusher.push()
    } catch (e) {
    }
})

router.afterEach((to, from) => {
    if(to.path === from.path) return
    // element which needs to be scrolled to
    const element = document.querySelector("#scrollReference");
// scroll to element
    scrollElementIntoView(element)
})

export {router}

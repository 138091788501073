<template>

<div class="fixed-top"        
        id="sticky-fast-quote-skippermate"
        style="min-height: 1px;"
        v-view="handleFastQuoteVisibility"
        data-section="sticky_fastquote">
        
        <div class="sticky-fast-quote bg-gradient-dark-blue-sticky position-fixed" v-if="stickyIsVisible">
            <div class="container py-4 py-md-4 my-md-2">
                <div class="row d-flex justify-content-between align-items-center">
                    <div class="col-md-7 col-lg-8 d-none d-md-block">
                        <div class="d-flex justify-content-start">
                            <img class="img-fluid"
                                    src="/images/24hassistance_logo_white.png"
                                    srcset="/images/24hassistance_logo_white@2x.png 2x,
                                            /images/24hassistance_logo_white@3x.png 3x,
                                        /images/24hassistance_logo_white@4x.png 4x"
                                    style="max-height:40px"/>
                                    <p style="padding-top:13px"
                                       class="lead text-uppercase text-white m-0 ml-2">Assicurazione barca
                                    </p>

                                    
                        </div>
                    </div>
                        <div class="d-flex justify-content-center d-md-none">
                            <div class="col-7 d-md-none mr-auto text-white pl-3">
                            Richiedi ora un preventivo veloce 
                            per la polizza barca!
                            </div>
                            <div class=" pr-3">                                
                                <a class="text-uppercase btn btn-primary rounded-pill px-4"
                                id="cta_sk_sticky_fastquote_1"
                                href="javascript:void(0)"
                                data-type="CTA"
                                onclick="window.fastquoteEventHandler(event, '/Assicurazione-barca-comparatore/Step1.aspx')">CALCOLA</a>
                            </div>
                        </div>
                <!-- </div> -->
                        <div>
                            <a class="text-uppercase btn btn-primary rounded-pill px-4 d-none d-md-block"
                                id="cta_sk_sticky_fastquote_2"
                                href="javascript:void(0)"
                                data-type="CTA"
                                onclick="window.fastquoteEventHandler(event, '/Assicurazione-barca-comparatore/Step1.aspx')">CALCOLA</a>
                        </div>
                </div>
            </div>

        </div>

</div>



<!-- 
   py-4 bg-gradient-dark-blue-sticky position-fixed fixed-top text-white -->

     <!-- <div class="sticky-fast-quote bg-gradient-dark-blue-sticky position-fixed"        
         id="sticky-fast-quote-skippermate"
         style="min-height: 1px;"
         v-view="handleFastQuoteVisibility">
        

        
        <div  v-if="stickyIsVisible" class="py-3 bg-gradient-dark-blue-sticky position-fixed fixed-top text-white d-none d-md-block" 
                    style="border-bottom-left-radius: 0.5rem; border-bottom-right-radius: 0.5rem;">
            <div class="py-md-3 container d-flex justify-content-between align-items-center" style="font-size: 16px">
                <div class="d-flex">
                <img class="img-fuid d-none d-md-block"
                    src="/images/24hassistance_logo_white.png"
                                 srcset="/images/24hassistance_logo_white@2x.png 2x,
                            /images/24hassistance_logo_white@3x.png 3x,
                          /images/24hassistance_logo_white@4x.png 4x"
                                 style="max-height:40px"/>
                
                <p style="padding-top:13px"
                    class="lead text-uppercase text-white m-0 ml-2">Assicurazione barca</p>
                </div> 

                <a href="/Assicurazione-barca-comparatore/Step1.aspx" 
                   class="btn btn-primary rounded-pill btn-cta"
                   style="border-radius: 0.75rem">
                   CALCOLA
                </a>
            </div> 
        </div>


        <div v-if="stickyIsVisible" class="py-4 px-3 bg-gradient-dark-blue-sticky position-fixed fixed-bottom text-white rounded-top d-block d-md-none">
            <div class="d-flex" style="font-size: 16px">
                <div class="mr-auto">
                    Richiedi ora un preventivo<br>veloce 
                    per la polizza barca!
                </div>
                <div class="ml-auto">
                    <a href="/Assicurazione-barca-comparatore/Step1.aspx" 
                       class="btn btn-primary rounded-pill btn-cta"
                       style="border-radius: 0.75rem">
                       CALCOLA
                    </a>
                </div>
            </div> 
        </div>

    </div> -->
</template>

<script>
    import FastquoteMotoplatinum from "./FastquoteMotoplatinum";
    import {bus} from "../EventBus";

    export default {
        name: "StickyFastquoteSkippermate",

        components: {
            'fastquote-motoplatinum': FastquoteMotoplatinum
        },
        props: ['title', 'url', 'onlyMobile' ],
        
        data: function () {
            return {
                stickyIsVisible: false
            }
        },

        created() {
            const _this = this;
            // Attende l'evento inviato dal fastquote quando è in
            // modalità sticky ed entra ed esce dal viewport
            bus.$on('onShowStickyFastquote', (_v) => {                
                _this.stickyIsVisible = _v;
            });

        },
        methods: {

            handleFastQuoteVisibility: function (e) {
                try {
                    if (e.scrollPercent > 0.05) {
                        bus.$emit('onShowStickyFastquote', true);
                    } else if (e.scrollPercent < 0.05) {
                        bus.$emit('onShowStickyFastquote', false);
                    }
                } catch (e) {
                }
                

            },
        }
    }
</script>



import AgenteApiService from "../../Agente/agente.service";

const _getDefaultState = () => {
    return {
        usersSearch: {
            results: [],
        }
    }
};


export default {
    state: _getDefaultState(),
    actions: {
        // Ottiene tutti i dati della polizza la cui modifica è in corso
        AGENTE_USERSEARCH({commit, state}, _data) {

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                AgenteApiService.userSearch(_data).then(res => {
                    commit('AGENTE_USERSEARCH_SET_RESULTS', res.data)
                    commit('STOP_LOADING')
                    resolve(res.data);
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AGENTE_LOGIN_AS({commit, state}, _userId) {

            const _data = {
                uid: _userId
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                AgenteApiService.userLoginAs(_data).then(res => {
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },
    },

    getters: {
        AGENTE_USERSEARCH_RESULTS: (state) => {
            return state.usersSearch.results
        }
    },
    mutations: {
        AGENTE_USERSEARCH_SET_RESULTS(state, payload) {
            state.usersSearch = payload;
        },
        AGENTE_USERSEARCH_RESET_RESULTS(state, payload) {
            state.usersSearch = {
                results: []
            };
        },
        RESET_AGENTE_STATE(state) {
            Object.assign(state, _getDefaultState());
        }
    }
}


import axios from 'axios';
import {API_URL} from '@/Common/constants';


const ArQuotationsApiService = {
    getUserQuotations: function (_request) {
        return axios.get(`${API_URL}/ar/getquotations`, {
            params: {
                ...(_request)
            },
        })
    },
    getOmniQuotations: function (_request) {
        return axios.get(`${API_URL}/ar/getomnichannelquotations`, {
            params: {
                ...(_request)
            },
        })
    },
    ownOmniQuotation: function (_request) {
        return axios.post(`${API_URL}/ar/setomnichannelquote`,_request)
    },
}

export default ArQuotationsApiService
<template>
    <div class="sticky-fast-quote bg-gradient-dark-blue-sticky position-fixed"
         v-if="stickyIsVisible"
         id="bp-sticky-fast-quote"
         data-section="sticky_fastquote">
        <div class="container py-3">
            <div class="">
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="d-none d-md-block col-md-6">
                        <div class="d-flex justify-content-start">
                            <img class="img-fluid"
                                 src="/images/24hassistance_logo_white.png"
                                 srcset="/images/24hassistance_logo_white@2x.png 2x,
                            /images/24hassistance_logo_white@3x.png 3x,
                          /images/24hassistance_logo_white@4x.png 4x"
                                 style="max-height:40px"/>
                            <p style="padding-top:13px"
                               class="lead text-uppercase text-white m-0 ml-2">Assicurazione bici</p>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-md-6 p-sm-0 px-md-3"> -->
                    <div class="col-11 col-md-6 px-4 px-md-3">

                        <p class="font-weight-bold text-white mb-1" style="border-bottom: 1px solid rgb(255, 255, 255);">
                            Calcola un preventivo veloce
                        </p>
                        <fastquote-bikeplatinum
                                size="small"
                                theme="dark"
                                alignment="horizontal"
                                :borders="true"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FastquoteBikeplatinum from "./FastquoteBikeplatinum";
    import {bus} from "../EventBus";

    export default {
        name: "StickyFastquoteBikeplatinum",

        components: {
            'fastquote-bikeplatinum': FastquoteBikeplatinum
        },
        data: function () {
            return {
                stickyIsVisible: false
            }
        },

        created() {
            const _this = this;
            // Attende l'evento inviato dal fastquote quando è in
            // modalità sticky ed entra ed esce dal viewport
            bus.$on('onShowStickyFastquote', (_v) => {
                _this.stickyIsVisible = _v;
            });

        },
        onBeforeDestroy(){
            bus.$off('onShowStickyFastquote')
        }
    }
</script>



import ApiService from '../../Preventivo/_Common/Services/GlobalQuotation.service';
import GlobalApiService from "../../Preventivo/_Common/Services/GlobalQuotation.service";
import merge from 'lodash/merge';
import moment from 'moment'

export default {
    state: {
        //Metodi di pagamento disponibili
        availablePayMethods: null,
        checkOutResponse: null,
        otpConfig: null,
        policyDocument: null,
        verifyOtp: null,
        flags: []
    },
    actions: {
        // Inizia la quotazione
        GET_PAY_METHODS({commit}, _quotationId) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ApiService.getPayMethods(_quotationId).then(res => {
                    commit('UPDATE_PAY_METHODS', res.data.availablePayMethods);
                    resolve(res);
                    commit('STOP_LOADING');
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });

        },

        // Acquista il preventivo
        CHECKOUT({commit, state}, _data) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                ApiService.checkOut(_data).then(res => {
                    commit('UPDATE_CHECKOUT_RESPONSE', res.data);
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });

        },

        GET_OTP_CONFIG({commit}, _quotationId) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ApiService.getOtpConfig(_quotationId).then(res => {
                    commit('UPDATE_OTP_CONFIG', res.data.otp);
                    resolve(res);
                    commit('STOP_LOADING');
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });
        },

        EDIT_OTP_EMAIL({commit, state}, _data) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                ApiService.editOtpEmail(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });

        },

        EDIT_OTP_PHONE({commit, state}, _data) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                ApiService.editOtpPhone(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });

        },

        EDIT_OTP_PHONE({commit, state}, _data) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                ApiService.editOtpPhone(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });

        },

        EDIT_OTP_PHONE({commit, state}, _data) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                ApiService.editOtpPhone(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });

        },

        SEND_OTP({commit, state}, _data) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                ApiService.sendOtp(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });
        },

        VERIFY_OTP({commit, state}, _data) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                ApiService.verifyOtp(_data).then(res => {
                    commit('UPDATE_VERIFY_OTP', res.data);
                    
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });

        },

        REMINDMELATER_OTP({commit, state}, _data) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                ApiService.remindmelaterOtp(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });

        },
    },
    mutations: {
        UPDATE_PAY_METHODS(state, payload) {
            state.availablePayMethods = merge(state.availablePayMethods, payload);
        },
        UPDATE_CHECKOUT_RESPONSE(state, payload) {
            state.checkOutResponse = merge(state.checkOutResponse, payload);
        },
        UPDATE_OTP_CONFIG(state, payload) {
            state.otpConfig = merge(state.otpConfig, payload);
        },
        UPDATE_OTP_CONFIG(state, payload) {
            state.otpConfig = merge(state.otpConfig, payload);
        },
        UPDATE_DOCUMENT_POLICY(state, payload) {
            state.policyDocument = payload
        },
        UPDATE_VERIFY_OTP(state, payload) {
            state.verifyOtp = payload
        },
        RESET_CHECKOUT_STATE(state) {
            const _state = {
                availablePayMethods: null,
                checkOutResponse: null
            };
            Object.assign(state, _state)
        },
        UPDATE_FLAGS_CHECKOUT(state, payload) {

            const FLAGS = new Array(...payload)

            for( let i in FLAGS){
               //if(FLAGS[i].hasOwnProperty('flagChild')) FLAGS[i].flagChild = []
            } 
            
            state.flags = FLAGS;
        },
    },

}

<template>
    <nav class="bread-crumbs d-none d-md-block">
        <ul class="">
            <li v-for="(item, i) in breadcrumbs"
                :key="i"
                :class="{'active' : $route.path.includes(item.link)}"
                class="breadcrumb-item">
                <a
                   @click.prevent=" goBack(item)"
                   href="#">
                    <h6 :class="{'text-muted' : !item.active}">
                        <i class="fa pr-2"
                           :class="item.icon"></i>{{ i + 1 }}. {{ item.label }}</h6>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
import {mapState} from "pinia";
import {useWebOrderStore} from "@/stores/weborder.store";

export default {
    name: "TheBreadcrumbs",
    props: {
        breadcrumbs: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapState(useWebOrderStore, ['currentUrlProd']),
    },
    methods: {
        goBack(item){
            if(this.currentUrlProd === 'dolomiti') return;

            if (item.clickable) this.$router.push({path: `/${item.link}`, query: {weborderid: this.$route.query.weborderid}})
        }
    }
}
</script>


<style lang="scss" scoped>

.bread-crumbs ul {
  list-style: none;
  display: inline-block;
  position: relative;
  left: -25px;
}

.bread-crumbs ul li {
  display: inline;

}

.bread-crumbs ul li a {
  .disabled {
    color: var(--gray) !important;
    text-decoration: none !important;
    cursor: not-allowed;
  }

  display: block;
  float: left;
  height: 50px;
  background-color: #f3f5fa;
  text-align: center;
  padding: 15px 20px 10px 60px;
  position: relative;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: #8093a7;
  font-style: normal;

}

.bread-crumbs ul li a:after {
  content: "";
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #f3f5fa;
  position: absolute;
  right: -25px;
  top: 0;
  z-index: 1;
}

.bread-crumbs ul li a:before {
  content: "" !important;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
}

.bread-crumbs ul li:first-child a {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bread-crumbs ul li:first-child a::before {
  display: none;
}

.bread-crumbs ul li:last-child a {
  padding-right: 40px;

}

.breadcrumb-item + .breadcrumb-item::before {
  content: "" !important;
}

</style>

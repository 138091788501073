<template>

    <div class="scrollableContainer w-100 h-100" >

        <div  class="w-100 h-100" >
            
            <Map v-if="partnerLocation"
                    ref="map"
                    :options="mapOptions"
                    :center="{lat: parseFloat(42.341208), lng: parseFloat(12.943866)}"
                    :zoom="mapZoom"
                    map-type-id="roadmap"
                    style="width: 100%; height: 100%; display:block;">
                <InfoWindow
                        :options="infoWindow.options"
                        :position="infoWindow.position"
                        :opened="infoWindow.open"
                        @closeclick="infoWindow.open = false">
                </InfoWindow>
                <MapMarker
                        v-for="m in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="false"
                        :icon="getIcon(m.index)"
                        @click="onPinClick(m.index)"/>
            </Map>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import {gmapApi} from 'gmap-vue';
import {Map} from 'gmap-vue';
import {Marker} from 'gmap-vue'
import {InfoWindow} from 'gmap-vue'
import * as GmapVue from "gmap-vue";
import {GOOGLE_MAPS_APIKEY} from "../Common/constants";
import Vue from 'vue';
import {bus} from "../Preventivo/_Common/EventBus";

Vue.use(GmapVue, {
    load: {
        key: GOOGLE_MAPS_APIKEY,
        libraries: 'places',
        region: 'IT',
        language: 'IT',
    },
    installComponents: false
})

export default {
    name: "TheOfficineConvenzionateMap",
    components: {
        Map: Map,
        MapMarker: Marker,
        InfoWindow: InfoWindow
    },
    props: [
        'partnerLocation',
    ],
    data() {
        return {
            selectedAddress: false,
            mapLoading: true,
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
            },
            mapZoom: 5,
            infoWindow: {
                options: {
                    content: '',
                    //optional: offset infowindow so it visually sits nicely on top of our marker
                    pixelOffset: {
                        width: 13,
                        height: -10
                    }
                },
                position: {},
                open: false
            },
            selectedMarker: null,
            icon: {
                path: 
                "M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z",
                fillOpacity: 1,
                strokeColor: "#ffffff",
                strokeWeight: 0.1,
                rotation: 0,
                scale: 0.05
            }

        }
    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.map.$mapPromise.then((map) => {
                //this.fitBounds()
                bus.$on('mapPanToPin', this.onPinClick)
            })
        })

        bus.$on('filterpartnerLocation', (val) => {
           this.infoWindow.open = false;
        })

        bus.$on('onUserNewLocation', (coords) => {
            this.infoWindow.open = false;
            const position = {
                        lat: parseFloat(coords.latitude),
                        lng: parseFloat(coords.longitude)
            };

            let bounds = new this.google.maps.LatLngBounds();
            bounds.extend(position)
            this.$refs.map.fitBounds(bounds);
            this.mapZoom = 12   
        })
    },
    computed: {
        google: gmapApi,
        markers() {
            return this.partnerLocation.map((val, index) => {

                if(!val) return false;

                return {
                    position: {
                        id: index,
                        lat: parseFloat(val.geographicCoordinates.latitude),
                        lng: parseFloat(val.geographicCoordinates.longitude)
                    },
                    index: index,
                    title: val.companyName,
                    content: `<span class="font-weight-bold mb-2 text-secondary">${val.companyName}</span><br/>
                             <span class="text-muted">${val.postalAddress}, ${val.zip}, ${val.town}</span><br/>
                             <span class="text-msecondary">${val.landlineNumber}</span>`
                }
            })
        },
    },
    methods: {
        fitBounds() {
            let bounds = new this.google.maps.LatLngBounds();
            this.markers.forEach((val) => {
                bounds.extend(val.position)
            })
            this.$refs.map.fitBounds(bounds)
        },
        // Centra il pin sulla mappa
        onPanToPin(position) {
            this.mapZoom = 16
            this.$refs.map.panTo(position)
        },
        // Attiva il popup sul marker
        onInfoWindowToggle(marker) {
            this.infoWindow.options.content = marker.content
            this.infoWindow.position = marker.position
            this.infoWindow.open = true
        },
        onPinClick(id) {
            this.centerPin(id)
            bus.$emit('scrollToLocation', id)
        },
        centerPin(id) {
            this.selectedMarker = id
            let marker = this.markers.find((m) => m.index === id)
            // Centro il marker sulla mappa
            this.onPanToPin(marker.position)
            // Mostro il pannello informativo sul marker
            this.onInfoWindowToggle(marker)
        },
        getIcon(id){
            return {
                ...(this.icon),
                fillColor: this.selectedMarker === id ? '#ffc850' : '#0761af'
            }
        }
    },
    beforeDestroy() {
        bus.$off('onUserNewLocation');
        bus.$off('filterpartnerLocation');
    },

}

</script>

import axios from 'axios';
import {API_URL, WEBORDER_API} from "@/Common/constants";

const motoplatinumService = {
    createAnonymousBeare: async () => {
        try {
            return await axios.post(`${WEBORDER_API}/auth/v1/bearer/createAnonymous`, {})
        } catch (e) {
            throw new Error(e);
        }
    },
    /**
     * Crea un nuovo webOrder MP
     * @param {object} request
     */
    newMp: async (request) => {
        const queryParams = new URLSearchParams(request).toString();

        try {
            return await axios.post(`${WEBORDER_API}/product/v2/new/mp?${queryParams}`)
        } catch (e) {
            throw new Error(e);
        }
    },
    /**
     * Crea un nuovo webOrder CVT
     * @param {object} request
     */
    newCvt: async (request) => {
        const queryParams = new URLSearchParams(request).toString();
        try {
            return await axios.post(`${WEBORDER_API}/product/v2/new/cvt?${queryParams}`)
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
      Ottiene un webOrder MP
      @param {string} webOrderId
    */
   
    getMp: async (webOrderId) => {
        try {
            return await axios.get(`${WEBORDER_API}/product/v2/get/mp/${webOrderId}`)
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
     * Ottiene un webOrder CVT
     * @param {string} webOrderId
     */
    getCvt: async (webOrderId) => {
        try {
            return await axios.get(`${WEBORDER_API}/product/v2/get/cvt/${webOrderId}`)
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
     * Aggiorna un webOrder MP
     * @param {object} request
     */
    setMp: async (request) => {
        try {
            return await axios.post(`${WEBORDER_API}/product/v2/set/mp`, request)
        } catch (e) {
            throw new Error(e);
        }
    },

     /**
     * Aggiorna un webOrder CVT
     * @param {object} request
     */
    setCvt: async (request) => {
        try {
            return await axios.post(`${WEBORDER_API}/product/v2/set/cvt`, request)
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
     * conferma un webOrder 
     * @param {string} webOrderId
    */
    confirm: async (webOrderId) => {
        try {
            return await axios.post(`${WEBORDER_API}/product/v2/confirm?webOrderId=${webOrderId}`)
        } catch (e) {
            throw new Error(e);
        }
    },

    searchProduct: async (request) => {
        try {
            return await axios.post(`${WEBORDER_API}/product/v1/searchProduct`, request)
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
     * conferma un webOrder 
     * @param {object} request
    */
    saveAndSendEmail: async (webOrderId, email) => {
        try {
            return await axios.post(`${WEBORDER_API}/product/v2/saveQuotation?webOrderId=${webOrderId}&email=${email}`)
        } catch (e) {
            throw new Error(e);
        }
    },

};

export default motoplatinumService;

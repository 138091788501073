export default () => {

    //Configurazione del widget di Zendesk
    const path = window.location.pathname;
    // Se la pagina non è in un iframe
    if (window.self === window.top) {
        // Se mi trovo in homepage
        if (path === '/') {
            window.zESettings = {
                webWidget: {
                    position: { horizontal: 'left', vertical: 'bottom' },
                    chat: {
                        suppress: true
                    },
                    helpCenter: {
                        suppress: false

                    }
                }
            };
        } else {
            if (!path.toLowerCase().includes('landing')) {
                window.zESettings = {
                    webWidget: {
                        position: { horizontal: 'right', vertical: 'bottom' },
                        chat: {
                            suppress: false
                        },
                        helpCenter: {
                            suppress: true,
                            title: {
                                'it-IT': 'Come possiamo aiutarti?',
                                '*': 'Come possiamo aiutarti?'
                            },
                            searchPlaceholder: {
                                '*': 'Es. Come fare per...'
                            }

                        }
                    }
                };
            } else {
                window.zESettings = {
                    webWidget: {
                        position: { horizontal: 'right', vertical: 'bottom' },
                        chat: {
                            suppress: true
                        },
                        helpCenter: {
                            suppress: true,
                            title: {
                                'it-IT': 'Come possiamo aiutarti?',
                                '*': 'Come possiamo aiutarti?'
                            },
                            searchPlaceholder: {
                                '*': 'Es. Come fare per...'
                            }

                        }
                    }
                };
            }
        }
    } else {
        window.zESettings = {
            webWidget: {
                chat: {
                    suppress: true
                },
                contactForm: {
                    suppress: true
                },
                helpCenter: {
                    suppress: true
                },
                talk: {
                    suppress: true
                },
                answerBot: {
                    suppress: true
                }
            }
        };

    }
    
        // La tabella in DD e MY è di default "collapse" su mobile e adeve aggiungere la classe show su desktop 
        function displayWindowSize() {
            var screenSize = document.documentElement.clientWidth;
    
            var polizze = document.querySelectorAll("#container-polizze .collapse");
            if (!polizze) return
            polizze.forEach((polizza) => {
                if (screenSize >= 768) {
                    polizza.classList.add("show");
                } else {
                    polizza.classList.remove("show")
                }
            })
        }
    
        displayWindowSize()
        const btns = document.querySelectorAll(".pacchetti-arrow");
        btns.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                const classes = e.target.classList
                if (classes.contains("fa-angle-down")){
                    classes.remove("fa-angle-down")
                    classes.add('fa-angle-up')
                } 
                else if (classes.contains("fa-angle-up")){
                    classes.remove("fa-angle-up")
                    classes.add('fa-angle-down')
                }
            })
        })
        window.addEventListener("resize", displayWindowSize);
};

// Parifica l'altezza dei div che corrispondono al selettore al più alto dei DIV
const EqualHeights = (selector) => {
    const findClass = document.querySelectorAll(selector);
    let tallest = 0;
    let i;

    for (i = 0; i < findClass.length; i++) {
        const ele = findClass[i];
        const eleHeight = ele.offsetHeight;
        tallest = (eleHeight > tallest ? eleHeight : tallest);
    }
    for (i = 0; i < findClass.length; i++) {
        findClass[i].style.height = tallest + "px";
    }
};




export { EqualHeights };




<template>
    <div>
        <transition-group name="fade" duration="200" mode="out-in">
            <adaptive-spinner
                    key="1"
                    v-if="loading"/>
            <div
                    key="2"
                    v-else-if="error"
                    class="d-flex justify-content-center align-items-center flex-column h-100 py-5 my-5">
                <i class="fal fa-times text-danger fa-4x"></i>
                <p class="text-danger">{{ error }}</p>
            </div>
            <div
                    key="3"
                    v-else-if="!loading && ready"
                    class="justify-content-center">
                <transition
                        name="fade" :duration="200" mode="out-in">
                    <router-view></router-view>
                </transition>
            </div>
        </transition-group>
    </div>

</template>

<script>

import {useWebOrderStore} from "@/stores/weborder.store";
import {mapWritableState} from 'pinia'
import {mapActions} from "pinia";
import AdaptiveSpinner from "@/Common/AdaptiveSpinner";
import {bus} from "@/Preventivo/_Common/EventBus";

export default {
    name: "SnowcareQuotation",
    data() {
        return {
            ready: false,
            loading: false,
            error: null
        }
    },
    async created() {
        await this.onWebOrderInit()
    },
    onBeforeDestroy() {
        bus.$off('onLocaleChange')
    },
    components: {AdaptiveSpinner},
    methods: {
        ...mapActions(useWebOrderStore, ['createNewWebOrder', 'setWebOrder']),

        async onCreateNewWebOrder() {
            try {
                this.loading = true
                await this.createNewWebOrder('snowcare', this.$i18n.locale)
                await this.$router.replace({
                    path: this.$route.path,
                    query: {...this.$route.query, weborderid: this.quotation.webOrderID}
                })
                this.loading = false
                this.ready = true
            } catch (e) {
                this.loading = false
                this.ready = false
                this.error = this.$t('global.errors.generic')
                throw(e)
            }
        },
        async onWebOrderInit() {
            // Verifico ci sia in querystring un numer odi weborder
            if (!this.$route.query.weborderid) {
                // Se non c'è creo un nuovo webOrder
                await this.onCreateNewWebOrder()
            } else this.ready = true
        },

    },
    computed: {
        ...mapWritableState(useWebOrderStore, ['quotation']),

    },
}
</script>

<style scoped>

</style>

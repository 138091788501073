<template>
    <div class="container-fluid p-0 m-0 ">
        <banner-alert v-if="ux.alertBanner.active"></banner-alert>
        <transition name="fade">
            <ux-loading-standard v-if="ux.loading > 0"></ux-loading-standard>
        </transition>
        <div class="container-fluid p-0 px-md-2 py-md-4 workspace"
             :class="{ 'bg-white' : IS_IFRAMED }">
            <div class="container p-0 overflow-hidden" :class="{ 'min-vh-100 pb-5' : IS_IFRAMED }">
                <div class="row">
                    <div class="col-12">
                        <banner-quotation-restore></banner-quotation-restore>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-12" :class="{ 'col-sm-12 col-md-8 pr-md-3 pr-lg-3 pr-xl-4': isEnabledSidebar }">
                        <!-- Blocco preventivatore -->
                        <div class="form-container">
                            <div class="form-container-header" v-if="ux.goBackEnabled || ux.currentPageTitle">
                                <div class="border-right p-3" v-if="ux.goBackEnabled && isBackButtonEnabled">
                                    <i class="form-header-button fa fa-lg fa-fw fa-chevron-left clickable"
                                       @click="onclickBack"></i>
                                </div>
                                <h2 class="form-container-title m-0 p-3 pl-2" v-if="ux.currentPageTitle">
                                    {{ux.currentPageTitle}}
                                </h2>
                                <div class="border-left p-3 d-block d-md-none">
                                    <i class="form-header-button fa fa-lg fa-fw fa-tasks "
                                       @click="onSummaryToggle"></i>
                                </div>
                            </div>
                            <transition
                                    name="slideLeft"
                                    mode="out-in">
                                <router-view></router-view>
                            </transition>
                        </div>
                        <!-- Fine Blocco preventivatore -->

                    </div>
                    <div class="mobile-summary d-block d-md-none overflow-hidden"
                         :class="{'summary-open' : showSummary}"
                         v-if="isEnabledSidebar">
                        <div class="vw-100 d-flex justify-content-between align-items-center mobile-summary-header border-bottom">
                            <div class="align-self-center">
                                <p class="m-0 text-secondary font-weight-bold">RIEPILOGO POLIZZA</p>
                            </div>
                            <div class="p-3 border-left">
                                <i class="fas fa-times text-primary fa-2x"
                                   @click="onSummaryToggle"></i></div>
                        </div>
                        <div class="p-2 vw-100">
                            <dd-summary></dd-summary>
                        </div>
                    </div>
                    <div class="d-none d-md-block col-sm-12 col-md-4 pr-0 mr-0  pl-md-4 pl-lg-4 pl-xl-4" v-if="isEnabledSidebar">
                        <dd-summary></dd-summary>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DdSummary from './Summary/Summary'
    import UxLoadingStandard from '../_Common/Components/UxLoadingStandard';
    import RouterViewContainer from '../_Common/RouterViewContainer'
    import UxLoadingQuotation from '../_Common/Components/UxLoadingQuotation';
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import BannerAlert from '../_Common/Components/BannerAlert';
    import BannerQuotationRestore from '../_Common/Components/BannerQuotationRestore';
    // Tutte le funzioni comuni di init del preventivatore si trovano qui
    import {BootstrapOperations} from '../_Common/Mixins/CommonInitFunctions.mixin'


    export default {
        components: {
            BannerAlert,
            BannerQuotationRestore,
            UxLoadingQuotation,
            UxLoadingStandard,
            DdSummary,
            RouterViewContainer

        },
        mixins: [BootstrapOperations],

        data() {
            return {
                currentProduct: 'dottordog'
            }
        },

        computed: {
            ...mapState(['ux', 'dottordog']),
            ...mapGetters([
                'DD_PET_KIND',
                'GLOBAL_LOGGED_USER',
                'IS_IFRAMED',
                'DD_TOKEN_DATA'
            ]),
            isEnabledSidebar(){
                return !this.$route.path.includes('otp') && !this.$route.path.includes('thank-you')
            },
            isBackButtonEnabled(){
                if((this.$route.path ==="/pet" || this.$route.path ==="/quotazione")  && this.DD_TOKEN_DATA.urlprod === 'facileit'){
                    return false
                }
                return true
            }

        },
        created(){
            function _getQueryVariable(variable) {
                const query = window.location.search.substring(1);
                const vars = query.split("&");
                for (let i = 0; i < vars.length; i++) {
                    const pair = vars[i].split("=");
                    if (pair[0].toLowerCase() === variable) {
                        return pair[1];
                    }
                }
                return '';
            }

            // Se l'urlProd non è presente nell'url lo resetto dallo store
            this.SET_URLPROD(_getQueryVariable('urlprod'));
        
        },
        methods: {
            ...mapActions(['DD_GET_QUOTATION',
                'GLOBAL_GET_FORMS_CONFIGURATION',
                'GLOBAL_RESTORE_ANAGRAFICA']),
            ...mapMutations(['RESET_LOADING',
                'SET_BANNER', 'REMOVE_BANNER',
                'SET_URLPROD',
                'FULL_RESET']),
        }
    }


</script>

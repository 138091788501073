import merge from 'lodash/merge';
import GlobalApiService from '../../Preventivo/_Common/Services/GlobalQuotation.service';
import * as Cookies from 'js-cookie';

const _getAnagraficaModel = () => {
    return {
        isCompany: false,
        name: '',
        surname: '',
        email: '',
        cellular: '',
        address: {
            street: '',
            number: '',
            location: '',
            zipCode: '',
            provinceId: '',
            countryId: ''

        },
        fiscalCode: '',
        professionId: null,
        maritalStatusId: null,
        flags: [],
    }
};

const _getDefaultState = () => {
    return {
        // Anagrafica cliente
        customerDetails: {
            isOwner: true,
            isBeneficiario: true,
            isContractor: null,
            isUtilizer: true,
            contractor: _getAnagraficaModel(),
            owner: _getAnagraficaModel(),
            utilizer: _getAnagraficaModel(),
            user: _getAnagraficaModel(),
            contractorIsOwner: null,
            contractorIsUtilizer: true,
            ownersDrivingLicense: null
        },
        // Configurazione campi restituita dal servizio
        formFields: {},
        dealerEmail: {
            emails: [],
            selectedDealerEmail: null
        },
        formConfiguration: {},
        flags: {}
    }
};

export default {
    state: _getDefaultState(),
    actions: {
        GLOBAL_RESTORE_ANAGRAFICA({state, commit}) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                GlobalApiService.getAnagrafica().then(res => {
                    if (res.data.address.street.includes(',')) {
                        const _split = res.data.address.street.split(',');
                        res.data.address.street = _split[0];
                        res.data.address.number = _split[1];
                    }
                    commit('UPDATE_ANAGRAFICA_CONTRACTOR', res.data);
                    commit('STOP_LOADING');
                    commit('UPDATE_CUSTOMER_COMPANY', res.data.fiscalCode.length < 12 && res.data.name === '');
                    resolve();
                }).catch(e => commit('STOP_LOADING'));
            });
        },

        // Conferma l'email dell'utente dal link nell'email preventivo
        GLOBAL_CONFIRM_EMAIL({commit}, _token) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                GlobalApiService.confirmEmail(_token).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });
        },

        // Recupera l'id quotazione dal token nella mail di salva preventivo
        GLOBAL_GET_SAVED_QUOTATION_ID({commit}, _token) {
            commit('START_LOADING');
            return new Promise((resolve, reject) => {
                GlobalApiService.getSavedQuotationId(_token).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        // Ottiene la configurazione dei campi
        GLOBAL_GET_FORMS_CONFIGURATION({state, commit, rootState}, _quotationId) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                GlobalApiService.formConfiguration(_quotationId).then(res => {
                    commit('UPDATE_FORM_FIELDS', res.data.forms);
                    if (rootState.ux.currentProduct === 'dottordog') {
                        commit('DD_UPDATE_BREEDS', {
                            dog: [...(res.data.forms.dogBreeds.values)],
                            cat: [...(res.data.forms.catBreeds.values)],
                        });
                    }
                    commit('STOP_LOADING');
                    resolve();
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },
        //NUOVO
        GET_DEALER_EMAILS({state, commit, dispatch, rootState}) {

            const _query = {
                quotationId: rootState.quotation.quotationData.quotationId,
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                GlobalApiService.getDealerEmails(_query).then(res => {

                    commit('UPDATE_DEALER_EMAILS', {emails: res.data});

                    commit('STOP_LOADING');
                    resolve();

                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },
        //NUOVO
        // Imposta l'email preferita del dealer
        POST_DEALER_EMAIL({state, commit, rootState, rootGetters}, _email) {

            const _data = {
                email: _email,
                quotationId: rootState.quotation.quotationData.quotationId,
                productType: rootGetters.GLOBAL_CURRENT_PRODUCT_SHORTNAME == "MP" ? rootState.motoplatinum.config.productType : rootGetters.GLOBAL_CURRENT_PRODUCT_SHORTNAME
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                GlobalApiService.setDealerEmail(_data).then(res => {
                    commit('UPDATE_DEALER_EMAILS', {selectedDealerEmail: _email});
                    commit('STOP_LOADING');
                    resolve();
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },
        GET_FORM_CONFIGURATION({commit}, _quotationId) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                GlobalApiService.formConfiguration(_quotationId).then(res => {
                    commit('UPDATE_FORM_CONFIGURATION', res.data);
                    resolve(res);
                    commit('STOP_LOADING');
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });
        },
        GET_FLAGS({commit}, _quotationId) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                GlobalApiService.getFlags(_quotationId).then(res => {
                    commit('UPDATE_FLAGS', res.data);
                    resolve(res);
                    commit('STOP_LOADING');
                }).catch((e) => {
                    reject(e);
                    commit('STOP_LOADING');
                });
            });
        },
    },
    getters: {
        GLOBAL_ANAGRAFICA_CONTRACTOR: (state) => {
            try {
                const _arr = Object.values(state.customerDetails.contractor);
                const _filteredArr = _arr.filter((value) => {
                    return value && typeof value !== 'object' && value.constructor !== Object
                });
                if (_filteredArr.some((_v) => !!_v)) {
                    return state.customerDetails.contractor
                } else return false;
            } catch (e) {
                return false;
            }
        },

        GLOBAL_ANAGRAFICA_OWNER: (state) => {
            try {
                const _arr = Object.values(state.customerDetails.owner);
                const _filteredArr = _arr.filter((value) => {
                    return value && typeof value !== 'object' && value.constructor !== Object
                });

                if (_filteredArr.some((_v) => !!_v)) {
                    return state.customerDetails.owner
                } else return false;
            } catch (e) {
                return false;
            }
        },

        GLOBAL_ANAGRAFICA_UTILIZER: (state) => {
            try {
                const _arr = Object.values(state.customerDetails.utilizer);
                const _filteredArr = _arr.filter((value) => {
                    return value && typeof value !== 'object' && value.constructor !== Object
                });

                if (_filteredArr.some((_v) => !!_v)) {
                    return state.customerDetails.utilizer
                } else return false;
            } catch (e) {
                return false;
            }
        },

        GLOBAL_ANAGRAFICA_USER: (state) => {
            try {
                const _arr = Object.values(state.customerDetails.user);
                const _filteredArr = _arr.filter((value) => {
                    return value && typeof value !== 'object' && value.constructor !== Object
                });

                if (_filteredArr.some((_v) => !!_v)) {
                    return state.customerDetails.user
                } else return false;
            } catch (e) {
                return false;
            }
        },

        // Indica se utente è loggato come AGENTE
        GLOBAL_IS_AGENT: () => {
            return process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? process.env.IUN_TOKEN || Cookies.get('IUN24hAssistance') : Cookies.get('IUN24hAssistance');
        },

        GLOBAL_LOGGED_USER: () => {

            // Token salvato
            const _token = window.globalConfigVariables.token;
            // Nome utente da visualizzare in menu ecc...
            const _userDisplayName = Cookies.get('24hname');
            // Tipo di utente P: produttore C: Cliente
            const _userKind = Cookies.get('24huserkind');

            const _userData = {
                token: _token,
                userDisplayName: _userDisplayName,
                userKind: _userKind
            };

            return _token && _token !== 'null' ? _userData : false;
        },
        GLOBAL_USER_DATA: () => {
            return JSON.parse(window.localStorage.getItem('arUserData'))
        },
        // Restituisce i due caratteri da usare nell'avatar in AR
        GLOBAL_USER_SHORTNAME: (state) => {
            if (/^[0-9]{11}$/i.test(state.customerDetails.user.fiscalCode)) {
                return state.customerDetails.user.surname.substring(0, 2)
            } else {
                return `${state.customerDetails.user.name.substring(0, 1)}${state.customerDetails.user.surname.substring(0, 1)}`
            }
        },

        // Configurazione dei form recuperata dall'api es.:
        // Contenuti delle select professione e stato civile, razze dei cani ecc...
        GLOBAL_FORM_CONFIG: (state) => {
            return state.formFields
        },

    },
    mutations: {

        UPDATE_ANAGRAFICA(state, payload) {
            state.customerDetails = merge(state.customerDetails, payload);
        },

        RESET_ANAGRAFICA_OWNER(state) {
            state.customerDetails.owner = _getAnagraficaModel();
        },

        RESET_ANAGRAFICA_CONTRACTOR(state) {
            state.customerDetails.contractor = _getAnagraficaModel();
        },

        RESET_ANAGRAFICA_UTILIZER(state) {
            state.customerDetails.utilizer = _getAnagraficaModel();
        },

        UPDATE_ANAGRAFICA_CONTRACTOR(state, payload) {
            state.customerDetails.contractor = merge(state.customerDetails.contractor, payload);
        },
        UPDATE_ANAGRAFICA_OWNER(state, payload) {
            state.customerDetails.owner = merge(state.customerDetails.owner, payload);
        },
        UPDATE_ANAGRAFICA_USER(state, payload) {
            state.customerDetails.user = merge(state.customerDetails.user, payload);
        },
        UPDATE_ANAGRAFICA_OWNER_EMAIL(state, payload) {
            state.customerDetails.owner.email = payload;
        },
        UPDATE_CUSTOMER_DATA(state, payload) {
            state.customerDetails[payload.customerKind] = payload.value;
        },
        UPDATE_DRIVING_LICENSE(state, payload) {

            state.customerDetails.ownersDrivingLicense = payload;
        },

        UPDATE_CUSTOMER_CELLULAR(state, payload) {
            state.customerDetails[payload.customerKind].cellular = payload.value;
        },
        UPDATE_CUSTOMER_KIND(state, payload) {
            state.customerDetails[payload.kind] = payload.value;
        },
        UPDATE_FORM_FIELDS(state, payload) {
            state.formFields = payload;
        },
        UPDATE_CUSTOMER_COMPANY(state, payload) {
            state.isCompany = payload;
        },
        UPDATE_OWNER_COMPANY(state, payload) {
            state.customerDetails.owner.isCompany = payload;
        },
        UPDATE_CONTRACTOR_COMPANY(state, payload) {
            state.customerDetails.contractor.isCompany = payload;
        },
        RESET_ANAGRAFICA_STATE(state) {
            Object.assign(state, _getDefaultState());
        },
        //NUOVO
        UPDATE_DEALER_EMAILS(state, payload) {
            state.dealerEmail = merge(state.dealerEmail, payload);
        },
        UPDATE_FORM_CONFIGURATION(state, payload) {
            state.formConfiguration = payload;
        },
        UPDATE_FLAGS(state, payload) {
            state.flags = payload;
        },
        UPDATE_FLAGS_CONTRACTOR(state, payload) {
            state.customerDetails.contractor.flags = payload;
        },
        UPDATE_FLAGS_OWNER(state, payload) {
            state.customerDetails.owner.flags = payload;
        },
        UPDATE_FLAGS_UTILIZER(state, payload) {
            state.customerDetails.utilizer.flags = payload;
        },

    }

}

<template>

    <div id="carousel-home" class="w-100 h-100">
        <div class="container-scroll-x pb-3">
            <ul class="nav nav-pills justify-content-between">
                <li class="nav-item">
                    <a href="#"
                       class="nav-link rounded-pill"
                       :class="{ active: activeItem === 'MP' }"
                       @click.prevent="()=>{ activeItem = 'MP'}">MOTO E SCOOTER</a>
                </li>
                <li class="nav-item">
                    <a href="#"
                       class="nav-link rounded-pill"
                       :class="{ active: activeItem === 'SC'}"
                       @click.prevent="()=>{ activeItem = 'SC'}">SCI E SNOWBOARD</a>
                </li>
                <li class="nav-item">
                    <a href="#"
                       class="nav-link rounded-pill"
                       :class="{ active: activeItem === 'MS'}"
                       @click.prevent="()=>{ activeItem = 'MS'}">SPORT</a>
                </li>
                <li class="nav-item">
                    <a href="#"
                       class="nav-link rounded-pill"
                       :class="{ active: activeItem === 'DD'}"
                       @click.prevent="()=>{ activeItem = 'DD'}">ANIMALI DOMESTICI</a>
                </li>
                <li class="nav-item">
                    <a href="#"
                       class="nav-link rounded-pill"
                       :class="{ active: activeItem === 'BP'}"
                       @click.prevent="()=>{ activeItem = 'BP'}">E-BIKE</a>
                </li>
                <li class="nav-item">
                    <a href="#"
                       class="nav-link rounded-pill"
                       :class="{ active: activeItem === 'SK'}"
                       @click.prevent="()=>{ activeItem = 'SK'}">BARCA</a>
                </li>
            </ul>
        </div>

        <div class="mt-4 mt-md-3">
            <transition name="fade" mode="out-in">
                <div key="1" v-if="activeItem === 'MP'">
                    <the-carousel
                            product="MP"
                            :double-image="false"
                            :items-desktop="4"
                            :items-mobile="3"
                            :show-navigation="true"
                            :items-gray="true">
                    </the-carousel>
                </div>

                <div key="2" v-else-if="activeItem === 'SC'">
                    <the-carousel
                            product="SC"
                            :double-image="false"
                            :items-desktop="4"
                            :items-mobile="3"
                            :show-navigation="true"
                            :items-gray="true">
                    </the-carousel>

                </div>

                <div key="3" v-else-if="activeItem === 'MS'">
                    <the-carousel
                            product="MS"
                            :double-image="false"
                            :items-desktop="4"
                            :items-mobile="3"
                            :show-navigation="true"
                            :items-gray="true">
                    </the-carousel>
                </div>

                <div key="4" v-else-if="activeItem === 'DD'">
                    <the-carousel
                            product="DD"
                            :double-image="false"
                            :items-desktop="4"
                            :items-mobile="3"
                            :show-navigation="true"
                            :items-gray="true">
                    </the-carousel>
                </div>

                <div key="5" v-else-if="activeItem === 'BP'">
                    <the-carousel
                            product="BP"
                            :double-image="false"
                            :items-desktop="4"
                            :items-mobile="3"
                            :show-navigation="true"
                            :items-gray="true">
                    </the-carousel>
                </div>

                <div key="6" v-else>
                    <the-carousel
                            product="SK"
                            :double-image="false"
                            :items-desktop="4"
                            :items-mobile="3"
                            :show-navigation="true"
                            :items-gray="true">
                    </the-carousel>
                    <div class="d-flex justify-content-center">
                        <div class="col-6 col-md-3">
                            <a href="/partner-skippermate" target="_blank">
                                <img alt="Maxitender"
                                     src="/images/index_logos/barca_maxitender.png"></a>
                        </div>
                        <div class="col-6 col-md-3">
                            <a href="/partner-skippermate" target="_blank">
                                <img alt="barca sacs"
                                     src="/images/index_logos/barca_sacs.png"></a>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import TheCarousel from "@/Common/TheCarousel";

export default {
    name: "CarouselSectionHome",
    components: {
        TheCarousel
    },
    data() {
        return {
            activeItem: 'MP'
        }
    }
}
</script>

<style lang="scss" scoped>

@media (max-width: 575.98px) {
  .nav.nav-pills {
    width: 150%;
  }
  .nav-link {
    font-size: 10px;
  }
}

</style>

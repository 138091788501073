import {bus} from "../EventBus";

export default {
    props: {
        size: {
            type: String,
            required: true,
            validator: (value) => {
                return ['small', 'regular', 'large'].indexOf(value) !== -1
            }
        },
        borders: {
            type: Boolean,
            required: true,
            validator: (value) => {
                return typeof value === 'boolean';
            }
        },
        theme: {
            type: String,
            required: true,
            validator: (value) => {
                return ['light', 'dark'].indexOf(value) !== -1
            }
        },
        alignment: {
            type: String,
            required: true,
            validator: (value) => {
                return ['vertical', 'horizontal', 'button-down', 'responsive'].indexOf(value) !== -1
            }
        },
        sticky: {
            type: Boolean,
            required: false,
            validator: (value) => {
                return typeof value === 'boolean';
            }
        },
        urlprod: {
            type: String,
            required: false
        }
    },
    computed: {
        classes: function () {
            return `fast-quote-${this.size} fast-quote-borders-${this.borders} fast-quote-${this.theme} fast-quote-${this.alignment}`
        }
    },
    methods: {
        onQuotationClick: function () {
            window.location.href = this.url;
        },

        handleFastQuoteVisibility: function (e) {
            if (this.sticky) {
                try {
                    if (e.percentTop < 0) {
                        bus.$emit('onShowStickyFastquote', true);
                    } else if (e.percentTop > 0) {
                        bus.$emit('onShowStickyFastquote', false);
                    }
                } catch (e) {
                }
            }

        },
    },
}

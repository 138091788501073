import GlobalApiService from '../_Common/Services/GlobalQuotation.service';
import MpApiService from './MotoplatinumQuotation.service';
import merge from 'lodash/merge';
import {GaEvents} from '../../Common/AnalyticsMethods';
import * as moment from "moment";
import isEqual from "lodash/isEqual";
import  cloneDeep from "lodash/cloneDeep";
import ArApiService from "../../AreaRiservata/AreaRiservata.service";
import SelligentPusher from "../../Common/selligentPusher";
import motoplatinumService from '../../services/motoplatinum.service';
import infobikeService from '../../services/infoBike.service';
import { formatNumberToComma } from '../../Common/utils';

const _getDefaultAtrcYears = () => {
    const _y = new Date().getFullYear();
    const _arr = []
    for (let i = 0; i < 10; i++) {
        _arr.push({"year": (_y - i).toString(), "value": "--"})
    }
    return _arr.reverse()
};
const _getDefaultState = () => {
    return {
        // Configurazioni del preventivatore
        config: {
            vehicleDataValues: {
                brands: [],
                models: [],
                versions: [],
            },
            // Indica lo step raggiunto e validato dall'utente nel processo di quotation
            // E quindi indica anche se lo step e raggiungibile dai breadcrumbs nella quotation
            // 0=nessuno 1=Veicolo, 2=Proprietario, 3=DatiAss, 4=Bundles, 5=Addons
            validatedQuotationSteps: 0,
            // Indica lo step raggiunto e validato dall'utente nel processo globale
            // E quindi indica anche se lo step e raggiungibile dai breadcrumbs
            // 0=nessuno, 1=Veicolo, 2=Proprietario, 3=DatiAss, 4=Bundles, 5=Addons
            validatedGlobalSteps: 0,
            vehicleDetail: null,
            bundles: [],
            motoAppInstalled: false,
            addons: [],
            // Garanzie aggiuntive es. MpGOLD
            services: [],
            specialistAddons: [],
            upsellAddons: null,
            specialistAddonsGrossAmount: null,
            specialistSelected: false,
            productType: null,
            printUrl: null,
            noFastQuote: false,
            notBlockingAlert: null,
            notBlockingAlertDescription: null,
            /*  dealerEmail: {
                 emails: [],
                 selectedDealerEmail: null
             }, */
            motoBox: {
                check: false,
                imei: null,
                sn: null,
                isEditable: false
            },
            A005: {
                data: {
                    exists: false,
                    name: null,
                    surname: null,
                    email: null,
                    phone: null,
                    fiscalCode: null,
                    plate: null,
                    urlProd: null
                },
                documentUploadData: {}
            },
            // La prima data di decorrenza restituita da startQuotation,
            // permette di limitare i calendari anche dopo che la data
            // di decorrenza è stata modificata
            minEffectiveDate: null,
            maxEffectiveDate: null,
            // Indica se il componente del preventivatore è caricato non nel flusso principale, es.: Area riservata
            callbackOverride: false,
            fastQuoteRequest: {
                plate: null,
                date: null
            },
            // includeFees flag necessario a volte in
            // getBundles in area riservata wrike id: 624006903
            // Di base indica se si tratta di un preventivo di modifica
            includeFees: null,
            // Indica di che tipo di preventivo si tratta
            webQuotationType: null

        },
        quotationSummary: {},
        quotationData: {
            vehicleData: {
                vehicleType: {
                    // 1 = MM 2 = MC 3 = Minicar
                    value: null,
                    disabled: false
                },
                plate: {
                    value: '',
                    disabled: false
                },
                registrationDate: {
                    value: '',
                    disabled: false
                },
                purchaseDate: {
                    value: '',
                    disabled: false
                },
                brand: {
                    value: null,
                    disabled: false
                },
                model: {
                    value: null,
                    disabled: false
                },
                version: {
                    value: null,
                    disabled: false
                },
                prgall: {
                    value: null,
                    disabled: false
                },
                abs: {
                    value: true,
                    disabled: false
                },
                insuredValue: {
                    value: null,
                    disabled: false
                },
                mileage: {
                    value: null,
                    disabled: false
                },
                antiTheft: {
                    // 1 = Catena 2= blocca disco 3=Blocca manubrio 4=Elettronico 5=LoJack 6=Altro 7=Nessuno
                    value: null,
                    disabled: false
                },
                kmsPerYear: {
                    //1 = Meno di 2000 2= tra 2 e 5 3= piu di 5
                    value: null,
                    disabled: false
                }
            },
            ownerData: {
                ownerFiscalCode: {
                    value: '',
                    disabled: false
                },
                ownerProvince: {
                    value: '',
                    disabled: false
                },
                ownerIstatCode: {
                    value: '',
                    disabled: false
                },
                ownerCAP: {
                    value: '',
                    disabled: false
                },
                carsInFamily: {
                    //0 = nessuno /1 = 1 / 2 = piu di 1
                    value: null,
                    disabled: false
                },
                drivingType: {
                    // 1= esclusiva 2=esperta 3=libera
                    value: null,
                    disabled: false
                },
                uniqueDriver: {
                    value: null,
                    disabled: false
                },
                usageType: {
                    // 1 l/s/t 2= Tempo libero , 3 = Consegne
                    value: null,
                    disabled: false
                }
            },
            insuranceData: {
                insuranceSituation: {
                    // 1 - Polizza scad o scaduta
                    // 2 - Prima polizza
                    // 3 - Altri cazzi
                    value: null,
                    disabled: false
                },
                riskCertificateAvailable: {
                    // 0 - Si
                    // 2 - No
                    // 3 - Si altro mio veicolo
                    // 1 - Altro veicolo famiglia
                    value: null,
                    disabled: false
                },
                familyRcVehicle: {
                    familyRcVehiclePlate: {
                        value: '',
                        disabled: false
                    },
                    familyRcVehicleOwnerFiscalCode: {
                        value: '',
                        disabled: false
                    }
                },
                atrc: {
                    disabled: true,
                    years: _getDefaultAtrcYears()
                },
                cu: {
                    value: null,
                    disabled: false
                },
                effectiveDate: {
                    value: null,
                    disabled: false
                }
            }
        },
        bookingData: {
            contractId: null,
            locations: null,
            appointments: [

                {
                    date: null,
                    endDateTime: null,
                    slot: null,
                    startDateTime: null,
                    weekDay: null,
                },
                {
                    date: null,
                    endDateTime: null,
                    slot: null,
                    startDateTime: null,
                    weekDay: null,
                },
                {
                    date: null,
                    endDateTime: null,
                    slot: null,
                    startDateTime: null,
                    weekDay: null,
                }
            ],
            dates: {
                availableBeginDate: null,
                availableEndDate: null
            },
            timeSlots: [],
            selectedInstallers: []
        },
        currentWebOrder: {},
        infobikeDetail: {},
        mpProduct: null,
        cvtProduct: null,
    };
   
};

export default {

    state: _getDefaultState(),
    getters: {
        MP_VEHICLE_DATA_VALUES: state => {
            return state.config.vehicleDataValues;
        },

        MP_SELECTED_BUNDLE: (state, getters, rootState) => {
            // Controlla se è stato selezionato un pacchetto senza box
            const _priceCheck = state.config.bundles.find(bundle => {
                return bundle.price.tariffId === rootState.quotation.quotationData.selectedBundleId
            });

            // Controlla se è stato selezionato un pacchetto con box
            const _boxPriceCheck = state.config.bundles.find(bundle => {
                return bundle.boxPrice && bundle.boxPrice.tariffId === rootState.quotation.quotationData.selectedBundleId
            });

            // Se non c'è un id salvato ritorno false
            if (!rootState.quotation.quotationData.selectedBundleId) return false;

            // Controllo se è stato selezionato il pacchetto con box o senza box e lo metto nella response
            const bundle = _priceCheck || _boxPriceCheck

            if(!bundle) return {}

            // Imposto il valore di default per l'opzione scelta
            bundle.chosenOption = null

            // Verifico se il pacchetto scelto è con box o senza box
            const _isBox = !rootState.quotation.quotationData.selectedBundleId.includes('NOBB')

            // Devo capire quale è il valore scelto
            bundle.chosenValue = _priceCheck ? _priceCheck.price.value : _boxPriceCheck.boxPrice.value

            // Se il pacchetto ha opzioni devo impostare quale è stata selezionata
            if (bundle.options.length > 0) {
                let option = bundle.options.find(el => el.idBox === rootState.quotation.quotationData.selectedBundleOptionId || el.idNoBox === rootState.quotation.quotationData.selectedBundleOptionId)

                // Se non arriva un opzione selezionata dall'api
                // metto di default quella che mi arriva come "isSelected" === true
                if (!option) option = option ? option : bundle.options.find(el => el.isSelected === true)
                bundle.chosenOption = option
                bundle.chosenValue = _isBox ? bundle.chosenValue + option.valueBox : bundle.chosenValue + option.valueNoBox
            }

            // E' il prezzo del pacchetto originale senza lo sconto dato dall'essere un pacchetto di garanzie
            bundle.oldValue = bundle.price.value * 1.1.toFixed(2)

            // Indica se e quanto si sta risparmiando con la box rispetto al prezzo originale del pacchetto
            bundle.boxSaving = bundle.boxPrice ? bundle.oldValue - bundle.boxPrice.value - 59 : null
            if (bundle.boxSaving) bundle.boxSaving = bundle.boxSaving.toFixed(2)

            // Ritorno il pacchetto scelto con il valore scelto dall'utente
            return bundle
        },

        MP_VEHICLE_DATA_DETAILS: state => {
            return state.config.vehicleDetail;
        },

        MP_ATRC_IS_VALID: state => {
            if (/[A-Za-z]{2}[0-9]{3}[A-Za-z]{2}/.test(state.quotationData.insuranceData.familyRcVehicle.familyRcVehiclePlate.value)
                && !state.quotationData.insuranceData.atrc.years) return true;
            else {
                return !!state.quotationData.insuranceData.atrc.years.find((el) => {
                    return el.value !== '--';
                });
            }

        },

        MP_CONFIG: state => {
            return state.config;
        },

        MP_BUNDLES: state => {
            return state.config.bundles;
        },

        MP_MOTO_APP_INSTALLED: state => {
            return state.config.motoAppInstalled;
        },

        MP_SPECIALIST_ADDONS: state => {
            return {
                addons: state.config.specialistAddons,
                value: state.config.specialistAddonsGrossAmount
            }
        },

        MP_ADDONS: state => {
            return state.config.addons;
        },

        MP_SERVICES: state => {
            return state.config.services;
        },

        MP_UPSELL_ADDONS: state => {
            return state.config.upsellAddons;
        },

        MP_QUOTATION_SUMMARY: state => {
            return state.quotationSummary;
        },

        MP_A005: state => {
            return state.config.A005;
        },

        MP_ALERT_DATA: state => {
            return {
                alert: state.config.notBlockingAlert,
                description: state.config.notBlockingAlertDescription
            }
        },

        MP_BOX_DATA: state => {
            return state.config.motoBox;
        },

        MP_STEPS: state => {
            return {
                quotation: state.config.validatedQuotationSteps,
                global: state.config.validatedGlobalSteps
            }
        },
        MP_BOOKING: state => {
            return state.bookingData
        },

        MP_BOOKING_TIME_SLOTS: state => {
            return state.bookingData.timeSlots.map(str => {
                return {
                    value: str.split('-').map(st => {
                        return st.split(':').map(s => {
                            return parseInt(s)
                        })
                    }),
                    name: str
                }
            })
        },

        // Mega oggettone che contiene tutti i dati possibili
        MP_FULL_QUOTATION: (state, getters, rootState) => {
            return {
                config: state.config,
                quotationData: state.quotationData,
                quotationSummary: state.quotationSummary,
                selectedBundle: getters.MP_SELECTED_BUNDLE,
                customerDetails: rootState.anagrafica.customerDetails
            };
        },
        CURRENT_WEB_ORDER: state => {
            return state.currentWebOrder;
        },
        INFO_BIKE_DETAIL: state => {
            return state.infobikeDetail;
        },
        TOTAL_PRICE: state => {
            const contractorRegistry = state.currentWebOrder.registries.length > 0 ? state.currentWebOrder.registries.find(item => item.isCustomer): state.currentWebOrder.registries[0]
            const option = state.currentWebOrder.priceItems.find( item => JSON.stringify(item.options) === JSON.stringify(contractorRegistry.options))
            return option.total
        },
        INDEX_CONTRACTOR_REGISTRY: state => {
            return state.currentWebOrder.registries.findIndex(item => item.isCustomer)
        },
        SUMMARY_OPTIONS: (state,getters) => {
            const options = state.currentWebOrder.registries[getters.INDEX_CONTRACTOR_REGISTRY].options

            const valRc = state.currentWebOrder.registries[getters.INDEX_CONTRACTOR_REGISTRY].options[getters.OPTION_INDEXES['RC']]
       
            const objMP = [
                {
                    name: `${valRc && valRc!='0'? "Franchigia " + formatNumberToComma( valRc )+"€" : "Nessuna franchigia"}`,
                    indexCode: 'RC',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'Motoplatinum WeRepair',
                    indexCode: 'MOTOPLATINUMWEREPAIR',
                    isIncluded: true,
                    value: 1,
                    saving: true
                },
                {
                    name: 'MOTO.APP - Livello Adventure',
                    indexCode: 'MOTOAPPADVENTURE',
                    isIncluded: true,
                    value: 1,
                    saving: true
                },
                
                {
                    name: 'Infortuni del conducente',
                    indexCode: 'INFORTUNIDELCONDUCENTE',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'Assistenza',
                    indexCode: 'ASSISTENZASTRADALE',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'Rinuncia alla rivalsa',
                    indexCode: 'RINUNCIAALLARIVALSA',
                    isIncluded: true,
                    value: 1,
                    saving: ''
                },
                {
                    name: 'Tutela legale',
                    indexCode: 'TUTELALEGALE',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'Furto e incendio',
                    indexCode: 'CVT',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'MotoplatinumGOLD',
                    indexCode: 'MOTOPLATINUMGOLD',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
            ]

            const objCVT = [
                {
                    name: `Copertura Incendio e Furto - ${state.currentWebOrder.registries[getters.INDEX_CONTRACTOR_REGISTRY].options[getters.OPTION_INDEXES['MONTHS']]} mesi`,
                    indexCode: 'MONTHS',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'MOTO.APP - Livello Adventure',
                    indexCode: 'MOTOAPPADVENTURE',
                    isIncluded: true,
                    value: 1,
                    saving: true
                },
            
            ]

            
            return state.currentWebOrder.productType === 'MP' ? objMP : objCVT
        }, 
        OPTION_INDEXES: state => {
            if ((!state.mpProduct || !state.mpProduct.product['options']) && (!state.cvtProduct || !state.cvtProduct.product['options'])) return ''
            let indexes = state.currentWebOrder.productType === 'MP' ? {...state.mpProduct.product.options} : {...state.cvtProduct.product.options}
            Object.keys(indexes).forEach((key, index)=> {
                indexes[key] = index;
            });
            return indexes
        },
    },

    actions: {
        MP_START_QUOTATION: function ({state, commit, rootState, getters}, _data) {


            const _request = {
                ...(typeof _data.includeFees !== 'undefined' ? {includeFees: _data.includeFees} : {}),
                plate: _data.plate ? _data.plate : 'null',
                date: _data.date ? _data.date : 'null',
                noFastQuote: _data.noFastQuote,
                ...(_data && 'isAr' in _data ? {policyId: _data.policyId} : {})
            };

            const _service = "isAr" in _data ? ArApiService[_data.isAr] : MpApiService.startQuotation;

            if (_data && 'isAr' in _data && _data.isAr === 'addGuarantees') {
                delete _request['date'];
                delete _request['noFastQuote'];
            }


            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                _service(_request).then(res => {

                    // Se è un A005 popolo lo store corrispondente
                    if (res.data.notBlockingAlert === 'A005' && !getters.GLOBAL_IS_AGENT && !_data) {
                        commit('MP_UPDATE_A005',
                            {
                                data: {...(res.data.alertData)}
                            });
                        commit('UPDATE_QUOTATION_DATA', {quotationId: res.data.quotationId});
                        if (_data && 'isAr' in _data && _data.isAr) {
                            commit('AR_UPDATE_ACTION_DATA', {
                                actionOperationId: res.data.quotationId,
                                documentsAction: res.data.action,
                            })
                        }
                        // Altrimenti popolo normalmente i dati del preventivo
                    } else {
                        commit('MP_UPDATE_QUOTATION_DATA', {
                                vehicleData: res.data.vehicleData,
                                ownerData: res.data.ownerData,
                                insuranceData: res.data.insuranceData
                            }
                        );
                        commit('MP_UPDATE_QUOTATION_CONFIG', {
                                productType: res.data.productType === "" ? null : res.data.productType,
                                notBlockingAlert: res.data.notBlockingAlert,
                                notBlockingAlertDescription: res.data.notBlockingAlertDescription,
                                minEffectiveDate: res.data.minEffectiveDate,
                                maxEffectiveDate: res.data.maxEffectiveDate,
                                webQuotationType: res.data.webQuotationType
                            }
                        );
                        commit('UPDATE_QUOTATION_DATA', {quotationId: res.data.quotationId});

                        if (_data && 'isAr' in _data && _data.isAr) {
                            commit('AR_UPDATE_ACTION_DATA', {
                                actionOperationId: res.data.quotationId,
                                documentsAction: res.data.action,
                            })
                        }

                        if (!res.data.insuranceData.atrc.years) commit('MP_RESET_ATRC_YEARS')
                    }

                    commit('STOP_LOADING');
                    resolve(res);

                }).catch(e => {
                    commit('STOP_LOADING');
                    reject(e);
                });
            });
        },

        // Aggiunge la patente del proprietario
        MP_POST_ALERT_DOCUMENTATION({state, commit, rootState}) {

            const _data = {
                quotationId: rootState.quotation.quotationData.quotationId,
                email: state.config.A005.data.email,
                name: state.config.A005.data.name,
                surname: state.config.A005.data.surname,
                phone: state.config.A005.data.phone
            };


            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MpApiService.alertRegistration(_data).then(res => {
                    commit('MP_UPDATE_A005',
                        {
                            documentUploadData: {...(res.data)}
                        });
                    commit('STOP_LOADING');
                    resolve();
                }).catch(() => {
                    commit('UPDATE_DRIVING_LICENSE', null);
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        MP_GET_BUNDLES({state, commit, rootState}, _data) {
            commit('START_LOADING');
            commit('MP_UPDATE_BUNDLES', []);

            const _productType = () => {
                if (state.quotationData.vehicleData.vehicleType.value === '1') return "MM";
                if (state.quotationData.vehicleData.vehicleType.value === '2') return "MC";
                if (state.quotationData.vehicleData.vehicleType.value === '3') return "MC";
            };

            const _service = _data && 'isAr' in _data ? ArApiService.getBundles : MpApiService.getBundles;


            const _request = {
                ...(_data && 'isAr' in _data ? {policyIdOld: _data.policyId} : {}),
                ...(_data && 'recalculate' in _data && _data.recalculate ? {recalculate: true} : {}),
                ...(state.config.includeFees === null ? {} : {includeFees: state.config.includeFees}),
                quotationId: rootState.quotation.quotationData.quotationId,
                productType: _productType(),
                ...(state.quotationData)
            };

            return new Promise((resolve, reject) => {


                _service(_request).then(res => {
                    GaEvents.quotation('MP');

                    // Se gli oggetti ritornati dal servizio sono diversi da quelli nello
                    // store allora resetto le scelte dell'utente e le garanzie
                    if (!isEqual(state.config.bundles, res.data)) {
                        commit('UPDATE_QUOTATION_DATA', {selectedBundleId: null});
                        commit('RESET_SELECTED_ADDONS_IDS');
                        commit('RESET_SELECTED_OPTIONS_IDS');
                        commit('MP_UPDATE_ADDONS', []);
                        commit('MP_UPDATE_SERVICES', []);
                    }
                    commit('MP_UPDATE_BUNDLES', res.data.bundles);
                    res.data.motoAppInstalled != null && commit('MP_UPDATE_MOTO_APP_INSTALLED', res.data.motoAppInstalled);

                    commit('STOP_LOADING');
                    resolve(res);

                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        // Setta i dati della quotazione e riceve la quotazione di ritorno
        MP_GET_QUOTATION({state, commit, rootState}) {

            const _productType = () => {
                if (state.quotationData.vehicleData.vehicleType.value === '1') return "MM";
                if (state.quotationData.vehicleData.vehicleType.value === '2') return "MC";
                if (state.quotationData.vehicleData.vehicleType.value === '3') return "MC";
            };

            commit('MP_UPDATE_QUOTATION_SUMMARY', null);
            const _request = {
                quotationId: rootState.quotation.quotationData.quotationId,
                ...(rootState.quotation.quotationData.discountCode ? {discountCode: rootState.quotation.quotationData.discountCode} : {}),
                ...(rootState.quotation.quotationData.affiliationCode ? {affiliationCode: rootState.quotation.quotationData.affiliationCode} : {}),
                selectedBundle: rootState.quotation.quotationData.selectedBundleId,
                selectedAddons: rootState.quotation.quotationData.selectedAddonsIds,
                selectedBundleOptionId: rootState.quotation.quotationData.selectedBundleOptionId,
                selectedOptions: rootState.quotation.quotationData.selectedAddonsOptions,
                productType: _productType(),
                isSpecialist: state.config.specialistSelected,
                ...(rootState.ux.isInAr ? {policyIdOld: rootState.areariservata.actions.policyId} : {}),
            };


            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                MpApiService.getQuotation(_request).then(res => {

                    // Dalla risposta devo riposizionare i dati ai lOro posti
                    commit('UPDATE_QUOTATION_DATA', {
                        discountCode: res.data.discountCode,
                        quotationCode: res.data.quotationCode,
                        discountCodeDescription: res.data.discountCodeDescription,
                        affiliationCode: res.data.affiliationCode,
                        isAffiliated: res.data.isAffiliated,
                        quotationDateTime: res.data.quotationDateTime,
                        quotationExpire: res.data.quotationExpire,
                        specialistSelected: res.data.isSpecialist
                    });

                    if (res.data.discountCode) {
                        commit('UPDATE_QUOTATION_DATA', {discountCodeIsValid: true})
                    }

                    commit('MP_UPDATE_QUOTATION_SUMMARY', res.data.quotation);
                    commit('MP_UPDATE_QUOTATION_CONFIG', {printUrl: res.data.printUrl});

                    commit('STOP_LOADING');
                    resolve(res);

                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        MP_GET_ADDONS({state, commit, rootState}, config) {
            // Scelgo quale Api chiamare a seconda
            // se mi trovo in area riservata oppure no
            const _service = rootState.ux.isInAr ? ArApiService.getAddons : MpApiService.getAddons;

            const _request = {
                ...(rootState.ux.isInAr ? {policyIdOld: rootState.areariservata.actions.policyId} : {}),
                quotationId: rootState.quotation.quotationData.quotationId,
                selectedBundleId: rootState.quotation.quotationData.selectedBundleId,
                selectedBundleOptionId: rootState.quotation.quotationData.selectedBundleOptionId,
                // Se false non aggiorna le garanzie ma restituisce solo quelle aggiuntive
                RefreshGuarantees: config.RefreshGuarantees
            };


            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                _service(_request).then(res => {

                    res.data.addons.forEach((_addon) => {
                        const _found = res.data.specialistAddons.find( (o) => {
                            return o.addonId === _addon.addonId;
                        });
                        if (_found) {
                            _addon.isSpecialist = true
                        }
                    });

                    let _arr = rootState.quotation.quotationData.selectedAddonsIds;
                    res.data.addons.forEach((_addon) => {
                        if (_addon.isSelected) {
                            if (_arr === null) _arr = [];
                            if (!_arr.includes(_addon.addonId)) {
                                _arr.push(_addon.addonId)
                            }
                        }
                    });
                    res.data.services.forEach((_addon) => {
                        if (_addon.isSelected) {
                            if (_arr === null) _arr = [];
                            if (!_arr.includes(_addon.addonId)) {
                                _arr.push(_addon.addonId)
                            }
                        }
                    });

                    commit('UPDATE_QUOTATION_DATA', {selectedAddonsIds: _arr});

                    commit('MP_UPDATE_ADDONS', res.data.addons);
                    commit('MP_UPDATE_SERVICES', res.data.services);
                    commit('MP_UPDATE_SPECIALIST_ADDONS', res.data.specialistAddons);
                    commit('MP_UPDATE_QUOTATION_CONFIG', {
                        specialistAddonsGrossAmount: res.data.specialistAddonsGrossAmount
                    });

                    commit('STOP_LOADING');
                    resolve();

                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        MP_GET_UPSELL_ADDONS({state, commit, rootState}, _quotationId) {
            commit('MP_UPDATE_UPSELL_ADDONS', null);
            const _request = {
                quotationId: _quotationId
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MpApiService.getUpsellAddons(_request).then(res => {

                    commit('MP_UPDATE_UPSELL_ADDONS', res.data.addons);
                    commit('STOP_LOADING');
                    resolve();

                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        MP_POST_UPSELL_ADDONS({state, commit, rootState}, _data) {
            const _request = {
                selectedAddons: _data,
                selectedOptions: rootState.quotation.quotationData.selectedAddonsOptions,
                quotationId: rootState.quotation.quotationData.quotationId
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MpApiService.updateAddons(_request).then(res => {
                    // Se la risposta è positiva aggiungo queste garanzie, a quelle selezionate, nello store
                    _data.forEach((_addon) => {
                        if (!rootState.quotation.quotationData.selectedAddonsIds.includes(_addon))
                            rootState.quotation.quotationData.selectedAddonsIds.push(_addon);
                    });
                    commit('STOP_LOADING');
                    resolve();

                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        MP_GET_RESIDENCE_CITIES({state, commit, rootState}, search) {

            return new Promise((resolve, reject) => {
              
                MpApiService.getResidenceCities(search).then(res => {
                  
                    resolve(res.data);

                }).catch(e => {
                    reject(e);
                });
            });
        },

        MP_GET_RESIDENCE_CITIES_BY_ID({state, commit, rootState}, id) {

            return new Promise((resolve, reject) => {
              
                MpApiService.getResidenceCitiesById(id).then(res => {
                    
                    resolve(res.data);

                }).catch(e => {
                    reject(e);
                });
            });
        },

        // Recupera i modelli selezionabili in base al tipo di veicolo e data di immatricolazione
        MP_GET_BRANDS({state, commit, rootState}) {

            const _query = {
                quotationId: rootState.quotation.quotationData.quotationId,
                vehicleType: state.quotationData.vehicleData.vehicleType.value,
                registrationDate: state.quotationData.vehicleData.registrationDate.value
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                commit('BRANDS_LOADING', true);

                MpApiService.getBrands(_query).then(res => {

                    commit('MP_UPDATE_VEHICLEDATA_BRANDS', res.data);
                    commit('MP_UPDATE_VEHICLEDATA_MODELS', []);
                    commit('MP_UPDATE_VEHICLEDATA_VERSIONS', []);
                    commit('MP_UPDATE_QUOTATION_DATA_VEHICLE', null);

                    commit('STOP_LOADING');
                    commit('BRANDS_LOADING', false);
                    resolve();

                }).catch(e => {
                    commit('STOP_LOADING');
                    commit('BRANDS_LOADING', false);
                    reject();
                });
            });
        },

        // Recupera i modelli selezionabili in base al tipo di veicolo e data di immatricolazione
        MP_GET_MODELS({state, commit, rootState}) {

            const _query = {
                quotationId: rootState.quotation.quotationData.quotationId,
                vehicleType: state.quotationData.vehicleData.vehicleType.value,
                registrationDate: state.quotationData.vehicleData.registrationDate.value,
                codmar: state.quotationData.vehicleData.brand.value
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                commit('MODELS_LOADING', true);

                MpApiService.getModels(_query).then(res => {

                    commit('MP_UPDATE_VEHICLEDATA_MODELS', res.data);
                    commit('MP_UPDATE_VEHICLEDATA_VERSIONS', []);
                    commit('MP_UPDATE_QUOTATION_DATA_VEHICLE', null);
                    commit('STOP_LOADING');
                    commit('MODELS_LOADING', false);

                    resolve();

                }).catch(e => {
                    commit('STOP_LOADING');
                    commit('MODELS_LOADING', false);
                    reject();
                });
            });
        },

        // Recupera i modelli selezionabili in base al tipo di veicolo e data di immatricolazione
        MP_GET_VERSIONS({state, commit, rootState}) {

            const _query = {
                quotationId: rootState.quotation.quotationData.quotationId,
                vehicleType: state.quotationData.vehicleData.vehicleType.value,
                registrationDate: state.quotationData.vehicleData.registrationDate.value,
                codmar: state.quotationData.vehicleData.brand.value,
                codmod: state.quotationData.vehicleData.model.value
            };


            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                commit('VERSIONS_LOADING', true);


                MpApiService.getVersions(_query).then(res => {

                    commit('MP_UPDATE_VEHICLEDATA_VERSIONS', res.data);
                    commit('MP_UPDATE_QUOTATION_DATA_VEHICLE', null);
                    commit('STOP_LOADING');
                    commit('VERSIONS_LOADING', false);

                    resolve();

                }).catch(e => {
                    commit('STOP_LOADING');
                    commit('VERSIONS_LOADING', false);
                    reject();
                });
            });
        },

        // Recupera i campi marca, modello e allestimento solo in caso sia
        // necessario popolare i campi perchè i dettagli del veicolo sono già presenti

        MP_RESTORE_VEHICLE_VERSIONS_OPTIONS({state, commit, rootState}) {
            commit('START_LOADING');
            const _queryBrands = {
                quotationId: rootState.quotation.quotationData.quotationId,
                vehicleType: state.quotationData.vehicleData.vehicleType.value,
                registrationDate: state.quotationData.vehicleData.registrationDate.value
            };

            const _queryModels = {
                quotationId: rootState.quotation.quotationData.quotationId,
                vehicleType: state.quotationData.vehicleData.vehicleType.value,
                registrationDate: state.quotationData.vehicleData.registrationDate.value,
                codmar: state.quotationData.vehicleData.brand.value
            };

            const _queryVersions = {
                quotationId: rootState.quotation.quotationData.quotationId,
                vehicleType: state.quotationData.vehicleData.vehicleType.value,
                registrationDate: state.quotationData.vehicleData.registrationDate.value,
                codmar: state.quotationData.vehicleData.brand.value,
                codmod: state.quotationData.vehicleData.model.value
            };

            const brandsPromise = new Promise((resolve, reject) => {
                commit('BRANDS_LOADING', true);

                MpApiService.getBrands(_queryBrands).then(res => {

                    commit('MP_UPDATE_VEHICLEDATA_BRANDS', res.data);
                    commit('BRANDS_LOADING', false);
                    resolve();

                }).catch(e => {
                    commit('BRANDS_LOADING', false);
                    reject();
                });
            });

            const modelsPromise = new Promise((resolve, reject) => {
                commit('MODELS_LOADING', true);

                MpApiService.getModels(_queryModels).then(res => {
                    commit('MP_UPDATE_VEHICLEDATA_MODELS', res.data);
                    commit('MODELS_LOADING', false);
                    resolve();

                }).catch(e => {
                    commit('MODELS_LOADING', false);
                    reject();
                });
            });

            const versionsPromise = new Promise((resolve, reject) => {
                commit('VERSIONS_LOADING', true);
                MpApiService.getVersions(_queryVersions).then(res => {
                    commit('MP_UPDATE_VEHICLEDATA_VERSIONS', res.data);
                    commit('VERSIONS_LOADING', false);
                    resolve();

                }).catch(e => {
                    commit('VERSIONS_LOADING', false);
                    reject();
                });
            });

            return Promise.all([brandsPromise, modelsPromise, versionsPromise]).then(value => {
                commit('STOP_LOADING');
            }, reason => {
                commit('STOP_LOADING');
            });
        },

        // Recupera i modelli selezionabili in base al tipo di veicolo e data di immatricolazione
        MP_GET_VEHICLE_DETAIL({state, getters, commit, rootState}) {
            const _query = {
                quotationId: rootState.quotation.quotationData.quotationId,
                vehicleType: state.quotationData.vehicleData.vehicleType.value,
                registrationDate: state.quotationData.vehicleData.registrationDate.value,
                codmar: state.quotationData.vehicleData.brand.value,
                codmod: state.quotationData.vehicleData.model.value,
                codall: state.quotationData.vehicleData.version.value,
                prgall: state.quotationData.vehicleData.prgall.value
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                commit('VEHICLE_DETAIL_LOADING', true);

                MpApiService.getVehicleDetail(_query).then(res => {
                    
                    commit('MP_UPDATE_QUOTATION_DATA', {
                        vehicleData: {
                            abs: {
                                value: res.data.abs,
                                // Metto ABS disablitato solo se il dato abs arriva e l'utente non è un agente
                                disabled: res.data.abs !== null && !getters.GLOBAL_IS_AGENT
                            },
                            insuredValue: {
                                value: res.data.insuredValue == 0 ? null : res.data.insuredValue
                            }

                        }
                    });

                    commit('MP_UPDATE_QUOTATION_DATA_VEHICLE', res.data);

                    commit('STOP_LOADING');
                    commit('VEHICLE_DETAIL_LOADING', false);

                    resolve();

                }).catch(e => {
                    commit('STOP_LOADING');
                    commit('VEHICLE_DETAIL_LOADING', false);

                    reject();
                });
            });
        },

        // Invia il preventivo per email all'utente
        // Salva la quotazione per inviare email al cliente
        MP_SAVE_QUOTATION({state, commit, rootState}, _email) {

            const _request = {
                email: _email,
                quotationId: rootState.quotation.quotationData.quotationId,
                emailConsent: true
            };
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                GlobalApiService.saveQuotation(_request).then(res => {
                    GaEvents.block('MP');
                    try {
                        let pusher = new SelligentPusher()
                        pusher.add('action', 'saveQuotation_motoplatinum')
                        pusher.push()
                    } catch (e) {

                    }
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        // Aggiunge la patente del proprietario
        MP_POST_DRIVING_LICENSE({state, commit, rootState}, _license) {

            const _data = {
                drivingLicense: _license,
                quotationId: rootState.quotation.quotationData.quotationId
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MpApiService.setDrivingLicense(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch(() => {
                    commit('UPDATE_DRIVING_LICENSE', null);
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        MP_CHECK_DOCUMENT_UPLOAD({state, commit, rootState}) {

            const _query = {
                quotationId: rootState.quotation.quotationData.quotationId,
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                MpApiService.checkDocumentUpload(_query).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);

                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        // Imposta l'email preferita del dealer TODO:REMOVE
        /* MP_POST_DEALER_EMAIL({state, commit, rootState}, _email) {

            const _data = {
                email: _email,
                quotationId: rootState.quotation.quotationData.quotationId,
                productType: state.config.productType
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MpApiService.setDealerEmail(_data).then(res => {
                    commit('MP_UPDATE_QUOTATION_CONFIG', {
                        dealerEmail: {
                            selectedDealerEmail: _email
                        }
                    });
                    commit('STOP_LOADING');
                    resolve();
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        }, */

        // Recupera se bisogn chiedere i dati della box
        MP_GET_BOX_CHECK({state, commit, rootState}, _quotationId) {

            const _query = {
                quotationId: rootState.quotation.quotationData.quotationId || _quotationId,
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                MpApiService.boxCheck(_query).then(res => {

                    commit('MP_UPDATE_MOTO_BOX', {
                        ...(res.data.motoBox),
                    });


                    commit('STOP_LOADING');
                    resolve();

                }).catch(e => {
                    commit('MP_UPDATE_MOTO_BOX', {check: false});
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        // Imposta i dati della box per l'installatore
        MP_POST_BOX_DATA({state, commit, rootState}) {

            const _data = {
                imei: state.config.motoBox.imei,
                sn: state.config.motoBox.sn,
                quotationId: rootState.quotation.quotationData.quotationId,
                byPassDevice: rootState.areariservata.partnerWarehouse.byPass
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MpApiService.boxUpdate(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(res.data);
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        // Cambia l'email del preventivo
        MP_CHANGE_EFFECTIVE_DATE({state, commit, rootState}, {_date, _quotationId = null}) {

            const _data = {
                date: moment(_date).toISOString(true),
                quotationId: _quotationId || rootState.quotation.quotationData.quotationId,
                recalculate: rootState.ux.isInAr ? true: false
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MpApiService.changeEffectiveDate(_data).then(res => {
                    commit('STOP_LOADING');
                    commit('MP_UPDATE_EFFECTIVE_DATE', _date);
                    resolve(_data);
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        // Cambia l'email del preventivo
        MP_CHANGE_QUOTATION_EMAIL({state, commit, rootState}, _email) {

            const _data = {
                email: _email,
                quotationId: rootState.quotation.quotationData.quotationId,
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MpApiService.changeQuotationEmail(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(_data);
                }).catch((err) => {
                    reject(err);
                    commit('STOP_LOADING');
                });
            });
        },


        // Verifica targa e codice fiscale per l'rc famigliare
        // e li aggiunge al model se la risposta è positiva
        MP_CHECK_FAMILY_RC_VEHICLE({state, commit, getters, rootState}, _data) {

            const _query = {
                    quotationId: rootState.quotation.quotationData.quotationId,
                    province: state.quotationData.ownerData.ownerProvince.value,
                    familyRcVehiclePlate: {
                        value: _data.plate,
                        disabled: false
                    },
                    familyRcVehicleOwnerFiscalCode: {
                        value: _data.fiscalCode,
                        disabled: false
                    },
                    plate: {
                        ...(state.quotationData.vehicleData.plate)
                    },
                    vehicleType: state.quotationData.vehicleData.vehicleType.value,
                    riskCertificateAvailable: state.quotationData.insuranceData.riskCertificateAvailable.value
                }
            ;


            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                const _resetData = () => {
                    commit('MP_RESET_ATRC_YEARS');
                    commit('MP_UPDATE_QUOTATION_DATA', {
                        insuranceData: {
                            familyRcVehicle: {
                                familyRcVehiclePlate: {
                                    value: ''
                                },
                                familyRcVehicleOwnerFiscalCode: {
                                    value: ''
                                }
                            },
                            atrc: {
                                disabled: true,
                            },
                            cu: {
                                value: null,
                                disabled: false
                            }
                        }
                    });
                };

                MpApiService.familyRcVehicle(_query).then(res => {

                    if (res.data.isValid === true) {
                        // Se ci sono anni nell'atrc resetto il campo years, altrimenti il merge di MP_UPDATE_QUOTATION_DATA
                        // Aggiunge gli anni a quelli presenti invece di sostituirli
                        if (res.data.atrc.years) commit('MP_EMPTY_ATRC_YEARS');

                        // Se la verifica è andata a buon fine aggiungo i dati al model
                        // E aggiorno anche i dati dell'atrc e la classe di merito
                        commit('MP_UPDATE_QUOTATION_DATA', {
                            insuranceData: {
                                familyRcVehicle: {
                                    familyRcVehiclePlate: {
                                        value: _data.plate
                                    },
                                    familyRcVehicleOwnerFiscalCode: {
                                        value: _data.fiscalCode
                                    }
                                },
                                atrc: {
                                    years: res.data.atrc.years,
                                    disabled: res.data.atrc.disabled,

                                },
                                cu: {
                                    value: res.data.cu.value,
                                    disabled: getters.GLOBAL_IS_AGENT ? false : true
                                }
                            }
                        });
                        if (!res.data.atrc.years) commit('MP_RESET_ATRC_YEARS');
                        commit('STOP_LOADING');
                        resolve();
                    } else {
                        _resetData();
                        commit('STOP_LOADING');
                        reject();
                    }


                    resolve();

                }).catch(e => {
                    // Altrimenti resetto il model
                    _resetData();
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        // Aggiorna i dati di preventivo salvato e acquistabile da partner
        MP_UPDATE_QUOTATION_PARTNER({state, commit, getters, rootState}, _data) {

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MpApiService.updateQuotationPartner(_data).then(res => {
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch(e => {
                    commit('STOP_LOADING');
                    reject(e);
                });
            });
        },

        // Salva la quotazione per procedere all'anagrafica
        MP_CONFIRM_QUOTATION({state, rootState, commit}, saveQuotation) {
            commit('START_LOADING');
            // saveQuotation indica se la confirmQuotation
            // deve proseguire o salvare il preventivo
            const _data = {
                quotationId: rootState.quotation.quotationData.quotationId,
                saveQuotation: saveQuotation
            };

            return new Promise((resolve, reject) => {
                MpApiService.confirmQuotation(_data).then(res => {
                    commit('STOP_LOADING');
                    commit('UPDATE_QUOTATION_DATA', {
                        quotationReady: true,
                        saveQuotation: saveQuotation
                    });
                    commit('UPDATE_ANAGRAFICA', {
                        owner: {
                            fiscalCode: state.quotationData.ownerData.ownerFiscalCode.value,
                            address: {
                                provinceId: rootState.anagrafica.formFields.provincia.values.find(_p => {
                                    return _p.value.toUpperCase() === state.quotationData.ownerData.ownerProvince.value.toUpperCase()
                                }).key,
                            },
                            isCompany: /^[0-9]{11}$/i.test(state.quotationData.ownerData.ownerFiscalCode.value)
                        },
                    });
                    resolve(res);
                }).catch(e => {
                    console.error(e);
                    commit('STOP_LOADING');
                });
            });
        },

        // Invia l'anagrafica pr procedere all'acquisto
        MP_CONFIRM_REGISTRATION({state, commit, rootState}) {
            commit('START_LOADING');
            const _data = {
                // Se l'utente non è anche il proprietario invio anche l'oggetto owner stessa cosa per l'oggetto utilizer
                quotationId: rootState.quotation.quotationData.quotationId,
                owner: rootState.anagrafica.customerDetails.owner,
                contractor: rootState.anagrafica.customerDetails.contractorIsOwner ? rootState.anagrafica.customerDetails.owner : rootState.anagrafica.customerDetails.contractor,
                contractorIsOwner: rootState.anagrafica.customerDetails.contractorIsOwner,
                ownersDrivingLicense: rootState.anagrafica.customerDetails.ownersDrivingLicense
            };

            const _requestParams = cloneDeep(_data)

            _requestParams.owner.professionId = _requestParams.owner.isCompany ? "22" : _requestParams.owner.professionId;
            _requestParams.owner.maritalStatusId = _requestParams.owner.isCompany ? "0" : _requestParams.owner.maritalStatusId;

            _requestParams.contractor.professionId = _requestParams.contractor.isCompany ? "22" : _requestParams.contractor.professionId;
            _requestParams.contractor.maritalStatusId = _requestParams.contractor.isCompany ? "0" : _requestParams.contractor.maritalStatusId;

            if (_requestParams.owner) {
                delete _requestParams.owner.isCompany
            }

            if (_requestParams.contractor) {
                delete _requestParams.contractor.isCompany
            }


            return new Promise((resolve, reject) => {
                MpApiService.confirmRegistration(_requestParams).then(res => {
                    GaEvents.registration('MP');
                    if (res.data.confirmEmail) {
                        commit('SET_EMAIL_CONFIRM', res.data.confirmEmail);
                    }
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((err) => {
                    reject(err);
                    commit('STOP_LOADING');
                });
            });
        },

        MP_RESTORE_QUOTATION({state, commit, rootState, dispatch}, _quotationId) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                commit('START_RESTORE_LOADING');
                MpApiService.getQuotationData(_quotationId).then(res => {
                    // Dalla risposta devo riposizionare i dati ai lOro posti
                    commit('UPDATE_QUOTATION_DATA', {
                        discountCode: res.data.quotation.discountCode,
                        discountCodeDescription: res.data.quotation.discountCodeDescription,
                        affiliationCode: res.data.quotation.affiliationCode,
                        selectedBundleId: res.data.quotation.selectedBundle,
                        selectedBundleOptionId: res.data.quotation.selectedBundleOptionId,
                        selectedAddonsIds: res.data.quotation.selectedAddons,
                        selectedAddonsOptions: res.data.quotation.selectedOptions,
                        quotationCode: res.data.quotationData.quotationCode,
                        quotationId: res.data.quotationId,
                        //isAffiliated: res.data.quotationData.isAffiliated,
                        quotationDateTime: res.data.quotationData.quotationDateTime,
                        quotationExpire: res.data.quotationData.quotationExpire
                    });

                    if (res.data.quotation.discountCode) {
                        commit('UPDATE_QUOTATION_DATA', {discountCodeIsValid: true})
                    }

                    commit('MP_UPDATE_QUOTATION_CONFIG', {
                        specialistSelected: res.data.quotation.isSpecialist,
                        productType: res.data.quotationData.productType,
                        dealerEmail: {
                            emails: res.data.dealerEmail.email,
                            selectedDealerEmail: res.data.dealerEmail.selectedDealerEmail
                        },
                        minEffectiveDate: res.data.quotationData.minEffectiveDate,
                        maxEffectiveDate: res.data.quotationData.maxEffectiveDate,
                        printUrl: res.data.printUrl ? res.data.printUrl : null,
                        webQuotationType: res.data.quotationData.webQuotationType
                    });

                    commit('MP_UPDATE_MOTO_BOX', {
                        ...(res.data.motoBox),
                        disabled: res.data.motoBox.imei && res.data.motoBox.sn
                    });

                    commit('MP_UPDATE_QUOTATION_DATA_VEHICLE', res.data.vehicleDetail);

                    commit('MP_UPDATE_QUOTATION_DATA', {
                        vehicleData: res.data.quotationData.vehicleData,
                        ownerData: res.data.quotationData.ownerData,
                        insuranceData: res.data.quotationData.insuranceData
                    });

                    commit('UPDATE_ANAGRAFICA_CONTRACTOR', res.data.registration.contractor);
                    commit('UPDATE_ANAGRAFICA_OWNER', res.data.registration.owner);
                    commit('UPDATE_ANAGRAFICA', {contractorIsOwner: res.data.registration.contractorIsOwner});
                    MpApiService.getDriverLicence(_quotationId).then((licenseRes) => {
                        commit('UPDATE_DRIVING_LICENSE', licenseRes.data !== "" ? licenseRes.data : null);
                    }).catch((e) => {
                        if (!e.status === 404) console.error(e)
                        else console.error('Patente non presente per questo codice fiscale', e)
                    })

                    commit('MP_UPDATE_BUNDLES', res.data.availableBundles);
                    res.data.motoAppInstalled != null && commit('MP_UPDATE_MOTO_APP_INSTALLED', res.data.motoAppInstalled);
                    commit('MP_UPDATE_ADDONS', res.data.availableAddons.addons);
                    commit('MP_UPDATE_SERVICES', res.data.availableAddons.services);
                    commit('MP_UPDATE_SPECIALIST_ADDONS', res.data.availableAddons.specialistAddons);
                    commit('MP_UPDATE_QUOTATION_CONFIG', {
                        specialistAddonsGrossAmount: res.data.availableAddons.specialistAddonsGrossAmount
                    });

                    commit('STOP_LOADING');
                    commit('STOP_RESTORE_LOADING');
                    resolve();

                }).catch((e) => {
                    commit('STOP_LOADING');
                    commit('STOP_RESTORE_LOADING');
                    reject(e);
                });

            });
        },

        MP_RESTORE_ANAGRAFICA({state, commit, rootState}) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                commit('RESET_ANAGRAFICA_OWNER');
                commit('RESET_ANAGRAFICA_CONTRACTOR');

                GlobalApiService.getAnagrafica().then(res => {
                    if (res.data.address.street.includes(',')) {
                        const _split = res.data.address.street.split(',');
                        res.data.address.street = _split[0];
                        res.data.address.number = _split[1];
                    }
                    // Mi assicuro che l'anagrafica rispecchi i dati che arrivano dal preventivatore
                    commit('UPDATE_ANAGRAFICA', {
                        owner: {
                            fiscalCode: state.quotationData.ownerData.ownerFiscalCode.value,
                            address: {
                                provinceId: rootState.anagrafica.formFields.provincia.values.find(_p => {
                                    return _p.value.toUpperCase() === state.quotationData.ownerData.ownerProvince.value.toUpperCase()
                                }).key
                            },
                            isCompany: /^[0-9]{11}$/i.test(state.quotationData.ownerData.ownerFiscalCode.value)
                        },
                    });

                    MpApiService.getDriverLicence(rootState.quotation.quotationData.quotationId).then((licenseRes) => {
                        commit('UPDATE_DRIVING_LICENSE', licenseRes.data !== "" ? licenseRes.data : null);
                    }).catch((e) => {
                        console.error(e)
                    })

                    // Devo capire quale delle due anagrafiche va popolata in base a contractorIsOwner

                    if (rootState.anagrafica.customerDetails.contractorIsOwner) {
                        commit('UPDATE_ANAGRAFICA_OWNER', res.data);
                        commit('UPDATE_OWNER_COMPANY', res.data.fiscalCode.length < 12 && res.data.name === '');
                    } else {
                        commit('UPDATE_ANAGRAFICA_CONTRACTOR', res.data);
                        commit('UPDATE_CONTRACTOR_COMPANY', res.data.fiscalCode.length < 12 && res.data.name === '');
                    }

                    commit('STOP_LOADING');
                    resolve();
                }).catch(e => commit('STOP_LOADING'));
            });
        },

        MP_GET_BOOKING_LOCATIONS({state, commit, rootState}, _req) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                MpApiService.getBookingLocations(_req).then((res) => {

                    if (_req.skip !== 0) {
                        if (state.bookingData.locations.values.length <= res.data.count) {
                            commit('MP_PUSH_BOOKING_LOCATIONS', res.data.values)
                        }
                    } else commit('MP_UPDATE_BOOKING', {locations: res.data})
                    commit('STOP_LOADING');
                    resolve()
                }).catch((err) => {

                    reject()
                    console.error(err)
                })

            });
        },

        async MP_GET_BOOKING_CONFIG({state, commit, rootState}, _req) {
            commit('START_LOADING');
            const _bookingDateLimit = await MpApiService.getBookingDateLimit(_req)
            const _bookingSlots = await MpApiService.getBookingTimeslots(_req)
            // Elimino l'array prima, per aggirare problemi di refresh tipici di VUE
            commit('MP_UPDATE_BOOKING', {timeSlots: []})
            commit('MP_UPDATE_BOOKING', {dates: _bookingDateLimit.data})
            commit('MP_UPDATE_BOOKING', {timeSlots: _bookingSlots.data})
            return {
                ...(_bookingDateLimit),
                ...(_bookingSlots)
            }


        },

        MP_SET_APPOINTMENTS({state, commit, rootState}, data) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                let _appointments = [];
                state.bookingData.appointments.forEach((appointment) => {
                    state.bookingData.selectedInstallers.forEach((location) => {
                        _appointments.push({
                            // Setto l'ora corretta nella data dell'appuntamento impostando l'ora di inizio della fascia oraria
                            appointmentDateTime: moment(appointment.startDateTime).add('hours', 3),
                            partnerId: location,
                            contractId: data.contractId,
                            preferenceOrder: state.bookingData.selectedInstallers.findIndex((e) => e === location),
                            timeSlot: appointment.slot
                        })
                    })
                })
                const _req = {
                    createAppointments: _appointments
                }
                MpApiService.setBookingAppointments(_req).then((res) => {
                    commit('STOP_LOADING');
                    resolve()
                }).catch((e) => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },
        async searchProduct({state, commit, rootState}, productType){
            try {
                commit('START_LOADING');
                
                const response = await motoplatinumService.searchProduct({
                    productType,
                    locale: 'IT'
                })

                if(productType === 'MP')
                    commit('UPDATE_MP_PRODUCT', response.data)
                else if(productType === 'CVT') 
                    commit('UPDATE_CVT_PRODUCT', response.data)

                commit('STOP_LOADING');
                return response.data
            } catch (e) {
                commit('STOP_LOADING');
                throw new Error(e)
            }
            
        },
        async getInfoBikeDetails({state, commit, rootState, dispatch}, params) {
            commit('START_LOADING');
            try {

                const response = await infobikeService.getDetail(params)

                commit('UPDATE_INFO_BIKE_DETAIL', response.data)
                
                commit('STOP_LOADING');
                return response.data
            } catch (e) {
                commit('STOP_LOADING');
                throw new Error(e)
            }
        },
        async getMpWebOrder({state, commit, rootState, dispatch}, webOrderID) {
            commit('START_LOADING');
            try {

                !state.mpProduct && await dispatch('searchProduct', 'MP')

                const response = await motoplatinumService.getMp(webOrderID)
                commit('UPDATE_CURRENT_WEB_ORDER', response.data)
                
                commit('STOP_LOADING');
                return response.data
            } catch (e) {
                commit('STOP_LOADING');
                throw new Error(e)
            }
        },
        async getCvtWebOrder({state, commit, rootState, dispatch}, webOrderID) {
            commit('START_LOADING');
            try {

                !state.cvtProduct && await dispatch('searchProduct', 'CVT')

                const response = await motoplatinumService.getCvt(webOrderID)
                commit('UPDATE_CURRENT_WEB_ORDER', response.data)
                
                commit('STOP_LOADING');
                return response.data
            } catch (e) {
                commit('STOP_LOADING');
                throw new Error(e)
            }
        },
    },

    mutations: {
        MP_UPDATE_FASTQUOTE_REQUEST(state, payload) {
            state.config.fastQuoteRequest = payload;
        },

        MP_UPDATE_OVERRIDE(state, payload) {
            state.config.callbackOverride = payload;
        },

        MP_UPDATE_QUOTATION_DATA(state, payload) {
            state.quotationData = merge(state.quotationData, payload);
        },
        MP_UPDATE_QUOTATION_CONFIG(state, payload) {
            state.config = merge(state.config, payload);
        },
        MP_UPDATE_QUOTATION_SUMMARY(state, payload) {
            state.quotationSummary = payload;
        },
        MP_UPDATE_ATRC_DATA(state, payload) {
            state.quotationData.insuranceData.atrc = payload;
        },

        MP_UPDATE_VEHICLEDATA_BRANDS(state, payload) {
            state.config.vehicleDataValues.brands = payload;
        },

        MP_RESET_ATRC_YEARS(state) {
            state.quotationData.insuranceData.atrc.years = _getDefaultAtrcYears();
        },

        MP_EMPTY_ATRC_YEARS(state) {
            state.quotationData.insuranceData.atrc.years = [];
        },

        MP_UPDATE_BUNDLES(state, payload) {
            state.config.bundles = payload;
        },
        MP_UPDATE_MOTO_APP_INSTALLED(state, payload) {
            state.config.motoAppInstalled = payload;
        },
        MP_UPDATE_ADDONS(state, payload) {
            state.config.addons = payload;
        },

        MP_UPDATE_SERVICES(state, payload) {
            state.config.services = payload;
        },

        MP_UPDATE_SPECIALIST_ADDONS(state, payload) {
            state.config.specialistAddons = payload;
        },

        MP_UPDATE_VEHICLEDATA_MODELS(state, payload) {
            state.config.vehicleDataValues.models = payload;
        },

        MP_UPDATE_UPSELL_ADDONS(state, payload) {
            state.config.upsellAddons = payload;
        },

        MP_UPDATE_VEHICLEDATA_VERSIONS(state, payload) {
            state.config.vehicleDataValues.versions = payload;
        },

        MP_UPDATE_QUOTATION_DATA_VEHICLE(state, payload) {
            state.config.vehicleDetail = payload;
        },

        MP_UPDATE_REGISTRATION_DATE(state, payload) {

            state.quotationData.vehicleData.registrationDate.value = moment(payload).startOf('day').toISOString(true);
        },

        MP_UPDATE_PURCHASE_DATE(state, payload) {

            state.quotationData.vehicleData.purchaseDate.value = moment(payload).startOf('day').toISOString(true);
        },

        MP_UPDATE_EFFECTIVE_DATE(state, payload) {
            state.quotationData.insuranceData.effectiveDate.value = moment(payload).startOf('hour').toISOString(true);
        },

        MP_UPDATE_EFFECTIVE_TIME(state, payload) {
            state.quotationData.insuranceData.effectiveDate.value = moment(state.quotationData.insuranceData.effectiveDate.value).hour(payload).toISOString(true);
        },
        //GLOBALE
        /* MP_UPDATE_DEALER_EMAILS(state, payload) {
            state.config.dealerEmail = merge(state.config.dealerEmail, payload);
        }, */

        MP_UPDATE_MOTO_BOX(state, payload) {
            state.config.motoBox = merge(state.config.motoBox, payload);
        },

        MP_UPDATE_A005(state, payload) {
            state.config.A005 = merge(state.config.A005, payload);
        },

        MP_UPDATE_MICROSTEP(state, payload) {
            state.config.validatedQuotationSteps = payload;
        },

        MP_UPDATE_STEP(state, payload) {
            state.config.validatedGlobalSteps = payload;
        },

        MP_UPDATE_BOOKING(state, payload) {

            state.bookingData = merge(state.bookingData, payload);
        },

        // Mantiene la reattività degli array di VUE, deve ricevere necessariamente un array
        MP_PUSH_BOOKING_LOCATIONS(state, payload) {

            payload.forEach((el) => {
                state.bookingData.locations.values.push(el)
            })
        },

        // Mantiene la reattività degli array di VUE, deve ricevere necessariamente un array
        MP_PUSH_BOOKING_SELECTED_INSTALLER(state, payload) {
            payload.forEach((el) => {
                state.bookingData.locations.values.push(el)
            })
        },

        RESET_MOTOPLATINUM_STATE(state, payload) {
            Object.assign(state, _getDefaultState());
        },

        RESET_MOTOPLATINUM_BOOKING(state) {
            const defaultState = _getDefaultState()
            Object.assign(state.bookingData, defaultState.bookingData);
        },
        RESET_MOTOPLATINUM_BOOKING_APPOINTMENTS(state) {
            const defaultState = _getDefaultState()
            state.bookingData.appointments = [];
            state.bookingData.appointments = defaultState.bookingData.appointments;
        },
        UPDATE_CURRENT_WEB_ORDER(state, payload) {
            state.currentWebOrder = payload
        },
        UPDATE_INFO_BIKE_DETAIL(state, payload) {
            state.infobikeDetail = payload
        },
        UPDATE_MP_PRODUCT(state, payload) {
            state.mpProduct = payload
        },
        UPDATE_CVT_PRODUCT(state, payload) {
            state.cvtProduct = payload
        }

    },
};

import axios from "axios"
import store from "../../../Store"
import Noty from 'noty'
import {GATEWAY_CREDENTIALS, NOTY_CONFIG,API_ERROR_400} from "@/Common/constants"
import {messages} from "@/lang/translations"

import {createPinia, PiniaVuePlugin} from 'pinia'
import Vue from 'vue';

import {useWebOrderStore} from "@/stores/weborder.store";
import { MP_ITEM, CLEAR_MP_ITEM } from "../../../Common/constants"
import {useMotoplatinumStore} from "@/stores/motoplatinum.store";

const pinia = createPinia()
Vue.use(PiniaVuePlugin)

Noty.overrideDefaults(NOTY_CONFIG);

const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

const translateAndShowError = (data) => {
    const err = data.errorDescription
    // Prendo la sigla dalla lingua del browser
    const lang = navigator.language.slice(0, 2)
    let error = ''

    // Cerco un messaggio di errore che corrisponda
    // al codice di errore restituito dall'api
    error = messages[lang].errors.api[err]
    
    if(error){
        new Noty({
            text: error,
            type: 'error'
        }).show();
    } else if(data.friendlyErrorDescription){
        new Noty({
            text: data.friendlyErrorDescription,
            type: 'error'
        }).show();

    }
    else{
        new Noty({
            // Se non si trova un messaggio di errore uso quello generico
            text: messages[lang].errors.generic,
            type: 'error'
        }).show();
    }

    

}


const showError = (err) => {
    let error = ''
    error = API_ERROR_400[err];

    new Noty({

        // Se non si trova un messaggio di errore uso quello generico
        text: error || 'Si è verificato un errore riprova più tardi.',
        type: 'error'
    }).show();

}





const AxiosConfiguration = () => {
    
    // Aggiungo l'urlProd se è presente nel cookie: cookiePartner24hAssistance e il token utente se è presente
    axios.interceptors.request.use(config => {
        if (config.url.includes('24hassistance.com/api') || config.url.includes('gateway') || config.url.includes('24hassistance-api')) {
            // Imposto l'eventuale urlProd
            if (store.state.ux.urlProd) config.headers.urlProd = store.state.ux.urlProd;
            // Se siamo in ambiente di sviluppo
            if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
                // Se è definito un token agente di sviluppo
                if (process.env.IUN_TOKEN) config.headers.iun = process.env.IUN_TOKEN;
                // Se non è definito un token agente di sviluppo lo cerco nei cookie
                else if (getCookie('IUN24hAssistance')) config.headers.iun = getCookie('IUN24hAssistance');

                // Se è definito un token utente di sviluppo
                if (process.env.USER_TOKEN) config.headers.userTokenId = process.env.USER_TOKEN
                // Se non è definito un token utente di sviluppo lo cerco nei cookie
                else if (window.globalConfigVariables.token) config.headers.userTokenId = window.globalConfigVariables.token;

            }
            // Se non siamo in ambiente di sviluppo la logica dei token resta uguale
            else {
                if (getCookie('IUN24hAssistance')) config.headers.iun = getCookie('IUN24hAssistance');
                if (window.globalConfigVariables.token) config.headers.userTokenId = window.globalConfigVariables.token;
            }
        }
        if (config.url.includes('gateway')) {
            if(!window.location.href.includes('/motoplatinum')){
                CLEAR_MP_ITEM();
            }
            
            // verifica se che non ci sia il Usertokenid
            if(!window.globalConfigVariables.token){
                const item = JSON.parse(localStorage.getItem('MP_ITEM'))
                const urlParams = new URLSearchParams(location.hash);

                if(item && item.token && (
                        window.location.href.includes('/motoplatinum/v2') || 
                        (urlParams.get('w') && urlParams.get('type'))
                    )
                ){
                    config.headers.common['Bearer'] = item.token
                }
                else config.headers.common['Authorization'] = 'Basic ' + GATEWAY_CREDENTIALS;

            }
            
            
            config.headers.common['Access-Control-Allow-Origin'] = '*';
        }
        return config;
    });


    // Gestisco i messaggi di errore con stato 420, sono gli errori personalizzati dell'API
    axios.interceptors.response.use(res => {
        return res;
    },  (error) =>{

        //Se l'errore non contiene response blocco
        if (!error.response) return Promise.reject(error);

        // SE l'errore ha status 420, è cioè uno degli errori
        // backend gestiti che vogliamo siano visualizzati
        // così come arrivano dall'api
        else if (error.response.status === 420) {
            if (error.response.data.code !== 'A005_NEW' &&
                error.response.data.code !== 'PFW_MP_NONUNIVOCO_EMAIL' && 
                error.response.data.code !== 'PFW_MP_NONUNIVOCO_CELLULARE') {
                new Noty({
                    text: error.response.data.message,
                    type: 'error'
                }).show();

                // Gestione errori, valore bici troppo alto e nocicloid
                if (error.response.data.code === "PFW_BP_EXCESSIVEBIKEVALUE"
                    || error.response.data.code === "PFW_BP_NOCICLOID") {
                    store.commit('SET_ERROR', error.response.data);
                }

            }

            if(error.response.data.code == 'PFW_MP_NONUNIVOCO_EMAIL'|| 
                error.response.data.code == 'PFW_MP_NONUNIVOCO_CELLULARE'){
                    store.commit('SET_ERROR', error.response.data);
                    
                    if(window.location.hash.includes('/otp')){
                        new Noty({
                            text: error.response.data.message,
                            type: 'error'
                        }).show();
                    }
                    
            }

            if(error.response.data.code == 'QUOTATION_ALREADYBOUGHT'){
                new Noty({
                    text: error.response.data.message,
                    type: 'error'
                }).show();
                CLEAR_MP_ITEM();
                setTimeout( ()=>{
                    window.location.href = 'https://www.24hassistance.com'
                }, 3000)
            }
           
            return Promise.reject(error.response);

        }

        // Gli errori 406 arrivano dall'api del webOrder e
        // hanno uan gestione diversa dovuta al multilingua
        else if (error.response.status === 406) {

            if(error.response.data.errorDescription == 'PFW_MP_NONUNIVOCO_EMAIL'|| 
                error.response.data.errorDescription == 'PFW_MP_NONUNIVOCO_CELLULARE'){
                  
                const webOrderStore = useWebOrderStore();
                webOrderStore.setError(error.response.data)

                // motoplatinum
                const motoplatinumStore = useMotoplatinumStore();
                motoplatinumStore.setError(error.response.data)
                
                return Promise.reject(error.response);
            }
           
            translateAndShowError(error.response.data)
            return Promise.reject(error.response.data);

        }

        // In caso di 401 unauthorized
        else if (error.response.status === 401) {
            
            new Noty({
                text: `Sessione scaduta`,
                type: 'error'
            }).show();

            if(window.location.href.includes('/motoplatinum/v2#/')){
                CLEAR_MP_ITEM();
                window.location.href = '/motoplatinum/v2#/quotation/fastquote';
            }
        }
        else if (error.response.status === 400) {
              
            showError(error.response.data)
            if(window.location.href.includes('/motoplatinum/v2#/start')){
                CLEAR_MP_ITEM();
                window.location.href = '/motoplatinum/v2#/quotation/fastquote';
            }           
            else
                return Promise.reject(error.response.data);

        }
        else {
            translateAndShowError()
            return Promise.reject(error.response);
        }
        
    });

    // Gestisco lo sblocco dei parametri del preventivo in caso di rete interna
    axios.interceptors.response.use(function (response) {

        if (response.data.isInternal) store.commit('SET_IS_INTERNAL', response.data.isInternal)
        return response

    }, function (error) {
        return Promise.reject(error);
    });

};

export default AxiosConfiguration;


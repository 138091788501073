<template>
    <div class="row m-0 p-0">
        <div class="col-12 m-0 p-0">
            <div class="row" v-if="!faqsReady">
                <div class="col-12">
                    <p class="text-center m-0 p-5 border rounded">
                        <i class="fa fa-spin fa-spinner text-muted fa-2x"></i><br/>
                        <span class="text-muted">Caricamento</span>
                    </p>
                </div>
            </div>
            <div class="row" v-if="faqsReady">
                <div class="col-12">
                    <div class="row justify-content-md-center">
                        <div class="col-12 col-md-8">
                            <div class="nav-pills nav-pills-faqs"
                                 id="v-pills-tab"
                                 role="tablist"
                                 aria-orientation="vertical">
                                <template v-for="(item, index) in faqs">
                                    <a class="nav-link rounded-0"
                                       :class="{active : index === selectedFaqIndex}"
                                       href="#"
                                       :index="index"
                                       @click.prevent="index === selectedFaqIndex ? selectedFaqIndex = false : selectedFaqIndex = index"
                                       id="v-pills-home-tab"
                                       role="tab"
                                       aria-controls="v-pills-home" aria-selected="true">
                                        <span v-if="item.title">{{item.title}} <i class="fas fa-chevron-down text-secondary float-right mt-1 mr-3"></i></span>
                                    </a>
                                    <transition name="fade">
                                        <div class="py-3" v-if="index === selectedFaqIndex && selectedFaqIndex > -1" v-html="item.body"></div>
                                    </transition>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="text-center py-5" style="font-size: 18px;">
                                Hai bisogno di ulteriori informazioni? Visita il nostro <a id="cta_support_faqs" data-type="CTA" target="_blank"
                                                                                           :href="`${domain}/hc/it/`">Sito
                                di supporto</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';


    export default {
        props: {
            // La keyword da cercare su zendesk
            Keyword: {
                type: String,
                required: true
            },
            articles: {
                type: String,
                required: false
             },
            Color: {
                type: String,
                required: false
            },
            Limit: {
                type: String,
                required: false
            },
        },

        data() {
            return {
                faqsReady: false,
                faqs: [],
                selectedFaqIndex: -1

            }
        },
        computed: {
            domain() {
                return this.Keyword.toLowerCase() === 'Snowcare' ||
                this.Keyword.toLowerCase() === 'Multisport' ? 'https://gbc-mountain.zendesk.com' : 'https://help.24hassistance.com'


            },
            selectedFaq() {
                return this.faqs[this.selectedFaqIndex];
            }

        },

        created() {
            const articleList = this.articles && this.articles.includes("+") ? this.articles.split("+") : [this.articles]
            axios.get(`${this.domain}/api/v2/help_center/articles/search.json?query=${this.Keyword}`)
            .then((_res) => {
                const _lim = parseInt(this.Limit) || 6;
                this.faqs = _res.data.results.slice(0, _lim);
                this.faqsReady = true;

                if (this.articles && articleList && articleList.length > 0){
                    articleList.forEach((article) => {
                        axios.get(`${this.domain}/api/v2/help_center/articles/search.json?query=${article}`)
                        .then((_res) => {
                            this.faqs.push(_res.data.results[0]);
                            this.faqsReady = true;
                        });
                    })
                }

            });
        }

    }

</script>

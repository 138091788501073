<template>
    <transition name="fade"
                appear mode="out-in">
        <div class="sticky-top overflow-hidden">
            <transition name="fade">
                <div class="card">
                    <div class="card-body">
                        <p class="h6 d-flex justify-content-between text-secondary font-weight-600 mb-0">
                            <span>Polizza</span>
                            <i class="fa-regular fa-pen-to-square text-secondary" aria-hidden="true" @click="$router.push('/veicolo')"></i>
                        </p>
                        <div class="row">
                                <div class="col-6 mb-3">
                                    <small class="font-weight-600">Bikeplatinum</small>
                                </div>
                                <div class="w-100"></div>
                                
                                <div class="col-6 mb-3">
                                    <small class="text-muted">Marca</small><br/>
                                    <small class="font-weight-600">{{ BP_BIKE_DATA.desmar }}</small>
                                </div>
                                <div class="col-6 mb-3">
                                    <small class="text-muted">Modello</small><br/>
                                    <small class="font-weight-600">{{ BP_BIKE_DATA.desmod }}</small>
                                </div>
                                <div class="col-6 mb-3">
                                    <small class="text-muted">Allestimento</small><br/>
                                    <small class="font-weight-600">{{ BP_BIKE_DATA.desall }}</small>
                                </div>
                                
                                <div class="col-6 mb-3">
                                    <small class="text-muted">Usata</small><br/>
                                    <small class="font-weight-600">{{ BP_BIKE_DATA.isUsed ? 'Si': 'No' }}</small>
                                </div>
                                <div class="col-12 mb-3">
                                    <small class="text-muted">Provincia di residenza</small><br/>
                                    <small class="font-weight-600">{{ provincePlaceholder }}</small>
                                </div>
                        </div>

                        <div>
                            <p class="h6 text-secondary font-weight-600 mb-3">Garanzie</p>
                            <div>
                                <div class="d-flex align-items-end font-weight-600">
                                    <i class="fa fa-check text-secondary d-inline-block mr-2"></i><span>Furto totale</span>
                                </div>
                            </div>

                            <div v-for="guarantee in bikeplatinum.insuranceData.optionalGuarantees">
                                <div class="d-flex align-items-end font-weight-600" v-if="guarantee.isSelected">
                                    <i class="fa fa-check text-secondary d-inline-block mr-2"></i><span>{{guarantee.description}}</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-footer bg-white">
                    
                        <div class="text-secondary font-weight-600 d-flex justify-content-between align-items-end">
                            <span class="d-inline-block mr-3">Totale</span><span style="font-size: 25px;">€{{totalAmount}}</span>
                        </div>

                    </div>
                </div>

            </transition>

        </div>

    </transition>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import SummaryItem from './SummaryItem';
    import * as moment from 'moment';
    import * as numeral from 'numeral';
    import {formatNumberToComma} from '../../../Common/utils';

    

    export default {
        name: 'dd-summary',
        components: {
            'summary-item': SummaryItem
        },
        data() {
            return {
                summary: {
                    bikeDetailsSectionOpen: true,
                    anagraficaSectionOpen: true,
                    insuranceDetailsSectionOpen: true

                }
            }
        },
        computed: {
            ...mapGetters([
                'BP_BIKE_DATA',
                'QUOTATION_DATA',
                'BP_SELECTED_BUNDLE',
                'GLOBAL_ANAGRAFICA_CONTRACTOR',
                'GLOBAL_ANAGRAFICA_OWNER',
                'GLOBAL_ANAGRAFICA_UTILIZER',
                'GLOBAL_LOGGED_USER'
            ]),
            ...mapState([
                'anagrafica',
                'bikeplatinum'
            ]),

            provincePlaceholder() {
                try {
                    return this.bikeplatinum.provinces.find((_el) => {
                        return _el.code === this.BP_BIKE_DATA.ownerData.ownerProvince
                    }).description
                } catch (e) {
                    return ''

                }
            },
            totalAmount(){
                try {
                    return formatNumberToComma(this.bikeplatinum.totalAmount)
                } catch (e) {
                    return ''

                }

            },

        },
        methods: {
            goTo(_routeName) {
                this.$router.replace('/' + _routeName);
            }
        }
    }
</script>

/* Controlla se c'è il parametro "primarycolor", se c'è calcola da quello i colori secondari, primari, chiari e scuri */
import * as tinyColor from 'tinycolor2';

export function colorsCheck() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('color')) {
        const contrastFactor = 60;
        const primaryColor = tinyColor(`#${params.get('color')}`);
        const secondaryColor = params.has('secondaryColor') ? tinyColor(`#${params.get('secondaryColor')}`) : primaryColor.clone().darken(10);
        const colors =
            [
                ['--primary', primaryColor.toHex()],
                ['--secondary', secondaryColor.toHex()],
                ['--secondary-title', secondaryColor.clone().lighten(30).toHex()],
                ['--dark-secondary', secondaryColor.isLight() ? secondaryColor.clone().darken(50).toHex() : secondaryColor.clone().darken(20).toHex()],
                ['--button-primary-color', primaryColor.isLight() ? primaryColor.clone().darken(contrastFactor).toHex() : primaryColor.clone().lighten(contrastFactor).toHex()],
                ['--button-primary-active', primaryColor.clone().isLight() ? primaryColor.clone().darken(10).toHex() : primaryColor.clone().lighten(10).toHex()],
                ['--button-secondary-color', secondaryColor.isLight() ? secondaryColor.clone().darken(contrastFactor).toHex() : secondaryColor.clone().lighten(contrastFactor).toHex()],
                ['--button-secondary-active', secondaryColor.clone().isLight() ? secondaryColor.clone().darken(10).toHex() : secondaryColor.clone().lighten(10).toHex()]
            ]
        colors.forEach((c) => {
            document.documentElement.style.setProperty(c[0], '#' + c[1]);
        })
    }
}

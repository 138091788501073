<template>
    <div class="w-100 h-100">
        <hooper :settings="hooperSettings">
            <slide v-for="(slide, i) in slides" :key="i" :index="i">

                <div v-if="doubleImage === true"
                     class="d-flex flex-column justify-content-center align-items-center h-100">
                    <a class="mb-3" :href="urlPartner"
                       target="_blank">
                        <the-lazy-image
                                :img-alt="slide[0].name"
                                :source="'/images/index_logos/' + slide[0].imageName"/>
                    </a>
                    <a v-if="slide.length > 1" :href="urlPartner"
                       target="_blank">
                        <the-lazy-image
                                :img-alt="slide[1].name"
                                :source="'/images/index_logos/' + slide[1].imageName"/>
                    </a>
                </div>

                <div v-else class="d-flex justify-content-center align-items-center h-100">
                    <a :href="urlPartner"
                       target="_blank">
                        <the-lazy-image
                                :img-alt="slide.name"
                                :source="'/images/index_logos/' + slide.imageName"/>
                    </a>
                </div>
            </slide>
            <hooper-navigation v-if="showNavigation" slot="hooper-addons"></hooper-navigation>
        </hooper>
    </div>
</template>

<script>
import {Hooper, Slide, Navigation as HooperNavigation} from "hooper";
import chunk from "lodash";
import {PARTNER_LIST, PARTNER_LIST_GRAY} from "@/Common/constants";
import TheLazyImage from "@/Components/TheLazyImage";

export default {
    name: 'TheCarousel',
    props: {
        // Indica quali partner di che prodotto deve mostrare il componente SC,DD,MP,SK,MS,MY,MM
        product: {
            type: String,
            required: true,
        },
        // Indica se mostrare gli elementi su 2 righe
        doubleImage: {
            type: Boolean,
            required: false,
            default: false
        },
        // Indica il numero di elementi da mostrare in desktop
        itemsDesktop: {
            type: Number,
            required: false,
            default: 4
        },

        // Indica il numero di elementi da mostrare in mobile
        itemsMobile: {
            type: Number,
            required: false,
            default: 3
        },

        // Indica se mostrare in grigio i loghi dei partner
        itemsGray: {
            type: Boolean,
            required: false,
            default: true
        },

        //Indica se mostrare i controlli del carousel
        keysControl: {
            type: Boolean,
            required: false,
            default: true
        },

        // Indica se mostrare la navigazione
        showNavigation: {
            type: Boolean,
            required: false,
            default: true
        },

    },

    components: {
        TheLazyImage,
        Hooper,
        Slide,
        HooperNavigation,
    },



    computed: {
        slides() {
            const list = this.itemsGray ? PARTNER_LIST_GRAY : PARTNER_LIST;

            const partnerListFiltered = list.filter(
                (p) => p.product === this.product
            );

            return this.doubleImage
                ? chunk(partnerListFiltered, 2)
                : partnerListFiltered;
        },
        hooperSettings() {
            return {
                infiniteScroll: true,
                keysControl: this.keysControl,
                breakpoints: {
                    768: {
                        itemsToShow: this.itemsDesktop,
                    },
                },
            };
        },
        urlPartner() {
            switch (this.product) {
                case 'DD':
                    return '/partner-dottorpet'
                case 'BP':
                    return '/partner-bikeplatinum'
                case 'SK':
                    return '/partner-skippermate'
                case 'SC':
                    return '/partner-snowcare'
                case 'MS':
                    return '/partner-multisport'
                case 'MSL':
                    return '/multisport'
                default:
                    return '/partner-motoplatinum'
            }
        }
    }
}
</script>

<style lang="scss">
.hooper {
  height: auto;
}

.hooper-slide {
  height: auto;
}

.hooper-next {
  right: -2rem;
}

.hooper-prev {
  left: -2rem;
}

img {
  max-width: 100%;
}

.no-cursor {
  cursor: initial;
}

@media (max-width: 767.98px) {
  .hooper-slide a {
    max-width: 80%;
  }

  .hooper-next {
    right: -1.75rem;
  }

  .hooper-prev {
    left: -1.75rem;
  }
}
</style>

<template>
    <transition name="fade" appear mode="out-in">
        <div class="quote-summary sticky-top overflow-hidden">
            <transition name="fade">
                <div class="card rounded bg-transparent border-secondary" v-if="MY_SELECTED_BUNDLE">
                    <div class="card-header">
                        <p class="h4 text-secondary mb-0">
                            <i class="fal fa-file-certificate"></i> La polizza
                            <a v-if="$route.name !== 'thank-you' && $route.name !== 'quotazione'"
                               @click="goTo('quotazione')"
                               class="edit-link">Modifica</a>

                        </p>
                    </div>
                    <div class="card-body d-flex justify-content-between border border-bottom">
                        <p class="h4 mb-0">
                            Multimobility <br>
                            {{MY_SELECTED_BUNDLE.title}}
                        </p>

                        <span class="h3 text-primary font-weight-bold mb-0 my-price"
                              :class="{'discounted' : QUOTATION_DATA.discountCodeIsValid}">
                                {{MY_SELECTED_BUNDLE.grossValue | currency }}
                        </span>
                    </div>
                    <div class="card-body d-flex justify-content-between align-items-center border border-bottom"
                         v-if="QUOTATION_DATA.discountCodeIsValid">
                        <p class="lead mb-0">
                            Con sconto<br/> <b>"{{QUOTATION_DATA.discountCodeDescription}}"</b>
                        </p>
                        <span class="h3 text-primary font-weight-bold mb-0">
                                {{MY_SELECTED_BUNDLE.value | currency }}
                        </span>
                    </div>
                    <div class="card-body">
                        <small>{{MY_SELECTED_BUNDLE.description}}</small>
                    </div>
                </div>
            </transition>

            <div class="overflow-hidden">
                <transition name="fade">
                    <div>
                        <div class="summary-header" @click="onAnagraficaSectionClick"
                             :class="{'clickable' : GLOBAL_ANAGRAFICA_CONTRACTOR}">
                            <span class="lead m-0 text-secondary"
                                  :class="{'text-muted' : !GLOBAL_ANAGRAFICA_CONTRACTOR}">
                              <i class="fal fa-id-card"></i>
                              Dati anagrafici
                            </span><i class="fa fa-chevron-down primary"
                                      :class="{'fa-chevron-up' : summary.anagraficaSectionOpen}"></i>
                        </div>
                        <template
                                v-if="summary.anagraficaSectionOpen && GLOBAL_ANAGRAFICA_CONTRACTOR.name || summary.anagraficaSectionOpen && GLOBAL_ANAGRAFICA_CONTRACTOR.surname">
                            <p class="summary-subtitle">Il contraente</p>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_CONTRACTOR.name && !GLOBAL_ANAGRAFICA_CONTRACTOR.isCompany">
                                <small class="summary-value-label">Nome</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_CONTRACTOR.name}}</small>
                            </div>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_CONTRACTOR.surname">
                                <small class="summary-value-label">{{!GLOBAL_ANAGRAFICA_CONTRACTOR.isCompany ? 'Cognome'
                                    : 'Ragione sociale'}}</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_CONTRACTOR.surname}}</small>
                            </div>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_CONTRACTOR.fiscalCode">
                                <small class="summary-value-label">{{!GLOBAL_ANAGRAFICA_CONTRACTOR.isCompany ? 'Codice fiscale'
                                    : 'P.IVA'}}</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_CONTRACTOR.fiscalCode}}</small>
                            </div>
                            <div class="d-flex justify-content-end" v-if="!GLOBAL_LOGGED_USER">
                                <a v-if="$route.name !== 'thank-you'"
                                   @click="goTo('anagrafica/contraente')"
                                   class="edit-link">Modifica</a>
                            </div>
                        </template>

                        <template v-if=" (GLOBAL_ANAGRAFICA_OWNER.name || GLOBAL_ANAGRAFICA_OWNER.surname) && !anagrafica.isOwner">
                            <p class="summary-subtitle">L'assicurato</p>
                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_OWNER.name && !GLOBAL_ANAGRAFICA_OWNER.isCompany">
                                <small class="summary-value-label">Nome</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_OWNER.name}}</small>
                            </div>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_OWNER.surname">
                                <small class="summary-value-label">{{!GLOBAL_ANAGRAFICA_OWNER.isCompany ? 'Cognome'
                                    : 'Ragione sociale'}}</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_OWNER.surname}}</small>
                            </div>

                            <div class="d-flex justify-content-between summary-table-row"
                                 v-if="GLOBAL_ANAGRAFICA_OWNER.fiscalCode">
                                <small class="summary-value-label">{{!GLOBAL_ANAGRAFICA_OWNER.isCompany ? 'Codice fiscale'
                                    : 'P.IVA'}}</small>
                                <small class="summary-value">{{GLOBAL_ANAGRAFICA_OWNER.fiscalCode}}</small>


                            </div>

                            <div class="d-flex justify-content-end">
                                <a v-if="$route.name !== 'thank-you'"
                                   @click="goTo('anagrafica/proprietario')"
                                   class="edit-link">Modifica</a>
                            </div>
                        </template>
                    </div>
                </transition>
            </div>

        </div>
    </transition>

</template>

<script>
    import {mapGetters, mapState} from 'vuex'
    import {GaEvents} from "../../../Common/AnalyticsMethods";

    export default {
        name: 'my-summary',
        /* mixins: [BootstrapOperations], */
        data() {
            return {
                summary: {
                    petDetailsSectionOpen: true,
                    anagraficaSectionOpen: true,

                }
            }
        },
        computed: {
            ...mapGetters([
                'QUOTATION_DATA',
                'MY_BREED_NAME',
                'MY_SELECTED_BUNDLE',
                'GLOBAL_ANAGRAFICA_CONTRACTOR',
                'GLOBAL_ANAGRAFICA_OWNER',
                'MY_ROUTER',
                'GLOBAL_LOGGED_USER'
            ]),

            ...mapState(['anagrafica']),

            getPetIcon() {
                try {
                    switch (this.MY_PET_KIND) {
                        case 'cane':
                            return 'fa-dog';

                        case 'gatto':
                            return 'fa-cat';

                        default:
                            return 'fa-paw-alt';
                    }

                } catch (e) {
                    console.error(e);
                    return 'fa-paw-alt';
                }
            },
        },
        methods: {
            /* onpetDataSectionClick() {
                this.summary.petDetailsSectionOpen = this.MY_PET_DATA ? !this.summary.petDetailsSectionOpen : false;
            }, */
            onAnagraficaSectionClick() {
                this.summary.anagraficaSectionOpen = this.GLOBAL_ANAGRAFICA_CONTRACTOR ? !this.summary.anagraficaSectionOpen : false;
            },
            summaryClose(){
                this.$emit('summaryClose', 'summary close');
            },
            goTo(_routeName) {
                GaEvents.modify('MY');
                this.$router.replace('/' + _routeName);
                this.summaryClose();
            }
        }
    }
</script>

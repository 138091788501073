<template>
    <div id="fq-nav-home" class="fqNaveHome w-100 h-100">
        <div class="container-scroll-x-products cardsContainer mb-4" id="cards-container" v-if="!showMobile">
            <ul class="nav nav-pills justify-content-between mb-3 mb-md-5" style="cursor: pointer;">
                <li id="cta_mp_hero" data-type="button" data-parent class="nav-item text-center">
                    <a class="nav-link py-3" :class="{ 'active' :  activeItem === 'MM'}"
                       @click.prevent="()=>{activeItem = 'MM'}">
                        <i class="fa-regular fa-motorcycle fa-2x nav-ico"></i><br/>
                        <span class="nav-title">MOTO E SCOOTER</span><br/>
                        <span class="nav-subtitle">Motoplatinum</span>
                    </a>
                </li>
                <li id="cta_sc_hero" data-parent class="nav-item text-center">
                    <a class="nav-link py-3" :class="{ 'active' :  activeItem === 'SC'}"
                       @click.prevent="()=>{activeItem = 'SC'}">
                        <i class="fa-regular fa-person-skiing fa-2x nav-ico"></i><br/>
                        <span class="nav-title">SCI E SNOWBOARD</span><br/>
                        <span class="nav-subtitle">Snowcare</span>
                    </a>
                </li>
                <li id="cta_ms_hero" data-type="button" data-parent class="nav-item text-center">
                    <a class="nav-link py-3" :class="{ 'active' :  activeItem === 'MS'}"
                       @click.prevent="()=>{activeItem = 'MS'}">
                        <i class="fa-solid fa-football-helmet fa-2x nav-ico"></i><br/>
                        <span class="nav-title">SPORT</span><br/>
                        <span class="nav-subtitle">Multisport</span>
                    </a>
                </li>
                <li id="cta_dp_hero" data-type="button" data-parent class="nav-item text-center">
                    <a class="nav-link py-3" :class="{ 'active' :  activeItem === 'DD'}"
                       @click.prevent="()=>{activeItem = 'DD'}">
                        <i class="fa-solid fa-dog fa-2x nav-ico"></i><br/>
                        <span class="nav-title">ANIMALI DOMESTICI</span><br/>
                        <span class="nav-subtitle">Dottorpet</span>
                    </a>
                </li>
                <li id="cta_bp_hero" data-type="button" data-parent class="nav-item text-center">
                    <a class="nav-link py-3" :class="{ 'active' :  activeItem === 'BP'}"
                       @click.prevent="()=>{activeItem = 'BP'}">
                        <i class="fa-regular fa-bicycle fa-2x nav-ico"></i><br/>
                        <span class="nav-title">E-BIKE</span><br/>
                        <span class="nav-subtitle">Bikeplatinum</span>
                    </a>
                </li>
                <li id="cta_my_hero" data-type="button" data-parent class="nav-item text-center">
                    <a class="nav-link py-3" :class="{ 'active' :  activeItem === 'MY'}"
                       @click.prevent="()=>{activeItem = 'MY'}">
                        <i class="fa-solid fa-charging-station fa-2x nav-ico"></i><br/>
                        <span class="nav-title">MOBILIT&Agrave; URBANA</span><br/>
                        <span class="nav-subtitle">Multimobility</span>
                    </a>
                </li>
                <li id="cta_sk_hero" data-type="button" data-parent class="nav-item text-center">
                    <a class="nav-link py-3" :class="{ 'active' :  activeItem === 'SK'}"
                       @click.prevent="()=>{activeItem = 'SK'}">
                        <i class="fa-solid fa-sailboat fa-2x nav-ico"></i><br/>
                        <span class="nav-title">BARCA</span><br/>
                        <span class="nav-subtitle">Skippermate</span>
                    </a>
                </li>
            </ul>
        </div>

        <div class="row d-flex justify-content-center" v-if="!showMobile" style="height:260px">
            <transition name="fade" mode="out-in" duration="200">
                <div key="1" class="item col-12 col-lg-8 col-xl-7  px-3" v-if="activeItem === 'MM'">

                    <div>
                        <div class="bg-white fast-quote-container-rounded shadow py-3 px-3 px-md-5 mb-2">
                            <fastquote-motoplatinum
                                    :urlprod="urlprodMm"
                                    size="regular"
                                    theme="light"
                                    :borders="true"
                                    alignment="responsive"
                                    :sticky="true">&nbsp;
                            </fastquote-motoplatinum>
                        </div>
                        <p class="text-center mt-5 mt-md-3">
                            <a class="text-dark-gray" href="javascript:void(0)"
                               data-toggle="modal" data-target="#dati-richiesti-modal">A cosa servono i dati
                                richiesti?</a>
                        </p>
                    </div>
                </div>

                <div key="2" class="item col-12 col-lg-9 col-xl-8 px-3 py-5"
                     v-if="activeItem === 'SC'">
                     <snowcare-fastquote :show-badge-feedaty="false"></snowcare-fastquote>
                </div>

                <div key="3" class="item col-auto px-3 py-5"
                     v-if="activeItem === 'MS'">
                    <a id="cta_ms_fastquote"
                        href="javascript:void(0)"
                        class="btn btn-primary rounded-pill px-5"
                        data-type="CTA"
                        onclick="window.fastquoteEventHandler(event, '/Assicurazione-infortuni-incidenti-sport/Step1.aspx')">FAI UN PREVENTIVO</a>

                </div>

                <div key="4" class="item col-12 col-md-5 px-3"
                     v-if="activeItem === 'DD'">

                    <div class="pt-3 pb-4 px-3 bg-white shadow rounded">
                        <p class="text-dark-secondary font-weight-bold text-left">
                            Chi vuoi assicurare?
                        </p>
                        <div class="d-flex justify-content-around align-items-center">
                            <div class="col px-0 mr-1">
                                <a id="cta_dp_fastquote_1"
                                    href="javascript:void(0)"
                                    class="btn btn-secondary text-white btn-block rounded-pill"
                                    data-type="CTA"
                                    onclick="window.fastquoteEventHandler(event, '/dottorpet/preventivo/#/inizio?petkind=Dog')">Cane</a>
                            </div>

                            <div class="col px-0 mr-1">
                                <a id="cta_dp_fastquote_2"
                                    href="javascript:void(0)"
                                    class="btn btn-secondary text-white btn-block rounded-pill"
                                    data-type="CTA"
                                    onclick="window.fastquoteEventHandler(event, '/dottorpet/preventivo/#/inizio?petkind=Cat')">Gatto</a>
                            </div>

                            <div class="col px-0">
                                <a id="cta_dp_fastquote_3"
                                    href="javascript:void(0)"
                                    class="btn btn-secondary text-white btn-block rounded-pill"
                                    data-type="CTA"
                                    onclick="window.fastquoteEventHandler(event, '/dottorpet/preventivo/#/inizio?petkind=Rabbit')">Coniglio</a>
                            </div>

                        </div>

                    </div>


                </div>

                <div key="5" class="item col-12 col-lg-8 px-3"
                     v-if="activeItem === 'BP'">

                    <div class="bg-white fast-quote-container-rounded shadow py-3 pb-4 px-3 pl-md-5 pr-md-4 mb-2 pb-lg-4">
                        <fastquote-bikeplatinum
                                :urlprod="urlprodBp"
                                size="regular"
                                theme="light"
                                :borders="true"
                                alignment="responsive"
                                :sticky="true"></fastquote-bikeplatinum>

                    </div>

                    <p class="text-center mt-5 mt-md-3"><a class="text-dark-gray"
                                                           href="javascript:void(0)"
                                                           data-toggle="modal"
                                                           data-target="#dati-richiesti-modal">A cosa
                        servono i dati richiesti?</a></p>

                </div>

                <div key="6" class="item col-12 col-md-5"
                     v-if="activeItem === 'MY'">
                    <div class="pt-3 pb-4 px-3 bg-white shadow rounded">
                        <p class="text-dark-secondary font-weight-bold text-left">
                            Che polizza vuoi stipulare?
                        </p>
                        <div class="d-flex justify-content-around align-items-center">
                            <div class="col px-0 mr-1">
                                <a id="cta_my_fastquote_1"
                                    href="javascript:void(0)"
                                    class="btn btn-secondary text-white btn-block rounded-pill"
                                    data-type="CTA"
                                    onclick="window.fastquoteEventHandler(event, '/multimobility/preventivo#/quotazione?insurancetype=individual')">Individuale</a>
                            </div>

                            <div class="col px-0">
                                <a id="cta_my_fastquote_2"
                                    href="javascript:void(0)"
                                    class="btn btn-secondary text-white btn-block rounded-pill"
                                    data-type="CTA"
                                    onclick="window.fastquoteEventHandler(event, '/multimobility/preventivo#/quotazione?insurancetype=family')">Familiare</a>
                            </div>
                        </div>
                    </div>

                </div>

                <div key="7" class="item col-auto px-3 py-5" v-if="activeItem === 'SK'">
                    <a id="cta_sk_fastquote_1"
                        href="javascript:void(0)" 
                        class="btn btn-primary rounded-pill px-5"
                        data-type="CTA"
                        onclick="window.fastquoteEventHandler(event, '/Assicurazione-barca-comparatore/Step1.aspx')">FAI UN PREVENTIVO</a>
                </div>
            </transition>

        </div>

        <div class="row justify-content-center" v-if="showMobile">
            <transition-group class="w-100" name="fade" mode="out-in" duration="200">
                <!-- MOBILE MP -->
                <div id="cta_mp_hero" data-type="button" data-parent key="1" class="item col-12 px-3"
                     v-if="!activeItem || activeItem === 'MM'">
                    <div class="bg-white fast-quote-container-rounded shadow py-3 px-4 mb-2">
                        <div @click.prevent="()=>{activeItem === 'MM' ? activeItem = null : activeItem = 'MM'}"
                                class="d-flex justify-content-between d-flex align-items-center">
                            <div class="d-flex d-flex align-items-center">
                                <i class="fa-solid fa-motorcycle icon-mobile text-secondary mr-2"></i>
                                <div>
                                    <span class="font-weight-bold">MOTO E SCOOTER</span><br/>
                                    <span class="text-gray">Motoplatinum</span>
                                </div>
                            </div>
                            <i class="fa-solid fa-chevron-right icon-mobile text-secondary"
                               :class="{'rotate-90' : activeItem === 'MM'}"></i>
                        </div>

                        <div class="overflow-hidden">
                            <transition name="fade" mode="out-in" duration="200">
                                <div class="my-2 pt-2" v-if="activeItem === 'MM'">
                                    <fastquote-motoplatinum
                                            :urlprod="urlprodMm"
                                            size="regular"
                                            theme="light"
                                            :borders="true"
                                            alignment="responsive"
                                            :sticky="true">
                                    </fastquote-motoplatinum>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <p v-if="activeItem === 'MM'"
                       class="text-center mt-5 mt-md-3"><a class="text-dark-gray"
                                                           href="javascript:void(0)"
                                                           data-toggle="modal"
                                                           data-target="#dati-richiesti-modal">
                        A cosa servono i dati richiesti?</a>
                    </p>
                </div>

                <!-- MOBILE SC -->
                <div id="cta_sc_hero" data-type="button" data-parent key="2" class="item col-12 px-3"
                     v-if="!activeItem || activeItem === 'SC'">
                    <div class="bg-white fast-quote-container-rounded shadow py-3 px-4 mb-2">
                        <div
                                @click.prevent="()=>{activeItem === 'SC' ? activeItem = null : activeItem = 'SC'}"
                                class="d-flex justify-content-between d-flex align-items-center">
                            <div class="d-flex d-flex align-items-center">
                                <i class="fa-solid fa-person-skiing icon-mobile text-secondary mr-2"></i>
                                <div>
                                    <span class="font-weight-bold text-uppercase">Sci e snowboard</span><br/>
                                    <span class="text-gray">Snowcare</span>
                                </div>
                            </div>
                            <i class="fa-solid fa-chevron-right icon-mobile text-secondary"
                               :class="{'rotate-90' : activeItem === 'SC'}"></i>
                        </div>

                        <div class="overflow-hidden">
                            <transition name="fade" mode="out-in" duration="200">
                                <div class="my-2 pt-2" v-if="activeItem === 'SC'">
                                    <div v-if="activeItem === 'SC'">
                                        <snowcare-fastquote :show-badge-feedaty="false"></snowcare-fastquote>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- MOBILE MS -->
                <div id="cta_ms_hero" data-type="button" data-parent key="3" class="item col-12 px-3"
                     v-if="!activeItem || activeItem === 'MS'">
                    <div class="bg-white fast-quote-container-rounded shadow py-3 px-4 mb-2">
                        <div @click.prevent="()=>{activeItem === 'MS' ? activeItem = null : activeItem = 'MS'}"
                             class="d-flex justify-content-between d-flex align-items-center">
                            <div class="d-flex d-flex align-items-center">
                                <i class="fa-solid fa-football-helmet icon-mobile text-secondary mr-2"></i>
                                <div>
                                    <span class="font-weight-bold text-uppercase">Sport</span><br/>
                                    <span class="text-gray">Multisport</span>
                                </div>
                            </div>
                            <i class="fa-solid fa-chevron-right icon-mobile text-secondary"
                               :class="{'rotate-90' : activeItem === 'MS'}"></i>
                        </div>

                        <div class="overflow-hidden">
                            <transition name="fade" mode="out-in" duration="200">
                                <div class="my-2 pt-2" v-if="activeItem === 'MS'">
                                    <a v-if="activeItem === 'MS'"
                                        id="cta_ms_fastquote"
                                        href="javascript:void(0)"
                                        data-type="CTA"
                                        onclick="window.fastquoteEventHandler(event, '/Assicurazione-infortuni-incidenti-sport/Step1.aspx')"
                                        class="btn btn-primary rounded-pill btn-block px-5 my-5">FAI UN PREVENTIVO</a>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- MOBILE DD -->
                <div id="cta_dp_hero" data-type="button" data-parent  key="4" class="item col-12 px-3"
                     v-if="!activeItem || activeItem === 'DD'">
                    <div class="bg-white fast-quote-container-rounded shadow py-3 px-4 mb-2">
                        <div
                                @click.prevent="()=>{activeItem === 'DD' ? activeItem = null : activeItem = 'DD'}"
                                class="d-flex justify-content-between d-flex align-items-center">
                            <div class="d-flex d-flex align-items-center">
                                <i class="fa-solid fa-dog icon-mobile text-secondary mr-2"></i>
                                <div>
                                    <span class="font-weight-bold text-uppercase">Animali domestici</span><br/>
                                    <span class="text-gray">Dottorpet</span>
                                </div>
                            </div>
                            <i class="fa-solid fa-chevron-right icon-mobile text-secondary"
                               :class="{'rotate-90' : activeItem === 'DD'}"></i>
                        </div>

                        <div class="overflow-hidden">
                            <transition name="fade" mode="out-in" duration="200">
                                <div class="my-2 pt-2" v-if="activeItem === 'DD'">
                                    <p class="text-dark-secondary font-weight-bold text-left">
                                        Chi vuoi assicurare?
                                    </p>
                                    <div class="d-flex justify-content-around align-items-center">
                                        <div class="col px-0 mr-1">
                                            <a  id="cta_dp_fastquote_1"
                                                href="javascript:void(0)"
                                                class="btn btn-secondary text-white btn-block rounded-pill"
                                                data-type="CTA"
                                                onclick="window.fastquoteEventHandler(event, '/dottorpet/preventivo/#/inizio?petkind=Dog')">Cane</a>
                                        </div>
                                        <div class="col px-0 mr-1">
                                            <a id="cta_dp_fastquote_2"
                                                href="javascript:void(0)"
                                                class="btn btn-secondary text-white btn-block rounded-pill"
                                                data-type="CTA"
                                                onclick="window.fastquoteEventHandler(event, '/dottorpet/preventivo/#/inizio?petkind=Cat')">Gatto</a>
                                        </div>
                                        <div class="col px-0">
                                            <a id="cta_dp_fastquote_3"
                                                href="javascript:void(0)"
                                                class="btn btn-secondary text-white btn-block rounded-pill"
                                                data-type="CTA"
                                                onclick="window.fastquoteEventHandler(event, '/dottorpet/preventivo/#/inizio?petkind=Rabbit')">Coniglio</a>
                                        </div>
                                    </div>

                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- MOBILE BP -->
                <div id="cta_bp_hero" data-type="button" data-parent key="5" class="item col-12 px-3"
                     v-if="!activeItem || activeItem === 'BP'">
                    <div class="bg-white fast-quote-container-rounded shadow py-3 px-4 mb-2">
                        <div
                                @click.prevent="()=>{activeItem === 'BP' ? activeItem = null : activeItem = 'BP'}"
                                class="d-flex justify-content-between d-flex align-items-center">
                            <div class="d-flex d-flex align-items-center">
                                <i class="fa-solid fa-bicycle icon-mobile text-secondary mr-2"></i>
                                <div>
                                    <span class="font-weight-bold text-uppercase">E-bike</span><br/>
                                    <span class="text-gray">Bikeplatinum</span>
                                </div>
                            </div>
                            <i class="fa-solid fa-chevron-right icon-mobile text-secondary"
                               :class="{'rotate-90' : activeItem === 'BP'}"></i>
                        </div>

                        <div class="overflow-hidden">
                            <transition name="fade" mode="out-in" duration="200">
                                <div class="my-2 pt-2" v-if="activeItem === 'BP'">
                                    <fastquote-bikeplatinum
                                        :urlprod="urlprodBp"
                                        size="regular"
                                        theme="light"
                                        :borders="true"
                                        alignment="responsive"
                                        :sticky="true"></fastquote-bikeplatinum>

                                           
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- MOBILE MY -->
                <div id="cta_my_hero" data-type="button" data-parent  key="6" class="item col-12 px-3"
                     v-if="!activeItem || activeItem === 'MY'">
                    <div class="bg-white fast-quote-container-rounded shadow py-3 px-4 mb-2">
                        <div @click.prevent="()=>{activeItem === 'MY' ? activeItem = null : activeItem = 'MY'}"
                             class="d-flex justify-content-between d-flex align-items-center">
                            <div class="d-flex d-flex align-items-center">
                                <i class="fa-solid fa-charging-station icon-mobile text-secondary mr-2"></i>
                                <div>
                                    <span class="font-weight-bold text-uppercase">Mobilità urbana</span><br/>
                                    <span class="text-gray">Multimobility</span>
                                </div>
                            </div>
                            <i class="fa-solid fa-chevron-right icon-mobile text-secondary"
                               :class="{'rotate-90' : activeItem === 'MY'}"></i>
                        </div>

                        <div class="overflow-hidden">
                            <transition name="fade" mode="out-in" duration="200">
                                <div class="my-2 pt-2" v-if="activeItem === 'MY'">
                                    <p class="text-dark-secondary font-weight-bold text-left">
                                        Che polizza vuoi stipulare?
                                    </p>
                                    <div class="d-flex justify-content-around align-items-center">
                                        <div class="col px-0 mr-1">
                                            <a id="cta_my_fastquote_1"
                                                href="javascript:void(0)"
                                                class="btn btn-secondary text-white btn-block rounded-pill"
                                                data-type="CTA"
                                                onclick="window.fastquoteEventHandler(event, '/multimobility/preventivo#/quotazione?insurancetype=individual')">Individuale</a>
                                        </div>
                                        <div class="col px-0">
                                            <a id="cta_my_fastquote_2"
                                                href="javascript:void(0)"
                                                class="btn btn-secondary text-white btn-block rounded-pill"
                                                data-type="CTA"
                                                onclick="window.fastquoteEventHandler(event, '/multimobility/preventivo#/quotazione?insurancetype=family')">Familiare</a>
                                        </div>
                                    </div>

                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- MOBILE SK -->
                <div id="cta_sk_hero" data-parent key="7" class="item col-12 px-3"
                     v-if="!activeItem || activeItem === 'SK'">
                    <div class="bg-white fast-quote-container-rounded shadow py-3 px-4 mb-2">
                        <div @click.prevent="()=>{activeItem === 'SK' ? activeItem = null : activeItem = 'SK'}"
                             class="d-flex justify-content-between d-flex align-items-center">
                            <div class="d-flex d-flex align-items-center">
                                <i class="fa-solid fa-sailboat icon-mobile text-secondary mr-2"></i>
                                <div>
                                    <span class="font-weight-bold text-uppercase">Barca</span><br/>
                                    <span class="text-gray">Skippermate</span>
                                </div>
                            </div>
                            <i class="fa-solid fa-chevron-right icon-mobile text-secondary"
                               :class="{'rotate-90' : activeItem === 'SK'}"></i>
                        </div>

                        <div class="overflow-hidden">
                            <transition name="fade" mode="out-in" duration="200">
                                <div class="my-2 pt-2" v-if="activeItem === 'SK'">
                                    
                                    <a v-if="activeItem === 'SK'"
                                        id="cta_sk_fastquote"
                                        href="javascript:void(0)"
                                        data-type="CTA"
                                        onclick="window.fastquoteEventHandler(event, '/Assicurazione-barca-comparatore/Step1.aspx')"
                                        class="btn btn-primary rounded-pill btn-block px-5 my-5">FAI UN PREVENTIVO</a>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>
<script>

import FastquoteMotoplatinum from "@/Preventivo/_Common/Components/FastquoteMotoplatinum";
import FastquoteBikeplatinum from "@/Preventivo/_Common/Components/FastquoteBikeplatinum";
import SnowcareFastquote from "@/Components/SnowcareFastquote";

export default {
    name: "HomeFastquote",
    components: {FastquoteBikeplatinum, FastquoteMotoplatinum,SnowcareFastquote },
    props: [
        "urlprodMm",
        "urlprodBp",
        "viewMobile",
    ],
    data() {
        return {
            activeItem: null,
            windowSize: 0
        };
    },

    mounted() {

        this.windowSize = window.innerWidth;

        if (!this.showMobile) this.activeItem = "MM"

        window.addEventListener('resize', () => {

            if (this.windowSize != window.innerWidth) {
                this.windowSize = window.innerWidth;
                if (this.showMobile) if (!this.showMobile) this.activeItem = "MM"
            }
        });

    },
    computed: {
        showMobile() {
            return this.windowSize < 576 && this.viewMobile
        }
    },
};
</script>

<style lang="scss" scoped>

#cards-container::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
    height: 2.5px !important;

}



#cards-container::-webkit-scrollbar-thumb {
    background-color: #999;
    /* outline: 1px solid #6c757d; */
    border-radius: 2rem;
}

.nav-link {
  -webkit-transition: background-color 150ms linear;
  -ms-transition: background-color 150ms linear;
  transition: background-color 150ms linear;
}

.fa-chevron-right {
  -webkit-transition: rotate 150ms linear;
  -ms-transition: rotate 150ms linear;
  transition: rotate 150ms linear;
}

.rotate-90 {
  rotate: 90deg;
}

div {
  -webkit-transition: height 150ms linear;
  -ms-transition: height 150ms linear;
  transition: height 150ms linear;
}


.icon-mobile {
  font-size: 20px;
}


@media (max-width: 575.98px) {
  .nav.nav-pills {
    width: 220%;
  }
  .nav-link {
    font-size: 10px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .nav.nav-pills {
    width: 150%;
  }
  .nav-link {
    font-size: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .nav.nav-pills {
    width: 150%;
  }
  .nav-link {
    font-size: 13px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav.nav-pills {
    width: 100%;
  }
  .nav-link {
    font-size: 14px;
  }
}


</style>

<template>
    <div>

        <vc-date-picker
                :key="datePickerRefreshKey"
                v-model="calendarRange"
                :columns="columns"
                :rows="rows"
                :max-date="maxDate"
                :min-date="minDate"
                :drag-attribute="attributes.colors"
                :model-config="attributes.config"
                :select-attribute="attributes.colors"
                is-expanded
                @dayclick="onDayClick"
                is-range/>

        <transition mode="out-in" name="fade">
            <div v-if="$v.range.start.$error && !$v.range.start.startOlderThanSixty"
                 class="mb-2 d-flex align-items-center justify-content-start bg-light-gray p-3 rounded-sm text-danger">
                <i class="fas fa-exclamation-triangle fa-xs mr-1"></i>
                <small>
                    La data di inizio non puo essere oltre 60 giorni da oggi
                </small>
            </div>
        </transition>




        <!-- Visualizzazione desktop -->
        <div class="d-none d-md-block">
            <div class="d-flex justify-content-between align-items-center border-top p-3 calendar-desktop">
               <div>
                   <div v-if="!$v.range.start.startOlderThanSixty">
                       <small class="text-danger">
                           <i class="fas fa-exclamation-triangle mr-1"></i>
                           La data di inizio non puo essere oltre 60 giorni da oggi
                       </small>
                   </div>
                   <div v-if="$v.range.start.startOlderThanSixty && this.range.start">
                       <div v-if="daysFromDates === 0 || daysFromDates >= 2"
                            class="font-weight-700 text-blue">
                           {{ rangeDate }} - {{ daysFromDates }} giorni

                       </div>
                       <div v-else class="font-weight-700 text-blue">
                           {{ rangeDate }} - {{ daysFromDates }} giorno

                       </div>
                   </div>
               </div>
                <button id="cta_sc_confirm_1"
                        dataType="CTA"
                        class="btn btn-secondary rounded-pill text-uppercase font-size-14"
                        type="button"
                        :disabled="!this.range.start && !this.range.end || !$v.range.start.startOlderThanSixty"
                        @click="onConfirm">
                    conferma
                </button>
            </div>
        </div>

        <!-- Visualizzazione mobile -->
        <div class="d-block d-md-none bg-white">
            <div class="w-100 fixed-bottom d-flex justify-content-between flex-column align-items-center border-top p-3 calendar-mobile">
                <div class="w-100 text-center" v-if="!$v.range.start.startOlderThanSixty">
                    <small class="text-danger">
                        <i class="fas fa-exclamation-triangle mr-1"></i>
                        La data di inizio non puo essere oltre 60 giorni da oggi<br/>
                    </small>
                </div>

                    <button v-if="daysFromDates === 0 || daysFromDates >= 2"
                            id="cta_sc_confirm_2"
                            dataType="CTA"
                            class="mt-2 col btn btn-secondary rounded-pill text-uppercase font-size-16" style="line-height: 1.2 !important"
                            type="button"
                            :disabled="!this.range.start && !this.range.end || !$v.range.start.startOlderThanSixty"
                            @click="onConfirm">
                        conferma
                        <div v-if="range.start" class="small font-weight-400 text-lowercase text-white">
                            {{rangeDate}} - {{daysFromDates}} giorni

                        </div>
                    </button>
                    <button v-else
                            id="cta_sc_confirm_3"
                            dataType="CTA"
                            class="mt-2 col btn btn-secondary rounded-pill text-uppercase font-size-16" style="line-height: 1.2 !important"
                            type="button"
                            :disabled="!this.range.start && !this.range.end || !$v.range.start.startOlderThanSixty"
                            @click="onConfirm">
                        conferma
                        <div v-if="range.start" class="small font-weight-400 text-lowercase text-white">
                            {{rangeDate}} - {{daysFromDates}} giorno

                        </div>
                    </button>



            </div>
        </div>

    </div>
</template>

<script>
import * as moment from 'moment';
import Vue from "vue";
import VCalendar from 'v-calendar';
import {CALENDAR_COLORS_CONFIG, CALENDAR_CONFIG} from "@/Common/constants";

Vue.use(VCalendar, {
    componentPrefix: 'vc'
});


export default {
    name: "SnowcareFastquoteCalendar",
    props: {
        columns: {
            type: Number,
            // required: true
        },
        rows:{
            type: Number,
            // required: true
        },
    },
    components: {},
    data() {
        return {
            range: {
                start: null,
                end: null
            },
            // Indica il numero do click effettuati sul calendario
            // è necessario per la logica richiesta dove a un
            // solo click devono gia corrispondere
            // entrambe le date start e end
            clicks: 0,
            // Model ad uso esclusivo del calendario sempre per la logica qui sopra
            calendarRange: {
                start: null,
                end: null
            },
            // Key necessario per far ri renderizzare il calendario e
            // mostrare eventuali cambiamenti esterni alla data
            datePickerRefreshKey: 0,
            attributes: {
                config: CALENDAR_CONFIG,
                colors: CALENDAR_COLORS_CONFIG
            }
        }
    },
    computed: {
        minDate() {
            return moment().toDate()
        },
        maxDate() {
            return this.range.start ? moment(this.range.start).add(365, 'days').toDate() : moment().add(365, 'days').toDate()
        },
        //ritorna la data o le date del periodo assicurazione
        rangeDate() {

            if (this.range.start && this.range.end) {
                //controlla se data inizio e fine sono uguali in mpdo da mostrare solo data di inizio altrimentri mostra entrambi le date
                return moment(this.range.start).isSame(this.range.end) ? moment(this.range.start).format('DD MMM YYYY') : `${moment(this.range.start).format('DD MMM YYYY')} / ${moment(this.range.end).format('DD MMM YYYY')}`

            } else return moment(this.range.start).format('DD MMM YYYY')


        },
        //ritorna il numero dei giorni dell'assicurazione
        daysFromDates() {
            if (this.range.start && !this.range.end) return 1
            return Math.abs(moment(this.range.start).diff(moment(this.range.end), 'days')) + 1
        },

        alertContent() {
            // Se inizio e fine corrispondono a oggi
            if (moment(this.range.start).isSame(new Date(), "day") && moment(this.range.end).isSame(new Date(), "day")) {
                return "La polizza sarà attiva 20 minuti dopo l'acquisto"
            }

            // Se inizio è oggi e fine invece no
            if (moment(this.range.start).isSame(new Date(), "day") && !moment(this.range.end).isSame(new Date(), "day")) {
                 return `${"La polizza sarà attiva 20 minuti dopo l'acquisto e terminerà il"} ${moment(this.range.end).format('DD/MM')} ${"alle"} ${moment(this.range.end).endOf('day').format('HH:mm')}`
             }

            // Se inizio non è oggi
             if (!moment(this.range.start).isSame(new Date(), "day")) {
                 return `${"La polizza sarà attiva dal"} ${moment(this.range.start).format('DD/MM')} ${"e terminerà il"} ${moment(this.range.end).format('DD/MM')} ${"alle"} ${moment(this.range.end).endOf('day').format('HH:mm')}`
             }

        },

    },
    methods: {

        onConfirm() {
            if (this.$v.range.start.$invalid) return
            const values = {
                date: this.range.start,
                unitAmount: this.daysFromDates,
                alertContent: this.alertContent
            }
            this.$emit('onDateConfirmed', values)

        },
        onDayClick(e) {

            if(moment(e.date).diff(this.minDate, 'days') < 0) {
                this.datePickerRefreshKey++
                return
            }
            
            if (this.clicks === 0) {
                this.clicks = this.clicks + 1
                this.range = {start: e.date, end: e.date}
                this.calendarRange = this.range
                this.datePickerRefreshKey++
                return
            }
            if (this.clicks === 1) {
                this.clicks = this.clicks + 1
                this.range.end = e.date
                this.calendarRange = this.range
                this.datePickerRefreshKey++
                return
            }
            if (this.clicks === 2) {
                this.clicks = 1
                this.range = {start: e.date, end: e.date}
                this.calendarRange = this.range
                this.datePickerRefreshKey++
            }
        },


    },
    validations: {
        range: {
            start: {
                startOlderThanSixty(value) {
                    if (!value) return true
                    return moment(value).isBefore(moment().add(60, 'days'))
                }
            }
        }
    },
    watch: {
        // Se l'utente inserisce una coppia di date invertite le reinverte
        // semplicemente in modo da considerare sempre il range
        'range': {
            handler(range) {
                if (moment(range.start).isAfter(moment(range.end))) {
                    const start = this.range.start
                    const end = this.range.end
                    this.range.start = end
                    this.range.end = start
                }
            },
            deep: true
        },
    }

}
</script>

<style lang="scss" scoped>


.vc-pane-container {
    font-size: 0.875rem !important;

}
.vc-container {
    background-color: transparent !important;
    border: none !important;

}
</style>

<template>
    <div>
        <transition name="fade">
            <div class="fq-dottordog-loading d-flex justify-content-center align-items-center py-5" v-if="loading">
                <i class="fal fa-spin fa-circle-notch fa-4x text-primary py-5 d-block"></i>
            </div>
        </transition>
        <div class="p-3" v-if="!kind">
            <p class="lead text-center">Scegli il tipo di animale domestico</p>
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-6 col-md-3 col-lg-4 text-center">
                    <a @click.prevent="onPetSelection('dog')" href="#" class="text-decoration-none">
                        <i class="fal fa-dog fa-4x text-primary"></i><br/>
                        <p class="lead text-secondary m-0">Cane</p>
                    </a>
                </div>
                <div class="col-6 col-md-3 col-lg-4 text-center">
                    <a @click.prevent="onPetSelection('cat')" href="#" class="text-decoration-none">
                        <i class="fal fa-cat fa-4x text-primary" style="font-size: 56px;"></i><br/>
                        <p class="lead text-secondary m-0">Gatto</p>
                    </a>
                </div>
                <div class="col-6 col-md-3 col-lg-4 text-center">
                    <a @click.prevent="onPetSelection('rabbit')" href="#" class="text-decoration-none">
                        <i class="fal fa-rabbit fa-4x text-primary" style="font-size: 56px;"></i><br/>
                        <p class="lead text-secondary m-0">Coniglio</p>
                    </a>
                </div>
            </div>
        </div>
        <div v-if="versions">
            <ul class="nav nav-tabs row no-gutters d-flex justify-content-center"
                id="myTab"
                role="tablist">
                <li class="nav-item fq-dottordog-nav-item col-4 text-center">
                    <a class="nav-link"
                       id="home-tab"
                       data-toggle="tab"
                       href="#Argento"
                       role="tab"
                       @click="tarId = versionOne.id"
                       aria-controls="Argento"
                       aria-selected="false">
                        <span class="fq-dottordog-tab-name">Argento</span>
                        <span class="lead fq-dottordog-tab-value">{{versionOne.value}}</span></a>

                </li>
                <li class="nav-item fq-dottordog-nav-item col-4 text-center">
                    <a class="nav-link active"
                       id="profile-tab"
                       data-toggle="tab"
                       href="#Oro"
                       role="tab"
                       @click="tarId = versionTwo.id"
                       aria-controls="Oro"
                       aria-selected="true">
                        <span class="fq-dottordog-tab-name">Oro</span>
                        <span class="lead fq-dottordog-tab-value">{{versionTwo.value}}</span></a>
                </li>
                <li class="nav-item fq-dottordog-nav-item col-4 text-center">
                    <a class="nav-link"
                       id="messages-tab"
                       data-toggle="tab"
                       href="#Platino"
                       role="tab"
                       @click="tarId = versionThree.id"
                       aria-controls="Platino"
                       aria-selected="false">
                        <span class="fq-dottordog-tab-name">Platino</span>
                        <span class="lead fq-dottordog-tab-value">{{versionThree.value}}</span></a>
                </li>
            </ul>
        </div>
        <div v-if="versions" class="px-3">
            <div class="tab-content">
                <div class="tab-pane"
                     id="Argento"
                     role="tabpanel"
                     aria-labelledby="Argento-tab">
                    <div class="row">
                        <div class="col-12">
                            <p class="mt-3">Tutela il tuo quattro zampe con la polizza RC, per essere coperto da
                                eventuali danni che potrebbe causare, senza rinunciare a garanzie e servizi
                                aggiuntivi.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <p class="h4 font-weight-light text-secondary">
                                Prezzo per un anno
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="h2 font-weight-bold text-secondary float-right">
                                {{versionOne.value}}€
                            </p>
                        </div>
                    </div>
                </div>

                <div class="tab-pane active"
                     id="Oro"
                     role="tabpanel"
                     aria-labelledby="Oro-tab">
                    <div class="row">
                        <div class="col-12">
                            <p class="mt-3">Per una copertura completa: oltre alla responsabilità civile, sei senza
                                preoccupazioni in caso di spese veterinarie, tutela legale e spese di ricerca.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <p class="h4 font-weight-light text-secondary">
                                Prezzo per un anno
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="h2 font-weight-bold text-secondary float-right">
                                {{versionTwo.value}}€
                            </p>
                        </div>
                    </div>
                </div>

                <div class="tab-pane"
                     id="Platino"
                     role="tabpanel"
                     aria-labelledby="Platino-tab">
                    <div class="row">
                        <div class="col-12">
                            <p class="mt-3">Offri la migliore protezione al tuo animale domestico, proprio come a un
                                figlio. Tutte le garanzie sono incluse.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <p class="h4 font-weight-light text-secondary">
                                Prezzo per un anno
                            </p>
                        </div>
                        <div class="col-4">
                            <p class="h2 font-weight-bold text-secondary float-right">
                                {{versionThree.value}}€
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="p-3 d-flex justify-content-end align-items-center border-top">
            <a id="cta_dp_purchase"
                data-type="CTA"
                class="btn btn-primary rounded-pill btn-sm"
                href="#"
                :class="{ 'disabled': !tarId }"
                @click.prevent="onQuotationConfirm">Acquista</a>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {API_URL} from "../Common/constants";

    export default {
        props: ['urlprod'],
        data() {
            return {
                kind: '',
                ready: false,
                versions: false,
                loading: false,
                tarId: ''

            }
        },
        computed: {
            versionOne() {
                return this.versions[this.kind][0];
            },
            versionTwo() {
                return this.versions[this.kind][1];
            },
            versionThree() {
                return this.versions[this.kind][2];
            },
            url() {
                const _withUrlProd = `/dottorpet/preventivo?urlProd=${this.urlprod}#/tipo?tarid=${this.tarId}&petkind=${this.kind}`;
                const _notUrlProd = `/dottorpet/preventivo/#/tipo?tarid=${this.tarId}&petkind=${this.kind}`;
                return this.urlprod.length > 1 ? _withUrlProd : _notUrlProd;
            }
        },

        methods: {
            onPetSelection(_v) {
                let axiosInstance = {};

                if(this.urlProd){
                     axiosInstance = axios.create({
                        headers: {
                            'urlProd': this.urlprod
                        }
                    });
                } else {
                     axiosInstance = axios.create();
                }


                this.loading = true;
                this.kind = _v;
                axiosInstance.get(`${API_URL}/dottorpet/getQuotation`).then((response) => {
                    this.versions = response.data.versions;
                    this.tarId = this.versions[this.kind][1].id;
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    console.error(error);
                });
            },

            onQuotationConfirm() {
                window.location.href = this.url;
            }
        },
    }
</script>



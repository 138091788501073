import {defineStore} from 'pinia'
//import {INSUREE_MODEL, REGISTRY_MODEL} from "@/Common/constants";
import motoplatinumService from '../services/motoplatinum.service';
import * as moment from "moment";
import forEach from "lodash/forEach";
import { formatNumberToComma } from './../Common/utils';
import { getUserData } from '../Common/userDataFetch';
import GlobalApiService from '../Preventivo/_Common/Services/GlobalQuotation.service';


const _getDefaultAtrcYears = () => {
    const _y = new Date().getFullYear();
    const _arr = []
    for (let i = 0; i < 10; i++) {
        _arr.push({"year": (_y - i).toString(), "value": "0"})
    }
    return _arr.reverse()
};

const VEHICLE_TYPES = ['ALLMP','Motorcycle','Moped','Minicar'];
const INSURANCE_SITUATION_TYPES = ['', 'ExpiringPolicy', 'FirstInsurancePolicy', 'OtherCases' ];
const RISK_CERTIFICATE_TYPES = [ 'Yes', 'FromOtherFamilyVehicle', 'None', 'FromMyOtherVehicle']

const REGISTRY_MODEL = {
    index: 0,
    name: "",
    surname: "",
    companyName: "",
    email: "",
    phone: "",
    jobName: "",
    birthDate: null,
    streetAddress: "",
    townName: "",
    zipCode: "",
    administrativeCode: "",
    stateCode: "",
    isCustomer: true,
    isInsuree: true,
    options: [],
    taxOrVatID: ""
}

export const useMotoplatinumStore = defineStore({
    id: 'motoplatinum',
    state: () => ({
        mpWebOrder: null,
        cvtWebOrder: null,
        currentWebOrder: null,
        mpProduct: null,
        cvtProduct: null,
        registryModel: REGISTRY_MODEL,
        paymentConfig: {},
        webOrderLoading: false,
        vehicleData: {
           
        },
        ownerData: {
           
        },
        insuranceData: {
            
        },
        hasAniaResponse: '',
        hasInfobikeResponse: '',
        vehicleProgressBar: {
            step: 0,
            detailIsValid: false,
            ownerIsValid: false,
            InsuranceIsValid: false,
        },
        userData: null,
        dealerEmail: {
            emails: [],
            selectedDealerEmail: null
        },
        errorState: {
            active: false,
            message: '',
            code: ''
        },
        validatedGlobalSteps:0, //stepper utilizzato da TheProgressbarMain
    }),
    getters: {
        mp(state){
            return state.mpWebOrder
        },
        cvt(state){
            return state.cvtWebOrder
        },
        isQuotationValid(state) {
            if (!state.quotation.webOrder) return false
            // Verifico ci siano tutte e 4 le opzioni richieste e la data di inizio
            return state.quotation.webOrder.insuranceStartTime.value
        },
        optionsIndexes(state) {
            if (!state.mpProduct?.product['options'] && !state.cvtProduct?.product['options']) return ''
            let indexes = state.currentWebOrder.productType === 'MP' ? {...state.mpProduct.product.options} : {...state.cvtProduct.product.options}
            Object.keys(indexes).forEach((key, index)=> {
                indexes[key] = index;
            });
            return indexes
        },
        error(state){
            return state.errorState;
        },
        currentUrlProd(state) {
            return state.quotation.webOrder.urlProd.toLocaleLowerCase();
        },
        totalPrice(state) {
            const contractorRegistry = state.currentWebOrder.registries.length > 0 ? state.currentWebOrder.registries.find(item => item.isCustomer): state.currentWebOrder.registries[0]
            const option = state.currentWebOrder.priceItems.find( item => JSON.stringify(item.options) === JSON.stringify(contractorRegistry.options))
            return option.total
        },
        indexContractorRegistry(state) {
            return state.currentWebOrder.registries.findIndex(item => item.isCustomer)
        },
        summaryOptions(state) {
            const options = state.currentWebOrder.registries[this.indexContractorRegistry].options
            const valRc = this.currentWebOrder.registries[this.indexContractorRegistry].options[this.optionsIndexes['RC']]
       
            const objMP = [
                {
                    name: `${valRc && valRc!='0'? "Franchigia " + formatNumberToComma( valRc )+"€" : "Nessuna franchigia"}`,
                    indexCode: 'RC',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'Motoplatinum WeRepair',
                    indexCode: 'MOTOPLATINUMWEREPAIR',
                    isIncluded: true,
                    value: 1,
                    saving: true
                },
                {
                    name: 'MOTO.APP - Livello Adventure',
                    indexCode: 'MOTOAPPADVENTURE',
                    isIncluded: true,
                    value: 1,
                    saving: true
                },
                
                {
                    name: 'Infortuni del conducente',
                    indexCode: 'INFORTUNIDELCONDUCENTE',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'Assistenza',
                    indexCode: 'ASSISTENZASTRADALE',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'Rinuncia alla rivalsa',
                    indexCode: 'RINUNCIAALLARIVALSA',
                    isIncluded: true,
                    value: 1,
                    saving: ''
                },
                {
                    name: 'Tutela legale',
                    indexCode: 'TUTELALEGALE',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'Furto e incendio',
                    indexCode: 'CVT',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'MotoplatinumGOLD',
                    indexCode: 'MOTOPLATINUMGOLD',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
            ]

            const objCVT = [
                {
                    name: `Copertura Incendio e Furto - ${this.currentWebOrder.registries[this.indexContractorRegistry].options[this.optionsIndexes['MONTHS']]} mesi`,
                    indexCode: 'MONTHS',
                    isIncluded: true,
                    value: 1,
                    saving: false
                },
                {
                    name: 'MOTO.APP - Livello Adventure',
                    indexCode: 'MOTOAPPADVENTURE',
                    isIncluded: true,
                    value: 1,
                    saving: true
                },
            
            ]

            
            return this.currentWebOrder.productType === 'MP' ? objMP : objCVT
        }
    },
    actions: {
        //productType: MP | CVT
        async searchProduct(productType){
            try {
                this.webOrderLoading = true
                
                const response = await motoplatinumService.searchProduct({
                    productType,
                    locale: 'IT'
                })

                if(productType === 'MP') this.mpProduct = response.data
                else if(productType === 'CVT') this.cvtProduct = response.data

                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
            
        },

        async createMpWebOrder(params) {
            const {plate, birthDate, noFastQuote} = params
            this.webOrderLoading = true
            try {
                !this.mpProduct && await this.searchProduct('MP')

                const response = await motoplatinumService.newMp({
                    locale: 'IT'
                })
                this.mpWebOrder = response.data

                //Aggiungiamo targa e data di nascita al modelle prima di settarlo
                
                !this.mpWebOrder.registries[0] && ( this.mpWebOrder.registries = [REGISTRY_MODEL] )
                if(noFastQuote){
                    this.mpWebOrder.properties.plate = 'NON HO LA TARGA'
                }
                else {
                    this.mpWebOrder.registries[0].birthDate = moment(birthDate).format('YYYY-MM-DD')
                    this.mpWebOrder.properties.plate = plate
                }
                

                await this.setMpWebOrder()
                this.webOrderLoading = false
                return this.mpWebOrder
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        async createCvtWebOrder(params) {
            const {plate, birthDate, noFastQuote} = params

            this.webOrderLoading = true
            try {
                !this.cvtProduct && await this.searchProduct('CVT')

                const response = await motoplatinumService.newCvt({
                    locale: 'IT'
                })
                this.cvtWebOrder = response.data

                //Aggiungiamo targa e data di nascita al modelle prima di settarlo
                !this.cvtWebOrder.registries[0] && (this.cvtWebOrder.registries = [REGISTRY_MODEL])
                if(noFastQuote){
                    this.mpWebOrder.properties.plate = 'NON HO LA TARGA'
                }
                else {
                    this.cvtWebOrder.registries[0].birthDate = moment(birthDate).format('YYYY-MM-DD')
                    this.cvtWebOrder.properties.plate = plate
                }

                await this.setCvtWebOrder()
                this.webOrderLoading = false
                return this.cvtWebOrder
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        /**
         * Salva i dati del MP
         */
        async setMpWebOrder() {
            try {
                this.webOrderLoading = true
                const response = await motoplatinumService.setMp(this.mpWebOrder)
                this.mpWebOrder = response.data
                this.hasAniaResponse = response.data.properties.hasAniaResponse 
                this.hasInfobikeResponse = response.data.properties.hasInfobikeResponse
                if(this.mpWebOrder.properties.aniaResponse.taxOrVatID)
                    this.mpWebOrder.registries[0].taxOrVatID = this.mpWebOrder.properties.aniaResponse.taxOrVatID
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },
        
        /**
         * Salva i dati del CVT
         */
        async setCvtWebOrder() {
            this.webOrderLoading = true
            try {
                const response = await motoplatinumService.setCvt(this.cvtWebOrder)
                this.cvtWebOrder = response.data
                this.hasAniaResponse = response.data.properties.hasAniaResponse
                this.hasInfobikeResponse = response.data.properties.hasInfobikeResponse
                if(this.cvtWebOrder.properties.aniaResponse.taxOrVatID)
                    this.cvtWebOrder.registries[0].taxOrVatID = this.cvtWebOrder.properties.aniaResponse.taxOrVatID
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        /**
         * Ottiene un webOrder
         * @param {string} webOrderID
         */

        async getMpWebOrder(webOrderID) {
            this.webOrderLoading = true
            try {

                !this.mpProduct && await this.searchProduct('MP')

                const response = await motoplatinumService.getMp(webOrderID)
                this.mpWebOrder = response.data
                this.hasAniaResponse = response.data.properties.hasAniaResponse
                this.hasInfobikeResponse = response.data.properties.hasInfobikeResponse
                this.setVehicleData()
                this.setOwnerData()
                this.setInsuranceData()
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        async getCvtWebOrder(webOrderID) {
            this.webOrderLoading = true
            try {
                !this.cvtProduct && await this.searchProduct('CVT')
                const response = await motoplatinumService.getCvt(webOrderID)
                this.cvtWebOrder = response.data
                this.hasAniaResponse = response.data.properties.hasAniaResponse
                this.hasInfobikeResponse = response.data.properties.hasInfobikeResponse
                this.setVehicleData()
                this.setOwnerData()
                this.setInsuranceData()
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },
        /**
         * imposta il weborder
         * @param {string} type MP || CVT
         */

        async setCurrentWebOrder() {
            try {
                this.webOrderLoading = true
                const response = this.currentWebOrder.productType === 'MP' ? await motoplatinumService.setMp(this.currentWebOrder) : await motoplatinumService.setCvt(this.currentWebOrder);
                this.currentWebOrder = response.data
                if(this.currentWebOrder.productType === 'MP'){
                    this.mpWebOrder = this.currentWebOrder;
                } else {
                    this.cvtWebOrder = this.currentWebOrder;
                }
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        async saveAndSendEmail(email) {
            try {
                this.webOrderLoading = true
                const response = await motoplatinumService.saveAndSendEmail(this.currentWebOrder.webOrderID,email)
                this.currentWebOrder = response.data
                if(this.currentWebOrder.productType === 'MP'){
                    this.mpWebOrder = this.currentWebOrder;
                } else {
                    this.cvtWebOrder = this.currentWebOrder;
                }
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        async confirmCurrentWebOrder() {
            try {
                this.webOrderLoading = true
                const response = await motoplatinumService.confirm(this.currentWebOrder.webOrderID)
                this.currentWebOrder = response.data
                if(this.currentWebOrder.productType === 'MP'){
                    this.mpWebOrder = this.currentWebOrder;
                } else {
                    this.cvtWebOrder = this.currentWebOrder;
                }
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        setVehicleData() {
            const webOrder =  this.currentWebOrder || this.mpWebOrder || this.cvtWebOrder;

            this.vehicleData ={
                plate: webOrder.properties.plate,
                firstRegistrationDate: webOrder.properties.aniaResponse.firstRegistrationDate,
                purchaseDate: webOrder.properties.aniaResponse.purchaseDate,
                insuredVehicleValue: webOrder.properties.insuredVehicleValue,
                infobikeCodAll: webOrder.properties.infobikeCodAll,
                infobikePrgAll: webOrder.properties.infobikePrgAll,
                brandId: webOrder.properties.infobikeCodMar,
                modelId: webOrder.properties.infobikeCodMod,
                vehicleType: this.convertVehicleTypeToInt(webOrder.properties.vehicleType),
                infobikeCodMarDisabled: webOrder.properties.infobikeCodMarDisabled,
                infobikeCodModDisabled: webOrder.properties.infobikeCodModDisabled,
            }
            
        },
        async setWebOrdersVehicleData(){
            const webOrders = this.currentWebOrder ? ['currentWebOrder'] : ['mpWebOrder', 'cvtWebOrder']
            webOrders.map( item => {
                if(this[item]){
                    this[item].properties.plate =  this.vehicleData.plate
                    this[item].properties.aniaResponse.firstRegistrationDate =  this.vehicleData.firstRegistrationDate
                    this[item].properties.aniaResponse.purchaseDate =  moment(this.vehicleData.purchaseDate).format('YYYY-MM-DD')
                    this[item].properties.insuredVehicleValue =  this.vehicleData.insuredVehicleValue
                    this[item].properties.infobikeCodAll =  this.vehicleData.infobikeCodAll
                    this[item].properties.infobikePrgAll =  this.vehicleData.infobikePrgAll
                    this[item].properties.infobikeCodMar =  this.vehicleData.brandId
                    this[item].properties.infobikeCodMod =  this.vehicleData.modelId
                    this[item].properties.vehicleType =  this.convertVehicleTypeToString()
                }
            })

            if(this.currentWebOrder) await this.setCurrentWebOrder()
            else {
                await this.setMpWebOrder()
                await this.setCvtWebOrder()
            }
            
            await this.setVehicleData()

        },
        setOwnerData() {
            const webOrder =  this.currentWebOrder || this.mpWebOrder || this.cvtWebOrder;

             this.ownerData ={
                ownerFiscalCode: webOrder.properties.aniaResponse.taxOrVatID || webOrder.registries[0].taxOrVatID || '',
                drivingType: webOrder.properties.drivingLevel,
                residenceIstatCode: webOrder.properties.aniaResponse.residenceIstatCode,
                birthDate: moment(webOrder.registries[0].birthDate).format('YYYYMMDD'),
            } 
            
        },
        async setWebOrdersOwnerData(){
            const webOrders = this.currentWebOrder ? ['currentWebOrder'] : ['mpWebOrder', 'cvtWebOrder']
            webOrders.map( item => {
                if(this[item]){
                    this[item].registries.forEach(el => {el.isInsuree === true ?  el.taxOrVatID = this.ownerData.ownerFiscalCode : null})
                    this[item].properties.aniaResponse.taxOrVatID =  this.ownerData.ownerFiscalCode
                    this[item].properties.aniaResponse.residenceIstatCode =  this.ownerData.residenceIstatCode
                }
            })

            if(this.currentWebOrder) await this.setCurrentWebOrder()
            else {
                await this.setMpWebOrder()
                await this.setCvtWebOrder()
            }
            await this.setOwnerData()

        },
        async setInsuranceData() {
            const webOrder =  this.currentWebOrder || this.mpWebOrder || this.cvtWebOrder;
            
            this.insuranceData = {
                insuranceSituation: this.convertInsuranceSituation(webOrder.properties.insuranceSituation),
                riskCertificateAvailable: this.convertRiskCertificate(webOrder.properties.riskCertificate),
                bersaniPlate: webOrder.properties.bersaniPlate,
                bersaniTaxID: webOrder.properties.bersaniTaxID,
                yearsInsured: webOrder.properties.aniaResponse.yearsInsured,
                cu: webOrder.properties.aniaResponse.cuClass,
                effectiveDate: webOrder.properties.insuranceStartTime,
                minEffectiveDate: webOrder.properties.insuranceMinTime ? moment(webOrder.properties.insuranceMinTime).toDate() : null,
                maxEffectiveDate: webOrder.properties.insuranceMaxTime ? moment(webOrder.properties.insuranceMaxTime).toDate() : null,
                claimCountByYear: webOrder.properties.aniaResponse.claimCountByYear ? Object.entries(webOrder.properties.aniaResponse.claimCountByYear).map(([key, value]) => ({ year:key, value })) : null,
                riskCertificateDisabled: webOrder.properties.riskCertificateDisabled,
            }

        },
        async setWebOrdersInsuranceData(){
            const webOrders = this.currentWebOrder ? ['currentWebOrder'] : ['mpWebOrder', 'cvtWebOrder']
            webOrders.map( item => {
                if(this[item]){
                    this[item].properties.insuranceSituation =   this.convertInsuranceSituationToString(this.insuranceData.insuranceSituation)
                    this[item].properties.riskCertificate =  this.convertRiskCertificateToString(this.insuranceData.riskCertificateAvailable)
                    this[item].properties.aniaResponse.cuClass =  this.insuranceData.cu
                    this[item].properties.insuranceStartTime =  this.insuranceData.effectiveDate
                    this[item].properties.bersaniPlate = this.insuranceData.bersaniPlate
                    this[item].properties.bersaniTaxID = this.insuranceData.bersaniTaxID
                    this[item].properties.aniaResponse.claimCountByYear = this.insuranceData.claimCountByYear? {} : null
                    forEach(this.insuranceData.claimCountByYear, el => {
                        this[item].properties.aniaResponse.claimCountByYear[el.year]=el.value
                    }) 
                }
            })
            
            
            if(this.currentWebOrder) await this.setCurrentWebOrder()
            else {
                await this.setMpWebOrder()
                await this.setCvtWebOrder()
            }
            await this.setInsuranceData()

        },
        async getUserData(){
            try {
                
                const response = await getUserData()
                this.userData = response.data
              
                return response.data
            } catch (e) {
                
                throw new Error(e)
            }
            
        },

        convertVehicleTypeToString(){
            return VEHICLE_TYPES[this.vehicleData.vehicleType];
        },
        
        convertVehicleTypeToInt(value){
            if(value)
                return VEHICLE_TYPES.indexOf(value)
            return '';
        },
        convertRiskCertificate(value){
            if(value)
                return RISK_CERTIFICATE_TYPES.indexOf(value);
            return ''
        },
        convertRiskCertificateToString(){
            return RISK_CERTIFICATE_TYPES[parseInt(this.insuranceData.riskCertificateAvailable)];
        },
        convertInsuranceSituation(value){
            if(value)
                return (INSURANCE_SITUATION_TYPES.indexOf(value));
            return ''
        },
        convertInsuranceSituationToString(){
            return INSURANCE_SITUATION_TYPES[parseInt(this.insuranceData.insuranceSituation)];
        },

        //salvataggio errori chiamata
        setError(payload){
            this.errorState.active = true;
            this.errorState.message = payload.friendlyErrorDescription;
            this.errorState.code = payload.errorDescription;
        },
        removeError() {
            this.errorState.active = false;
            this.errorState.message = '';
            this.errorState.code = '';
        },

        clearMotoplatinumStore() {
            this.currentWebOrder = null;
            this.mpWebOrder = null;
            this.cvtWebOrder = null;
        },
        async getDealerEmails() {

            try {
                this.webOrderLoading = true
                
                const response = await GlobalApiService.getDealerEmails({
                    quotationId: this.currentWebOrder.quotationReference,
                })
                console.log('getDealerEmails', response.data);
                this.dealerEmail.emails = response.data
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },
        //NUOVO
        // Imposta l'email preferita del dealer
        async setDealerEmail( _email) {

            try {
                this.webOrderLoading = true
                
                const response = await GlobalApiService.setDealerEmail({
                    email: _email,
                    quotationId: this.currentWebOrder.quotationReference,
                    productType: "MP" // rootState.motoplatinum.config.productType : rootGetters.GLOBAL_CURRENT_PRODUCT_SHORTNAME
                })

                console.log('setDealerEmail', response.data);
                this.dealerEmail.selectedDealerEmail = _email
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        },

        /**
         * Salva i dati del weborder e in più conferma quando
         * l'utente ha completato l'anagrafica, ha gli stessi
         * effetti della setWeborder ma server side fa scattare
         * diverse logiche es.: l'invio delle email all'utente
         */
       /*  async confirmWebOrder() {
            try {
                this.webOrderLoading = true
                const response = await WebOrderService.confirm(this.quotation)
                this.quotation = response.data
                this.webOrderLoading = false
                return response.data
            } catch (e) {
                this.webOrderLoading = false
                throw new Error(e)
            }
        }, */

        
    },


   
})

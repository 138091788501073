import axios from 'axios';
import {API_URL} from '../Common/constants';


const AgenteApiService = {
    // Polizza
    userSearch: function (_data) {

         return axios.get(`${API_URL}/agent/userSearch`, {
             params: {
                 ...(_data)
             },
         })
    },

    userLoginAs: function (_data) {

        return axios.get(`/Website/Login.aspx`, {
            withCredentials: true,
            headers:{
                luid: _data.uid,
                callback: '/'
            }
        })
    },
};

export default AgenteApiService;

import GlobalApiService from '../_Common/Services/GlobalQuotation.service';
import DdApiService from './DottordogQuotation.service';
import merge from 'lodash/merge';
import findKey from 'lodash/findKey';
import cloneDeep from 'lodash/cloneDeep'
import {GaEvents} from "../../Common/AnalyticsMethods";
import SelligentPusher from "../../Common/selligentPusher";

const tokenData = ()=>{
    return {
        urlprod: null,
        exp:null,
        iat:null,
        mail:null,
        ownerName:null,
        ownerSurname:null,
        petAge:null,
        petBreed:null,
        petKind:null,
        petName:null,
        petSex:null,
        policybundle:null
    }
}

const versionLabels =() => {
    let labels = [];
    
    // Spese mediche da intervento chirurgico
    let speseIntervento = {name:"Spese veterinarie per intervento chirurgico da malattia o infortunio",description:""};
    speseIntervento.description = '<div style="line-height: normal;">';
    speseIntervento.description += '<small class="font-weight-bold"> Rimborsa le spese veterinarie per infortunio e/o malattia che richiedono intervento o ricovero, scoperto del 20%. Per esempio:</small>';
    speseIntervento.description += '<ul class="pl-4">';
    speseIntervento.description += '<li> <small class="font-weight-bold">Esami, visite, analisi, accertamenti diagnostici nei 30 giorni precedenti e successivi all’intervento chirurgico </small></li>'
    speseIntervento.description += '<li> <small class="font-weight-bold">Spese durante il day hospital o ricovero per trattamenti fisioterapici, esami, medicinali, rette di degenza, assistenza e cure </small></li>'
    speseIntervento.description += '<li> <small class="font-weight-bold">Onorari del veterinario, costi di sala operatoria e del materiale utilizzato nell’intervento, comprese le protesi </small></li>'
    speseIntervento.description += '</ul>';
    speseIntervento.description += '</div>';
    labels.push(speseIntervento);

    // Spese mediche impreviste
    let speseImpreviste = {name:"Rimborso spese veterinarie impreviste",description:""};
    speseImpreviste.description = '<div style="line-height: normal;">';
    speseImpreviste.description += '<small class="font-weight-bold">Rimborsa le spese veterinarie con esami strumentali diagnostici per infortunio o malattia senza intervento o ricovero, scoperto del 10%. Per esempio:</small>';
    speseImpreviste.description += '<ul class="pl-4">';
    speseImpreviste.description += '<li> <small class="font-weight-bold">Tac senza \ con anestesia, Ecografie, Radiografie, Endoscopia. </small></li>';
    speseImpreviste.description += '</ul>';
    speseImpreviste.description += '</div>';
    labels.push(speseImpreviste);

    // Spese mediche di prevenzione
    let spesePrevenzione = {name:"Rimborso spese di prevenzione",description:""};
    spesePrevenzione.description = '<div style="line-height: normal;">';
    spesePrevenzione.description += '<small class="font-weight-bold">Rimborsa le spese veterinarie con una franchigia di 20€. Per esempio:</small><br/>';
    spesePrevenzione.description += '<ul class="pl-4">';
    spesePrevenzione.description += '<li> <small class="font-weight-bold">Esami del sangue, sverminazione, sterilizzazione, vaccini, microchipatura, Detartrasi, Fisioterapia.</small></li>';
    spesePrevenzione.description += '</ul>';
    spesePrevenzione.description += '</div>';
    labels.push(spesePrevenzione);

    // Informazione veterinarie
    let informazioniVeterinarie = {name:"informazioni veterinarie",description:""};
    informazioniVeterinarie.description = '<div style="line-height: normal;">';
    informazioniVeterinarie.description += '<small class="font-weight-bold">Per i pacchetti Platinum e Diamond: servizio di teleconsulto veterinario 24/7 prestato da un network di veterinari specializzati.</small>';
    informazioniVeterinarie.description += '</div>';
    labels.push(informazioniVeterinarie);

    // Tutela legale
    let tutelaLegale = {name:"Tutela legale",description:""};
    labels.push(tutelaLegale);

    // Spese di ricerca
    let speseRicerca = {name:"Spese di ricerca",description:""};
    labels.push(speseRicerca);

    return labels;
}

const garanzieSilver =() => {
    return [
        `500,00€`,
        `No`,
        `No`,
        `Incluse`,
        `1.000,00€`,
        `250,00€`
    ]
}

const garanzieGold =() => {
    return [
        `1.500,00€`,
        `No`,
        `No`,
        `Incluse`,
        `5.000,00€`,
        `500,00€`
    ]
}

const garanziePlatinum =() => {
    return [
        `2.000,00€`,
        `250,00€`,
        `No`,
        `Incluse + teleconsulto`,
        `5.000,00€`,
        `1.000,00€`
    ]
}

const garanzieDiamond =() => {
    return [
        `3.000,00€`,
        `500,00€`,
        `100,00€`,
        `Incluse + teleconsulto`,
        `5.000,00€`,
        `1.000,00€`
    ]
}

const versionsModel = () => {
    return {
        cat: {
            Silver: {
                id: '',
                value: 0,
                garanzie: garanzieSilver()

            },
            Gold: {
                id: '',
                value: 0,
                garanzie: garanzieGold()
            },
            Platinum: {
                id: '',
                value: 0,
                garanzie: garanziePlatinum()
            },
            Diamond: {
                id: '',
                value: 0,
                garanzie: garanzieDiamond()
            }
        },
        dog: {
            Silver: {
                id: '',
                value: 0,
                garanzie: garanzieSilver()

            },
            Gold: {
                id: '',
                value: 0,
                garanzie: garanzieGold()
            },
            Platinum: {
                id: '',
                value: 0,
                garanzie: garanziePlatinum()
            },
            Diamond: {
                id: '',
                value: 0,
                garanzie: garanzieDiamond()
            }
        },
        rabbit: {
            Silver: {
                id: '',
                value: 0,
                garanzie: garanzieSilver()

            },
            Gold: {
                id: '',
                value: 0,
                garanzie: garanzieGold()
            },
            Platinum: {
                id: '',
                value: 0,
                garanzie: garanziePlatinum()
            },
            Diamond: {
                id: '',
                value: 0,
                garanzie: garanzieDiamond()
            }
        }
    }
}
export default {

    state: {
        //Dati dell'animale da assicurare (Step 2)
        petData: {
            // Pet selezionato dall'utente 'Dog' oppure 'Cat'
            kind: '',
            sex: '',
            breedId: '',
            petName: '',
            petBirth: ''
        },
        // garanzie aggiuntive
        addons: [],
        // prezzo totale
        totalAmount: 0,
        //prezzo totale gross
        totalGrossAmount: 0,
        // il pacchetto piu venduto
        bestSoldBundleIdId: '',
        // Preventivo restituito da getQuotation
        versions: versionsModel(),
        // Configurazioni del preventivatore
        config: {
            breeds: [],
            labelsGaranzie: versionLabels()
        },
        tokenData: tokenData()
    },
    getters: {
        DD_BEST_SOLD_BUNDLE_ID: state=> {
            return state.bestSoldBundleId;
        },
        DD_TOTAL_GROSS_AMOUNT: state=> {
          return state.totalGrossAmount;
        },
        DD_TOTAL_AMOUNT: state => {
            return state.totalAmount;
        },

        DD_PET_DATA: state => {
            try {
                const _arr = Object.values(state.petData);
                const _filteredArr = _arr.filter((value) => {
                    return value && typeof value !== 'object' && value.constructor !== Object
                });

                if (_filteredArr.some((_v) => !!_v)) {
                    return state.petData
                } else return false;
            } catch (error) {
                console.error(error)
            }
        },
        DD_ADDONS: state => {
            return state.addons;
        },
        DD_VERSIONS: state => {
            return state.versions;
        },

        DD_VERSIONS_LABELS: state => {
            return state.config.labelsGaranzie;
        },
        DD_SELECTED_BUNDLE: (state, getters, rootState) => {

            try {
                const _key = findKey(state.versions[state.petData.kind.toLowerCase()], function (o) {
                    return o.id === rootState.quotation.quotationData.selectedBundleId;
                });
                return state.versions[state.petData.kind.toLowerCase()][_key] ? state.versions[state.petData.kind.toLowerCase()][_key] : false;
            } catch (e) {
                return false;
            }

        },

        DD_SELECTEDS_ADDONS: (state, getters,rootState) => {
            try {
                return state.addons.filter(function(el){ return  rootState.quotation.quotationData.selectedAddonsIds.indexOf(el.id) > -1});     
            } catch (e) {
                return [];
            }

        },

        DD_GET_FORM_CONFIG: (state) => {

            return state.config;
        },

        DD_PET_KIND: (state) => {

            switch (state.petData.kind) {
                case 'Dog':
                    return 'cane';

                case 'Cat':
                    return 'gatto';

                case 'Rabbit':
                    return 'coniglio';

                default:
                    return 'pet';
            }
        },
        DD_BREED_NAME(state) {
            // Accrocchio necessario perchè le razze possono variare
            // in base a cane o gatto ma non vogliamo usare
            // due elementi form diversi
            try {
                const _filteredBreed = state.config.breeds[state.petData.kind.toLowerCase()].find((_obj) => {
                    return _obj.key === state.petData.breedId;
                });

                return _filteredBreed.value;

            } catch (error) {
                return false;
            }
        },
        DD_BREED_SELECTED(state) {
            try {
                const _filteredBreed = state.config.breeds[state.petData.kind.toLowerCase()].find((_obj) => {
                    return _obj.key === state.petData.breedId;
                });

                return _filteredBreed;

            } catch (error) {
                return false;
            }
        },
        DD_TOKEN_DATA: (state) => {
            return state.tokenData;
        }
    },

    actions: {
        // Inizia la quotazione, la chiamata prende automaticamente codice sconto e codiceAffiliazione dallo store e li manda solo se necessari
        DD_GET_QUOTATION({state, commit, rootState}) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                const _requestParams = {
                    ...(rootState.quotation.quotationData.quotationId ? {quotationId: rootState.quotation.quotationData.quotationId} : {}),
                    ...(rootState.quotation.quotationData.discountCode ? {discountCode: rootState.quotation.quotationData.discountCode} : {}),
                    ...(rootState.quotation.quotationData.affiliationCode ? {affiliationCode: rootState.quotation.quotationData.affiliationCode} : {}),
                    ...(rootState.quotation.quotationData.externalReferenceCode ? {externalReferenceCode: rootState.quotation.quotationData.externalReferenceCode} : {}),
                    ...(rootState.quotation.quotationData.selectedBundleId ? {tarId: rootState.quotation.quotationData.selectedBundleId} : {}),
                    ...(rootState.quotation.quotationData.selectedAddonsIds ? {optionalGuarantees: rootState.quotation.quotationData.selectedAddonsIds} : {}),
                    ...(state.petData.kind ? {petkind: state.petData.kind.toUpperCase()} : {}),
                };
                DdApiService.getQuotation(_requestParams).then(res => {

                    // Correggo il formato dell'oggetto rispetto a come arriva dall'API
                    const _arr = cloneDeep(res.data.versions);
                    const _newArr = {};

                    Object.keys(_arr).forEach((_obj) => {
                        _newArr[_obj] = {};
                        if( _arr[_obj]){
                            _arr[_obj].forEach((e) => {

                                _newArr[_obj][e.title] = e;
                            })
                        }
                       
                    });

                    commit('UPDATE_QUOTATION_DATA', {
                        discountCode: res.data.discountCode,
                        quotationId: res.data.quotationId,
                        quotationCode: res.data.quotationCode,
                        discountCodeDescription: res.data.discountCodeDescription,
                        affiliationCode: res.data.affiliationCode,
                        isAffiliated: res.data.isAffiliated,
                        selectedBundleId: res.data.selectedTarId,
                        quotationDateTime: res.data.quotationDateTime,
                        quotationExpire: res.data.quotationExpire,
                        quotationConfirmed: false,
                        discountCodeLocked: res.data.discountCodeLocked,
                        externalReferenceCode: res.data.externalReferenceCode
                    });

                    commit('DD_UPDATE_BEST_SOLD_BUNDLE_ID',  res.data.bestSoldTarId);
                    commit('DD_UPDATE_TOTAL_GROSS_AMOUNT',  res.data.totalGrossAmount);
                    commit('DD_UPDATE_TOTAL_AMOUNT',  res.data.totalAmount);
                    commit('DD_UPDATE_ADDONS',  res.data.optionalGuarantees);
                    commit('DD_UPDATE_VERSIONS', _newArr);
                    commit('DD_UPDATE_BREEDS', res.data.breeds);
                    commit('STOP_LOADING');

                    resolve(res);
                }).catch(e => commit('STOP_LOADING'));
            });
        },

        // Salva la quotazione per procedere all'anagrafica
        DD_CONFIRM_QUOTATION({commit}, _data) {

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                DdApiService.confirmQuotation(_data).then(res => {
                    GaEvents.quotation('DD');
                    commit('STOP_LOADING');
                    commit('UPDATE_QUOTATION_DATA', {quotationConfirmed: true});
                    resolve();
                }).catch(e => commit('STOP_LOADING'));
            });
        },

        async DD_RESTORE_QUOTATION({state, commit, rootState}, _quotationId) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                DdApiService.getQuotationData(_quotationId).then(res => {

                    // Dalla risposta devo riposizionare i dati ai lOro posti
                    commit('UPDATE_QUOTATION_DATA', {
                        discountCode: res.data.quotationData.discountCode,
                        quotationId: res.data.quotationData.quotationId,
                        quotationCode: res.data.quotationData.quotationCode,
                        discountCodeDescription: res.data.quotationData.discountCodeDescription,
                        affiliationCode: res.data.quotationData.affiliationCode,
                        isAffiliated: res.data.quotationData.isAffiliated,
                        selectedBundleId: res.data.quotationData.tarId,
                        quotationDateTime: res.data.quotationData.quotationDateTime,
                        quotationExpire: res.data.quotationData.quotationExpire,
                        selectedAddonsIds: res.data.optionalGuaranteesSelected
                    });


                    if (res.data.quotationData.discountCode) {
                        commit('UPDATE_QUOTATION_DATA', {discountCodeIsValid: true})
                    }


                    if (res.data.petData) {
                        if (res.data.petData.kind) commit('DD_UPDATE_SELECTED_PET_KIND', res.data.petData.kind.toLowerCase());
                        if (res.data.petData.breedId !== null) commit('DD_UPDATE_PET_DATA', res.data.petData);
                    }

                    //commit('DD_UPDATE_SELECTED_PET_KIND', res.data.quotationData.tarId.toLowerCase().includes('cane') ? 'dog' : 'cat');

                    if (res.data.registry) commit('UPDATE_ANAGRAFICA', res.data.registry);
                    if (res.data.registry && res.data.registry.owner) {
                        commit('UPDATE_CUSTOMER_KIND',
                            {
                                kind: 'isOwner',
                                value: false
                            });
                    }
                    commit('UPDATE_ANAGRAFICA',  res.data.registry);

                    // Reimposto i pacchetti del preventivo
                    // modificando il formato dell'oggetto rispetto a come arriva dall'API
                    const _arr = cloneDeep(res.data.versions);
                    const _newArr = {};
                    Object.keys(_arr).forEach((_obj) => {
                        _newArr[_obj] = {};
                        if( _arr[_obj]){
                            _arr[_obj].forEach((e) => {

                                _newArr[_obj][e.title] = e;
                            })
                        }
                    });

                    commit('DD_UPDATE_TOTAL_GROSS_AMOUNT',  res.data.quotationData.grossAmount);
                    commit('DD_UPDATE_TOTAL_AMOUNT',  res.data.quotationData.amount);
                    commit('DD_UPDATE_ADDONS',  res.data.optionalGuarantees);
                    commit('DD_UPDATE_VERSIONS', _newArr);
                    commit('UPDATE_QUOTATION_DATA', {quotationConfirmed: true});

                    commit('STOP_LOADING');
                    resolve();

                }).catch((e) => {
                    console.error(e);
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        // Invia l'anagrafica pr procedere all'acquisto
        DD_CONFIRM_REGISTRATION({state, commit, rootState}) {
            const _requestParams = {
                // Se l'utente non è anche il proprietario invio anche l'oggetto owner
                quotationId: rootState.quotation.quotationData.quotationId,
                contractor: rootState.anagrafica.customerDetails.contractor,
                ...(!rootState.anagrafica.customerDetails.isOwner ? {owner: rootState.anagrafica.customerDetails.owner} : {}),
                contractorIsOwner: rootState.anagrafica.customerDetails.isOwner
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                DdApiService.confirmRegistration(_requestParams).then(res => {
                    GaEvents.registration('DD');
                    if (res.data.confirmEmail) {
                        commit('SET_EMAIL_CONFIRM', res.data.confirmEmail);
                    }
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((err) => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        // Salva la quotazione per inviare email al cliente
        DD_SAVE_QUOTATION({commit}, _data) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                GlobalApiService.saveQuotation(_data).then(res => {
                    GaEvents.block('DD');
                    try {
                        let pusher = new SelligentPusher()
                        pusher.add('action', 'saveQuotation_dottordog')
                        pusher.push()
                    } catch (e) {

                    }
                    commit('STOP_LOADING');
                    resolve();
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        }
    },

    mutations: {
        DD_UPDATE_BEST_SOLD_BUNDLE_ID(state, payload) {
            state.bestSoldBundleId = payload;
        },
        DD_UPDATE_TOTAL_AMOUNT(state, payload) {
            state.totalAmount = payload;
        },
        DD_UPDATE_TOTAL_GROSS_AMOUNT(state, payload) {
            state.totalGrossAmount = payload;
        },
        DD_UPDATE_ADDONS(state, payload) {
            state.addons = payload;
        },
        DD_UPDATE_VERSIONS(state, payload) {
            // Per poter avere gia lato front end alcune parti delle
            // versioni DD uso merge di lodash per aggiungere e modificare
            // le proprietà nuove senza modificare quelle gia presenti,
            // necessarie per avere label e description in pagina

            state.versions = merge(state.versions, payload);
        },
        DD_UPDATE_PET_DATA(state, payload) {
            state.petData = payload;
        },

        DD_UPDATE_SELECTED_PET_KIND(state, payload) {
            state.petData.kind = payload;
        },
        DD_UPDATE_BREEDS(state, payload) {
            state.config.breeds = payload;
        },

        RESET_DOTTORDOG_STATE(state) {
            const _state = {
                //Dati dell'animale da assicurare (Step 2)
                petData: {
                    // Pet selezionato dall'utente 'Dog' oppure 'Cat'
                    kind: '',
                    sex: '',
                    breedId: '',
                    petName: '',
                    petBirth: ''
                },
                addons:[],
                // prezzo totale
                totalAmount: 0,
                //prezzo totale gross
                totalGrossAmount: 0,
                // Preventivo restituito da getQuotation
                versions: versionsModel(),
                // Configurazioni del preventivatore
                config: {
                    breeds: [],
                    labelsGaranzie: versionLabels()
                },
                tokenData: tokenData()
            };
            Object.assign(state, _state);
        },
        DD_UPDATE_TOKEN_DATA(state, payload) {
            state.tokenData = payload;
        },

    }
};

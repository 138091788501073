<template>
    <div class="fast-quote" :class="classes">
    <!-- justify-content-center  -->
        <div class="form-row no-gutters mx-0 align-items-center"
             v-view="handleFastQuoteVisibility">

            <div class="form-group rounded-pill" :class="{ 'col-md-6': size === 'large', 'col-md-4': size !== 'large', 'mb-0': $v.date.$error }">
                <label class="text-dark-gray" for="FastQuoteBirthDate"><small style="font-size:12px">{{this.size !== 'small' ? 'Data di nascita del proprietario*' : 'Data di nascita'}}</small></label>
                <input type="text"
                        class="text-dark-secondary"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        @blur="$v.date.$touch()"
                        :class="{
                            'is-invalid':$v.date.$invalid && $v.date.$error,
                            'is-valid' : !$v.date.$invalid && $v.date.$dirty
                        }"
                        @keyup.enter="onQuotationClick"
                        id="FastQuoteBirthDate"
                        ref="FastQuoteBirthDate"
                        placeholder="GG/MM/AAAA">
                <div class="invalid-feedback" v-if="$v.date.$error">
                    <span v-if="!$v.date.required">
                        Campo data richiesto
                    </span>

                    <span v-if="$v.date.required && !$v.date.minLength || !$v.date.maxLength">
                        Campo data non completo
                    </span>

                    <span v-if="$v.date.required && $v.date.minLength && $v.date.maxLength && !$v.date.birthDate">
                        Inserisci una data di nascita valida
                    </span>
                </div>
            </div>
            <!-- 'col-xl-4': size === 'large' -->
            <div class="form-group rounded-pill" :class="{ 'col-md-6': size === 'large', 'col-md-3': size !== 'large', 'mb-0': $v.plate.$error }">
                <label class="text-dark-gray" for="FastQuotePlate"><small style="font-size:12px">Targa del veicolo{{this.size !== 'small' ? '*' : ''}}</small></label>
                <input type="text"
                        class="text-dark-secondary"
                        v-uppercase
                        v-remove-spaces
                        maxlength="8"
                        :class="{
                        'is-invalid' :$v.plate.$invalid && $v.plate.$error,
                        'is-valid' : !$v.plate.$invalid && $v.plate.$dirty }"
                        @blur="$v.plate.$touch()"
                        @paste="onPaste"
                        id="FastQuotePlate"
                        ref="FastQuotePlate"
                        placeholder="AB12345"
                        @keyup.enter="onQuotationClick"
                        v-model="plate"/>
                <div class="invalid-feedback" v-if="$v.plate.$error">
                    <span v-if="!$v.plate.required">
                        Targa richiesta
                    </span>

                    <span v-if="$v.plate.required && !$v.plate.plateFormat">
                        Il formato della targa non è corretto
                    </span>


                </div>

            </div>

            <div class="form-group col text-center">

                <button 
                        id="cta_mp_fastquote"
                        data-type="CTA"
                        type="button"
                        @click.prevent="onQuotationClick"
                        class="btn btn-primary rounded-pill"
                        :class="{
                            'disabled': $v.plate.$invalid || $v.date.$invalid
                            }"
                        ref="FastQuoteButton"
                        :disabled="$v.plate.$invalid || $v.date.$invalid">
                        {{this.size !== 'small' ? 'Calcola un preventivo' : 'Calcola'}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Inputmask from "inputmask";
    import * as moment from "moment";
    import Vue from 'vue'
    import Vuelidate from 'vuelidate'
    import {maxLength, minLength, required} from 'vuelidate/lib/validators';
    import Fastquote from '../Mixins/Fastquote.mixin';
    import SelligentPusher from "../../../Common/selligentPusher";
    import {mapState} from "vuex";

    Vue.use(Vuelidate);

    const forcedAlphanumeric = (t) => {
        return /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(t);
    };

    const plateFormat = (p) => {
        return /[XY][BCDFGHJKLMNPRSTVWXYZ23456789]{5}$/.test(p.toUpperCase())
            || /^[a-zA-Z]{2}[0-9]{5,6}$/.test(p.toUpperCase())
    };

    const birthDate = (_d) => {
        return moment(_d, 'DD-MM-YYYY').isBefore(moment().subtract(1, 'days').endOf('day'));
    };

    export default {
        mixins: [Fastquote],
        data() {
            return {
                date: '',
                plate: ''
            }
        },
        mounted: function () {
            const _this = this;
            this.$nextTick(function () {
                if(_this.$refs.FastQuoteBirthDate){
                    Inputmask("99/99/9999",
                        {
                            placeholder: "GG/MM/AAAA",
                            insertMode: true,
                            oncomplete: function (_date) {
                                _this.date = _this.$refs.FastQuoteBirthDate.value;
                            }
                        }).mask(this.$refs.FastQuoteBirthDate);
                }
            });
        },
        computed: {
            formattedDate() {
                const _arr = this.date.split("/");
                return _arr[2] + _arr[1] + _arr[0];
            },
            url() {
                let _query = `?plate=${this.plate.toUpperCase()}&date=${this.formattedDate}`;
                return `/motoplatinum/v2${this.urlprod ? '?urlProd=' + this.urlprod + '&visual=interno' : ''}#/start${_query}`;
            },
        },

        methods: {
            onQuotationClick: function (e) {
                window.gtmCustomEvents.fastQuoteClick(e)
                if (!this.$v.date.$invalid && !this.$v.plate.$invalid) {
                    try {
                        let pusher = new SelligentPusher()
                        pusher.add('action', 'fastquote_motoplatinum')
                        pusher.push()
                    } catch (e) {

                    }
                    if (this.$route && this.$route.name === 'fastquote') this.$emit('fastQuoteSubmitted', {
                        date: this.formattedDate,
                        plate: this.plate.toUpperCase()
                    });

                    else window.location.href = this.url;
                }
            },

            onPaste: function (e) {
                e.preventDefault();
                if (e.isTrusted) {
                    this.plate = e.clipboardData.getData('Text').replace(/\s/g, '').toUpperCase();
                    this.$v.plate.$reset();
                }

            }
        },

        watch: {
            'date': function (date) {
                if (date.length === 10) {
                    this.$refs.FastQuotePlate.focus();
                }
            }
        },

        validations: {
            date: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
                birthDate: birthDate
            },
            plate: {
                required,
                forcedAlphanumeric,
                plateFormat,
                minLength: minLength(4),
                maxLength: maxLength(9),

            }
        },

    }

</script>

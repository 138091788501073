import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../../Store';
import SelligentPusher from "../../Common/selligentPusher";


const App = () =>  import('./Dottordog')
const PetKind = () =>  import('./01_Quotation/PetKind')
const PetQuotation = () =>  import('./01_Quotation/PetQuotation')
const petData = () =>  import('./01_Quotation/petData')
const CustomerDetail = () =>  import('./02_CustomerDetail/CustomerDetail')
const CustomerDetailContraente = () =>  import('./02_CustomerDetail/CustomerDetailContraente')
const CustomerDetailProprietario = () =>  import('./02_CustomerDetail/CustomerDetailProprietario')
const Acquisto = () =>  import('./03_Checkout/Acquisto')
const ThankYouPage = () =>  import('./03_Checkout/ThankYouPage')
const EmailConfirmation = () =>  import('./02_CustomerDetail/EmailConfirmation')
const OtpPage = () => import('./03_Checkout/OtpPage')

Vue.use(VueRouter);

/** Scrolls the element into view
 * Manually created since Safari does not support the native one inside an iframe
 */
const scrollElementIntoView = (element, behavior) => {

    let scrollTop = window.scrollY || element.scrollTop

    const finalOffset = element.getBoundingClientRect().top + scrollTop

    window.parent.scrollTo({
        top: finalOffset,
        behavior: behavior || 'auto'
    })
}

const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: App,
            meta: {
                title: 'Preventivo',
                backButtonEnabled: true
            },
            children: [
                {
                    path: 'inizio',
                    name: 'inizio',
                    component: PetKind,
                    meta: {
                        title: `Calcola il prezzo dell'assicurazione`,
                        backButtonEnabled: false,
                        quotationIdRequired: false,
                    },
                },
                // Ridondante ma mantenuto per retro compatibilità con gli iframe
                {
                    path: 'tipo',
                    name: 'tipo',
                    component: PetKind,
                    meta: {
                        title: `Calcola il prezzo dell'assicurazione`,
                        backButtonEnabled: false,
                        quotationIdRequired: false,
                    },
                }, {
                    path: 'quotazione',
                    name: 'quotazione',
                    component: PetQuotation,
                    meta: {
                        title: `Scegli il pacchetto più adatto al tuo pet`,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                        backButtonUrl: '/inizio'
                    }
                },
                {
                    path: 'pet',
                    name: 'pet',
                    component: petData,
                    meta: {
                        title: `Inserisci i dati del tuo pet`,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                        backButtonUrl: '/quotazione'
                    }
                },
                {
                    path: 'anagrafica',
                    component: CustomerDetail,
                    meta: {
                        backButtonEnabled: true,
                        quotationIdRequired: true
                    },
                    children: [{
                        path: 'contraente',
                        name: 'anagraficaContraente',
                        component: CustomerDetailContraente,
                        meta: {
                            title: 'Inserisci i dati del contraente',
                            backButtonEnabled: true,
                            quotationIdRequired: true,
                            backButtonUrl: '/pet'
                        }
                    },
                        {
                            path: 'proprietario',
                            name: 'anagraficaProprietario',
                            component: CustomerDetailProprietario,
                            meta: {
                                title: 'Inserisci i dati del proprietario',
                                backButtonEnabled: true,
                                quotationIdRequired: true,
                                backButtonUrl: '/anagrafica/contraente'
                            }
                        },
                        {
                            path: 'conferma',
                            name: 'conferma',
                            component: EmailConfirmation,
                            meta: {
                                title: 'Conferma la tua email',
                                backButtonEnabled: false,
                                quotationIdRequired: false
                            }
                        }]
                },
                {
                    path: 'acquista',
                    name: 'acquista',
                    component: Acquisto,
                    meta: {
                        title: 'Dati di pagamento',
                        backButtonEnabled: false,
                        quotationIdRequired: true
                    }
                },
                {
                    path: 'thank-you',
                    name: 'thank-you',
                    component: ThankYouPage,
                    meta: {
                        title: ``,
                        backButtonEnabled: false,
                        quotationIdRequired: false
                    }
                },
                {
                    path: 'otp',
                    name: 'otp',
                    component: OtpPage,
                    meta: {
                        title: ``,
                        backButtonEnabled: false,
                        quotationIdRequired: false
                    }
                }

            ]
        },
        {
            path: '*',
            component: App,
        }
    ],

});

// Imposto il titolo della pagina e l'impostazione del pulsante indietro
router.beforeEach((to, from, next) => {
    store.dispatch('SET_VIEW_TITLE', to.meta.title);
    store.dispatch('SET_BACK_BUTTON_STATE', to.meta.backButtonEnabled);
    store.dispatch('SET_CURRENT_PRODUCT', 'dottordog');
    store.dispatch('SET_AR_STATE', to.path.includes('/auth/'));
    next();
});


// Controllo se l'utente puo entrare o meno in determinate pagine e da li faccio i redirect necessari
router.beforeEach((to, from, next) => {
    const _vuexStore = JSON.parse(window.sessionStorage.getItem('vuex'));
    if (to.meta.quotationIdRequired &&
        _vuexStore && !_vuexStore.quotation.quotationData.quotationId && !to.query.q) {
        next('/inizio');
    } else {
        next();
    }
});

// Segnalo a selligent ogni cambio di pagina
router.afterEach((to, from)=>{
    try{
        let pusher = new SelligentPusher()
        pusher.add('page', window.location.pathname + '/#' + to.fullPath)
        pusher.push()
    } catch (e) {
    }
})

router.afterEach((to, from) => {
    if(to.path === from.path) return
    // element which needs to be scrolled to
    const element = document.querySelector("#scrollReference");
// scroll to element
    scrollElementIntoView(element)
})

export {router}

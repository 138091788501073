<template>

    <div class="fixed-top"
        data-section="sticky_fastquote"
        id="dd-sticky-fast-quote"
        style="min-height: 1px;"
        v-view="handleFastQuoteVisibility">

        <div class="sticky-fast-quote bg-gradient-dark-blue-sticky position-fixed" v-if="stickyIsVisible">
            <div class="container py-3">
                <div class="row d-flex align-items-center">
                    <div class="col-md-7 col-lg-8 d-none d-md-block">
                        <div class="d-flex justify-content-start">
                            <img class="img-fluid"
                                    src="/images/24hassistance_logo_white.png"
                                    srcset="/images/24hassistance_logo_white@2x.png 2x,
                                            /images/24hassistance_logo_white@3x.png 3x,
                                        /images/24hassistance_logo_white@4x.png 4x"
                                    style="max-height:40px"/>
                                    <p style="padding-top:13px"
                                       class="lead text-uppercase text-white m-0 ml-2">Assicurazione animali domestici
                                    </p>
                        </div>
                    </div>
                    <div class="col-12 col-md-5 col-lg-4 p-sm-0 px-md-3">
                        <p class="font-weight-bold text-white mb-2" style="border-bottom: 1px solid rgb(255, 255, 255);">
                            Chi vuoi assicurare?
                        </p>
                        <div class="d-flex justify-content-start">
                            <a class="btn btn-sm bg-white text-seconday px-4 mr-1" 
                                style="border-radius: 2rem !important;"
                                id="cta_dp_sticky_fastquote_1"
                                href="javascript:void(0)"
                                data-type="CTA"
                                onclick="window.fastquoteEventHandler(event, '/dottorpet/preventivo/#/inizio?petkind=Dog')">
                                    Cane
                            </a>
                            <a class="btn btn-sm bg-white text-seconday px-4 mr-1" 
                                style="border-radius: 2rem !important;"
                                id="cta_dp_sticky_fastquote_2"
                                href="javascript:void(0)"
                                data-type="CTA"
                                onclick="window.fastquoteEventHandler(event, '/dottorpet/preventivo/#/inizio?petkind=Cat')">
                                    Gatto
                            </a>
                            <a class="btn btn-sm bg-white text-seconday px-4" 
                                style="border-radius: 2rem !important;"
                                id="cta_dp_sticky_fastquote_3"
                                href="javascript:void(0)"
                                data-type="CTA"
                                onclick="window.fastquoteEventHandler(event, '/dottorpet/preventivo/#/inizio?petkind=Rabbit')">
                                    Coniglio
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>




</template>

<script>
    import FastquoteMotoplatinum from "./FastquoteMotoplatinum";
    import {bus} from "../EventBus";

    export default {
        name: "StickyFastquoteDottordog",

        components: {
            'fastquote-motoplatinum': FastquoteMotoplatinum
        },
        data: function () {
            return {
                stickyIsVisible: false
            }
        },

        created() {
            const _this = this;
            // Attende l'evento inviato dal fastquote quando è in
            // modalità sticky ed entra ed esce dal viewport
            bus.$on('onShowStickyFastquote', (_v) => {
                _this.stickyIsVisible = _v;
            });

        },
         methods: {

            handleFastQuoteVisibility: function (e) {
                try {
                    if (e.scrollPercent > 0.05) {
                        bus.$emit('onShowStickyFastquote', true);
                    } else if (e.scrollPercent < 0.05) {
                        bus.$emit('onShowStickyFastquote', false);
                    }
                } catch (e) {
                }


            },
        },
        onBeforeDestroy(){
            bus.$off('onShowStickyFastquote')
        }
    }
</script>



import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

// Operazioni e dichiarazioni comuni a tutti i preventivatori
// va richiamato nel modulo {NOME_PRODOTTO}App.vue
const BootstrapOperations = {

    data() {
        return {
            showSummary: false,
        }
    },

    beforeRouteEnter(to, from, next) {

        if (to.path === '/') {
            next(vm => {
                if(vm.$store.state.ux.currentProduct === 'bikeplatinum') next('/quotation');
                if(vm.$store.state.ux.currentProduct === 'motoplatinum') next('/quotazione/fastquote');
                if(vm.$store.state.ux.currentProduct === 'dottordog') next('/inizio');
                if(vm.$store.state.ux.currentProduct === 'multimobility') next('/inizio');

            });
        } else next();
    },

    created() {

        function _getQueryVariable(variable) {
            const query = window.location.search.substring(1);
            const vars = query.split("&");
            for (let i = 0; i < vars.length; i++) {
                const pair = vars[i].split("=");
                if (pair[0].toLowerCase() === variable) {
                    return pair[1];
                }
            }
            return (false);
        }

        // Se l'urlProd è presente nell'url lo
        // salvo in store, verrà usato poi come header
        if (_getQueryVariable('urlprod')) this.SET_URLPROD(_getQueryVariable('urlprod'));

        // Faccio in modo che all oscadere della sessione
        // dell'utente i dati del preventivo si salvino in un cookie
        //window.addEventListener('beforeunload', ()=>{
        //    this.storeLastQuotationCookie();
        // });

        this.RESET_LOADING();

        // listener per aggiornare creare un getter dei breakpoint responsive
        this.$store.commit('SET_SCREEN_WIDTH', window.innerWidth);
        window.addEventListener(
            'resize', () => {
                this.$store.commit('SET_SCREEN_WIDTH', window.innerWidth);
            })

    },

    mounted() {

        // Modifica il logo bianco nell'header mobile in base al prodotto
        if (window.innerWidth < 768) {
            try {
                const _ddLogoData = {
                    src: '/images/Logo-dottorpet-bianco.png',
                    srcset: '/images/Logo-dottorpet-bianco@2x.png 2x, /images/Logo-dottorpet-bianco@3x.png 3x, /images/Logo-dottorpet-bianco@4x.png 4x',
                    href: '/dottorpet/preventivo'
                };

                const _mpLogoData = {
                    src: '/images/motoplatinum_logo_white.png',
                    srcset: '/images/motoplatinum_logo_white@2x.png 2x, /images/motoplatinum_logo_white@3x.png 3x, /images/motoplatinum_logo_white@4x.png 4x',
                    href: '/motoplatinum/v2#/quotation/fastquote'
                };

                const _bpLogoData = {
                    src: '/images/bikeplatinum_logo_white.png',
                    srcset: '/images/bikeplatinum_logo_white@2x.png 2x, /images/bikeplatinum_logo_white@3x.png 3x, /images/bikeplatinum_logo_white@4x.png 4x',
                    href: '/bikeplatinum/preventivo/#/quotazione'
                };
                const _myLogoData = {
                    src: '/images/multimobility_logo_w.png',
                    srcset: '/images/multimobility_logo_w@2x.png 2x, /images/multimobility_logo_w@3x.png 3x, /images/multimobility_logo_w@4x.png 4x',
                    href: '/multimobility/preventivo#/quotazione?insurancetype=individual'
                };

                const _getProductData = () => {
                    switch (this.ux.currentProduct) {
                        case 'dottordog':
                            return _ddLogoData;
                        case 'motoplatinum':
                            return _mpLogoData;
                        case 'bikeplatinum':
                            return _bpLogoData;
                        case 'multimobility':
                            return _myLogoData;
                    }
                };

                document.getElementById('navbarBrandImg').src = _getProductData().src;
                document.getElementById('navbarBrandImg').srcset = _getProductData().srcset;
                document.getElementById('navbarBrand').href = _getProductData().href;
            } catch (e) {

            }
        }


    },

    computed: {
        ...mapState(['ux'])
    },

    methods: {
        ...mapMutations([
            'RESET_LOADING',
            'SET_URLPROD']),

        onclickBack() {
            // Se la route ha il meta custom backButtonUrl allora il tasto indietro porterà li, altrimenti come fallback va indietro di uno step nella storia
            const _goBack = this.$route.meta.backButtonUrl ? this.$router.push(this.$route.meta.backButtonUrl) : this.$router.go(-1);

        },
        onSummaryToggle() {
            this.showSummary = !this.showSummary;
        },
    },
};

// Operazioni e dichiarazioni comuni a tutti i preventivatori
// va richiamato nel modulo corrispondente alla route "start" di ogni preventivatore

const StartOperations = {

    methods: {
        ...mapActions(['GLOBAL_RESTORE_ANAGRAFICA']),
    },
    computed: {
        ...mapGetters(['GLOBAL_LOGGED_USER']),
    },

    mounted() {
        this.$store.dispatch('GLOBAL_QUOTATION_RESET');
        if (this.GLOBAL_LOGGED_USER && this.GLOBAL_LOGGED_USER.userKind === 'C' && !this.$route.path.includes('auth')) this.GLOBAL_RESTORE_ANAGRAFICA();
    }
};

export {BootstrapOperations, StartOperations};


import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueOffline from 'vue-offline';
import BikeplatinumApp from './Bikeplatinum/Bikeplatinum';
import DottordogApp from './Dottordog/Dottordog';
import Motoplatinum from './Motoplatinum/Motoplatinum';
import MotoplatinumApp from './Motoplatinum/MotoplatinumApp';
import Multimobility from "./Multimobility/Multimobility";
import Snowcare from "@/Components/Snowcare";
import {router as BikeplatinumRouter} from './Bikeplatinum/Bikeplatinum.router';
import {router as DottordogRouter} from './Dottordog/Dottordog.router';
//@NOTE: vecchio router
import {router as MotoplatinumRouter} from './Motoplatinum/Motoplatinum.router';
//@NOTE: nuovo router
import {router as MotoplatinumRouterApp} from '../routers/Motoplatinum.router';
import {router as MultimobilityRouter} from './Multimobility/Multimobility.router';
import {router as SnowcareRouter} from '../routers/Snowcare.router';
import store from '../Store';
import ButtonLoadingSpinner from './_Common/Components/ButtonLoadingSpinner';
import 'iframe-resizer';
import AxiosConfiguration from './_Common/Services/AxiosConfiguration.service';
import {VBPopoverPlugin} from 'bootstrap-vue';
import {ModalPlugin} from 'bootstrap-vue';
import VuePromiseBtn from 'vue-promise-btn';
import {createPinia, PiniaVuePlugin} from 'pinia'
import * as numeral from 'numeral';
import VueI18n from 'vue-i18n'
import {messages} from "@/lang/translations";
import VueMoment from 'vue-moment'
import VueNoty from "vuejs-noty";
import {NOTY_CONFIG} from "@/Common/constants";
import LazyLoadDirective from "@/directives/lazyLoadDirective";

numeral.register('locale', 'it', {
    delimiters: {
        thousands: '.',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function (number) {
        return number === 1 ? 'er' : 'ème';
    },
    currency: {
        symbol: '€'
    }
});
numeral.locale('it');

if (document.getElementById("bpQuotation") ||
    document.getElementById("mpQuotation") ||
    document.getElementById("mpAppQuotation") ||
    document.getElementById("ddQuotation") ||
    document.getElementById("scQuotation") ||
    document.getElementById("myQuotation")) {

    AxiosConfiguration();

    Vue.use(VBPopoverPlugin);
    Vue.use(ModalPlugin)
    Vue.use(Vuelidate);
    Vue.use(VueNoty, NOTY_CONFIG)
    Vue.use(VueOffline, {
        // Fa si che il mixin non sia
        // iniettato automaticamente in tutti i componenti
        mixin: false
    });
    Vue.use(VueMoment)
    Vue.use(VuePromiseBtn)
    Vue.use(VueI18n)
    // Create VueI18n instance with options
    const i18n = new VueI18n({
        locale: 'it', // set locale
        messages, // set locale messages
    })
    Vue.component('button-loading-spinner', ButtonLoadingSpinner);

    if (document.getElementById("bpQuotation")) {
        let app = new Vue({
            el: '#bpQuotation',
            router: BikeplatinumRouter,
            store,
            template: '<transition name="fade"  mode="out-in" appear><router-view></router-view></transition>',
            components: {BikeplatinumApp}
        });
    }

    if (document.getElementById("ddQuotation")) {
        let app = new Vue({
            el: '#ddQuotation',
            router: DottordogRouter,
            store,
            template: '<transition name="fade"  mode="out-in" appear><router-view></router-view></transition>',
            components: {DottordogApp}
        });
    }
    //@NOTE: Vecchio preventivatore - decomentare per visualizzare il vecchio preventivatore 
    
    if (document.getElementById("mpQuotation")) {
        let app = new Vue({
            el: '#mpQuotation',
            router: MotoplatinumRouter,
            store,
            template: '<transition name="fade"  mode="out-in" appear><router-view></router-view></transition>',
            components: {Motoplatinum}
        });
    } 

    //@NOTE: Nuovo preventivatore
    if (document.getElementById("mpAppQuotation")) {
        const pinia = createPinia()
        Vue.use(PiniaVuePlugin)
        let app = new Vue({
            el: '#mpAppQuotation',
            router: MotoplatinumRouterApp,
            pinia,
            template: '<transition name="fade"  mode="out-in" appear><router-view></router-view></transition>',
            components: {MotoplatinumApp}
        });
    }

    if (document.getElementById("myQuotation")) {
        let app = new Vue({
            el: '#myQuotation',
            router: MultimobilityRouter,
            store,
            template: '<transition name="fade"  mode="out-in" appear><router-view></router-view></transition>',
            components: {Multimobility}
        });
    }

    if (document.getElementById("scQuotation")) {
        const pinia = createPinia()
        Vue.use(PiniaVuePlugin)
        let app = new Vue({
            el: '#scQuotation',
            router: SnowcareRouter,
            pinia,
            i18n,
            template: '<transition name="fade"  mode="out-in" appear><router-view></router-view></transition>',
            components: {Snowcare}
        });
    }
}


















import axios from 'axios'
import {API_URL} from '../../Common/constants';

const BpApiService = {

    startQuotation: function (_data) {

        return axios.post(`${API_URL}/bikeplatinum/startQuotation`,{
            ...(_data)
        }).catch(error => {
            throw new Error(error);
        });

    },

    getQuotation: function (_data) {

        return axios.post(`${API_URL}/bikeplatinum/getQuotation`, {
            ...(_data)
        }).catch(error => {
            throw new Error(error);
        });

    },

    getQuotationData: function (_quotationId) {

        return axios.get(`${API_URL}/bikeplatinum/getQuotationData`, {
            params: {
                quotationId: _quotationId,
            },

        }).catch(error => {
            throw new Error(error);
        });

    },

    confirmQuotation: function (_data) {

        return axios.post(`${API_URL}/bikeplatinum/confirmQuotation`, {
            ...(_data)
        }).catch(error => {
            throw new Error(error);
        });

    },

    confirmRegistration: function (_data) {
        return axios.post(`${API_URL}/bikeplatinum/confirmRegistration`,
            {...(_data)}).catch(error => {
            throw new Error(error);
        });

    },

    getBrandsEbike: function () {
        return axios.get(`${API_URL}/bikeplatinum/getbrandsebike`).catch(error => {
            throw new Error(error);
        });

    },

    getModelsEbike: function (_data) {
        return axios.get(`${API_URL}/bikeplatinum/getmodelsebike`, {
            
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });

    },
    getVersionsEbike: function (_data) {
        return axios.get(`${API_URL}/bikeplatinum/getversionsebike`, {

            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });

    },
    getVehicleDetailEbike: function (_data) {
        return axios.get(`${API_URL}/bikeplatinum/getvehicledetailebike`, {
           
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });

    },

    getProvinces: function () {
        return axios.get(`${API_URL}/bikeplatinum/getprovinces`).catch(error => {
            throw new Error(error);
        });

    },
    setVin: function (_data) {

        return axios.post(`${API_URL}/bikeplatinum/setvin`, null, {
            params: _data
        } ).catch(error => {
            throw new Error(error);
        });

    },

};

export default BpApiService;

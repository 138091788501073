<template>
    <div class="container-fluid p-0 border-bottom">
        <div class="container-fluid bg-light-blue py-4">
            <div class="container my-0 mx-1 p-0">
                <div class="row no-gutters">
                    <div class="col-12">
                        <p class="h1 text-dark-secondary mb-2 font-weight-400">{{ title || $route.meta.title }}</p>
                        <p class="mb-0">{{ description || $route.meta.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Questo componente puo accettare title e description
// arbitrarie via props, se non vengono passate mostra
// i meta dati della router "title" e "description"
export default {
    name: "TheArHeader",
    props: ['title', 'description']
}
</script>

<style scoped>

</style>

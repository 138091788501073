<template>
    <div class="button-loading-spinner-container" style="position:relative;">
        <i class="fa fa-spin fa-spinner" v-show="true"></i>
    </div>
</template>

<script>
    export default {
        name: "ButtonLoadingSpinner",
        computed: {
            isLoading: function () {
                return this.$store.state.ux.loading > 0
            }
        }
    }
</script>

import axios from "axios";
import {API_URL} from "./constants";

const service = new axios.create()

service.interceptors.request.use(config => {
    const token = window.globalConfigVariables.token
    if (token) {
        config.headers.usertokenid = token
    }
    return config;
});

export const getUserData = async () => {
    return service.get(`${API_URL}/ar/getuserdata`)
}

const getUserPolicies = async () => {
    return service.get(`${API_URL}/ar/getpolicies`)
}

const getUserQuotations = async () => {
    return service.get(`${API_URL}/ar/getquotations`)
}

const userDataFetchInit = async () => {
    const token = window.globalConfigVariables.token
    if (!token) return;

    try {
        let _userData = await getUserData();
        window.localStorage.setItem('arUserData', JSON.stringify(_userData.data))

        let _userPolicies = {};
        let _userQuotations = {};

        if (_userData.data.userType === 1) {
            _userPolicies = await getUserPolicies(token)
            _userQuotations = await getUserQuotations(token)
            window.localStorage.setItem('arUserPolicies', JSON.stringify(_userPolicies.data.policies))
            window.localStorage.setItem('arUserQuotations', JSON.stringify(_userQuotations.data.quotations))
        }

    } catch (e) {
        console.error(e)
    }
};

export default userDataFetchInit

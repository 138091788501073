import axios from 'axios';
import {WEBORDER_API} from "@/Common/constants";

const InfobikeService = {
    
    /**
     * {vehicletype, codall, prgall, vehiclefirstregistrationdate}
     * @param {object} request
     */

    getInfobikeMpb: async (codeMpb) => {
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getinfobikempb/${codeMpb}`)
        } catch (e) {
            throw new Error(e);
        }
    },

    getInfobike: async (request) => {
        const {codall, prgall} = request
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getinfobike/${codall}/${prgall}`)
        } catch (e) {
            throw new Error(e);
        }
    },

    getInfobikeAllestiment: async (request) => {
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getinfobikeallestiment`, {
                params: {...(request)},
            })
        } catch (e) {

            throw new Error(e);
        }
    },

    getInfobikeAllestimentiMicrocar: async (request) => {
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getinfobikeallestimenti_microcar`, {
                params: {...(request)},
            })
        } catch (e) {

            throw new Error(e);
        }
    },

    getBrands: async (request) => {
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getbrands`, {
                params: {...(request)},
            })
        } catch (e) {

            throw new Error(e);
        }
    },

    getModels: async (request) => {
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getmodels`, {
                params: {...(request)},
            })
        } catch (e) {

            throw new Error(e);
        }
    },

    getVersions: async (request) => {
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getversions`, {
                params: {...(request)},
            })
        } catch (e) {

            throw new Error(e);
        }
    },
   
    getDetail: async (request) => {
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getdetail`,{
                params: {...(request)},
            })
        } catch (e) {
            throw new Error(e);
        }
    },

    getDetailByCodtot: async (request) => {
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getdetailbycodtot`,{
                params: {...(request)},
            })
        } catch (e) {
            throw new Error(e);
        }
    },

    getriskclass: async (request) => {
        try {
            return await axios.get(`${WEBORDER_API}/quotation/v2/infobike/getriskclass`,{
                params: {...(request)},
            })
        } catch (e) {
            throw new Error(e);
        }
    },

};

export default InfobikeService;

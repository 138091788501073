import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const _getDefaultState = () => {
    return {
        // True se il preventivatore sta caricando dati da Ciclo Registro
        bPquotationLoading: false,
        // Se maggiore di zero c'è una richiesta in corso,
        // ogni request aumenta l'indice di uno e lo abbassa di uno alla fine
        loading: 0,
        // true se c'è un azione di restore in corso
        restoreLoading: true,
        // caricamento in corso del campo brands in motoplatinum
        brandsLoading: false,
        // caricamento in corso del campo models in motoplatinum
        modelsLoading: false,
        // caricamento in corso del campo versions in motoplatinum
        versionsLoading: false,
        // caricamento in corso del dettaglio veicolo in motoplatinum
        vehicleDetailLoading: false,
        // Lo stato attuale del preventivatore, serve a
        // popolare il titolo del box che contiene il preventivatore
        currentPageTitle: '',
        // Indica se il pulsante indietro è abilitato oppure no
        goBackEnabled: true,

        // Stabilisce se il processo di acquisto
        // passerà dalla conferma dell'email oppure no
        emailConfirm: false,
        // urlprod, è un parametro url che ci dice da quale sito
        // partner arriva l'utente, se è prosente viene mandato come header
        urlProd: '',
        // isInternal indica se i parametri del preventivo sono sbloccati perchè siamo in rete interna
        isInternal: false,
        // Box per comunicazione di errori o messaggi
        errorState: {
            active: false,
            message: '',
            code: ''
        },
        // Larghezza dello schermo, necessaria per avere un getter che mi da il breakpoint responsive
        screenWidth: null,
        alertBanner: {
            active: false,
            title: '',
            message: '',
            kind: '',
            // Indica se il banner si puo eliminare manualmente
            dismiss: true,
            // Se truthy indica la presenza di una cta e l'url della cta
            url: '',
            // indica se il click sulla cta deve eseguire il restore di un preventivo
            restore: false
        },
        // Indica in che prodotto si trova l'utente
        // usato in molte parti dell'applicazione
        currentProduct: '',
        // Questo dato viene aggiornato dai router e ci dice se siamo in AR oppure no
        isInAr: null,
    }
};

export default {
    state: _getDefaultState(),
    actions: {
        SET_VIEW_TITLE({commit}, payload) {
            commit('SET_VIEW_TITLE', payload);
        },
        SET_AR_STATE({commit}, payload) {
            commit('SET_AR_STATE', payload);
        },
        SET_BACK_BUTTON_STATE({commit}, payload) {
            commit('SET_BACK_BUTTON_STATE', payload);
        },
        SET_CURRENT_PRODUCT({commit}, payload) {
            commit('UPDATE_CURRENT_PRODUCT', payload);
        },
        SET_BANNER_NOTIFICATION({commit}, payload) {
            commit('SET_BANNER', payload);
            if (payload.expire) setTimeout(() => commit('REMOVE_BANNER'), payload.expire);
        }
    },

    getters: {
        GET_ALERT_BANNER: state => {
            return state.alertBanner
        },
        GLOBAL_CURRENT_PRODUCT_SHORTNAME: state => {
            switch (state.currentProduct) {
                case 'skippermate':
                    return 'SK';
                case 'bikeplatinum':
                    return 'BP';
                case 'dottordog':
                    return 'DD';
                case 'motoplatinum':
                    return 'MP';
                case 'snowcare':
                    return 'SC';
                case 'multisport':
                    return 'MS';
                case 'multimobility':
                    return 'MY';

            }
        },

        GLOBAL_VIEW_BREAKPOINT: state => {
            const _w = state.screenWidth
            switch (true) {
                // Small
                case _w > 0 && _w < 768:
                    return 'SM';
                // Medium
                case _w >= 768 && _w < 992:
                    return 'MD';
                // Large
                case _w >= 992 && _w < 1200:
                    return 'LG';
                // Extra Large
                case _w >= 1200:
                    return 'XL';
            }
        },
        GLOBAL_LOADING_STATE: state => {
            return state.loading > 0
        }
    },

    mutations: {
        START_LOADING(state) {
            state.loading = state.loading + 1;
        },

        STOP_LOADING(state) {
            state.loading = state.loading - 1;
        },

        START_RESTORE_LOADING(state) {
            state.restoreLoading = true;
        },

        STOP_RESTORE_LOADING(state) {
            state.restoreLoading = false;
        },

        RESET_LOADING(state) {
            state.loading = 0;
        },

        BRANDS_LOADING(state, payload) {
            state.brandsLoading = payload;
        },

        SET_AR_STATE(state, payload) {
            state.isInAr = payload;
        },

        MODELS_LOADING(state, payload) {
            state.modelsLoading = payload;
        },

        VERSIONS_LOADING(state, payload) {
            state.versionsLoading = payload;
        },

        VEHICLE_DETAIL_LOADING(state, payload) {
            state.vehicleDetailLoading = payload;
        },

        SET_URLPROD(state, payload) {
            state.urlProd = payload;
        },

        START_QUOTATION_LOADING(state) {
            state.bPquotationLoading = true;
        },

        STOP_QUOTATION_LOADING(state) {
            state.bPquotationLoading = false;
        },

        SET_VIEW_TITLE(state, payload) {
            state.currentPageTitle = payload;
        },

        UPDATE_CURRENT_PRODUCT(state, payload) {
            state.currentProduct = payload;
        },

        SET_BACK_BUTTON_STATE(state, payload) {
            state.goBackEnabled = payload;
        },

        SET_SCREEN_WIDTH(state, payload) {
            state.screenWidth = payload;
        },

        SET_BANNER(state, payload) {
            state.alertBanner.active = true;
            state.alertBanner.title = payload.title;
            state.alertBanner.message = payload.message;
            state.alertBanner.kind = payload.kind;
            state.alertBanner.dismiss = payload.dismiss;
            state.alertBanner.url = payload.url;
            state.alertBanner.restore = payload.restore;
        },

        SET_ERROR(state, payload) {
            state.errorState.active = true;
            state.errorState.message = payload.message;
            state.errorState.code = payload.code;
        },

        REMOVE_ERROR(state, payload) {
            state.errorState.active = false;
            state.errorState.message = '';
            state.errorState.code = '';
        },

        REMOVE_BANNER(state) {
            state.alertBanner.active = false;
            state.alertBanner.title = '';
            state.alertBanner.message = '';
            state.alertBanner.kind = '';
            state.alertBanner.url = '';
            state.alertBanner.restore = false;

        },

        SET_EMAIL_CONFIRM(state, payload) {
            state.emailConfirm = payload
        },

        SET_IS_INTERNAL(state, payload) {
            state.isInternal = payload;
        },

        RESET_UX_STATE(state) {
            Object.assign(state, _getDefaultState());
        }
    }
};

import * as Cookies from 'js-cookie';

export default function cookiesHandler() {
    window.cookiePrivacyAccepted = cookiePrivacyAccepted;
    cookiePrivacyCheck();
}

export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export function cookiePrivacyCheck() {
    const $c = document.getElementById('cookie-banner');
    if ($c) {
        if (Cookies.get('cookiebanner-accepted') || inIframe()) {
            $c.style.display = 'none';
        } else {
            $c.style.display = 'flex';
        }
    }

}

export function cookiePrivacyAccepted() {
    Cookies.set('cookiebanner-accepted', 1, {domain: document.location.hostname, expires: 50});
}
export function formatNumberToComma(number) {
    const options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
    return number.toLocaleString('it-IT', options);
}

export function deepClone(obj) {
    // Verifica se l'oggetto è null o non è un oggetto
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
  
    // Crea una nuova istanza dell'oggetto
    let clone = Array.isArray(obj) ? [] : {};
  
    // Itera attraverso tutte le chiavi dell'oggetto
    for (let key in obj) {
      // Verifica se la chiave è una proprietà diretta dell'oggetto
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // Clona ricorsivamente le proprietà dell'oggetto
        clone[key] = deepClone(obj[key]);
      }
    }
  
    return clone;
  }
<template>
    <!--questo id viene usato dal componente snowcare.vue-->
    <div id="snowcareContentScroll" class="overflow-hidden">
        <section class="py-5">
            <div class="container">
                <div class="row pt-5 justify-content-center">
                    <div class="col-10">
                        <h2 class="h1 text-center mb-5"> {{ $t('snowcare.productComponent.benefits.title') }}</h2>
                    </div>
                </div>
                <div class="row mb-5 justify-content-center">
                    <div class="col-10 col-md-3">
                        <div class="box-element h-100 px-4 px-md-3 px-lg-4 pt-4 pb-3">
                            <p class="text-dark-secondary font-weight-bolder text-center text-md-left">
                                <i class="fa-regular fa-briefcase-medical fa-2x mb-3 text-secondary"></i><br/>
                                {{ $t('snowcare.productComponent.benefits.box1.title') }}
                            </p>
                            <p class="text-center text-md-left">
                                <small>{{ $t('snowcare.productComponent.benefits.box1.description') }}</small>
                            </p>
                            <div class="d-none d-md-block text-right mt-auto">
                                <small><a href="#garanzie" style="text-decoration: none;">{{
                                        $t('snowcare.productComponent.benefits.box1.linkText')
                                    }} <i class="pl-1 fa-solid fa-circle-arrow-right"></i></a> </small>
                            </div>
                        </div>
                    </div>

                    <div class="col-10 col-md-3">
                        <div class="box-element h-100 px-4 px-md-3 px-lg-4 pt-4 pb-3">
                            <p class="text-dark-secondary font-weight-bolder text-center text-md-left">
                                <i class="fa-regular fa-rocket-launch fa-2x mb-3 text-secondary"></i><br/>
                                {{ $t('snowcare.productComponent.benefits.box2.title') }}
                            </p>
                            <p class="text-center text-md-left">
                                <small>{{ $t('snowcare.productComponent.benefits.box2.description') }}</small>
                            </p>

                            <div class="d-none d-md-block text-right mt-auto">
                                <small><a href="#partner" style="text-decoration: none;">{{
                                        $t('snowcare.productComponent.benefits.box2.linkText')
                                    }}<i class="pl-1 fa-solid fa-circle-arrow-right"></i></a> </small>
                            </div>
                        </div>
                    </div>

                    <div class="col-10 col-md-3">
                        <div class="box-element h-100 px-4 px-md-3 px-lg-4 pt-4 pb-3">
                            <p class="text-dark-secondary font-weight-bolder text-center text-md-left">
                                <i class="fa-regular fa-mask-face fa-2x mb-3 text-secondary"></i><br/>
                                {{ $t('snowcare.productComponent.benefits.box3.title') }}
                            </p>
                            <p class="text-center text-md-left">
                                <small>{{ $t('snowcare.productComponent.benefits.box3.description') }}</small>
                            </p>
                            <div class="d-none d-md-block text-right mt-auto">
                                <small><a href="#pacchetti" style="text-decoration: none;">{{
                                        $t('snowcare.productComponent.benefits.box3.linkText')
                                    }}<i class="pl-1 fa-solid fa-circle-arrow-right"></i></a> </small>
                            </div>
                        </div>

                    </div>

                </div>

                <div id="partner" class="row py-3 justify-content-center">
                    <div class="col-md-9 text-center">
                        {{ $t('snowcare.productComponent.benefits.text1') }}
                    </div>

                </div>

                <div id="container-polizze" class="row justify-content-center mt-2 pb-3" style="margin-top: -30px;">

                    <div class="col-12 mt-5 mt-md-5 mb-3">
                        <div class="shadow rounded h-100 pl-4 pt-4 pr-4 pb-2 bg-white">
                            <div class="circle-top bg-white">
                                <i class="fa-solid fa-triangle-exclamation text-red"></i><br/>
                            </div>
                            <p class="h6 text-center pt-3 pb-2 pb-md-0 font-weight-700 text-secondary">
                                {{ $t('snowcare.productComponent.benefits.alert.title') }}
                            </p>

                            <p class="text-center pt-1 pb-2 pb-2"
                               v-html="$t('snowcare.productComponent.benefits.alert.description')"></p>
                        </div>
                    </div>

                </div>

            </div>
        </section>

        <section id="pacchetti" class="overflow-hidden pt-4 pb-5 pb-md-0">

            <div class="bg-gradient-dark-blue-bikeplatinum py-5">

                <div class="container position-relative pt-1 pt-md-5 pb-5 pb-md-0 feedaty-container"
                     style="z-index: 9;">
                    <div class="row justify-content-center my-5 py-md-5">
                        <div class="col-12 col-md-10 mb-5 pb-5">
                            <h2 class="h1 text-white text-center mb-3 mb-md-4">
                                {{ $t('snowcare.productComponent.pacchetti.title') }}</h2>
                            <p class="text-white text-center"
                               v-html="$t('snowcare.productComponent.pacchetti.text1')"></p>
                            <p class="text-white text-center"
                               v-html="$t('snowcare.productComponent.pacchetti.text2')"></p>
                            <p id="garanzie" class="text-white text-center mb-1 pb-5 pb-md-5"
                               v-html="$t('snowcare.productComponent.pacchetti.text3')"></p>
                        </div>
                    </div>
                </div>
                        <!-- {{ $t('snowcare.productComponent.table.title2') }} -->
            </div>
        </section>

        <!-- DESKTOP TABLE -->
        <div class="garanzie-sc-container container mb-4">

            <div class="d-flex">
                <table class="table col-12">

                    <tbody class="">
                        <div class="w-100">
                            <tr class="row w-100 mx-0">
                                <td class="col-6 col-md-5 col-lg-4 no-border">
                                    <div class="h5 text-dark-blue font-weight-700 text-uppercase text-left">
                                        {{ $t('snowcare.productComponent.table.title1') }}
                                        <!-- Garanzie obbligatorie -->
                                    </div>
                                </td>
                                <td class="d-flex no-border align-items-end">
                                    <div class="h5 text-dark-blue font-weight-700 text-uppercase text-left">
                                        {{ $t('snowcare.productComponent.table.titleMassimali') }}
                                        <!-- Limiti -->
                                    </div>                        
                                </td>
                            </tr>                   
                        </div>
                    </tbody>

                    <thead>
                        <tr class="pb-4">
                            <td class="no-border">
                                <!-- il thead va a finire più in alto quindi non faccio ved il testo -->
                                <span class="h5 text-white font-weight-700 text-uppercase text-left">
                                    .
                                </span>
                            </td>
                            <td class="no-border mb-4">
                                <span class="h5 text-white font-weight-700 text-uppercase text-left">
                                    .
                                </span>
                            </td>
                        </tr>
                    </thead>

                    <tbody class="guarantees-body-table-sc shadow rounded mb-5">
                        <div class="rounded">
                            <tr>
                                <td class="py-4 col-6 col-md-5 col-lg-4 max-width no-border">
                                    <span class="font-weight-600 text-white">.</span><br>
                                    <span class="font-weight-700"
                                        v-html="$t('snowcare.productComponent.table.garanzia1.label')">
                                    </span><br>
                                      <!-- {{$t('snowcare.productComponent.table.garanzia1.label') }} -->
                                        <!-- Responsabilità civile per danni a <br>persone e cose di terze</span><br> -->
                                </td>
                                <td class="no-border">
                                    <span v-html="$t('snowcare.productComponent.table.garanzia1.massimale1')">
                                    </span><br>
                                     <!-- <strong>150.000€</strong> danni a persone -->
                                    <!-- <span>
                                    <strong>10.000€</strong> danni a cose
                                    </span><br>
                                    <span>
                                        Franchigia fissa 500€
                                    </span><br>
                                    <span> Scoperto 10%</span> -->
                                </td>
                            </tr>
                        </div>
                    </tbody>

                </table>

            </div>
        </div>

        <div class="container mb-4">
            <div class="d-flex">
                <table class="table col-12">
                    <tbody class="">
                        <div class="w-100">
                            <tr class="row w-100 mx-0">
                                <td class="col-6 col-md-5 col-lg-4 no-border">
                                    <div class="h5 text-dark-blue font-weight-700 text-uppercase text-left">
                                        <!-- Già incluse nel prezzo -->
                                        {{$t('snowcare.productComponent.table.title2') }}
                                    </div>
                                </td>
                                <td class="d-flex no-border align-items-end">
                                    <div class="h5 text-dark-blue font-weight-700 text-uppercase text-left">
                                        {{ $t('snowcare.productComponent.table.titleMassimali') }}
                                        <!-- Limiti -->
                                    </div>                        
                                </td>
                            </tr>                   
                        </div>
                    </tbody>


                    <tbody class="guarantees-body-table-sc shadow rounded mb-5">
                        <div class="rounded">
                            <tr>
                                <td class="pt-4 col-6 col-md-5 col-lg-4 no-border max-width">
                                    <!-- Tutela legale -->
                                    <span v-html="$t('snowcare.productComponent.table.garanzia2.label')" 
                                        class="font-weight-700">
                                    </span><br>
                                </td>
                                <td class="pt-4 no-border">
                                    <span><strong>1.000€</strong></span>
                                </td>
                            </tr>
                            <tr>
                                <td v-html="$t('snowcare.productComponent.table.garanzia3.label')" 
                                    class="col-6 col-md-5 col-lg-4 border-light">
                                  
                                </td>
                                <td class="border-light"
                                    v-html="$t('snowcare.productComponent.table.garanzia3.massimale1')">
                                   
                                </td>
                            </tr>
                            <tr>
                                <td class="pb-4 col-6 col-md-5 col-lg-4 border-light">
                                    <span class="font-weight-700">
                                        {{ $t('snowcare.productComponent.table.garanzia4.label') }}
                                        <!-- Autista a disposizione -->
                                    </span><br>
                                </td>
                                <td class="border-light">
                                    <span><strong>
                                        {{ $t('snowcare.productComponent.table.garanzia4.massimale1') }}
                                        <!-- Costo intero -->
                                    </strong></span>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-6 col-md-5 col-lg-4 border-light"
                                    v-html="$t('snowcare.productComponent.table.garanzia5.label')">
                                   
                                </td>
                                <td class="border-light"
                                v-html="$t('snowcare.productComponent.table.garanzia5.massimale2')">
                                </td>
                            </tr>
                            <tr>
                                <td class="col-6 col-md-5 col-lg-4 border-light"
                                v-html="$t('snowcare.productComponent.table.garanzia6.label')">
                                </td>
                                <td class="border-light"
                                    v-html="$t('snowcare.productComponent.table.garanzia6.massimale1')">
                                </td>
                            </tr>
                            <tr>
                                <td class="col-6 col-md-5 col-lg-4 border-light"
                                v-html="$t('snowcare.productComponent.table.garanzia7.label')">
                                </td>
                                <td class="border-light">
                                    <span><strong v-html="$t('snowcare.productComponent.table.garanzia7.massimale1')">
                                    </strong></span><br>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-6 col-md-5 col-lg-4 border-light"
                                v-html="$t('snowcare.productComponent.table.garanzia8.label')">
                                </td>
                                <td class="border-light"
                                v-html="$t('snowcare.productComponent.table.garanzia8.massimale1')">
                                </td>
                            </tr>
                            <tr>
                                <td class="pb-4 col-6 col-md-5 col-lg-4 border-light"
                                v-html="$t('snowcare.productComponent.table.garanzia9.label')">
                                </td>
                                <td class="border-light">

                                    <span><strong v-html="$t('snowcare.productComponent.table.garanzia9.massimale1')">
                                    </strong></span><br>
                                </td>
                            </tr>                  
                        </div>

                    </tbody>
                </table>
            
            </div>
        </div>

        <!-- END TABLE -->

        <section>
            <div class="container">
                <div class="row justify-content-center" v-html="$t('snowcare.productComponent.supportCopy')"></div>
                <div class="row justify-content-center pb-0 pb-md-2 mb-5 mb-md-0 mx-1">
                    <div class="col-12 col-md-auto">
                        <div class="p-4 bg-white box-element-2 rounded py-3 text-secondary">
                            <p class="h4 pb-2 pr-md-3 pb-md-3 text-md-center">
                                {{ $t('snowcare.productComponent.docs.title') }}</p>
                            <div class="d-md-flex pb-md-2">
                                <div class="mr-2 mb-2">
                                    <a target="_blank" :href="$t('snowcare.productComponent.docs.doc1.link')">
                                        <i class="fal fa fa-file-pdf text-black"></i>
                                    </a>&nbsp;
                                    <a class="text-black mr-4" target="_blank"
                                       :href="$t('snowcare.productComponent.docs.doc1.link')">
                                        {{ $t('snowcare.productComponent.docs.doc1.label') }}
                                    </a>
                                </div>

                                <div class="mb-2 mr-4">
                                    <a target="_blank" :href="$t('snowcare.productComponent.docs.doc2.link')">
                                        <i class="fal fa fa-file-pdf  mb-0 text-black"></i>
                                    </a>&nbsp;
                                    <a class="text-black" target="_blank"
                                       :href="$t('snowcare.productComponent.docs.doc2.link')">
                                        {{ $t('snowcare.productComponent.docs.doc2.label') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="overflow-hidden py-5">
            <div class="bg-gradient-dark-blue-curve py-5">
                <div class="container position-relative py-5 feedaty-container" style="z-index: 9;">
                    <div class="row justify-content-center pt-0 pt-md-5 mt-0 mt-md-5">
                        <div class="col-12 col-md-10">
                            <p class="h1 text-white text-center mb-3">{{
                                    $t('snowcare.productComponent.reviews.title')
                                }}</p>
                            <p class="text-white text-center mb-5">
                                {{ $t('snowcare.productComponent.reviews.description') }}</p>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-stretch pb-0 pb-md-5 mb-4 mb-md-5">

                        <div class="col-10 col-md-3 col-lg-3 my-5 my-md-3">
                            <div class="d-flex flex-column bg-white shadow rounded-xl p-4 h-100">
                                <div class="circle-top bg-white">
                                    <img class="feedaty-circle" src="/images/feedaty_logo_blue.svg" alt="feedaty">
                                </div>
                                <p class="font-weight-bolder pt-4">
                                    <span class="d-block text-dark-secondary mb-3">{{
                                            $t('snowcare.productComponent.reviews.review1.title')
                                        }}</span>
                                    <span class="d-none d-md-inline-block text-secondary font-weight-bold mb-3">{{
                                            $t('snowcare.productComponent.reviews.review1.label1')
                                        }}</span>
                                    <small class="text-dark-secondary">{{
                                            $t('snowcare.productComponent.reviews.review1.label2')
                                        }}</small>
                                </p>

                                <div class="text-primary mt-auto">
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                </div>

                            </div>
                        </div>

                        <div class="col-10 col-md-3 col-lg-3 my-5 my-md-3">
                            <div class="d-flex flex-column bg-white shadow rounded-xl p-4 h-100">
                                <div class="circle-top bg-white">
                                   <img class="feedaty-circle" src="/images/feedaty_logo_blue.svg" alt="feedaty">
                                </div>
                                <p class="font-weight-bolder pt-4">
                                    <span class="d-block text-dark-secondary mb-3">{{
                                            $t('snowcare.productComponent.reviews.review2.title')
                                        }}</span>
                                    <span class="d-none d-md-inline-block text-secondary font-weight-bold mb-3">{{
                                            $t('snowcare.productComponent.reviews.review2.label1')
                                        }}</span>
                                    <small class="text-dark-secondary">{{
                                            $t('snowcare.productComponent.reviews.review2.label2')
                                        }}</small>
                                </p>

                                <div class="text-primary mt-auto">
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                </div>

                            </div>
                        </div>

                        <div class="col-10 col-md-3 col-lg-3 my-5 my-md-3">
                            <div class="d-flex flex-column bg-white shadow rounded-xl p-4 h-100">
                                <div class="circle-top bg-white">
                                    <img class="feedaty-circle" src="/images/feedaty_logo_blue.svg" alt="feedaty">
                                </div>
                                <p class="font-weight-bolder pt-4">
                                    <span class="d-block text-dark-secondary mb-3">{{
                                            $t('snowcare.productComponent.reviews.review3.title')
                                        }}</span>
                                    <span class="d-none d-md-inline-block text-secondary font-weight-bold mb-3">{{
                                            $t('snowcare.productComponent.reviews.review3.label1')
                                        }}</span>
                                    <small class="text-dark-secondary">{{
                                            $t('snowcare.productComponent.reviews.review3.label2')
                                        }}</small>
                                </p>

                                <div class="text-primary mt-auto">
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                </div>

                            </div>
                        </div>

                        <div class="col-10 col-md-3 col-lg-3 my-5 my-md-3">
                            <div class="d-flex flex-column bg-white shadow rounded-xl p-4 h-100">
                                <div class="circle-top bg-white">
                                    <img class="feedaty-circle" src="/images/feedaty_logo_blue.svg" alt="feedaty">
                                </div>
                                <p class="font-weight-bolder pt-4">
                                    <span class="d-block text-dark-secondary mb-3">{{
                                            $t('snowcare.productComponent.reviews.review4.title')
                                        }} </span>
                                    <span class="d-none d-md-inline-block text-secondary font-weight-bold mb-3">{{
                                            $t('snowcare.productComponent.reviews.review4.label1')
                                        }}</span>
                                    <small class="text-dark-secondary">{{
                                            $t('snowcare.productComponent.reviews.review4.label2')
                                        }}</small>
                                </p>

                                <div class="text-primary mt-auto">
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                    <i class="fa-solid fa-star fa-2x"></i>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>

    </div>
</template>

<script>

import Carousel from "@/Common/TheCarousel";
import TheCarousel from "@/Common/TheCarousel";
import {BModal} from "bootstrap-vue";

export default {
    name: "SnowcareContent",
    components: {
        TheCarousel,
        Carousel,
        // 'b-modal': BModal
    },
}
</script>


<style scoped>

    @media (max-width: 767.98px) {
    .guarantees-body-table-sc td{
        font-size: 14px!important;
    }
    }
    .guarantees-body-table-sc td{
    height: 130px;
    vertical-align: middle!important;
    }
.border-gold{
    border: 4px solid #FFC820 !important;
  }

  td.no-border {
    border-top: none !important;
  }

  .table tbody + tbody {
    border-top: 2px solid transparent !important;
}
  .col-md-4.max-width {
    max-width: 60.33333% !important;
  }

  .table td .border-light {
    border-top: 1px solid #efeeee !important;
  }


  .rounded-corners {
    position: relative; 
    border: 4px solid transparent;
    border-radius: 16px;
    border-radius: 16px;
    padding: 10px;
  }

  .rounded-corners::after {
    position: absolute; 
    top: -4px; 
    bottom: -4px; 
    left: -4px; 
    right: -4px; 
    border: 4px solid #ffc820 !important; 
    content: '';  
    z-index: -1; 
    border-radius: 16px;
  }


.modal-footer {
    display: none !important;
  }

#goldModal___BV_modal_title_ { 
    font-size: 1.5rem !important; 
    font-weight: 700; 
    margin-left: 1rem !important;
}

h5.modal-title {
    font-size: 1.5rem !important; 
    font-weight: 700; 
    margin-left: 1rem !important;
}

</style>
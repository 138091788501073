import Vue from 'vue'
import jQuery from "@/Common/jqueryLoader";
import 'document-register-element/build/document-register-element'
import VueCustomElement from 'vue-custom-element'
import FastquoteBikeplatinum from '../Preventivo/_Common/Components/FastquoteBikeplatinum'
import FastquoteMotoplatinum from '../Preventivo/_Common/Components/FastquoteMotoplatinum'
import FastquoteDottordog from './DottordogFastquote'
import MappaPuntiVendita from './MappaPuntiVendita'
import TheOfficineConvenzionate from '../Components/TheOfficineConvenzionate'
import FaqsWidget from './FaqsWidget'
import UxBehaviour from './UxBehaviour'
import homeHandler from './home'
import careersHandler from './careers'
import Carousel from './TheCarousel'
import CarouselSectionContattaci from './CarouselSectionContattaci'
import CarouselSectionHome from './CarouselSectionHome'
import HomeFastquote from './HomeFastquote'
import MenuAreaRiservata from './MenuAreaRiservata'
import carouselCustom from './carousel-custom'
import cookiesHandler from './cookies'
import checkView from 'vue-check-view'
import {AnalyticsInit, SelligentInit} from './AnalyticsConfig'
import VueWindowSize from 'vue-window-size'
import blogHandler from "./blog"

import StickyFastquoteBikeplatinum from "../Preventivo/_Common/Components/StickyFastquoteBikeplatinum"
import StickyFastquoteMotoplatinum from "../Preventivo/_Common/Components/StickyFastquoteMotoplatinum"
import StickyFastquoteMultimobility from "../Preventivo/_Common/Components/StickyFastquoteMultimobility"
import StickyFastquoteSnowcare from "../Preventivo/_Common/Components/StickyFastquoteSnowcare"
import StickyFastquoteDottordog from "../Preventivo/_Common/Components/StickyFastquoteDottordog"
import StickyFastquoteSkippermate from "../Preventivo/_Common/Components/StickyFastquoteSkippermate"
import StickyFastquoteCta from "../Preventivo/_Common/Components/StickyFastquoteCta"
import DocumentUploader from "../Preventivo/_Common/Components/DocumentUploader/DocumentUploader"
import {colorsCheck} from "./colors"
import userDataFetchInit from "./userDataFetch";
import {chatsInit} from "./chat";
import TheCarousel from "@/Common/TheCarousel";
import SnowcareFastquote from "../Components/SnowcareFastquote";
import SnowcareFastquoteCalendar from "../Components/SnowcareFastquoteCalendar";
import TheReclamiForm from "../Components/TheReclamiForm";




Vue.use(VueCustomElement)
Vue.use(checkView)
Vue.use(VueWindowSize)


// Vue-custom-element è una libreria che
//permette di creare dei widget di vue dall'istanza autogenerante,
//in questo modo è possibile i nqualunque pagina del sit
//inserire gli widget e passare i parametri richiesti
Vue.customElement('fastquote-motoplatinum', FastquoteMotoplatinum)
Vue.customElement('fastquote-bikeplatinum', FastquoteBikeplatinum)
Vue.customElement('sticky-fastquote-bikeplatinum', StickyFastquoteBikeplatinum)
Vue.customElement('sticky-fastquote-motoplatinum', StickyFastquoteMotoplatinum)
Vue.customElement('sticky-fastquote-multimobility', StickyFastquoteMultimobility)
Vue.customElement('sticky-fastquote-snowcare', StickyFastquoteSnowcare)
Vue.customElement('sticky-fastquote-dottordog', StickyFastquoteDottordog)
Vue.customElement('sticky-fastquote-skippermate', StickyFastquoteSkippermate)
Vue.customElement('sticky-fastquote-cta', StickyFastquoteCta)
Vue.customElement('fastquote-dottordog', FastquoteDottordog)
Vue.customElement('faqs-widget', FaqsWidget)
Vue.customElement('mappa-punti-vendita', MappaPuntiVendita)
Vue.customElement('the-officine-convenzionate', TheOfficineConvenzionate)
Vue.customElement('the-reclami-form', TheReclamiForm)
Vue.customElement('document-uploader', DocumentUploader)
Vue.customElement('the-carousel', TheCarousel)
Vue.customElement('carousel-section-contattaci', CarouselSectionContattaci)
Vue.customElement('carousel-section-home', CarouselSectionHome)
Vue.customElement('home-fastquote', HomeFastquote)
Vue.customElement('snowcare-fastquote', SnowcareFastquote)
Vue.customElement('snowcare-fastquote-calendar', SnowcareFastquoteCalendar)


// Quando il documento è load
document.addEventListener("DOMContentLoaded", async () => {
    // Inizializza il behaviour della UX
    UxBehaviour()
    careersHandler()
    homeHandler()
    await userDataFetchInit()
    MenuAreaRiservata()
    carouselCustom()
    cookiesHandler()
    AnalyticsInit()
    SelligentInit()
    chatsInit()
    blogHandler()
    colorsCheck()
});

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fast-quote",class:_vm.classes},[_c('div',{directives:[{name:"view",rawName:"v-view",value:(_vm.handleFastQuoteVisibility),expression:"handleFastQuoteVisibility"}],staticClass:"form-row no-gutters mx-0 align-items-center"},[_c('div',{staticClass:"form-group rounded-pill",class:{ 'col-md-6': _vm.size === 'large', 'col-md-4': _vm.size !== 'large', 'mb-0': _vm.$v.date.$error }},[_c('label',{staticClass:"text-dark-gray",attrs:{"for":"FastQuoteBirthDate"}},[_c('small',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(this.size !== 'small' ? 'Data di nascita del proprietario*' : 'Data di nascita'))])]),_vm._v(" "),_c('input',{ref:"FastQuoteBirthDate",staticClass:"text-dark-secondary",class:{
                        'is-invalid':_vm.$v.date.$invalid && _vm.$v.date.$error,
                        'is-valid' : !_vm.$v.date.$invalid && _vm.$v.date.$dirty
                    },attrs:{"type":"text","inputmode":"numeric","pattern":"[0-9]*","id":"FastQuoteBirthDate","placeholder":"GG/MM/AAAA"},on:{"blur":function($event){return _vm.$v.date.$touch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onQuotationClick.apply(null, arguments)}}}),_vm._v(" "),(_vm.$v.date.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.date.required)?_c('span',[_vm._v("\n                    Campo data richiesto\n                ")]):_vm._e(),_vm._v(" "),(_vm.$v.date.required && !_vm.$v.date.minLength || !_vm.$v.date.maxLength)?_c('span',[_vm._v("\n                    Campo data non completo\n                ")]):_vm._e(),_vm._v(" "),(_vm.$v.date.required && _vm.$v.date.minLength && _vm.$v.date.maxLength && !_vm.$v.date.birthDate)?_c('span',[_vm._v("\n                    Inserisci una data di nascita valida\n                ")]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group rounded-pill",class:{ 'col-md-6': _vm.size === 'large', 'col-md-3': _vm.size !== 'large', 'mb-0': _vm.$v.plate.$error }},[_c('label',{staticClass:"text-dark-gray",attrs:{"for":"FastQuotePlate"}},[_c('small',{staticStyle:{"font-size":"12px"}},[_vm._v("Targa del veicolo"+_vm._s(this.size !== 'small' ? '*' : ''))])]),_vm._v(" "),_c('input',{directives:[{name:"uppercase",rawName:"v-uppercase"},{name:"remove-spaces",rawName:"v-remove-spaces"},{name:"model",rawName:"v-model",value:(_vm.plate),expression:"plate"}],ref:"FastQuotePlate",staticClass:"text-dark-secondary",class:{
                    'is-invalid' :_vm.$v.plate.$invalid && _vm.$v.plate.$error,
                    'is-valid' : !_vm.$v.plate.$invalid && _vm.$v.plate.$dirty },attrs:{"type":"text","maxlength":"8","id":"FastQuotePlate","placeholder":"AB12345"},domProps:{"value":(_vm.plate)},on:{"blur":function($event){return _vm.$v.plate.$touch()},"paste":_vm.onPaste,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onQuotationClick.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.plate=$event.target.value}}}),_vm._v(" "),(_vm.$v.plate.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.plate.required)?_c('span',[_vm._v("\n                    Targa richiesta\n                ")]):_vm._e(),_vm._v(" "),(_vm.$v.plate.required && !_vm.$v.plate.plateFormat)?_c('span',[_vm._v("\n                    Il formato della targa non è corretto\n                ")]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-group col text-center"},[_c('button',{ref:"FastQuoteButton",staticClass:"btn btn-primary rounded-pill",class:{
                        'disabled': _vm.$v.plate.$invalid || _vm.$v.date.$invalid
                        },attrs:{"id":"cta_mp_fastquote","data-type":"CTA","type":"button","disabled":_vm.$v.plate.$invalid || _vm.$v.date.$invalid},on:{"click":function($event){$event.preventDefault();return _vm.onQuotationClick.apply(null, arguments)}}},[_vm._v("\n                    "+_vm._s(this.size !== 'small' ? 'Calcola un preventivo' : 'Calcola')+"\n            ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
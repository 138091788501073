import 'owl.carousel';

export default function carouselCustom() {

    $('#pills-partner-mp .owl-carousel').owlCarousel( {
        loop: true,
        margin:10,
        nav:true,
        responsiveClass:true,
        autoplay:true,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:2,
            },
            540:{
                items:4,
            },
            1140:{
                items:7,
            }
        }
    });

    $('#pills-partner-dd .owl-carousel, .dd-carousel.owl-carousel').owlCarousel( {
        loop: false,
        margin:10,
        nav:true,
        responsiveClass:true,
        autoplay: false,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:2,
                loop: true,
                autoplay:true,
            },
            540:{
                items:4,
                loop: true,
                autoplay:true,
            },
            1140:{
                items:6
            }
        }
    });

    $('#pills-partner-sk .owl-carousel, .sk-carousel.owl-carousel').owlCarousel( {
        loop: false,
        items:2,
        margin:10,
        nav:true,
        responsiveClass:true,
        autoplay: false,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2
            },
            540: {
                items: 2
            },
            1140:{
                items: 2
            }
        }
    });

    /* Pagina Motoplatinum */
    $('#mp-carousel .owl-carousel').owlCarousel( {
        loop: true,
        margin:10,
        nav:true,
        responsiveClass:true,
        autoplay:true,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:2,
            },
            540:{
                items:4,
            },
            1140:{
                items:7,
            }
        }
    });

    $('#pills-partner-bp .owl-carousel, .bp-carousel.owl-carousel').owlCarousel({
        loop: false,
        items: 4,
        margin: 10,
        nav: true,
        responsiveClass: true,
        autoplay: false,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2
            },
            540: {
                items: 4
            },
            1140: {
                items: 7
            }
        }
    });

    /* Pagina bikeplatinum */
    $('#bp-carousel .owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        responsiveClass: true,
        autoplay: true,
        autoplayTimeout:2000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2
            },
            540: {
                items: 4
            },
            1140: {
                items: 7
            }
        }
    });

}


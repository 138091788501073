import axios from 'axios';
import {API_URL, WEBORDER_API, SCHEDULING_API_KEY, SCHEDULING_API_URL} from '../../Common/constants';


const MpApiService = {

    startQuotation: function (_data) {

        return axios.get(`${API_URL}/motoplatinum/startQuotation`, {
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });

    },

    getBundles: function (_data) {

        return axios.post(`${API_URL}/motoplatinum/getBundles`,
            {
                ...(_data)
            })
    },

    getAddons: function (_data) {

        return axios.post(`${API_URL}/motoplatinum/getAddons`,
            {
                ...(_data)
            }).catch(error => {
            throw new Error(error);
        });

    },

    getUpsellAddons: function (_data) {

        return axios.get(`${API_URL}/motoplatinum/getUpsellAddons`,
            {
                params: {
                    ...(_data)
                }
            }).catch(error => {
            throw new Error(error);
        });

    },

    updateAddons: function (_data) {

        return axios.post(`${API_URL}/motoplatinum/updateAddons`, {
            ...(_data)
        }).catch(error => {
            throw new Error(error);
        });

    },

    getQuotation: function (_data) {

        return axios.post(`${API_URL}/motoplatinum/getQuotation`, {
            ...(_data)
        }).catch(error => {
            throw new Error(error);
        });

    },

    getQuotationData: function (_quotationId) {

        return axios.get(`${API_URL}/motoplatinum/getQuotationData`, {
            params: {
                quotationId: _quotationId,
            },

        }).catch(error => {
            throw new Error(error);
        });

    },

    confirmQuotation: function (_data) {

        return axios.post(`${API_URL}/motoplatinum/confirmQuotation`,
            {
                ...(_data)
            }).catch(error => {
            throw new Error(error);
        });

    },

    changeQuotationEmail: function (_data) {

        return axios.post(`${API_URL}/motoplatinum/ChangeQuotationEmail`,
            {
                ...(_data)
            })
    },

    changeEffectiveDate: function (_data) {

        return axios.post(`${API_URL}/motoplatinum/changeEffectiveDate`,
            {
                ...(_data)
            }).catch(error => {
            throw new Error(error);
        });

    },

    confirmRegistration: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/confirmRegistration`,
            {...(_data)})
    },

    getResidenceCities: function (search) {
       
        return axios.get(`${WEBORDER_API}/quotation/v2/istat/byusersearch/${search}`).catch(error => {
            throw new Error(error);
        });

    },
    getResidenceCitiesById: function (id) {
       
        return axios.get(`${WEBORDER_API}/quotation/v2/istat/byistatcode/${id}`).catch(error => {
            throw new Error(error);
        });

    },

    getBrands: function (_data) {

        return axios.get(`${API_URL}/motoplatinum/getBrands`, {
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });

    },

    getModels: function (_data) {

        return axios.get(`${API_URL}/motoplatinum/getModels`, {
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });

    },

    getVersions: function (_data) {

        return axios.get(`${API_URL}/motoplatinum/getVersions`, {
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });

    },

    getVehicleDetail: function (_data) {

        return axios.get(`${API_URL}/motoplatinum/getVehicleDetail`, {
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });
    },

    checkDocumentUpload: function (_data) {
        return axios.get(`${API_URL}/motoplatinum/checkDocumentUpload`, {
            params: {
                ...(_data)
            },
        }).catch(error => {
            throw new Error(error);
        });
    },

    boxCheck: function (_data) {

        return axios.get(`${API_URL}/motoplatinum/BoxCheck`, {
            params: {
                ...(_data)
            },

        }).catch(error => {
            throw new Error(error);
        });
    },

    boxUpdate: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/boxUpdate`,
            {...(_data)}).catch(error => {
            throw new Error(error);
        });

    },

    familyRcVehicle: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/familyRcVehicle`,
            {...(_data)}).catch(error => {
            throw new Error(error);
        });

    },

    setDrivingLicense: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/SetDriverLicensev2`,
            {...(_data)}).catch(error => {
            throw new Error(error);
        });
    },

    alertRegistration: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/alertRegistration`,
            {...(_data)}).catch(error => {
            throw new Error(error);
        });
    },

    updateQuotationPartner: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/updateQuotationPartner`,
            {...(_data)})
    },

    getDriverLicence: function (_data) {
        return axios.get(`${API_URL}/Motoplatinum/GetDriverLicense`, {
            params: {
                quotationId: _data
            }
        })
    },
    getBookingDateLimit: function (_data) {
        return axios.get(`${API_URL}/paperoga/getavailabledate`,
            {
                params: {...(_data)}
            })
    },

    getBookingLocations: function (_data) {
        return axios.post(`${API_URL}/paperoga/getdealers`,{...(_data)})
    },

    getBookingTimeslots: function (_data) {
        return axios.get(`${API_URL}/paperoga/gettimesslots`)
    },

    setBookingAppointments: function (_data) {
        return axios.post(`${API_URL}/paperoga/createappointments`,
            {...(_data)})
    },


};

export default MpApiService;

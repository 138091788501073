<template>
   
    <div class="fixed-top"        
         id="sticky-fast-quote-cta"
         style="min-height: 1px;"
         v-view="handleFastQuoteVisibility"
         data-section="sticky_fastquote">
        
        <div  v-if="stickyIsVisible" class="container-fluid bg-dark-secondary pt-2 pb-3">
            <div class="container px-0 px-md-3 py-1">
                <div class="row d-flex align-items-center">
                    <div class="col col-md-6">
                        <div class="d-flex justify-content-start">
                            <img class="img-fluid d-none d-md-block"
                                 src="/images/24hassistance_logo_white.png"
                                 srcset="/images/24hassistance_logo_white@2x.png 2x,
                            /images/24hassistance_logo_white@3x.png 3x,
                          /images/24hassistance_logo_white@4x.png 4x"
                                 style="max-height:40px"/>
                            <p style="padding-top:13px" class="lead text-uppercase text-white m-0 ml-2">{{title}}</p>
                        </div>
                    </div>
                    <div class="col-auto col-md-6 p-sm-0 px-md-3">
                        
                        <div class="position-relative text-right">
                            <a  id="cta_mp_calculate"
                                data-type="CTA"
                                class="btn btn-primary rounded-pill" :href="url">calcola</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FastquoteMotoplatinum from "./FastquoteMotoplatinum";
    import {bus} from "../EventBus";

    export default {
        name: "StickyFastquoteCta",

        components: {
            'fastquote-motoplatinum': FastquoteMotoplatinum
        },
        props: ['title', 'url', 'onlyMobile' ],
        
        data: function () {
            return {
                stickyIsVisible: false
            }
        },

        created() {
            const _this = this;
            // Attende l'evento inviato dal fastquote quando è in
            // modalità sticky ed entra ed esce dal viewport
            bus.$on('onShowStickyFastquote', (_v) => {                
                _this.stickyIsVisible = _v;
            });

        },
        methods: {

            handleFastQuoteVisibility: function (e) {
                try {
                    if (e.scrollPercent > 0.05) {
                        bus.$emit('onShowStickyFastquote', true);
                    } else if (e.scrollPercent < 0.05) {
                        bus.$emit('onShowStickyFastquote', false);
                    }
                } catch (e) {
                }
                

            },
        }
    }
</script>



export default class SelligentPusher {
    constructor() {

        this._tags = []
    }

    /**
     * Aggiunge i tag all'istanza di selligent e li prepara all'invio, si aspetta un array il cui primo valore è il nome del tag e il secondo è il suo valore.
     * @param tag
     * @param value
     */
    add(tag, value) {
        if(!tag || !value) {
            console.error('tag o valore mancanti')
            return
        }
        // Ritorno errore se non sono presenti i dati
        this._tags.push({
            tag: tag, value: value
        })

    }

    /**
     * Elimina i tag caricati nell'istanza.
     */
    clear() {
        this._tags = []
    }

    /**
     * Elimina dall'istanza il tag all'indice specificato.
     * @param index
     */
    delete(index) {
        if(!index) {
            console.error('Indice dell\'elemento da eliminare mancante')
            return
        }
        this._tags.splice(index, 1)
    }

    /**
     * Restituisce l'elenco dei tags presenti nell'istanza.
     */
    get tags() {
        return this._tags
    }

    /**
     * Invia i tag a selligent e svuota i tag caricati nell'istanza.
     */
    push() {
        if(!this._tags.length > 0){
            console.error('Non sono ancora stati caricati dei tag nell\'istanza')
            return
        }
        /* wa.bt_queue.push({
            tagValues: this._tags,
            errorCallback: "selligentErrorCallback",
            async: true,
            isEvent: false,
            isTargeting: false
        }); */

        this._tags = []
    }

}

const selligentErrorCallback = e => console.error(e)

<template>
    <div class="container-fluid p-0 m-0 ">
        <banner-alert
                v-if="ux.alertBanner.active"></banner-alert>
        <div class="container-fluid p-0 px-md-2 py-md-4 workspace"
             :class="{ 'bg-white' : IS_IFRAMED }">
            <div class="container p-0 overflow-hidden"
                 :class="{ 'min-vh-100 pb-5' : !IS_IFRAMED }">
                <div class="row">
                    <div class="col-12">
                        <banner-quotation-restore></banner-quotation-restore>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-12" v-if="showBreadcrumbs">
                        <div class="d-none d-md-block">
                            <the-breadcrumbs-commons :breadcrumbs="breadcrumbs"/>
                        </div>
                        
                    </div>
                    <div class="col-12" :class="{ 'col-sm-12 col-md-8 pr-md-3 pr-lg-3 pr-xl-4': isEnabledSidebar }">
                        
                        <!-- Blocco preventivatore -->
                        <div
                             :class="{ 'mt-5' : IS_IFRAMED , 'form-container': false}">
                            <div class="d-flex flex-row align-items-center justify-content-between"  v-if="ux.goBackEnabled || ux.currentPageTitle">
                                <div class="d-flex flex-row align-items-center">
                                    <div class="pl-0 pr-3 py-3 p-md-3" v-if="ux.goBackEnabled">
                                        <i class="form-header-button fa fa-lg fa-fw fa-chevron-left clickable"
                                        @click="onclickBack"></i>
                                    </div>
                                    <h4 class="text-dark-secondary m-0" v-if="ux.currentPageTitle">
                                        {{ux.currentPageTitle}}
                                    </h4>
                                </div>
                                
                                <div class="py-3 pl-3 d-block d-md-none" v-if="isEnabledSidebar">
                                    <i class="form-header-button fa fa-lg fa-fw fa-tasks"
                                       @click="onSummaryToggle"></i>
                                </div>
                            </div>
                            <transition
                                    name="slideLeft"
                                    mode="out-in">
                                <router-view></router-view>
                            </transition>
                        </div>
                        <!-- Fine Blocco preventivatore -->

                    </div>
                    <div class="mobile-summary d-block d-md-none overflow-hidden"
                         :class="{'summary-open' : showSummary}"
                          v-if="isEnabledSidebar">
                        <div class="vw-100 d-flex justify-content-between align-items-center mobile-summary-header border-bottom">
                            <div class="align-self-center">
                                <p class="m-0 text-secondary font-weight-bold px-3">RIEPILOGO POLIZZA</p>
                            </div>
                            <div class="p-3 border-left">
                                <i class="fas fa-times text-primary fa-2x"
                                   @click="onSummaryToggle"></i></div>
                        </div>
                        <div class="p-2 vw-100">
                          <bp-summary/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 pr-0 mr-0 d-none d-md-block pl-md-4 pl-lg-4 pl-xl-4" v-if="isEnabledSidebar">
                        <bp-summary/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BpSummary from './Summary/Summary'
    import UxLoadingStandard from '../_Common/Components/UxLoadingStandard';
    import RouterViewContainer from '../_Common/RouterViewContainer'
    import UxLoadingQuotation from '../_Common/Components/UxLoadingQuotation';
    import {mapGetters, mapState} from 'vuex';
    import BannerAlert from '../_Common/Components/BannerAlert';
    import BannerQuotationRestore from '../_Common/Components/BannerQuotationRestore';
    // Tutte le funzioni comuni di init del preventivatore si trovano qui
    import {BootstrapOperations} from '../_Common/Mixins/CommonInitFunctions.mixin'
    import TheBreadcrumbsCommons from "../_Common/Components/TheBreadcrumbsCommons";


    export default {
        components: {
            BannerAlert,
            BannerQuotationRestore,
            UxLoadingQuotation,
            UxLoadingStandard,
            BpSummary,
            RouterViewContainer,
            TheBreadcrumbsCommons

        },
        mixins: [BootstrapOperations],

        data() {
            return {
                currentProduct: 'bikeplatinum'
            }
        },


        computed: {
            ...mapState(['ux']),
            ...mapGetters([
                'IS_IFRAMED'
            ]),
            isEnabledSidebar(){
                return !this.$route.path.includes('otp') &&
                        !this.$route.path.includes('thank-you') &&
                        !this.$route.path.includes('veicolo') &&
                        !this.$route.path.includes('inizio') &&
                        !this.$route.path.includes('pacchetti') &&
                        !this.$route.path.includes('vinpage') &&
                        !this.$route.path.includes('quotation')
            },
            showBreadcrumbs(){
                return !this.$route.path.includes('otp') &&
                        !this.$route.path.includes('thank-you')
            },
            breadcrumbs() {
                return [
                    {
                        label: 'Preventivo',
                        link: 'veicolo',
                        clickable: true,
                        active: true,
                        icon: 'fa-money-check-dollar'
                    },
                    {
                        label: 'I tuoi dati',
                        link: 'anagrafica/contraente',
                        clickable: this.$route.path.includes('anagrafica') ||  this.$route.path.includes('acquista'),
                        active: this.$route.path.includes('anagrafica') ||  this.$route.path.includes('acquista'),
                        icon: 'fa-file-lines'
                    },
                    {
                        label: 'Acquisto',
                        link: 'acquista',
                        clickable: this.$route.path.includes('acquista'),
                        active: this.$route.path.includes('acquista'),
                        icon: 'fa-basket-shopping'
                    }
                ]
            },
        }
    }


</script>



import GlobalApiService from '../_Common/Services/GlobalQuotation.service';
import MyApiService from './Multimobility.service';
import findKey from 'lodash/findKey';
import cloneDeep from 'lodash/cloneDeep'
import {GaEvents} from "../../Common/AnalyticsMethods";
import SelligentPusher from "../../Common/selligentPusher";

export default {

    state: {
        // Preventivo restituito da getQuotation
        versions:{},
        // Configurazioni del preventivatore
        prices: {
            argento: [
                '150.000€',
                '10.000€',
                '1.000€',
                '-',
                '-',
                '-'
            ],
            oro: [
                '250.000€',
                '10.000€',
                '5.000€',
                '50.000€',
                '-',
                '-'
            ],
            platino: [
                '500.000€',
                '10.000€',
                '10.000€',
                '100.000€',
                '500€',
                '30€'
            ]
        },
        config: {
            breeds: [],
            garanzie: [
                {
                    label: `Responsabilità civile per danni a persone terze`,
                    tooltip: ``
                },
                {
                    label: `Responsabilità civile per danni a cose di terzi`,
                    tooltip: ``
                },
                {
                    label: `Tutela Legale`,
                    tooltip: ``
                },
                {
                    label:  `Infortuni del conducente`,
                    tooltip: ``
                },
                {
                    label: `Annullamento franchigia`,
                    tooltip: `<p class="m-0">Applicata dalla società di sharing<br/> in caso di danno del mezzo</p>`
                },
                {
                    label: `Ritorno a casa`,
                    tooltip: `<p class="m-0">A seguito di danno o<br/> incidente del mezzo</p>`
                }

            ]
        }
    },
    getters: {
        MY_VERSIONS: state => {
            return state.versions;
        },
        MY_VERSION_TYPE: (state, getters, rootState)=> {

        },
        MY_SELECTED_BUNDLE: (state, getters, rootState) => {
            try {

                const versionKey = findKey(state.versions, function (version) {
                    return findKey( version, function (v) {
                        return v.id === rootState.quotation.quotationData.selectedBundleId;
                    })
                });

                const priceKey = findKey(state.versions[versionKey], function (p) {
                        return p.id === rootState.quotation.quotationData.selectedBundleId;
                });

                return state.versions[versionKey][priceKey] ? state.versions[versionKey][priceKey] : false;
            } catch (e) {
                return false;
            }

        },
        MY_VERSIONS_GARANZIE: state => {
            return state.config.garanzie;
        },
        MY_VERSIONS_PRICES: state =>{
            return state.prices;
        }
    },

    actions: {
        // Inizia la quotazione, la chiamata prende automaticamente codice sconto e codiceAffiliazione dallo store e li manda solo se necessari
        MY_GET_QUOTATION({state, commit, rootState}) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                const _requestParams = {
                    ...(rootState.quotation.quotationData.quotationId ? {quotationId: rootState.quotation.quotationData.quotationId} : {}),
                    ...(rootState.quotation.quotationData.discountCode ? {discountCode: rootState.quotation.quotationData.discountCode} : {}),
                    ...(rootState.quotation.quotationData.affiliationCode ? {affiliationCode: rootState.quotation.quotationData.affiliationCode} : {})
                };

                MyApiService.getQuotation(_requestParams).then(res => {

                    commit('UPDATE_QUOTATION_DATA', {
                        discountCode: res.data.discountCode,
                        quotationId: res.data.quotationId,
                        quotationCode: res.data.quotationCode,
                        discountCodeDescription: res.data.discountCodeDescription,
                        affiliationCode: res.data.affiliationCode,
                        isAffiliated: res.data.isAffiliated,
                        selectedBundleId: res.data.tarId,
                        quotationDateTime: res.data.quotationDateTime,
                        quotationExpire: res.data.quotationExpire,
                        quotationConfirmed: false,
                        discountCodeLocked: res.data.discountCodeLocked
                    });


                    if (res.data.discountCode) {
                        commit('UPDATE_QUOTATION_DATA', {discountCodeIsValid: true})
                    }

                    //commit('MY_UPDATE_VERSIONS', _newArr);
                    commit('MY_UPDATE_VERSIONS', res.data.versions);
                    commit('STOP_LOADING');

                    resolve(res);
                }).catch(e => commit('STOP_LOADING'));
            });
        },

        // Salva la quotazione per procedere all'anagrafica
        MY_CONFIRM_QUOTATION({commit}, _data) {

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MyApiService.confirmQuotation(_data).then(res => {
                    GaEvents.quotation('MY');
                    commit('STOP_LOADING');
                    commit('UPDATE_QUOTATION_DATA', {quotationConfirmed: true});
                    resolve();
                }).catch(e => commit('STOP_LOADING'));
            });
        },

        async MY_RESTORE_QUOTATION({state, commit, rootState}, _quotationId) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                MyApiService.getQuotationData(_quotationId).then(res => {
                    // Dalla risposta devo riposizionare i dati ai lOro posti
                    commit('UPDATE_QUOTATION_DATA', {
                        discountCode: res.data.quotationData.discountCode,
                        quotationId: res.data.quotationData.quotationId,
                        quotationCode: res.data.quotationData.quotationCode,
                        discountCodeDescription: res.data.quotationData.discountCodeDescription,
                        affiliationCode: res.data.quotationData.affiliationCode,
                        isAffiliated: res.data.quotationData.isAffiliated,
                        selectedBundleId: res.data.quotationData.tarId,
                        quotationDateTime: res.data.quotationData.quotationDateTime,
                        quotationExpire: res.data.quotationData.quotationExpire
                    });

                    if (res.data.quotationData.discountCode) {
                        commit('UPDATE_QUOTATION_DATA', {discountCodeIsValid: true})
                    }


                    if (res.data.registry) commit('UPDATE_ANAGRAFICA', res.data.registry);
                    if (res.data.registry && res.data.registry.owner) {
                        commit('UPDATE_CUSTOMER_KIND',
                            {
                                kind: 'isOwner',
                                value: false
                            });
                    }
                    commit('UPDATE_ANAGRAFICA',  res.data.registry);


                    commit('MY_UPDATE_VERSIONS', res.data.versions);
                    commit('UPDATE_QUOTATION_DATA', {quotationConfirmed: true});

                    commit('STOP_LOADING');
                    resolve();

                }).catch((e) => {
                    console.error(e);
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        // Invia l'anagrafica pr procedere all'acquisto
        MY_CONFIRM_REGISTRATION({state, commit, rootState}) {
            const _data = {
                // Se l'utente non è anche il proprietario invio anche l'oggetto owner
                quotationId: rootState.quotation.quotationData.quotationId,
                contractor: rootState.anagrafica.customerDetails.contractor,
                ...(!rootState.anagrafica.customerDetails.isOwner ? {owner: rootState.anagrafica.customerDetails.owner} : {}),
                contractorIsOwner: rootState.anagrafica.customerDetails.isOwner
            };

            const _requestParams = cloneDeep(_data);

            if (_requestParams.contractor) {

                _requestParams.contractor.professionId = _requestParams.contractor.professionId.length != 0 ? _requestParams.contractor.professionId : null;
                _requestParams.contractor.maritalStatusId = _requestParams.contractor.maritalStatusId != 0 ? _requestParams.contractor.maritalStatusId : null;
                //delete _requestParams.contractor.isCompany
            }

            if (_requestParams.owner) {
                _requestParams.owner.professionId = _requestParams.owner.professionId.length  != 0 ? _requestParams.owner.professionId : null;
                _requestParams.owner.maritalStatusId = _requestParams.owner.maritalStatusId.length != 0 ? _requestParams.owner.maritalStatusId : null;
               // delete _requestParams.owner.isCompany
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                 MyApiService.confirmRegistration(_requestParams).then(res => {
                    GaEvents.registration('MY');
                    if (res.data.confirmEmail) {
                        commit('SET_EMAIL_CONFIRM', res.data.confirmEmail);
                    }
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((err) => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        // Salva la quotazione per inviare email al cliente
        MY_SAVE_QUOTATION({commit}, _data) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                GlobalApiService.saveQuotation(_data).then(res => {
                    GaEvents.block('MY');
                    try {
                        let pusher = new SelligentPusher()
                        pusher.add('action', 'saveQuotation_multimobility')
                        pusher.push()
                    } catch (e) {

                    }
                    commit('STOP_LOADING');
                    resolve();
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        }
    },

    mutations: {
        MY_UPDATE_VERSIONS(state, payload) {
            // Per poter avere gia lato front end alcune parti delle
            // versioni DD uso merge di lodash per aggiungere e modificare
            // le proprietà nuove senza modificare quelle gia presenti,
            // necessarie per avere label e description in pagina

            state.versions = payload;
        },

        RESET_MULTIMOBILITY_STATE(state) {
            Object.assign(state, _getDefaultState());
        }

    }
};

import merge from 'lodash/merge';

export default {
    state: {
        quotationData: {
            // Pacchetto selezionato dall'utente
            selectedBundleId: false,
            // Garanzie selezionate dall'utente
            selectedAddonsIds: [],
            // Opzioni delle garanzie a opzione multipla selezionate dall'utente
            selectedAddonsOptions: [],
            // ID quotazione, necessario per richiamare la quotazione attuale dall'api
            quotationId: false,
            // ID preventivo
            quotationCode: false,
            // quotationDateTime, ora della richiesta di preventivo
            quotationDateTime: '',
            // Scadenza del proventivo, da impostare come expire del cookie che contiene lo store di vuex, per persistere il preventivo
            quotationExpire: '',
            // Eventuale codice sconto inserito dall'utente
            discountCode: '',
            // Indica se il codice sconto è valido
            discountCodeIsValid: false,
            // Il testo effettivo da presentare all'utente nel summary
            discountCodeDescription: '',
            // E' un dato tornato dal WS dop oaver ricevuto l'urlProd, ci dice se di deve vedere
            // il codice sconto o lo spazio per inserire il numero di tessera della convenzione
            discountCodeLocked: true,
            // Indica se ci sono promozioni accessibili con un codice cliente o tessera per questo urlProd
            isAffiliated: false,
            // Eventuale codicetessera partner inserito dall'utente
            affiliationCode: '',
            // Indica se la quotazione è stata scaricata
            quotationReady: false,
            // Indica se la quotazione è stata confermata
            quotationConfirmed: false,
            // Data di decorrenza
            decorrenza: '',
            // Indica se il preventivo in corso è un preventivo da salvare
            saveQuotation: null
        },

    },
    getters: {
        QUOTATION_DATA: state => {
            return state.quotationData;
        },
    },

    mutations: {
        UPDATE_QUOTATION_DATA(state, payload) {
            merge(state.quotationData, payload)
        },
        RESET_SELECTED_ADDONS_IDS(state){
            state.quotationData.selectedAddonsIds = [];
        },
        RESET_SELECTED_OPTIONS_IDS(state){
            state.quotationData.selectedAddonsOptions = [];
        },
        RESET_QUOTATION_STATE(state) {
            const _state = {
                quotationData: {
                    selectedBundleId: false,
                    selectedBundleOptionId: null,
                    selectedAddonsIds: [],
                    selectedAddonsOptions: [],
                    quotationId: false,
                    quotationCode: false,
                    quotationDateTime: '',
                    quotationExpire: '',
                    discountCode: '',
                    discountCodeIsValid: false,
                    discountCodeDescription: '',
                    discountCodeLocked: true,
                    isAffiliated: false,
                    affiliationCode: '',
                    quotationReady: false,
                    quotationConfirmed: false,
                    decorrenza: '',
                },
            };
            Object.assign(state, _state)
        }
    }
};

import axios from 'axios';
import {API_URL, WEBORDER_API} from "@/Common/constants";

const WebOrderService = {
    /**
     * Crea un nuovo webOrder
     * @param {object} request
     */
    new: async (request) => {
        try {
            return await axios.post(`${WEBORDER_API}/product/v1/newWebOrder`, request)
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
     * Ottiene un webOrder
     * @param {object} request
     */
    get: async (request) => {
        try {
            return await axios.get(`${WEBORDER_API}/product/v1/getWebOrder`, {
                params: {...(request)},

            })
        } catch (e) {

            throw new Error(e);
        }
    },

    /**
     * Ottiene la configurazione di pagamento
     * @param {object} request
     */
    getPaymentConfig: async (request) => {
        try {
            return await axios.get(`${API_URL}/quotationV2/checkoutConfig`,{
                params: {...(request)},
            })
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
     * Aggiorna un webOrder
     * @param {object} request
     */
    set: async (request) => {
        try {
            return await axios.post(`${WEBORDER_API}/product/v1/setWebOrder`, request)
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
     * Conferma un webOrder
     * @param {object} request
     */
    confirm: async (request) => {
        try {
            return await axios.post(`${WEBORDER_API}/product/v1/confirmWebOrder`, request)
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
     * Paga un webOrder
     * @param {object} request
     */
    buy: async (request) => {
        try {
            return await axios.put(`${WEBORDER_API}/product/v1/buyWebOrder`, request)
        } catch (e) {
            throw new Error(e);
        }
    },

    /**
     * Conferma un webOrder
     * @param {object} request
     */
     sendSaveQuotation: async (request) => {
        try {
            return await axios.post(`${WEBORDER_API}/product/v1/sendSaveQuotation`, request)
        } catch (e) {
            throw new Error(e);
        }
    },
    

};

export default WebOrderService;

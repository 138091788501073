<template>

    <div class="d-flex justify-content-between summary-table-row">
        <small class="summary-value-label">
            {{Title}}
            <i v-if="Tooltip"
               v-b-tooltip.hover
               :title="Tooltip"
               class="fas fa-question-circle ml-1"></i>
        </small>
        <small class="summary-value" v-html="Value"></small>
    </div>

</template>

<script>
    import {BTooltip, VBTooltip} from 'bootstrap-vue';

    export default {
        name: "SummaryItem",
        props: ['Title', 'Value', 'Tooltip'],
        directives: {
            'b-tooltip': VBTooltip
        }
    }
</script>



import Vue from 'vue'
import VueRouter from 'vue-router'
import SelligentPusher from "../Common/selligentPusher"


const Snowcare= () => import('../Components/Snowcare')
const SnowcareQuotation= () => import("@/Components/SnowcareQuotation")
const SnowcareRegistry= () => import("@/Components/SnowcareRegistry")
const SnowcareRegistryContractor= () => import("@/Components/SnowcareRegistryCustomer")
const SnowcareRegistryBeneficiary= () => import("@/Components/SnowcareRegistryInsuree")
const SnowcareRegistryGroup= () => import("@/Components/SnowcareRegistryGroup")
const SnowcareCheckout= () => import("@/Components/SnowcareCheckout")
const SnowcareThankyoupage= () => import("@/Components/SnowcareThankyoupage")
const SnowcareQuotationOptions= () => import("@/Components/SnowcareQuotationOptions")
const SnowcareQuotationBundles= () => import("@/Components/SnowcareQuotationBundles")

Vue.use(VueRouter)

/** Scrolls the element into view
 * Manually created since Safari does not support the native one inside an iframe
 */
const scrollElementIntoView = (element, behavior) => {

    let scrollTop = window.scrollY || element.scrollTop

    const finalOffset = element.getBoundingClientRect().top + scrollTop

    window.parent.scrollTo({
        top: finalOffset,
        behavior: behavior || 'auto'
    })
}

const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: Snowcare,
            meta: {
                backButtonEnabled: true
            },
            children:
                [{
                    path: '/quotation',
                    component: SnowcareQuotation,
                    meta: {
                        backButtonEnabled: true
                    },
                    children: [
                        {
                            path: 'options',
                            component: SnowcareQuotationOptions,
                            meta: {
                                backButtonEnabled: false
                            },
                        }, {
                            path: 'bundles',
                            component: SnowcareQuotationBundles,
                            meta: {
                                backButtonEnabled: true
                            },
                        },
                    ]
                },
                    {
                        path: 'registry',
                        component: SnowcareRegistry,
                        meta: {
                            backButtonEnabled: true
                        },
                        children: [
                            {
                                path: 'contractor',
                                component: SnowcareRegistryContractor,
                                meta: {
                                    backButtonEnabled: true
                                },
                                children: []
                            },
                            {
                                path: 'beneficiary',
                                component: SnowcareRegistryBeneficiary,
                                meta: {
                                    backButtonEnabled: true
                                },
                                children: []
                            },
                            {
                                path: 'group',
                                component: SnowcareRegistryGroup,
                                meta: {
                                    backButtonEnabled: true
                                },
                                children: []
                            }
                        ]
                    },
                    {
                        path: 'checkout',
                        component: SnowcareCheckout,
                        meta: {
                            backButtonEnabled: true
                        },
                    },
                    {
                        path: 'thank-you',
                        component: SnowcareThankyoupage,
                        meta: {
                            backButtonEnabled: false
                        },
                    }
                ]
        }
    ],
})


// redirect se punta a root del preventivatore
router.beforeEach((to, from, next) => {
    if (to.path === '/' || to.path === '/quotation') {
        next({
            path: '/quotation/options',
            query: to.query
        })
    } else {
        next()
    }
})


// Verifico che se url diverso da quello iniziale ci sia il weborderid nell'url
router.beforeEach((to, from, next) => {
    if (to.path !== '/quotation/options' && !to.query.weborderid) {
        next('/quotation/options')
    } else {
        next()
    }
})
// Verifico che se url diverso da quello iniziale ci sia il weborderid nell'url
router.beforeEach((to, from, next) => {
    if (to.path !== '/quotation/options' && !to.query.weborderid) {
        next('/quotation/options')
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    if(to.path === from.path) return
    // element which needs to be scrolled to
    const element = document.querySelector("#scrollReference")
// scroll to element
    scrollElementIntoView(element)
})

// Segnalo a selligent ogni cambio di pagina
router.afterEach((to, from) => {
    try {
        let pusher = new SelligentPusher()
        pusher.add('page', window.location.pathname + '/#' + to.fullPath)
        pusher.push()
    } catch (e) {
    }
})

export {router}

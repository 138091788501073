import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueOffline from 'vue-offline';
import store from '../Store'
import ButtonLoadingSpinner from '../Preventivo/_Common/Components/ButtonLoadingSpinner';
import 'iframe-resizer';
import AxiosConfiguration from '../Preventivo/_Common/Services/AxiosConfiguration.service';
import {ModalPlugin, VBPopoverPlugin} from 'bootstrap-vue';
import ArBase from "./ArBase";
import {router as AreaRiservataRouter} from './AreaRiservata.router';
import VuePromiseBtn from 'vue-promise-btn';
import VueNoty from "vuejs-noty";
import {NOTY_CONFIG} from "@/Common/constants";
import * as u from 'umbrellajs';

if (document.getElementById("arContainer")) {
    AxiosConfiguration();


    Vue.use(VBPopoverPlugin);
    Vue.use(ModalPlugin)
    Vue.use(Vuelidate);
    Vue.use(VueNoty, NOTY_CONFIG)
    Vue.use(VueOffline, {
        // Fa si che il mixin non sia
        // iniettato automaticamente in tutti i componenti
        mixin: false
    });
    Vue.use(VuePromiseBtn);

    Vue.component('button-loading-spinner', ButtonLoadingSpinner);

    let app = new Vue({
        el: '#arContainer',
        router: AreaRiservataRouter,
        store,
        template: '<transition name="fade"  mode="out-in" appear><router-view></router-view></transition>',
        components: {ArBase}
    });


}

document.addEventListener("DOMContentLoaded", () => {

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ;

    const arMenuToggle =  document.getElementById('arMenuToggle');
    
    if(!arMenuToggle) return;
    const userName = arMenuToggle.dataset.shortUserName.split(" ");
    const acr = userName.length > 1 ? 
        userName[0].charAt(0).toLocaleUpperCase() + userName[1].charAt(0).toLocaleUpperCase() :
        userName[0].charAt(0).toLocaleUpperCase() + userName[0].charAt(1).toLocaleUpperCase();

    arMenuToggle.innerHTML = acr;

    if(location.pathname + location.hash == "/ar/#/auth/user" || location.pathname == "/" ){
        u('#btn-logged-user').addClass('login-btn-animation');
        
    } else {
        u('#btn-logged-user') ?? u('#btn-logged-user').removeClass('login-btn-animation');
    }
    
});















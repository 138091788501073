import ArApiService from "../../AreaRiservata/AreaRiservata.service"
import merge from 'lodash/merge';

const _getDefaultState = () => {
    return {
        actions: {
            policyId: null,
            policy: {
                policyData: {
                    contractorName: null,
                    contractorSurname: null,
                    type: null,
                    insuredEntity: null,
                    effectiveDate: null,
                    expiringDate: null,
                    policyNumber: null,
                    policyContractor: null,
                    motoplatinumData: {
                        plate: null,
                        bundleName: null,
                        withBox: null,
                        addons: [],
                        annualPremium: null
                    }
                },
                availableActions: {
                    vehicleChange: null,
                    ownerChange: null,
                    rcRefund: null,
                    lostPlate: null,
                    suspendPolicy: null,
                    reactivatePolicy: null,
                    addGuarantees: null,
                    rightToReconsider: null
                }
            },
            actionData: {
                refundBeneficiary: null,
                refundIban: null,
                refundDate: null,
                refundType: null,
                actionOperationId: null,
                lostPlateDate: null,
                suspendDate: null,
                reactivateDate: null,
                agentBoxActionIntegration: null,
            },
            userActionData: {
                updatedPhone: null,
                updatedEmail: null,
                addressToCheckout: true

            },
            pendingOperationsData: []
        },
        salesData: {
            data: []
        },
        partnerWarehouse: null
    }
}

export default {
    state: _getDefaultState(),

    getters: {
        AR_POLICY: state => {
            return state.actions.policy.policyData;
        },
        AR_POLICY_ID: state => {
            return state.actions.policyId;
        },
        AR_POLICY_DATA: state => {
            return state.actions.policy;
        },
        AR_ACTION_DATA: state => {
            return state.actions.actionData;
        },
        AR_PENDING_OPERATIONS: state => {
            return state.actions.pendingOperationsData;
        },
        AR_SALES_DATA: state => {
            return state.salesData;
        },
        AR_PARTNER_WAREHOUSE_DATA: state => {
            return state.partnerWarehouse;
        },

    },

    actions: {
        // Ottiene tutti i dati della polizza la cui modifica è in corso
        AR_GET_POLICY_DATA({commit, state}, _policyId) {

            const _data = {
                PolicyId: _policyId
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.getPolicyData(_data).then(res => {
                    commit('AR_UPDATE_POLICY', res.data)
                    commit('AR_UPDATE_POLICY_ID', _policyId)
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_RC_REFUND({commit, state}) {

            const _data = {
                policyId: state.actions.policyId,
                date: state.actions.actionData.refundDate,
                type: state.actions.actionData.refundType,
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.rcRefund(_data).then(res => {
                    commit('AR_UPDATE_ACTION_DATA', {
                        refundBeneficiary: res.data.beneficiary,
                        refundIban: res.data.iban,
                    })
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_RC_REFUND_IBAN({commit, state}) {

            const _data = {
                policyId: state.actions.policyId,
                iban: state.actions.actionData.refundIban,
                date: state.actions.actionData.refundDate,
                type: state.actions.actionData.refundType,
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.rcRefundIban(_data).then(res => {
                    commit('AR_UPDATE_ACTION_DATA', {
                        actionOperationId: res.data.quotationId,
                        documentsAction: res.data.action,
                    })
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_RC_REFUND_CONFIRM({commit, state}) {

            const _data = {
                quotationId: state.actions.actionData.actionOperationId
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.rcRefundConfirm(_data).then(res => {
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_POST_LOST_PLATE({commit, state}, _config) {

            const _data = {
                policyId: state.actions.policyId,
                plate: _config.plate,
                date: _config.date,
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.lostPlate(_data).then(res => {
                    commit('AR_UPDATE_ACTION_DATA', {
                        actionOperationId: res.data.quotationId,
                        documentsAction: res.data.action,
                    })
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },


        AR_SUSPEND_POLICY({commit, state}, _date) {

            const _data = {
                policyId: state.actions.policyId,
                date: _date
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.suspendPolicy(_data).then(res => {
                    commit('AR_UPDATE_ACTION_DATA', {
                        actionOperationId: res.data.operationId,
                        documentsAction: res.data.action,
                        suspendDate: _date
                    });

                    commit('STOP_LOADING')
                    resolve(res);
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_SUSPEND_POLICY_CONFIRM({commit, state}) {

            const _data = {
                policyId: state.actions.policyId,
                date: state.actions.actionData.suspendDate
            }


            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.suspendPolicyConfirm(_data).then(res => {
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_REACTIVATE_POLICY({commit, state}, _date) {

            const _data = {
                policyId: state.actions.policyId,
                date: _date
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.reactivatePolicy(_data).then(res => {
                    commit('AR_UPDATE_ACTION_DATA', {
                        actionOperationId: res.data.operationId,
                        documentsAction: res.data.action,
                        reactivateDate: _date
                    })
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    commit('STOP_LOADING');
                    reject(e);
                });
            });
        },

        AR_CONFIRM_ACTION({commit, state}) {

            const _data = {
                IdOldPolicy: state.actions.policyId,
                QuotationId: state.actions.actionData.actionOperationId
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.confirmAction(_data).then(res => {
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    commit('STOP_LOADING')
                    reject(e);
                });
            });
        },

        AR_AGENT_BOX_AFTERTOUGHT({commit, state}, _date) {

            const _data = {
                policyId: state.actions.policyId,
                date: _date
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.afterthoughtOnlyBox(_data).then(res => {

                    commit('AR_UPDATE_ACTION_DATA', {
                        actionOperationId: res.data.quotationId,
                        documentsAction: res.data.action,
                        agentBoxActionIntegration: res.data.integration
                    })
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    commit('STOP_LOADING');
                    reject(e);
                });
            });
        },

        AR_PARTNER_WAREHOUSE({commit, state, rootState}){
            const _data = {
                QuotationId: rootState.quotation.quotationData.quotationId
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.checkPartnerWarehouse(_data).then(res => {
                    commit('$AR_UPDATE_PARTNER_WAREHOUSE', res.data )
                    commit('STOP_LOADING')
                    resolve( res);
                }).catch(e => {
                    commit('STOP_LOADING');
                    reject(e);
                });
            });

        },

        // Ottiene tutti i dati della polizza la cui modifica è in corso
        AR_GET_FORM_CONFIG({commit}) {

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.getFormConfig().then(res => {
                    commit('UPDATE_FORM_FIELDS', res.data.forms);
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        // Ottiene tutti i dati dell'utente per l'AR
        AR_GET_USER_DATA({commit, state}, _policyId) {

            const _data = {
                policyId: _policyId
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.getRegistryUserData(_data).then(res => {
                    commit('UPDATE_ANAGRAFICA_USER', res.data)
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        // Modifica i dati dell'utente
        AR_POST_USER_DATA({commit, state, rootState}, _address) {
            const _data = {
                user: {...(rootState.anagrafica.customerDetails.user)}
            }
            if (_address) _data.user.address = _address
            _data.user.consense1 = true

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.updatePersonalData(_data).then(res => {

                    commit('UPDATE_ANAGRAFICA_USER', res.data)
                    if (res.data.quotationId) {
                        commit('AR_UPDATE_ACTION_DATA', {
                            actionOperationId: res.data.quotationId,
                            documentsAction: res.data.action,
                        })
                    }

                    commit('AR_UPDATE_USER_ACTION_DATA', {addressToCheckout: res.data.hasToCheckOut})

                    commit('STOP_LOADING')
                    resolve(res.data);
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_POST_EMAIL({commit, state, rootState}, _email) {

            const _data = {
                email: _email
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.updateEmail(_data).then(res => {
                    commit('AR_UPDATE_USER_ACTION_DATA', {updatedEmail: _email})
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_POST_EMAIL_OTP({commit, dispatch, state, rootState}, _otp) {

            const _data = {
                otp: _otp,
                email: state.actions.userActionData.updatedEmail

            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.updateEmailOtp(_data).then(res => {

                    dispatch('AR_GET_USER_DATA').then(() => {
                        commit('STOP_LOADING')
                        resolve();
                    })
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_POST_PHONE({commit, state, rootState}, _phone) {

            const _data = {
                phone: _phone
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.updatePhone(_data).then(res => {
                    commit('AR_UPDATE_USER_ACTION_DATA', {updatedPhone: _phone})
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_POST_PHONE_OTP({commit, state, rootState}, _otp) {

            const _data = {
                otp: _otp,
                phone: state.actions.userActionData.updatedPhone
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.updatePhoneOtp(_data).then(res => {
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_POST_NEW_PASSWORD({commit, state, rootState}, _data) {

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.updatePassword(_data).then(res => {
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        AR_GET_PENDING_OPERATIONS({commit, state, rootState}, _data) {

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                ArApiService.getPendingOperations(_data).then(res => {
                    commit('AR_UPDATE_PENDING_OPERATIONS', res.data.details)
                    commit('STOP_LOADING')
                    resolve();
                }).catch(e => {
                    reject(e);
                    commit('STOP_LOADING')
                });
            });
        },

        async AR_GET_SALESDATA({commit, state, rootState}, _data) {
            // Si è diverso ma a tendere usiamo questo stile
            // per gestire le promise, è più pulito e facile da leggere
            commit('START_LOADING')
            let response
            try {
                response = await ArApiService.getSalesData(_data)
                commit('AR_UPDATE_SALESDATA', {data: response.data})
                commit('STOP_LOADING')
                return response.data
            } catch (e) {
                commit('STOP_LOADING')
                return e
            }
        },
        async AR_ADD_SALESDATA({commit, state, rootState}, _data) {
            commit('START_LOADING')
            let response
            try {
                response = await ArApiService.addSalesData(_data)
                commit('STOP_LOADING')
                return response.data
            } catch (e) {
                commit('STOP_LOADING')
                return e
            }
        },

        async AR_EDIT_SALESDATA({commit, state, rootState}, _data) {
            commit('START_LOADING')
            let response
            try {
                response = await ArApiService.editSalesData(_data)
                commit('STOP_LOADING')
                return response.data
            } catch (e) {
                commit('STOP_LOADING')
                return e
            }
        },

        async AR_DELETE_SALESDATA({commit, state, rootState}, _data) {
            commit('START_LOADING')
            let response
            try {
                response = await ArApiService.deleteSalesData(_data)
                commit('STOP_LOADING')
                return response.data
            } catch (e) {
                commit('STOP_LOADING')
                return e
            }
        },
    },

    mutations: {
        AR_UPDATE_POLICY_ID(state, payload) {
            state.actions.policyId = payload;
        },

        AR_UPDATE_POLICY(state, payload) {
            state.actions.policy = payload;
        },

        AR_UPDATE_SALESDATA(state, payload) {
            state.salesData = payload;
        },

        AR_UPDATE_ACTION_DATA(state, payload) {
            state.actions.actionData = merge(state.actions.actionData, payload);
        },
        AR_UPDATE_USER_ACTION_DATA(state, payload) {
            state.actions.userActionData = merge(state.actions.userActionData, payload);
        },

        AR_UPDATE_PENDING_OPERATIONS(state, payload) {
            state.actions.pendingOperationsData = payload;
        },
        $AR_UPDATE_PARTNER_WAREHOUSE(state, payload) {
            state.partnerWarehouse = payload;
        },
        RESET_AR_ACTION_STATE(state) {
            Object.assign(state.actions.actionData, _getDefaultState().actions.actionData);
        },

        RESET_AR_USER_STATE(state) {
            Object.assign(state.actions.userActionData, _getDefaultState().actions.userActionData);
        },

        RESET_AR_STATE(state) {
            Object.assign(state, _getDefaultState());
        }
    }
}

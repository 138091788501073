export const TABLE_ITA_SILVER = ` <div class="pt-3">
        <div class="d-flex justify-content-between pb-2">
            <small class="text-secondary font-weight-600">GARANZIE</small>
            <small class="text-secondary font-weight-600">LIMITI</small>
        </div>

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Responsabilità civile per danni a <br/> persone e cose di terze</small>
            
            <small class="font-weight-600 text-right">
            150.000€ danni a persone <br/>
            10.000€ danni a cose
            </small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Tutela legale</small>
            <small class="font-weight-600">1.000€</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Spese mediche d’urgenza</small>
            <small class="font-weight-600">500€</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Autista a disposizione</small>
            <small class="font-weight-600">Costo intero</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Soccorso sulle piste italiane</small>
            <small class="font-weight-600">-</small>
        </div>
        <hr class="my-1 py-1">
        
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Soccorso sulle piste straniere</small>

                <small class="font-weight-600 text-right">
                400€ in pista <br/>
                5.000€ in elicottero</small>
           
        </div>
        
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Annullamento skipass non goduto per infortunio,<br/> malattia o quarantena*</small>
            <small class="font-weight-600">Quota non<br/> utilizzata</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Annullamento lezione e/o noleggio materiale sci<br/> per infortunio*</small>
            <small class="font-weight-600">100€/<br/>giorno</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rientro sanitario*</small>
            <small class="font-weight-600">5.000€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-400">*Valida solo con skipass nominativi o con foto</small>
            <small class="font-weight-400"></small>
        </div>
        
    </div>`


export const TABLE_ITA_GOLD = ` <div class="pt-3">
        <div class="d-flex justify-content-between pb-2">
            <small class="text-secondary font-weight-600">GARANZIE</small>
            <small class="text-secondary font-weight-600">LIMITI</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between  pb-2">
            <small class="font-weight-600">Responsabilità civile per danni a <br/> persone e cose di terze</small>
                <small class="font-weight-600">150.000€ danni a persone<br/>
                10.000€ danni a cose
                </small>
          
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Tutela legale</small>
            <small class="font-weight-600">1.000€</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Spese mediche d’urgenza</small>
            <small class="font-weight-600">500€</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Autista a disposizione</small>
            <small class="font-weight-600">Costo intero</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Soccorso sulle piste italiane</small>
            <small class="font-weight-600 text-right">200€ in pista<br/>5.000€ in elicottero</small>       
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Soccorso sulle piste straniere</small>
            <small class="font-weight-600 text-right">400€ in pista<br/>5.000€ in elicottero</small>     
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Annullamento skipass non goduto per infortunio,<br/> malattia o quarantena*</small>
            <small class="font-weight-600">Quota non<br/> utilizzata</small>
        </div>
        <hr class="my-1 py-1">


        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Annullamento lezione e/o noleggio materiale sci<br/> per infortunio*</small>
            <small class="font-weight-600">100€/<br/>giorno</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rientro sanitario*</small>
            <small class="font-weight-600">5.000€</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-400">*Valida solo con skipass nominativi o con foto</small>
            <small class="font-weight-400"></small>
        </div>
        <hr class="my-1 py-1">

    </div>`
export const TABLE_EN_SILVER = `  <div class="pt-3">
        <div class="d-flex justify-content-between pb-2">
            <small class="text-secondary font-weight-600">INSURANCE COVERAGE</small>
            <small class="text-secondary font-weight-600">LIMITS</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between  pb-2">
            <small class="font-weight-600">Third-party liability for personal injury<br/>and property damage</small>
                <small class="font-weight-600 text-right">150.000€ personal injury<br/>10.000€ property damage</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Legal protection</small>
            <small class="font-weight-600">1.000€</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Emergency medical expenses</small>
            <small class="font-weight-600">500€</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Private chauffeur service</small>
            <small class="font-weight-600">Full price</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">First aid on Italian slopes</small>
            <small class="font-weight-600">-</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">First aid on non-Italian slopes</small>
            <small class="font-weight-600 text-right">€400 on slopes<br/>€5,000 helicopter transportation</small>    
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Cancellation of skipass not used due to
            <br/>  accident, illness or quarantine*</small>
            <small class="font-weight-600">Unused part</small>
        </div>
        <hr class="my-1 py-1">


        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Cancellation of skiing lessons and/or 
            <br/> ski equipment hire due to accident*</small>
            <small class="font-weight-600">€100/<br/>day</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Medical repatriation*</small>
            <small class="font-weight-600">5.000€</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-400">*Valid only with skipass bearing user's name or photograph</small>
            <small class="font-weight-400"></small>
        </div>
    </div>`
export const TABLE_EN_GOLD = ` <div class="pt-3">
        <div class="d-flex justify-content-between pb-2">
            <small class="text-secondary font-weight-600">INSURANCE COVERAGE</small>
            <small class="text-secondary font-weight-600">LIMITS</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Third-party liability for personal injury<br/>and property damage</small>
                <small class="font-weight-600 text-right">150.000€ personal injury<br/>10.000€ property damage</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Legal protection</small>
            <small class="font-weight-600">1.000€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Emergency medical expenses</small>
            <small class="font-weight-600">500€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Private chauffeur service</small>
            <small class="font-weight-600">Full price</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">First aid on Italian slopes</small>
                <small class="font-weight-600 text-right">€200 on slopes<br/>€1.000 helicopter transportation</small>       
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">First aid on non-Italian slopes</small>
            <small class="font-weight-600 text-right">€400 on slopes<br/>€5,000 helicopter transportation</small>     
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Cancellation of skipass not used due to
            <br/>  accident, illness or quarantine*</small>
            <small class="font-weight-600">Unused part</small>
        </div>
        <hr class="my-1 py-1">

        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Cancellation of skiing lessons and/or 
            <br/> ski equipment hire due to accident*</small>
            <small class="font-weight-600">€100/<br/>day</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Medical repatriation*</small>
            <small class="font-weight-600">5.000€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-400">*Valid only with skipass bearing user's name or photograph</small>
            <small class="font-weight-400"></small>
        </div>
    </div>`
export const TABLE_DE_SILVER = ` <div class="pt-3">
        <div class="d-flex justify-content-between pb-2">
            <small class="text-secondary font-weight-600">VERSICHERUNGSSCHUTZ</small>
            <small class="text-secondary font-weight-600">MAXIMALE DECKUNGSSUMME</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between  pb-2">
            <small class="font-weight-600">Haftpflichtversicherung für Personen-<br/>und Sachschäden</small>
                <small class="font-weight-600 text-right">150.000€ Personenschäden<br/>10.000€ Sachschäden</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rechtsschutz</small>
            <small class="font-weight-600">1.000€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Notarztkosten</small>
            <small class="font-weight-600">500€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Fahrer steht zu Verfügung</small>
            <small class="font-weight-600">Gesamte Kosten</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rettung auf italienischen Pisten</small>
            <small class="font-weight-600">-</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rettung auf ausländischen Pisten</small>
                <small class="font-weight-600 text-right">400€ auf der Piste<br/>5.000€ im Hubschrauber</small>       
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rückerstattung eines nicht benutzten Skipasses aufgrund
            <br/>  von Unfall, Krankheit oder Quarantäne*</small>
            <small class="font-weight-600">Nicht genutzter Anteil</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Stornierung von Unterricht und/oder Verleih von
            <br/> Skiausrüstung aufgrund eines Unfalls*</small>
            <small class="font-weight-600">100€/<br/>Tag</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Patientenrücktransport*</small>
            <small class="font-weight-600">5.000€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-400">*Gültig nur in Verbindung mit einem Skipass mit Namen oder Foto</small>
            <small class="font-weight-400"></small>
        </div>
    </div>`
export const TABLE_DE_GOLD = `<div class="pt-3">
        <div class="d-flex justify-content-between pb-2">
            <small class="text-secondary font-weight-600">VERSICHERUNGSSCHUTZ</small>
            <small class="text-secondary font-weight-600">MAXIMALE DECKUNGSSUMME</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between  pb-2">
            <small class="font-weight-600">Haftpflichtversicherung für Personen-<br/>und Sachschäden</small>
            <div class="d-flex flex-column justify-content-end">
                <small class="font-weight-600">150.000€ Personenschäden</small>
                <small class="font-weight-600">10.000€ Sachschäden</small>
            </div>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rechtsschutz</small>
            <small class="font-weight-600">1.000€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Notarztkosten</small>
            <small class="font-weight-600">500€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Fahrer steht zu Verfügung</small>
            <small class="font-weight-600">Gesamte Kosten</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rettung auf italienischen Pisten</small>
                <small class="font-weight-600 text-right">200€ auf der Piste<br/>5.000€ im Hubschrauber</small>      
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rettung auf ausländischen Pisten</small>
            <small class="font-weight-600 text-right">400€ auf der Piste<br/>5.000€ im Hubschrauber</small>       
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Rückerstattung eines nicht benutzten Skipasses aufgrund
            <br/>  von Unfall, Krankheit oder Quarantäne*</small>
            <small class="font-weight-600">Nicht genutzter<br/>Anteil</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Stornierung von Unterricht und/oder Verleih von
            <br/> Skiausrüstung aufgrund eines Unfalls*</small>
            <small class="font-weight-600">€100/<br/>Tag</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-600">Patientenrücktransport*</small>
            <small class="font-weight-600">5.000€</small>
        </div>
        <hr class="my-1 py-1">
        <div class="d-flex justify-content-between pb-2">
            <small class="font-weight-400">*Gültig nur in Verbindung mit einem Skipass mit Namen oder Foto</small>
            <small class="font-weight-400"></small>
        </div>
    </div>`

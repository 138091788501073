import BpApiService from './BikeplatinumQuotation.service';
import * as moment from 'moment';
import merge from "lodash/merge";
import find from "lodash/find";
import {GaEvents} from "../../Common/AnalyticsMethods";
import GlobalApiService from "../_Common/Services/GlobalQuotation.service";
import SelligentPusher from "../../Common/selligentPusher";

const objectToQueryString = (obj, parentKey = '') => {
    const parts = [];
  
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
  
        if (value !== null && typeof value === 'object') {
          // Ricorsivamente elabora oggetti nidificati
          parts.push(objectToQueryString(value, fullKey));
        } else if (value !== undefined) {
          // Aggiunge solo se il valore è definito
          parts.push(`${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`);
        }
      }
    }
  
    return parts.join('&');
}

const initialSate = {
    //Dati della bici da assicurare (Step 2)
    vehicleData: {
        desmar: null,
        codmar: null,
        desmod: null,
        codmod: null,
        desall: null,
        codall: null,
        prgall: null,
        isEBike: true,
        insuredValue: null,
        isUsed: false,
        buyDate: null,
        vehicleFirstBuyYear: null,
        ownerData: {
            ownerProvince: null
        },

    },
    vin: null,
    insuranceData: {},
    totalAmount: 0,
    // Preventivo restituito da getQuotation
    bundles: [],
    config: {},
    guaranteeList: null,
    vehicleDataValues: {
        brands: [],
        models: [],
        versions: [],
    },
    provinces: []
}



export default {

    state: initialSate,
    getters: {
        BP_BUNDLES: state => {
            return state.bundles;
        },
        BP_SELECTED_BUNDLE: (state, getters, rootState) => {
            // Ritorna l'oggetto del pacchetto selezionato dall'utente
            console.log('state.bundles',state.bundles);
            console.log('', rootState.quotation.quotationData)
            return find(state.bundles, a => {
                return a.id === rootState.quotation.quotationData.selectedBundleId
            })
        },
        BP_BIKE_DATA: state => {
            return state.vehicleData
        },
        BP_VEHICLE_DATA_VALUES: state => {
            return state.vehicleDataValues;
        },
    },

    actions: {
        //BP_GET_VEHICLE_DATA
        BP_START_QUOTATION({state, commit},) {

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                const _request = {
                    vehicleData: {
                      codall: state.vehicleData.codall,
                      prgall: state.vehicleData.prgall,
                      insuredValue: state.vehicleData.insuredValue,
                      isUsed: state.vehicleData.isUsed,
                      buyDate: state.vehicleData.buyDate,
                      vehicleFirstBuyYear: state.vehicleData.vehicleFirstBuyYear
                    },
                    ownerData: {
                      ownerProvince: state.vehicleData.ownerData.ownerProvince
                    },
                    
                  }
          
                BpApiService.startQuotation(_request).then(res => {
                    res.data.insuranceData.purchaseDate = moment(res.data.insuranceData.purchaseDate).format('DD/MM/YYYY');
                    res.data.vehicleData.vehicleFirstBuyYear = moment.utc(res.data.vehicleData.vehicleFirstBuyYear ).format('YYYY');
                    const _vehicleData = {
                        ...(res.data.vehicleData),
                    };
                    state.insuranceData = res.data.insuranceData,
                    state.ownerData = res.data.ownerData,
                    state.totalAmount = res.data.totalAmount,

                    commit('BP_UPDATE_BIKE_DATA', _vehicleData);

                    commit('UPDATE_QUOTATION_DATA', {quotationId: res.data.quotationId});
                    commit('STOP_LOADING');
                    resolve();

                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        // Salva la quotazione per procedere all'anagrafica
        BP_CONFIRM_QUOTATION({commit}, _data) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                BpApiService.confirmQuotation(_data).then(res => {
                    GaEvents.quotation('BP');
                    commit('STOP_LOADING');
                    commit('UPDATE_QUOTATION_DATA', {quotationReady: true});
                    resolve();
                }).catch(e => commit('STOP_LOADING'));
            });
        },

        // Invia l'anagrafica pr procedere all'acquisto
        BP_CONFIRM_REGISTRATION({state, commit, rootState}) {
            const _requestParams = {
                // Se l'utente non è anche il proprietario invio anche l'oggetto owner stessa cosa per l'oggetto utilizer
                quotationId: rootState.quotation.quotationData.quotationId,
                contractor: rootState.anagrafica.customerDetails.contractor,
                ...(!rootState.anagrafica.customerDetails.isOwner ? {owner: rootState.anagrafica.customerDetails.owner} : {}),
                ...(!rootState.anagrafica.customerDetails.isUtilizer ? {utilizer: rootState.anagrafica.customerDetails.utilizer} : {}),
                contractorIsOwner: rootState.anagrafica.customerDetails.isOwner,
                contractorIsUtilizer: rootState.anagrafica.customerDetails.isUtilizer
            };

            if (_requestParams.owner) {
                delete _requestParams.owner.isCompany
            }

            if (_requestParams.utilizer) {
                delete _requestParams.utilizer.isCompany
            }

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                BpApiService.confirmRegistration(_requestParams).then(res => {
                    GaEvents.registration('BP');
                    if (res.data.confirmEmail) {
                        commit('SET_EMAIL_CONFIRM', res.data.confirmEmail);
                    }
                    commit('STOP_LOADING');
                    resolve(res);
                }).catch((err) => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        // Richiede la quotazione, la chiamata prende automaticamente codice sconto e codiceAffiliazione dallo store e li manda solo se necessari
        BP_GET_QUOTATION({state, commit, rootState}) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');

                const _requestParams = {
                    quotationId: rootState.quotation.quotationData.quotationId,
                    bikeValue: 1000,
                    ...(rootState.quotation.quotationData.discountCode ? {discountCode: rootState.quotation.quotationData.discountCode} : {}),
                    ...(rootState.quotation.quotationData.affiliationCode ? {affiliationCode: rootState.quotation.quotationData.affiliationCode} : {}),
                    ...(state.guaranteeList ? {guaranteeList: state.guaranteeList} : {})
                    
                };
               
                BpApiService.getQuotation(_requestParams).then(res => {

                    res.data.insuranceData.purchaseDate = moment(res.data.insuranceData.purchaseDate).format('DD/MM/YYYY');
                    res.data.vehicleData.vehicleFirstBuyYear = moment.utc(res.data.vehicleData.vehicleFirstBuyYear ).format('YYYY');
                    const _vehicleData = {
                        ...(res.data.vehicleData),
                    };
                    state.insuranceData = res.data.insuranceData,
                    state.ownerData = res.data.ownerData,
                    state.totalAmount = res.data.totalAmount,

                    commit('BP_UPDATE_BIKE_DATA', _vehicleData);

                    commit('UPDATE_QUOTATION_DATA', {
                        discountCode: res.data.discountCode,
                        quotationId: res.data.quotationId,
                        quotationCode: res.data.quotationCode,
                        discountCodeDescription: res.data.discountCodeDescription,
                        affiliationCode: res.data.affiliationCode,
                        isAffiliated: res.data.isAffiliated,
                        selectedBundleId: res.data.tarId,
                        quotationDateTime: res.data.quotationDateTime,
                        quotationExpire: res.data.quotationExpire,
                        quotationConfirmed: true,
                        discountCodeLocked: res.data.discountCodeLocked
                    });

                    commit('BP_UPDATE_BUNDLES', res.data.tariffVersions || {});

                    commit('STOP_LOADING');
                    resolve(res);
                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

        // Salva la quotazione per inviare email al cliente
        BP_SAVE_QUOTATION({commit}, _data) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                GlobalApiService.saveQuotation(_data).then(res => {
                    GaEvents.block('BP');
                    try {
                        let pusher = new SelligentPusher()
                        pusher.add('action', 'saveQuotation_bikeplatinum')
                        pusher.push()
                    } catch (e) {

                    }
                    commit('STOP_LOADING');
                    resolve();
                }).catch(() => {
                    reject();
                    commit('STOP_LOADING');
                });
            });
        },

        async BP_RESTORE_QUOTATION({state, commit, rootState}, _quotationId) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                BpApiService.getQuotationData(_quotationId).then(res => {

                    // Dalla risposta devo riposizionare i dati ai lOro posti
                    commit('UPDATE_QUOTATION_DATA', {
                        discountCode: res.data.quotationData.discountCode,
                        quotationId: res.data.quotationData.quotationId,
                        quotationCode: res.data.quotationData.quotationCode,
                        discountCodeDescription: res.data.quotationData.discountCodeDescription,
                        affiliationCode: res.data.quotationData.affiliationCode,
                        isAffiliated: res.data.quotationData.isAffiliated,
                        selectedBundleId: res.data.quotationData.tarId,
                        quotationDateTime: res.data.quotationData.quotationDateTime,
                        quotationExpire: res.data.quotationData.quotationExpire
                    });

                    commit('BP_UPDATE_BUNDLES', res.data.tariffVersions);

                    if (res.data.quotationData.discountCode) {
                        commit('UPDATE_QUOTATION_DATA', {discountCodeIsValid: true})
                    }
                    res.data.insuranceData.purchaseDate = moment(res.data.insuranceData.purchaseDate).format('DD/MM/YYYY');
                    
                    const _vehicleData = {
                        ...(res.data.vehicleData),
                    };
                    state.insuranceData = res.data.insuranceData,
                    state.ownerData = res.data.ownerData,
                    state.totalAmount = res.data.totalAmount,

                    commit('BP_UPDATE_BIKE_DATA', _vehicleData);

                    if (res.data.registry) commit('UPDATE_ANAGRAFICA', res.data.registry);

                    if (res.data.registry && res.data.registry.owner) {
                        commit('UPDATE_CUSTOMER_KIND',
                            {
                                kind: 'isOwner',
                                value: false
                            });
                    }

                    if (res.data.registry && res.data.registry.utilizer) {
                        commit('UPDATE_CUSTOMER_KIND',
                            {
                                kind: 'isUtilizer',
                                value: false
                            });
                    }

                    commit('STOP_LOADING');
                    resolve();

                }).catch((e) => {
                    console.error(e);
                    commit('STOP_LOADING');
                    reject();
                });

            });
        },

        
        BP_GET_BRANDS_EBIKE({state, commit, rootState}) {
            return new Promise((resolve, reject) => {
                commit('BRANDS_LOADING', true);

                BpApiService.getBrandsEbike().then(res => {
                    commit('BP_UPDATE_VEHICLEDATA_BRANDS', res.data);

                    commit('BRANDS_LOADING', false);
                    resolve(res);
                }).catch(e => {
                    commit('BRANDS_LOADING', false);
                    reject();
                });
            });
        },

        BP_GET_MODELS_EBIKE({state, commit, rootState}) {
            return new Promise((resolve, reject) => {
                commit('MODELS_LOADING', true);

                const _query = {
                    CodMar: state.vehicleData.codmar
                };

                BpApiService.getModelsEbike(_query).then(res => {
                    commit('BP_UPDATE_VEHICLEDATA_MODELS', res.data);

                    commit('MODELS_LOADING', false);
                    resolve(res);
                }).catch(e => {
                    commit('MODELS_LOADING', false);
                    reject();
                });
            });
        },

        BP_GET_VERSIONS_EBIKE({state, commit, rootState} ) {
            return new Promise((resolve, reject) => {
                commit('VERSIONS_LOADING', true);
                const _query = {
                    CodMar: state.vehicleData.codmar,
                    codMod: state.vehicleData.codmod
                };
                BpApiService.getVersionsEbike(_query).then(res => {

                    commit('BP_UPDATE_VEHICLEDATA_VERSIONS', res.data);

                    commit('VERSIONS_LOADING', false);
                    resolve(res);
                }).catch(e => {
                    commit('VERSIONS_LOADING', false);
                    reject();
                });
            });
        },
        BP_GET_VEHICLES_DETAIL_EBIKE({state, commit, rootState} ) {
            return new Promise((resolve, reject) => {
                commit('VEHICLE_DETAIL_LOADING', true);
                const _query = {
                    CodAll: state.vehicleData.codall,
                    PrgAll: state.vehicleData.prgall
                };

                BpApiService.getVehicleDetailEbike(_query).then(res => {
                    state.vehicleData.insuredValue = res.data.insuredNominalValue

                    commit('BP_UPDATE_BIKE_DATA', _vehicleData);

                    commit('VEHICLE_DETAIL_LOADING', false);
                    resolve(res);
                }).catch(e => {
                    commit('VEHICLE_DETAIL_LOADING', false);
                    reject();
                });
            });
        },
        BP_GET_PROVINCES({state, commit, rootState} ) {
            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                BpApiService.getProvinces().then(res => {
                    commit('BP_UPDATE_PROVINCES', res.data);

                    commit('STOP_LOADING');
                    resolve(res);
                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },
        BP_SET_VIN({state, commit, rootState}, _vin ) {
            const _requestParams = {
                quotationId: rootState.quotation.quotationData.quotationId,
                vin: _vin
            };

            return new Promise((resolve, reject) => {
                commit('START_LOADING');
                BpApiService.setVin(_requestParams).then(res => {

                    commit('STOP_LOADING');
                    resolve(res);
                }).catch(e => {
                    commit('STOP_LOADING');
                    reject();
                });
            });
        },

    },

    mutations: {
        BP_UPDATE_BUNDLES(state, payload) {
            state.bundles = payload;
        },
        BP_UPDATE_BIKE_DATA(state, payload) {
            state.vehicleData = merge(state.vehicleData, payload);
        },
        RESET_BIKEPLATINUM_STATE(state){
            const _state = {
                //Dati della bici da assicurare (Step 2)
                vehicleData: {
                    // Id cicloregistro inserito dall'utente
                    cicloId: '',
                    // I successivi dati sono tutto quello che torna l'api
                    brandId: '',
                    brandName: '',
                    modelName: '',
                    isEbike: false,
                    kind: '',
                    frameDescription: '',
                    frameSerial: '',
                    productionYearFrom: '',
                    productionYearTo: '',
                    pictureUrl: '',
                    purchaseDate: '',
                    insuranceData: {
                        cu: '',
                        bikeValue: '',
                        purchaseDate: ''
                    },
                    ownerData: {
                        name: '',
                        surname: '',
                        bornDate: '',
                        address: {
                            provinceShortName: ''
                        }
                    }

                },
               
                insuranceData: {},
                // Preventivo restituito da getQuotation
                bundles: [],
                config: {},
                vehicleDataValues: {
                    brands: [],
                    models: [],
                    versions: [],
                }
            };
            Object.assign(state, initialSate);
        },
        BP_UPDATE_QUOTATION_DATA(state, payload) {
            state.quotationData = merge(state.quotationData, payload);
        },
        BP_UPDATE_VEHICLEDATA_BRANDS(state, payload) {
            state.vehicleDataValues.brands = payload;
        },
        BP_UPDATE_VEHICLEDATA_MODELS(state, payload) {
            state.vehicleDataValues.models = payload;
        },
        BP_UPDATE_VEHICLEDATA_VERSIONS(state, payload) {
            state.vehicleDataValues.versions = payload;
        },
        BP_UPDATE_PROVINCES(state, payload) {
            state.provinces = payload;
        },


    }
};

<template>
    <div class="bp-fast-quote fast-quote" :class="classes">
 <!-- justify-content-center   -->
        <div class="form-row no-gutters mx-0 align-items-center justify-content-center">
            <div class="form-group rounded-pill" :class="{ 'col-md-6': size === 'large', 'col-md-4': size !== 'large', 'mb-0': $v.codmar.$error }">
                <label class="text-dark-gray" for="FastQuoteBirthDate">
                   <small style="font-size:12px">Marca</small>
                </label>

                <form-placeholder-loading v-if="brandsLoading"/>
                <multiselect v-else
                    :placeholder="brandPlaceholder"
                    :options="brands"
                    label="desmar"
                    @input="e => updateVehicleBrand(e.codmar)"
                    @change="e => updateVehicleBrand(e.codmar)"
                    track-by="codmar"
                    selected-label=" "
                    select-label=" "
                    deselectLabel=" "
                    :searchable="true"
                    :showNoResults="false"/>

            </div>

            <div class="form-group rounded-pill" :class="{ 'col-md-6': size === 'large', 'col-md-4': size !== 'large', 'mb-0': $v.codmod.$error }">
                <label class="text-dark-gray" for="FastQuoteCicloId">
                    <small style="font-size:12px">Modello</small>
                </label>

                <form-placeholder-loading v-if="modelsLoading"/>
                <multiselect v-else
                    :placeholder="modelPlaceholder"
                    :options="models"
                    label="desmod"
                    @input="e => updateVehicleModel(e.codmod)"
                    @change="e => updateVehicleModel(e.codmod)"
                    track-by="desmod"
                    selected-label=" "
                    select-label=" "
                    deselectLabel=" "
                    :searchable="true"
                    :showNoResults="false"/>

            </div>

            <div class="d-flex flex-column form-group col form-group-button">

                <button
                    id="cta_bp_fastquote"
                    data-type="CTA"
                    type="button"
                    style="font-size: 14px; line-height: 1.75"
                    @click.prevent="onQuotationClick"
                    class="btn btn-primary rounded-pill"
                    :class="{'disabled': $v.codmod.$invalid || $v.codmar.$invalid}"
                    ref="FastQuoteButton"
                    :disabled="$v.codmod.$invalid || $v.codmar.$invalid">
                        {{this.size === 'regular' ? 'Calcola il preventivo' : 'Calcola'}}

                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Inputmask from "inputmask";
    import * as moment from 'moment';
    import {VBTooltip} from 'bootstrap-vue';
    import Vue from 'vue'
    import Vuelidate from 'vuelidate'
    import {maxLength, minLength, required} from 'vuelidate/lib/validators';
    import Fastquote from '../Mixins/Fastquote.mixin';
    import Multiselect from 'vue-multiselect';
    import { mapActions, mapGetters, mapState } from "vuex";
    import FormPlaceholderLoading from "./FormPlaceholderLoading";
    import BpApiService from '../../Bikeplatinum/BikeplatinumQuotation.service';
    Vue.use(Vuelidate);

    export default {

        directives: {
            'b-tooltip': VBTooltip
        },
        mixins: [Fastquote],
        components: {
            Multiselect,
            FormPlaceholderLoading
        },

        data() {
            return {
                codmar: '',
                codmod: '',
                brands: [],
                models: [],
                brandsLoading: false,
                modelsLoading: false
            }
        },
        mounted: function () {
           this.getBrands();
        },
        computed: {
            url: function () {
                let _query = `?codmar=${this.codmar}&codmod=${this.codmod}`;
                let _urlString = `/bikeplatinum/preventivo${this.urlprod ? `?urlProd=${this.urlprod}` : ``}#/inizio${_query}`;
                return _urlString;
            },
            brandPlaceholder() {
                try {
                    return this.brands.find((_el) => {
                    return _el.codmar === this.codmar
                    }).desmar
                } catch (e) {
                    return ''
                }
            },
            modelPlaceholder() {
                try {
                    return this.models.find((_el) => {
                        return _el.codmod === this.codmod
                    }).desmod
                } catch (e) {
                    return ''
                }
            },
        },

        methods: {
            updateVehicleBrand(_v) {
                //codmar
                this.codmar = _v;
                this.codmod = '';
                this.getModels();
            },
            updateVehicleModel(_v) {
                //codmod
                this.codmod = _v;
            },
            getBrands(){
                this.brandsLoading = true;
                BpApiService.getBrandsEbike().then(res => {
                    this.brands = res.data;

                    this.brandsLoading = false;
                    resolve(res);
                }).catch(e => {
                    this.brandsLoading = false;
                    reject();
                });

            },
            getModels(){
                const _query = {
                    CodMar: this.codmar
                };
                this.modelsLoading =  true;
                BpApiService.getModelsEbike(_query).then(res => {
                   this.models = res.data;

                    this.modelsLoading =  false;
                    resolve(res);
                }).catch(e => {
                    this.modelsLoading =  false;
                    reject();
                });

            },
            onQuotationEnter: function (e) {
                if (!this.$v.codmar.$invalid && !this.$v.cicloid.$invalid) {
                    window.location.href = this.url;
                }
            },
        },

        validations: {
            codmar:{
                required,
            },
            codmod:{
                required,
            },
        },

    }

</script>

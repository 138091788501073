import Vue from 'vue';
import VueRouter from 'vue-router';
import SelligentPusher from "../Common/selligentPusher";
import store from '../Store';

const AgenteBase = () => import("./AgenteBase")
const AgenteRicerca = () => import( "./Components/AgenteRicerca")

Vue.use(VueRouter);


const router = new VueRouter({
    routes: [
        {
            path: '/dashboard',
            component: AgenteBase,
            meta: {
                title: 'Agente',
                backButtonEnabled: true
            },
            children: [
                {
                    path: 'ricerca',
                    name: 'ricerca',
                    component: AgenteRicerca,
                    meta: {
                        title: 'Ricerca utenti',
                        description: 'Usa questa sezione per cercare un utente di 24hassistance',
                        backButtonEnabled: true,
                        titleBarDisabled: true
                    },
                },
            ]
        },
    ]
});

// Imposto il titolo della pagina e l'impostazione del pulsante indietro
router.beforeEach((to, from, next) => {
    store.dispatch('SET_VIEW_TITLE', to.meta.title);
    store.dispatch('SET_BACK_BUTTON_STATE', to.meta.backButtonEnabled);
    store.dispatch('SET_CURRENT_PRODUCT', 'motoplatinum');
    store.dispatch('SET_AR_STATE', to.path.includes('/auth/'));
    next();
});


//Controllo se l'utente puo entrare o meno in determinate pagine e da li faccio i redirect necessari
router.beforeEach((to, from, next) => {
    if (to.path === '/') next('/dashboard/ricerca')
    else next()
});

// Segnalo a selligent ogni cambio di pagina
router.afterEach((to, from) => {
    try {
        let pusher = new SelligentPusher()
        pusher.add('page', window.location.pathname + '/#' + to.fullPath)
        pusher.push()
    } catch (e) {
    }
})

export {router}

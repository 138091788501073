<template>

    <div :class="{'bg-light-blue' : selected}"
        class="p-3 border-bottom d-flex justify-content-between align-items-center" 
        style="border-bottom-style: dashed!important;">
        
        <div>

            <small v-if="partnerLocation.officialBrands.includes(currentUficialbrand)"
                    class="font-weight-bold mb-1 clickable text-dark-secondary font-weight-bold"><i class="fa-solid fa-badge-check"></i> Rivenditore e officina autorizzata
            </small>
            
            <small v-else class="font-weight-bold mb-1 clickable text-dark-secondary font-weight-bold">Officina convenzionata</small>

            <br/>
            <span class="font-weight-bold mb-1 clickable text-secondary"
                  @click="onPartnerLocationSelected(partnerLocation)"
            >{{ partnerLocation.companyName }}
            </span><br/>
            <span>{{ partnerLocation.postalAddress}}, {{ partnerLocation.zip}}, {{ partnerLocation.town}}</span><br/>
            <span class="text-secondary">{{ partnerLocation.landlineNumber }}</span><br/>
            
        </div>
      
    </div>

</template>

<script>
import {bus} from "../Preventivo/_Common/EventBus";

export default {
    name: "TheOfficineConvenzionateItem",
    props: [
        'partnerLocation',
        'selected',
        'highlighted',
        'index',
        'currentUficialbrand'
    ],
    data() {
        return {
            expanded: false,
        }
    },
    methods: {
        onPartnerLocationClick(){
            bus.$emit('mapPanToPin', this.index)
        },

        onPartnerLocationSelected(partner){
            this.onPartnerLocationClick()
            this.$emit('onPartnerLocationSelected', partner)
        }
    }

}

</script>

import { render, staticRenderFns } from "./CarouselSectionContattaci.vue?vue&type=template&id=36c57599&scoped=true&"
import script from "./CarouselSectionContattaci.vue?vue&type=script&lang=js&"
export * from "./CarouselSectionContattaci.vue?vue&type=script&lang=js&"
import style0 from "./CarouselSectionContattaci.vue?vue&type=style&index=0&id=36c57599&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c57599",
  null
  
)

export default component.exports
<template>
    <section>
        <transition duration="200" mode="out-in" name="fade">
            <div
                    v-if="loading"
                    :key="1"
                    class="d-flex justify-content-center align-items-center py-5 my-5">
                <adaptive-spinner/>
            </div>
            <div
                    v-else-if="error"
                    :key="2"
                    class="d-flex justify-content-center align-items-center flex-column h-100 py-5 my-5">
                <i class="fal fa-times text-danger fa-4x"></i>
                <p class="text-danger">{{ error }}</p>
            </div>
            <section
                    v-else-if="!loading && ready"
                    :key="3">

                <div class="container pb-5 px-0 px-md-auto">
                    <div class="d-lg-flex justify-content-between align-items-center p-3">
                        <the-breadcrumbs v-if="'webOrder' in quotation" :breadcrumbs="breadcrumbs"/>
                        <the-locale-select/>
                    </div>
                    <transition
                            duration="200" mode="out-in" name="fade">
                        <router-view></router-view>
                    </transition>
                    <div
                            v-if="showContent && this.$route.path.includes('quotation')"
                            class="d-flex justify-content-center align-items-center w-100 text-muted mt-4 mt-md-5">
                        <div class="text-center">
                            <small>{{ $t('snowcare.quotation.findOutMoreButton') }}</small><br/>
                            <i class="fa fa-chevron-down fa-2x pulse class rounded-pill d-block bkg-light"
                               @click="scrollToContent"></i>
                        </div>
                    </div>
                </div>
            </section>
        </transition>

        <div v-if="showContent && this.$route.path.includes('quotation')">
            <snowcare-content/>
        </div>

    </section>
</template>

<script>
import SnowcareQuotation from "@/Components/SnowcareQuotation";
import TheBreadcrumbs from "@/Components/TheBreadcrumbs";
import {mapActions, mapState} from "pinia"
import {useUserStore} from "@/stores/user.store";
import TheLocaleSelect from "@/Components/TheLocaleSelect";
import SnowcareContent from "@/Components/SnowcareContent";
import axios from "axios";
import AdaptiveSpinner from "@/Common/AdaptiveSpinner";
import {bus} from "@/Preventivo/_Common/EventBus";
import {useWebOrderStore} from "@/stores/weborder.store";
import HomeFastquote from "@/Common/HomeFastquote";
import TheCarousel from "@/Common/TheCarousel";
import { CLEAR_MP_ITEM } from '@/Common/constants';

export default {
    name: "Snowcare",
    components: {
        TheCarousel,
        HomeFastquote, AdaptiveSpinner, TheLocaleSelect, TheBreadcrumbs, SnowcareQuotation, SnowcareContent
    },
    data() {
        return {
            loading: true,
            ready: false,
            error: null,
            showContent: false
        }
    },


    async mounted() {
        CLEAR_MP_ITEM()
        await this.init()

    },
    onBeforeDestroy() {
        bus.$off('onLocaleChange')
    },
    computed: {
        ...mapState(useWebOrderStore, ['quotation', 'isQuotationValid']),
        breadcrumbs() {
            return [
                {
                    label: this.$t('global.breadcrumbs.quotation'),
                    link: 'quotation/options',
                    clickable: true,
                    active: true,
                    icon: 'fa-money-check-dollar'
                },
                {
                    label: this.$t('global.breadcrumbs.registry'),
                    link: 'registry/contractor',
                    clickable: 'webOrder' in this.quotation ? this.isQuotationValid : false,
                    active: this.isQuotationValid && this.$route.path.includes('registry') || this.isQuotationValid && this.$route.path.includes('checkout'),
                    icon: 'fa-file-lines'
                },
                {
                    label: this.$t('global.breadcrumbs.checkout'),
                    link: 'checkout',
                    clickable: false,
                    active: this.$route.path.includes('checkout'),
                    icon: 'fa-basket-shopping'
                }
            ]
        },

    },
    methods: {
        ...mapActions(useUserStore, ['collectUserData']),
        ...mapActions(useWebOrderStore, ['getWebOrder', 'setWebOrder']),

        async init() {
            try {
                this.loading = true

                // se parametro è presente mostro i
                // contenuti della pagina prodotto qui sotto
                if (this.$route.query.showcontent) localStorage.setItem('showScContent', 'true');
                if (localStorage.getItem('showScContent')) this.showContent = true


                this.$i18n.locale = navigator.language.split('-')[0]

                // Associo un evento al cambio di locale per aggiornare il preventivo se necessario
                bus.$on('onLocaleChange', async () => {
                    this.loading = true
                    this.quotation.webOrder.locale = this.$i18n.locale
                    try{
                        await this.setWebOrder()
                        this.loading = false
                    }catch (e) {
                        console.error(e)
                        this.loading = false
                    }
                })
                // Se l'urlProd è presente lo configuro come header di axios,
                const urlProd = this.$route.query.urlprod
    
                axios.defaults.headers.urlProd = urlProd;
                    

                // Controllo se l'utente è loggato e recuper oi dati anagrafici
                await this.collectUserData()

                // SE è presente il weborderid recupero il webOrder prima di procedere
                if (this.$route.query.weborderid) await this.getWebOrder(this.$route.query.weborderid)

                this.ready = true
                this.loading = false
            } catch (e) {
                this.ready = true
                this.loading = false
                this.error = this.$t('global.errors.generic')
                console.error(e)
            }
        },
        scrollToContent() {
            const element = document.querySelector("#snowcareContentScroll")
            element.scrollIntoView(true)
        }
    }
}
</script>

<style lang="scss" scoped>
.pulse {
  animation: pulse 5s linear infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #909090;
  }
  40% {
    box-shadow: 0 0 0 30px rgba(255, 26, 67, 0);
  }
  80% {
    box-shadow: 0 0 0 30px rgba(255, 206, 67, 0);
  }
  100% {
    box-shadow: 0 0 0 rgba(255, 206, 67, 0);
  }
}
</style>

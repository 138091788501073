import * as Cookies from 'js-cookie';
import SelligentPusher from "./selligentPusher";

export default function homeHandler() {

    if (window.location.pathname === '/') {
        const _JWT = window.globalConfigVariables.token
        if (!_JWT) {
            window.sessionStorage.removeItem('arUserData');
            window.sessionStorage.removeItem('arUserQuotations');
            window.sessionStorage.removeItem('arUserPolicies');
        }
    }

    // rendiamo globali le funzioni
    window.goToSupport = goToSupport;
    window.goToSupportFromLink = goToSupportFromLink;
    window.goToClaimsFromLink = goToClaimsFromLink;
    window.handlerKeyUpSearch = handlerKeyUpSearch;
    window.changeCarouselEvent = changeCarouselEvent;
    window.cleanBrowserData = cleanBrowserData;
    window.handlerClickBanner = handlerClickBanner;

    cleanBrowserData();
    orizontalScrollMobil();

    window.addEventListener('resize', function (event) {
        orizontalScrollMobil();
    }, true);

    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        if (!sessionStorage.getItem('bannerOpen')) {
            handlerClickBanner();
            sessionStorage.setItem('bannerOpen', 'true');
        }
    }

    if (location.pathname == '/') {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('callback')) window.location = urlParams.get('callback')
    }
}

export function handlerKeyUpSearch(e) {
    if (e.keyCode == 13) {
        var value = document.getElementById(e.target.id).value;
        try {
            if (value.length > 1) {
                const tag = e.target.id.includes('box') ? 'hp_search_box' : 'hp_search_bottom'
                let pusher = new SelligentPusher()
                pusher.add('support', tag)
                pusher.push()
            }
        } catch (e) {

        }
        goToSupport(value);
    }
}

export function goToSupportFromLink(source) {
    try {
        const tag = source.includes('box') ? 'hp_link_box' : 'hp_link_bottom'
        let pusher = new SelligentPusher()
        pusher.add('support', tag)
        pusher.push()

    } catch (e) {
    }
    window.open('https://help.24hassistance.com/hc/it', '_blank');
    return false;
}

export function goToClaimsFromLink(source) {
    try {
        const tag = source.includes('box') ? 'claimFromHpBox' : 'claimFromHpBottom'
        let pusher = new SelligentPusher()
        pusher.add('action', tag)
        pusher.push()

    } catch (e) {
    }

    // window.open('/W_sinistri/Step1.aspx', "_self");
    window.open('https://dsw.24hassistance.com', "_self");
    return false;
}

export function goToSupport(query) {
    var url = query ? 'https://help.24hassistance.com/hc/it/search?utf8=%E2%9C%93&query=' + query + '&commit=Cerca' : 'https://help.24hassistance.com/hc/it';
    window.open(url, '_blank');
    return false;
}

export function changeCarouselEvent() {

    const value = document.getElementById("select-carousel").value;

    if (value) {

        switch (value) {
            case 'Motoplatinum':
                $('#pills-carousel .tab-pane').removeClass('show active');
                $('#pills-partner-mp').tab('show');
                break;
            case 'Bikeplatinum':
                $('#pills-carousel .tab-pane').removeClass('show active');
                $('#pills-partner-bp').tab('show');
                break;
            case 'Dottordog':
                $('#pills-carousel .tab-pane').removeClass('show active');
                $('#pills-partner-dd').tab('show');
                break;
            case 'Skippermate':
                $('#pills-carousel .tab-pane').removeClass('show active');
                $('#pills-partner-sk').tab('show');
                break;
            default:
            // code block
        }

    }


}


export function cleanBrowserData() {

    if (!Cookies.get('cleared') || Cookies.get('cleared') && Cookies.get('cleared') !== '3') {
        const _cookies = Cookies.get();
        const _sessionStorage = window.sessionStorage;
        const _localStorage = window.localStorage;

        // Rimuovo tutti i cookies
        Object.keys(_cookies).forEach(_k => {
            Cookies.remove(_k);

        });


        //Rimuovo il sessionStorage
        Object.keys(_sessionStorage).forEach((_k) => {
            window.sessionStorage.removeItem(_k);
        });

        // Rimuovo il localStorage
        Object.keys(_localStorage).forEach((_k) => {
            window.localStorage.removeItem(_k);
        });

        Cookies.set('cleared', 3, {expires: 1000});
    }

}

export function handlerClickBanner() {

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.open('https://app.powerbi.com/view?r=eyJrIjoiZGE2ODRlODctZmVmMC00ZmU1LTllOTYtNDIwNjhiZjgxYjc5IiwidCI6ImI1NDNmYjdlLTAzZTEtNGNlMi1hZDZjLWE5Mjc4NTBiMmU1OCIsImMiOjh9', '_blank');
    } else {

        /*const modal = $('#iframe-modal');

        if(modal){ modal.modal('show'); }*/

    }

}

// Rimuovere commenti per riattivare modale promo HP
/*$(document).ready(function () {
    if (!document.getElementById('iframe-modal')) return
    $("#iframe-modal").hide();
    setTimeout(function () {
        $("#iframe-modal").modal('show');
    }, 20000);
});*/

export function orizontalScrollMobil() {
    const elements = document.querySelectorAll(".container-scroll-x");

    const scrollX = () => {

        elements.forEach(e => {
            const scroll = e.getAttribute('data-scroll')
            const eScrollLeft = e.offsetWidth * scroll / 100;
            e.scrollLeft = eScrollLeft;
        });

    }

    if (elements) scrollX();
}

<template>
    <div v-if="MP_ALERT_DATA.alert && GLOBAL_IS_AGENT"
         class="d-flex justify-content-start align-items-center border rounded mx-3 p-2">
        <small class="text-muted d-block">
            <span class="font-weight-bold">Alert: {{MP_ALERT_DATA.alert}}</span><br/>
            <span v-html="MP_ALERT_DATA.description"/>
        </small>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "MpAgentAlertdata",
        computed: {
            ...mapGetters(['MP_ALERT_DATA', 'GLOBAL_IS_AGENT'])
        }
    }
</script>


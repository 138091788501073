<template>

    <div id="carousel-home" class="w-100 h-100">
        <div class="container-scroll-x">
            <ul class="nav nav-pills justify-content-between mb-3 font-weight-700 text-white">
                <li class="nav-item text-white cursore">
                    <a class="btn-secondary btn btn-secondary mr-md-2 py-1 px-2 cursore"
                       onclick="goToSupport('Come+fare+un+preventivo')"
                       style="cursor: pointer !important; user-select:none !important;">Preventivo</a>
                </li>
                <li class="nav-item">
                    <a class="btn-secondary btn btn-secondary mr-md-2 py-1 px-2 cursore"
                       :class="{ active: isActive('') }"
                       onclick="goToSupport('Riattivare+la+polizza')"
                       style="cursor: pointer !important; user-select:none !important;">Riattiva polizza</a>
                </li>
                <li class="nav-item">
                    <a class="btn-secondary btn btn-secondary mr-md-2 py-1 px-2  cursore"
                       :class="{ active: isActive('') }"
                       onclick="goToSupport('Garanzie+e+coperture')"
                       style="cursor: pointer !important; user-select:none !important;">Garanzie</a>
                </li>
                <li class="nav-item">
                    <a class="btn-secondary btn btn-secondary mr-md-2 py-1 px-2 cursore"
                       :class="{ active: isActive('') }"
                       style="cursor: pointer !important; user-select:none !important;"
                       onclick="goToSupport('/articles/115003361445-Posso-rinnovare-la-mia-polizza-dall-Area-Riservata')">Rinnovo
                        polizza</a>
                </li>
                <li class="nav-item">
                    <a class="btn-secondary btn btn-secondary mr-md-2 py-1 px-2 cursore"
                       :class="{ active: isActive('') }"
                       style="cursor: pointer !important; user-select:none !important;"
                       onclick="goToSupport('/articles/115003361245-Come-fare-per-cambiare-il-mezzo-assicurato')">
                       Cambio mezzo
                    </a>
                </li>
            </ul>
        </div>
    </div>

</template>

<script>

export default {
    name: "CarouselSectionContattaci",
    props: [],

    data() {
        return {
            activeItem: 'MM'
        };
    },

    mounted() {
    },
    computed: {},
    methods: {
        isActive(menuItem) {
            return this.activeItem === menuItem
        },
        setActive(menuItem) {
            this.activeItem = menuItem
        }
    },
};
</script>

<style lang="scss" scoped>

.item {
  visibility: hidden;
  height: 0;

  &.active {
    visibility: visible;
    height: auto;
  }
}

@media (max-width:  575.98px) {
  .nav.nav-pills {
    width: 167%;

  }
}
</style>

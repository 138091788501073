<template>
    <transition name="fade">
        <div class="alert alert-info alert-restore shadow p-0" v-if="showRestoreBanner">
            <div class="d-flex justify-content-start align-items-center">
                <div class="border-right p-0">
                    <i class="p-3 fal fa-repeat fa-2x text-white"></i>
                </div>
                <div class="flex-grow-1">
                    <p class="text-white m-2 ml-3" style="line-height: 1.2em"><b>Recupera il preventivo</b><br/>
                        Puoi recuperare il preventivo <span
                                style="text-transform: capitalize;">{{currentProduct}}</span> che stavi facendo
                        {{quotationDate}}
                    </p>

                </div>
                <a href="#"
                   class="btn btn-sm btn-outline-light mr-0"
                   v-loading-button="ux.loading > 0"
                   @click.prevent="onBannerCtaClick">
                    Recupera</a>

                <i class="fal fa-lg fa-times clickable text-light alert-dismiss shadow-sm m-3"
                   @click="onBannerDismiss"></i>
            </div>
        </div>
    </transition>

</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
    import * as Cookies from 'js-cookie';
    import * as moment from 'moment';

    export default {
        name: 'BannerQuotationRestore',

        data() {
            return {
                showRestoreBanner: false,
                latestQuotationData: {}
            }
        },

        computed: {
            ...mapState(['ux']),
            latestQuotation: function () {
                return Cookies.getJSON('LatestQuotation') ? Cookies.getJSON('LatestQuotation') : false;
            },
            quotationDate: function () {
                return moment(Cookies.getJSON('LatestQuotation').date).fromNow();
            },
            currentProduct: function () {
                return Cookies.getJSON('LatestQuotation').product;
            }
        },

        created() {

            // All'avvio del preventivatore controllo
            // che sia presente il cookie del preventivo precedente
           /* if (this.latestQuotation && this.$route.name === 'inizio') {
                this.START_LOADING();

                // Se il cookie è presente controllo che la risposta sia valida
                DdApiService.getQuotationData(this.latestQuotation.quotationId).then(res => {

                    // Se la risposta è valido allora la metto di parte in
                    // attesa che l'utente decida cosa fare del precedente preventivo
                    this.latestQuotationData = res.data;
                    this.showRestoreBanner = true;
                    this.STOP_LOADING();
                }).catch((e) => {
                    console.error(e);
                    this.STOP_LOADING();
                    Cookies.remove('LatestQuotation');
                });


            }*/
        },

        methods: {
            ...mapActions(['DD_RESTORE_QUOTATION', 'DD_GET_QUOTATION']),
            ...mapMutations(['START_LOADING', 'STOP_LOADING']),
            onBannerCtaClick() {
                const _this = this;
                // Se l'utente decide di recuperare il preventivo allora prendo i dati
                // precedentemente salvati resetto l ostore e lo reidrato coi nuovi dati
                this.DD_RESTORE_QUOTATION(this.latestQuotationData).then(function () {
                    _this.$router.push(_this.latestQuotation.path);
                    _this.showRestoreBanner = false;

                }).catch(() => {
                    Cookies.remove('LatestQuotation');
                    this.showRestoreBanner = false;
                })

            },
            onBannerDismiss() {
                Cookies.remove('LatestQuotation');
                this.showRestoreBanner = false;
            }
        }

    }
</script>

import axios from 'axios';
import {API_URL} from "@/Common/constants";

const UserService = {
    /**
     * Ottiene l'anagrafice dell'utente loggato
     */
    getUserRegistry: async () => {
        try {
            return await axios.get(`${API_URL}/Account/GetRegistryUserData`)
        } catch (e) {
            throw new Error(e);
        }
    },
    getUserConfig: async () => {
        try {
            return await axios.get(`${API_URL}/ar/getUserData`)
        } catch (e) {
            throw new Error(e);
        }
    },

};

export default UserService;

// Wrike: https://www.wrike.com/open.htm?id=697735740

import {loadZendesk} from "./zendeskLoad";

const chatsInit = () => {
    // Controllo se l'utente è autenticato
    const token = window.globalConfigVariables.token

    let hasGold = false;
    let hasRinActive = false;
    let isCustomer = false;
    let isAr = false;
    let isCheckout = false;
    let toggleFromUrl = window.location.href.includes('openchat')

    // Recupera i dati dal local storage e setta le variabili
    const collectData = () => {
        // Recupero i dati dell'utente dal local storage
        const userPolicies = JSON.parse(window.localStorage.getItem('arUserPolicies'))
        const userQuotations = JSON.parse(window.localStorage.getItem('arUserQuotations'))
        const userData = JSON.parse(window.localStorage.getItem('arUserData'))

        const goldCheck = () => {
            if (!userPolicies) return false
            const results = userPolicies.filter((el) => {
                return el.guarantees.includes('00/MotoplatinumGOLD') && el.status === 'Valida'
            })

            return results.length > 0
        }

        const rinCheck = () => {
            if (!userQuotations) return false
            const results = userQuotations.filter((el) => {
                return el.quotationNumber.includes('RIN') && el.status !== 'Scaduto' && el.status !== 'Acquistato'
            })

            return results.length > 0

        }

        // Controllo se l'utente ha la gold tra le garanzie e la polizza è attiva
        hasGold = goldCheck()

        // Controllo se ha un preventivo di rinnovo attivo
        hasRinActive = rinCheck()

        // Controllo se è un loggato C
        isCustomer = !userData ? false : userData.userType === 1

        // Controllo se siamo nell'ar
        isAr = window.location.pathname.toLowerCase().startsWith('/ar')
        isCheckout = window.location.pathname.toLowerCase().includes('checkout')
    };

    // Avvio la funzione
    collectData()


    // Se la pagina non è l'hp e se non ci troviamo in un iframe e l'utente
    if (window.self == window.top && window.location.pathname !== '/' && window.location.pathname.toLowerCase() !== '/contattaci') {
        //controllo che l'utente corrisponda ai parametri corretti per aprire la chat
        if (toggleFromUrl || isAr && hasGold && token && isCustomer || isCheckout && hasRinActive && token && isCustomer || toggleFromUrl && isCheckout && token && isCustomer) {
            loadZendesk(true).then(function () {
                // Se c'è il parametro nell'url apre la chat
                // Altrimenti la rende solo cliccabile in basso a dx
                if (toggleFromUrl) {
                    zE('webWidget', 'open');
                }
            })

        } else {
            if(window.innerWidth > 768 && window.location.pathname.includes('preventivo')) {
                loadZendesk(false).then(function () {
                    // Se c'è il parametro nell'url apre la chat
                    // Altrimenti la rende solo cliccabile in basso a dx
                    if (toggleFromUrl) {
                        zE('webWidget', 'open');
                    }
                })
            }
        }
    }

    // Forza l'apertura della chat, richiamato ad esempio nei pulsanti chatta con noi
    window.openChat = async () => {
        await loadZendesk(false)
        zE('webWidget', 'open');
    }
}

export {
    chatsInit
}

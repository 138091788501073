import 'core-js/stable';
import jQuery from "@/Common/jqueryLoader";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'iframe-resizer/js/iframeResizer.contentWindow.min.js';
import 'flatted';
import './Styles/main.scss'
import './Preventivo/main.js';
import './Common/main.js';
import './AreaRiservata/main';
import './Agente/main';
import Vue from 'vue';
import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";
import {Vue as VueIntegration} from "@sentry/integrations";
import VCalendar from 'v-calendar';
import * as moment from 'moment';


import * as GmapVue from "gmap-vue";
import {GOOGLE_MAPS_APIKEY} from "./Common/constants";
import LazyLoadDirective from "@/directives/lazyLoadDirective";

Vue.use(GmapVue, {
    load: {
        key: GOOGLE_MAPS_APIKEY,
        libraries: 'places',
        region: 'IT',
        language: 'IT',
    },
    installComponents: false
})
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    componentPrefix: 'vc',
});

Vue.directive("lazyload", LazyLoadDirective);

Sentry.init({
        dsn: 'https://a49475eaae934014a23a24f94dd2bc79@o309343.ingest.sentry.io/5446494',
        release: window.globalConfigVariables ? "24hassistance-es6@" + window.globalConfigVariables.currentSiteVersion : '"24hassistance-es6@"testing_release',
        integrations: [new Integrations.BrowserTracing(), new VueIntegration({Vue, attachProps: true, logErrors: true})],
        tracesSampleRate: __SENTRY_TRACING_RATE__, // Be sure to lower this in production
    },
);


<template>
  <div>
    <transition name="slideLeft" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'RouterViewContainer'
  }
</script>



import axios from 'axios';
import {API_URL} from '../Common/constants';


const ArApiService = {
    // Polizza
    getPolicyData: function (_data) {
        return axios.get(`${API_URL}/motoplatinum/getPolicyData`, {
            params: {
                ...(_data)
            },
        })
    },

    getUserData: function (_data) {
        return axios.get(`${API_URL}/ar/getuserdata`, {
            params: {
                ...(_data)
            },
        })
    },

    getUserPolicies: function (_data) {
        return axios.get(`${API_URL}/ar/getuserpolicies`, {
            params: {
                ...(_data)
            },
        })
    },

    getUserQuotations: function (_data) {
        return axios.get(`${API_URL}/ar/getuserquotations`, {
            params: {
                ...(_data)
            },
        })
    },

    changeVehicle: function (_data) {

        return axios.get(`${API_URL}/Motoplatinum/ChangeVehicle`, {
            params: {
                ...(_data)
            },
        })
    },

    changeOwner: function (_data) {

        return axios.get(`${API_URL}/Motoplatinum/changeOwner`, {
            params: {
                ...(_data)
            },
        })
    },

    getBundles: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/GetBundlesAR`, {
            ...(_data)
        })
    },

    getAddons: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/GetAddonsAR`, {
            ...(_data)
        })
    },

    addGuarantees: function (_data) {
        return axios.get(`${API_URL}/Motoplatinum/AddonsGuaranteesPolicy`, {
            params: {
                ...(_data)
            },
        })
    },


    lostPlate: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/lostPlate`, {
            ...(_data)
        })
    },

    rcRefund: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/rcRefund`, {
            ...(_data)
        })
    },

    rcRefundIban: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/rcRefundIban`, {
            ...(_data)
        })
    },

    rcRefundConfirm: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/rcRefundConfirm`, {
            ...(_data)
        })
    },

    suspendPolicy: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/suspendPolicy`, {
            ...(_data)
        })
    },

    suspendPolicyConfirm: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/suspendPolicyConfirm`, {
            ...(_data)
        })
    },

    reactivatePolicy: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/reactivatePolicy`, {
            ...(_data)
        })
    },

    afterthoughtOnlyBox: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/afterthoughtOnlyBox`, {
            ...(_data)
        })
    },

    checkPartnerWarehouse: function (_data) {
        return axios.post(`${API_URL}/motoplatinum/checkPartnerWarehouse`, {
            ...(_data)
        })
    },

    getRegistryUserData: function (_data) {
        return axios.get(`${API_URL}/account/getRegistryUserData`, {
            params: {
                ...(_data)
            },
        })
    },

    updatePersonalData: function (_data) {
        return axios.post(`${API_URL}/account/updatePersonalData`, {
            ...(_data)
        })
    },

    updateEmail: function (_data) {
        return axios.post(`${API_URL}/account/updateEmail`, {
            ...(_data)
        })
    },
    updateEmailOtp: function (_data) {
        return axios.post(`${API_URL}/account/updateEmailOtp`, {
            ...(_data)
        })
    },

    updatePhone: function (_data) {
        return axios.post(`${API_URL}/account/updatePhone`, {
            ...(_data)
        })
    },
    updatePhoneOtp: function (_data) {
        return axios.post(`${API_URL}/account/updatePhoneOtp`, {
            ...(_data)
        })
    },

    updatePassword: function (_data) {
        return axios.post(`${API_URL}/account/updatePassword`, {
            ...(_data)
        })
    },

    confirmAction: function (_data) {
        return axios.post(`https://devapibo24hassistancecore.24hassistance.com/postVendita/changePolicy`, {
            ...(_data)
        })
    },

    getPendingOperations: function (_data) {
        return axios.get(`${API_URL}/motoplatinum/getPendingOperations`, {
            params: {
                ...(_data)
            },
        })
    },

    getFormConfig: function (_data) {
        return axios.get(`${API_URL}/motoplatinum/formConfig`, {
            params: {
                ...(_data)
            },
        })
    },

    getStats: function (_data) {
        return axios.get(`${API_URL}/ar/stats`, {
            params: {
                ...(_data)
            },
        })
    },

    async getBrands (_data) {
        return axios.get(`${API_URL}/ar/brands`, {
            params: {
                ...(_data)
            },
        })
    },

    async getSegments (_data) {
        return axios.get(`${API_URL}/ar/segments`, {
            params: {
                ...(_data)
            },
        })
    },

    async getSalesData (_data) {
        return axios.get(`${API_URL}/ar/salesdata`, {
            params: {
                ...(_data)
            },
        })
    },

    async addSalesData (_data) {
        return axios.post(`${API_URL}/ar/salesdata/add`, {
            ...(_data)
        })
    },

    async editSalesData (_data) {
        return axios.patch(`${API_URL}/ar/salesdata/edit`, {
            ...(_data)
        })
    },

    async deleteSalesData (_data) {
        return axios.delete(`${API_URL}/ar/salesdata/delete`, {
            params: {
                ...(_data)
            },
        })
    },

    getPartnerBrands () {
        return axios.get(`${API_URL}/ar/getpartnerbrands`)
    },

    getPartnerProvinces () {
        return axios.get(`${API_URL}/ar/getpartnerprovinces`)
    },

    getPartnerLocation (_data) {
        return axios.get(`${API_URL}/ar/getpartnerlocation`, {
            ...(_data)
        })
    },

};

export default ArApiService;

// Ready translated locale messages
import {
    TABLE_DE_GOLD,
    TABLE_DE_SILVER,
    TABLE_EN_GOLD,
    TABLE_EN_SILVER,
    TABLE_ITA_GOLD,
    TABLE_ITA_SILVER
} from "@/lang/snowcareGuaranteesTables";

export const messages = {
    it: {
        global: {
            h1: 'Preventivo assicurazione sci e snowboard',
            breadcrumbs: {
                quotation: 'Preventivo',
                registry: 'Anagrafica',
                checkout: 'Acquisto'
            },
            registry: {
                title: {
                    contractor: 'Inserisci i dati del contraente',
                    beneficiary: "Inserisci i dati dell'assicurato",
                },
                form: {
                    name: 'Nome',
                    surname: 'Cognome',
                    companyName: 'Ragione sociale',
                    imAcompany: "Sono un'azienda",
                    imAperson: "Sono una persona fisica",
                    nationality: 'Nazionalità',
                    birthDate: 'Data di nascita',
                    birthDatePlaceHolder: 'DD/MM/YYYY',
                    vatId: 'Partita IVA',
                    taxId: 'Codice fiscale',
                    email: 'Email',
                    phone: 'Telefono',
                    phoneSubtext: `<b>Inserisci un numero di telefono valido </b><br/>Ti servirà per l'emissione della polizza e ricevere la documentazione. Non ti disturberemo al telefono per azioni commerciali.`,
                    address: {
                        residence: 'Hai la residenza in Italia?',
                        autocomplete: {
                            label: 'Indirizzo',
                            subText: 'Inizia a scrivere il tuo indirizzo'
                        },
                        street: 'Via',
                        number: 'Numero',
                        city: 'Città',
                        postalCode: 'Cap',
                        province: 'Provincia',
                        country: 'Nazione',
                        buttons: {
                            searchAgain: "Ricerca nuovamente l'indirizzo"
                        }
                    },
                    maritalStatus: 'Stato civile',
                    profession: 'Profession',
                    buttons: {
                        companySelection1: `Sono un'azienda`,
                        companySelection2: 'Sono una persona fisica',
                        reloadAddress: `Reinserisci l'indirizzo`,
                        checkout: 'Avanti'
                    },
                    errors: {
                        generic: {
                            required: 'Campo richiesto',
                            minLength2Char: "Deve'essere lungo almeno 2 caratteri",
                            noSpecialCharsOrNumbers: 'Non deve contenere numeri o caratteri speciali',
                            invalidFormat: 'Formato non valido'
                        },
                        surname: {
                            unique: 'Controlla che nome e cognome siano diversi'
                        },
                        fiscalCode: {
                            differentFromOtherRegistries: `Il codice fiscale dev'essere diverso da quello inserito nelle altre anagrafiche`,
                            nameNotCorresponding: 'Il codice fiscale non corrisponde al nome',
                            surnameNotCorresponding: 'Il codice fiscale non corrisponde al cognome',

                        },
                        province: {
                            mustMatchProvince: 'Non è una provincia italiana'
                        },
                        email: {
                            alreadyTaken: `L'indirizzo email è già associato a un altro utente`
                        },
                        phone: {
                            invalidFormat: 'Formato non valido, ricorda di aggiungere il prefisso internazionale ad esempio (+39)',
                            addPlusThreeNine: 'Digita il numero con il prefisso internazionale (+39)',
                            alreadyTaken: 'Il numero di telefono è già associato a un altro utente'
                        },
                        date: {
                            notBefore: 'La data di inizio non puo essere superiore alla data di fine'
                        }
                    }
                }
            }
        },
        snowcare: {
            quotation: {
                title: 'I dati del tuo preventivo',
                form: {
                    howMany: {
                        label: 'Quante persone vuoi assicurare?',
                    },
                    age: {
                        age1: 'Da 8 anni in su',
                        age2: 'Età 0-7 anni',
                        adult: 'Adulto',
                        adults: 'Adulti',
                        child:'Bambino',
                        children:'Bambini',
                        note: "Indica l'età effettiva al momento dell'acquisto"
                    },
                    policyDuration: {
                        label: 'Seleziona il periodo di date',
                        btn1: 'Data inizio',
                        btn2: 'Data fine',
                        today: 'Oggi',
                        alertToday: "La polizza sarà attiva 20 minuti dopo l'acquisto",
                        alertFromToday: "La polizza sarà attiva 20 minuti dopo l'acquisto e terminerà il",
                        alertNotTodayPre: "La polizza sarà attiva dal",
                        alertNotTodayAfter: "e terminerà il",
                        at: "alle",
                        errors: {
                            startOlderThanSixty: 'La data di inizio non puo essere oltre 60 giorni da oggi',
                            startDateLessThanToday: 'La data di decorrenza è stata aggiornata'
                        }
                    },
                    modal: {
                        day: "giorno",
                        days: "giorni",
                        confirm: "Conferma",
                    },
                },
                findOutMoreButton: 'Scopri di più su Snowcare',
                bundles: {
                    modal: {
                        title: 'Sei sicuro?',
                        message: 'Con il pacchetto GOLD sei rimborsato anche in caso di soccorso sulle piste italiane a pagamento (costo medio 200€).',
                        btn1: ' Corro il rischio, scelgo SILVER ',
                        btn2: 'Mantieni GOLD'
                    },
                    title: 'Scegli il pacchetto',
                    mostSold: 'Il più venduto',
                    silver: {
                        name: 'Silver',
                        description: "Comprende <b>RC obbligatoria</b> + Spese mediche d'urgenza + Annullamento skipass/lezione + Tutela legale e tante altre garanzie.",
                        guaranteesLabel: 'Tutte le garanzie',
                        modal: {
                            title: 'Garanzie SILVER',
                            subTitle: 'Oltre alla RC obbligatoria, ti assicuri per incidenti e infortuni durante l’attività. Non comprende il soccorso sulle piste italiane a pagamento.',
                            content: TABLE_ITA_SILVER
                        }
                    },
                    gold: {
                        name: 'Gold',
                        description: `Comprende <b>RC obbligatoria</b> + Spese mediche d'urgenza + Annullamento skipass/lezione + Tutela legale + <b>Soccorso sulle piste</b> e tante altre garanzie.`,
                        guaranteesLabel: 'Tutte le garanzie',
                        modal: {
                            title: 'Garanzie GOLD',
                            subTitle: 'Hai tutte le coperture del pacchetto SILVER, con l’aggiunta del soccorso sulle piste a pagamento nelle località aderenti.',
                            content: TABLE_ITA_GOLD
                        }
                    }
                },
                buttons: {
                    calculate: 'Calcola',
                    email: 'Ricevi per email',
                    next: 'Procedi'
                },
                emailModal: {
                    title: 'Ricevi i dati per email',
                    recap: 'Inserisci la tua email per ricevere il riepilogo del tuo preventivo Snowcare. Ricorda che per bloccare il prezzo e salvare un preventivo acquistabile è necessario cliccare su PROCEDI inserendo tutti i dati necessari.',
                    email: 'email@email.com',
                    consent: 'Do il consenso al trattamento dei miei dati personali anche per finalità commerciali (potrò revocarlo in qualsiasi momento)',
                    validate: 'Inserisci una email valida',
                    btn: 'Invia',
                    stepMail: "L'invio è andato a buon fine",
                    btn2: 'Chudi'
                },
                price: {
                    label: 'Il tuo prezzo'
                }
            },
            sideBar: {
                policy: {
                    title: 'Riepilogo polizza',
                    value: 'Polizza',
                    individual: 'Individuale',
                    group: 'Più persone',
                    adult: 'Adulto',
                    kid: 'Bambino',
                    number: 'N. Preventivo',
                    labels: {
                        startDate: 'Data inizio',
                        endDate: 'Data fine',
                        total: 'Totale',
                        day: 'Giorno',
                        days: 'Giorni',
                        today: "Oggi - 20 min dall'acquisto"
                    }
                },
                customer: {
                    title: 'Dati Contraente'
                },
                insuree: {
                    title: 'Dati Assicurati'
                },
                group: {
                    title: 'Dati assicurati',
                    componentsNumber: 'N° assicurati',
                }
            },
            registry: {
                groups: {
                    title: {
                      singular:"Inserisci i dati dell'assicurato",
                      plural:"Inserisci i dati del gruppo",
                    },
                    isCustomerAlsoInsuree: {
                        label: 'Sei la persona da assicurare?',
                        btn1: 'Si',
                        btn2: 'No',
                    },
                    form: {
                        label: 'Contraente',
                        adult: 'Adulto',
                        child: 'Bambino',
                        input1: 'Nome e cognome',
                        input2: 'Data di nascita',
                        link: 'Hai dimenticato qualcuno?',
                        modalTitle: 'Vuoi aggiungere un assicurato?',
                        name: 'Nome',
                        surname: 'Cognome',
                        birthDatePlaceHolder: 'DD/MM/YYYY',
                        birthDateInvalidErrors: 'La data inserita non è valida',
                        birthDateKidInvalidErrors: 'La data inserita non è di un bambino',
                        birthDateAdultInvalidErrors: 'La data inserita non è di un adulto',
                        recap: 'Il tuo preventivo'
                    },
                },
                buttons: {
                    next: 'Avanti',
                    save:'Salva',
                }
            },
            policy: {
                adultPrice: 'Prezzo unitario adulto',
                childPrice: 'Prezzo unitario bambino',
            },
            discountCode: {
                link: 'Hai un codice sconto?',
                input: 'Codice sconto',
                label: 'Il tuo preventivo'
            },
            checkout: {
                title: 'Acquista il tuo preventivo'
            },
            fiscalCode: {
                calculate: "Calcola codice fiscale",
                birthDatePlaceHolder:'DD/MM/YYYY',
                insertBirthDate: "Inserisci data di nascita",
                province: "Prov.",
                birthPlace: "Luogo di nascita",
                gender: "Sesso",
                genderM: "Uomo",
                genderF: "Donna"
            },
            productComponent: {
                benefits: {
                    title: 'Snowcare è la polizza sci con RC più diffusa in Europa per le tue attività sulla neve per uno o più giorni',
                    box1: {
                        title: 'Soccorso sulle piste incluso',
                        description: 'Tutelati da eventuali spese extra anche nelle località in cui il soccorso sulle piste è a pagamento (costo medio 200€)!',
                        linkText: 'Tutte le garanzie'
                    },
                    box2: {
                        title: 'Online o direttamente con lo skipass',
                        description: 'Nessun modulo da firmare e nessuna coda in più alle casse. In caso di incidente e/o infortunio sugli sci o sullo snowboard la verifica sarà fatta direttamente dal soccorso sulle piste.',
                        linkText: 'Scopri le località partner'
                    },
                    box3: {
                        title: 'Rimborso skipass per quarantena',
                        description: 'Con l’assicurazione Snowcare sei coperto anche se non puoi andare a sciare a causa di malattia o quarantena prima dell\'inizio della validità dello Skipass.',
                        linkText: 'Scopri i pacchetti'
                    },
                    text1: 'La polizza Snowcare è valida anche per un solo giorno, per te o per un gruppo di persone a partire da 3,00€ al giorno.',
                    alert: {
                        title: 'Da quest’anno l’assicurazione RC è obbligatoria sulle piste da sci!',
                        description: `Conserva sempre il tuo skipass: l'assicurazione è legata al tuo skipass ed è valida solo in caso di richiesta di <br />
                        intervento del soccorso sulle piste nel luogo di accadimento del sinistro. `,
                    }
                },
                partner: {
                    title: 'I nostri partner',
                    description: 'Siamo presenti nelle principali località sciistiche e collaboriamo con i migliori brand appassionati di montagna.',
                    linkText: 'Scopri tutti i partner'
                },
                pacchetti: {
                    title: 'Scopri i dettagli della copertura Snowcare',
                    text1: `Snowcare è l<strong>’assicurazione sci e snowboard</strong> che tutela oltre un milione di sciatori ogni anno.<br/> 
                        Puoi acquistare l’<strong>assicurazione skipass giornaliera </strong> a partire da 3,00€ presso le casse delle principali località sciistiche.`,
                    text2: `Scegli il pacchetto di garanzie Snowcare più adatto alle tue esigenze per tutelarti <strong> in caso di incidente e/o infortunio su sci e snowboard</strong>.`,
                    text3: `Oltre alla Responsabilità Civile obbligatoria, sono già incluse anche le garanzie di Tutela legale,<br/> 
                    Soccorso sulle piste, Spese mediche d’urgenza, Autista a disposizione e tante altre per una copertura completa. `,
                },
                silverBox: {
                    title: 'Silver',
                    description: 'Oltre alla RC obbligatoria, ti assicuri per incidenti e infortuni durante l’attività. Non comprende il soccorso sulle piste italiane a pagamento.'
                },
                goldBox: {
                    title: 'Gold',
                    description: 'Hai tutte le coperture del pacchetto SILVER, con l’aggiunta del soccorso sulle piste a pagamento nelle località aderenti.',
                    moreChosen: 'IL PIÙ SCELTO'
                },
                table: {
                    title1: 'GARANZIE OBBLIGATORIE',
                    // titleModal: 'Garanzie <span class="text-uppercase text-gold">GOLD</span>',
                    titleModal: 'Garanzie',
                    titleInternalModal: 'Garanzie',
                    title2: 'Già incluse nel prezzo',
                    titleMassimali: 'LIMITI',
                    garanzia1: {
                        label: 'Responsabilità civile per danni a persone e cose di terze',
                        labelModal: 'Responsabilità civile per danni <br/> a persone e cose di terze',
                        massimaleModal: '150.000€ danni a persone<br/><span>10.000€ danni a cose</span>',
                        massimale1: '<b>150.000€</b> danni a persone<br/><span><b>10.000€</b> danni a cose</span><br/><span>Franchigia fissa 500€ Scoperto 10%</span>',
                        massimale2: '<b>150.000€</b> danni a persone<br/><span><b>10.000€</b> danni a cose</span><br/><span>Franchigia fissa 500€ Scoperto 10%</span>'
                    },
                    garanzia2: {
                        label: 'Tutela legale',
                        massimale1: '1.000€',
                        massimale2: '1.000€'
                    },
                    garanzia3: {
                        label: '<span class="font-weight-700">Spese mediche d’urgenza</span><br><span class="small font-weight-400 italic">Si intendono esclusivamente le spese sostenute nell’immediatezza del sinistro, solo in caso di intervento del servizio di soccorso sulle piste nel luogo di accadimento del sinistro</span>',
                        labelModal: 'Spese mediche d’urgenza',
                        massimaleModal: '500€',
                        massimale1: '<b>500€</b><br/> <small>Esclusivamente presso le strutture sanitarie convenzionate</small>',
                        massimale2: '<b>500€</b> <br/><small>Esclusivamente presso le strutture sanitarie convenzionate</small>'
                    },
                    garanzia4: {
                        label: 'Autista a disposizione',
                        massimale1: 'Costo intero',
                        massimale2: 'Costo intero'
                    },
                    garanzia5: {
                        label: '<span class="font-weight-700">Soccorso sulle piste italiane</span><br><small>Solo in caso di intervento del servizio di soccorso sulle piste nel luogo di accadimento del sinistro</small>',
                        labelModal: 'Soccorso sulle piste italiane',
                        massimaleModal: '250€ in pista<br/> 5.000€ in elicottero',
                        massimale1: '-',
                        massimale2: '<b>250€</b> in pista<br/><span class="font-weight-400"><b>1.000€</b> in elicottero</span>'
                    },
                    garanzia6: {
                        label: '<span class="font-weight-700">Soccorso sulle piste straniere</span><br><small class="font-weight-400">Solo in caso di intervento del servizio di soccorso sulle piste nel luogo di accadimento del sinistro</small>',
                        labelModal: 'Soccorso sulle piste straniere',
                        massimaleModal: '400€ in pista<br/> 5.000€ in elicottero',
                        massimale1: '<b>400€</b> in pista<br/><span class="font-weight-400"><b>5.000€</b> in elicottero</span></td>',
                        massimale2: '<b>400€</b> in pista<br/><span class="font-weight-400"><b>5.000€</b> in elicottero</span></td>'
                    },
                    garanzia7: {
                        label: '<span class="font-weight-700">Annullamento skipass non goduto per infortunio,malattia oquarantena</span><br><small class="font-weight-400">Valida solo con skipass nominativi o con foto</small>',
                        labelModal: 'Annullamento skipass non goduto per infortunio, malattia o quarantena*',
                        massimale1: 'Quota non utilizzata',
                        massimale2: 'Quota non utilizzata'
                    },
                    garanzia8: {
                        label: '<span class="font-weight-700">Annullamento lezione e/o noleggio materiale sci per infortunio</span><br><small class="font-weight-400">Valida solo con skipass nominativi o con foto</small>',
                        labelModal: 'Annullamento lezione e/o noleggio materiale sci per infortunio',
                        massimaleModal: '100€/giorno',
                        massimale1: '<b>100€/giorno</b><br/><span class="font-weight-400">Franchigia fissa 50€</span>',
                        massimale2: '<b>100€/giorno</b><br/><span class="font-weight-400">Franchigia fissa 50€</span>'
                    },
                    garanzia9: {
                        label: '<span class="font-weight-700">Rientro sanitario</span><br><small class="font-weight-400">Valido solo con skipass nominativi o con foto</small>',
                        labelModal: 'Rientro sanitario*',
                        labelModalExpl: '*Valida solo con skipass nominativi o con foto',
                        massimale1: '5.000€',
                        massimale2: '5.000€'
                    },
                },
                supportCopy: `<p class="col-11 top-sm text-center mb-md-5 pb-md-4">
                            La polizza sci e snowboard Snowcare è disponibile a condizioni particolari <b>per i gruppi</b> (Sci club, CRAL, Circoli, Associazioni etc.). <br/>
                            Per richiedere un preventivo gratuito dell'assicurazione o richiedere informazioni visita il nostro <a target="_blank" href="https://help.24hassistance.com/hc/it/requests/new">sito di supporto</a>.
                        </p><br/>`,
                docs: {
                    title: 'Documentazione',
                    doc1: {
                        label: 'Set informativo Snowcare',
                        link: '/download/Assicurazione-sci-snowboard-Set-informativo-ITA_Sogessur.pdf'
                    },
                    doc2: {
                        label: 'DIP Snowcare',
                        link: '/download/DIP_Snowcare.pdf'
                    }
                },
                reviews: {
                    title: 'Gli altri utenti la consigliano',
                    description: 'Più di 1 milione di sciatori assicurati ogni anno e oltre il 90% di clienti soddisfatti.',
                    review1: {
                        title: 'Niente coda alle casse',
                        label1: 'Consigliata! Permette di risparmiare sulla polizza sci evitando le code in cassa',
                        label2: 'Greta, 3 mar ',
                    },
                    review2: {
                        title: 'Copertura giornaliera',
                        label1: 'Facile e veloce... anche solo per 1 giorno',
                        label2: 'Cristina, 2 feb',
                    },
                    review3: {
                        title: 'Facile e istantanea',
                        label1: 'Ottima polizza modalità di acquisto semplicissimo',
                        label2: 'Chiara, 31 gen',
                    },
                    review4: {
                        title: 'Utile e digitale',
                        label1: 'La consiglio a chi si reca in montagna in giornata. Ottima copertura e servizio di erogazione rapidissimo!',
                        label2: 'Gianni, 11 gen',
                    }
                }

            }
        },
        privacyComponent: {
            title: 'Consensi privacy'
        },
        checkoutComponent: {
            amountToPay: 'Totale da pagare',
            payMethods: 'Metodi di pagamento',
            changePayMethod: 'Cambia metodo di pagamento',
            saveForNextPurchase: 'Salva questo metodo per i prossimi acquisti',
            component: {
                iban: 'Bonifico bancario',
                ibanSubText: 'La copertura si attiverà alla ricezione del pagamento.',
                credit: 'Credito produttore',
            },
            buttons: {
                checkout: 'Acquista'
            }
        },
        thankYouComponent: {
            title: "Grazie per il tuo acquisto!",
            brainTree: {
                summary: "Ecco il riepilogo della polizza che hai acquistato:",
                weSentYouDocuments1: "Riceverai un'email con i documenti relativi alla tua polizza ",
                weSentYouDocuments2: ", entro 10 minuti, all'indirizzo email inserito in fase di preventivo.",
            },
            iban: {
                sendTo: "Devi inviare il bonifico a:",
                amount: "Importo",
                beneficiary: "Intestato a",
                reason: "Causale",
                quotationPurchase: "Acquisto preventivo",
                alert: "Attenzione! La copertura verrà attivata alla ricezione del pagamento"
            },
            button: "Torna all'homepage"
        },

        errors: {
            generic: 'Si è verificato un errore riprova più tardi.',
            quotationNotBuyable: 'Questo preventivo non è acquistabile.',
            pageUnauthorized: 'Non sei abilitato a visitare questa pagina.',
            api: {
                QUOTATION_ALREADYBOUGHT: "Preventivo già acquistato",
                QUOTATION_DELETED: 'Preventivo eliminato',
                QUOTATION_EXPIRED: 'Preventivo scaduto',
                OTP_CODE_INCORRECT: 'Il codice OTP non è corretto o è scaduto',
                OLD_PASSWORD_INCORRECT: 'La vecchia password non è corretta',
                PASSWORD_INCORRECT_FORMAT: 'La password non può superare i 20 caratteri',
                UNMODIFIABLE_DATA: "Dati non modificabili",
                UNMODIFIABLE_DATA_DATETIME: 'Data non più modificabile',
                INEXISTENT_DATA: 'Dati inesistenti',
                INVALID_DISCOUNT_CODE: 'Il codice sconto non è valido',
                UNHATHORIZED: 'Accesso non autorizzato',
                PRIVACY_1: 'Consenso dati sensibili obbligatorio',
                PFW_MP_NONUNIVOCO_EMAIL: 'Questa email risulta essere già associata al codice fiscale di un\'altra persona. E\' possibile che tu abbia già fatto un preventivo per un\'altra persona con questa email in passato. Per procedere con l\'acquisto devi inserire una email diversa.',
                PFW_MP_NONUNIVOCO_CELLULARE: 'Questo numero risulta essere già associato al codice fiscale di un\'altra persona. E\' possibile che tu abbia già fatto un preventivo per un\'altra persona con questo numero in passato. Per procedere con l\'acquisto devi inserire un numero di cellulare diverso.',
                DUPLICATED_CUSTOMER: 'Errore nell\'inserimento delle anagrafiche, se il problema persiste contatta il team support.',
                UNDERAGE_CUSTOMER: "Il contraente di polizza non può essere un minorenne.",
                INVALID_EMAIL_FORMAT: 'Formato email non valido',
                E00088: 'Pagamento fallito! Si è verificato un errore nel pagamento: riprova o rivolgiti alla tua banca per risolvere il problema',
                ADDRESS_IS_MANDATORY: 'Inserisci l\'indirizzo per proseguire'

            },
            emailUnivocModal: {
                title: `L'indirizzo email inserito è già utilizzato da un altro profilo`,
                body: `
                    Per procedere puoi:<br/><br/>
                    - utilizzare ora una mail diversa che possiedi e proseguire con l'acquisto<br/>
                    - entrare nell'<a href="/Website/Login.aspx" target="_blank">area riservata</a> del nominativo a cui è associata la mail che vuoi utilizzare per modificarla. (<a href="/W_Anagrafica/Anagrafica_RecuperaAccount.aspx" target="_blank">Clicca qui</a> per recuperare la password)<br/>
                    - <a href="https://help.24hassistance.com/hc/it/requests/new" target="_blank">aprire una richiesta</a> al servizio clienti per maggiori dettagli
                `
            },
            cellUnivoccModal: {
                title: 'Il cellulare inserito è già utilizzato da un altro profilo',
                body: `
                    Per procedere puoi:<br/><br/>
                    - utilizzare ora un numero diverso che possiedi e proseguire con l'acquisto<br/>
                    - entrare nell'<a href="/Website/Login.aspx" target="_blank">area riservata</a> del nominativo a cui è associato il numero che vuoi utilizzare per modificarlo. (<a href="/W_Anagrafica/Anagrafica_RecuperaAccount.aspx" target="_blank">Clicca qui</a> per recuperare la password)<br/>
                    - <a href="https://help.24hassistance.com/hc/it/requests/new" target="_blank">aprire una richiesta</a> al servizio clienti per maggiori dettagli
                `
            }
        }
    },
    en: {
        global: {
            h1: 'Ski and snowboard insurance quote',
            breadcrumbs: {
                quotation: 'Get a quote',
                registry: 'Registry',
                checkout: 'Checkout'
            },
            registry: {
                title: {
                    contractor: 'Policyholder data',
                    beneficiary: "Insured data",
                },
                form: {
                    name: 'Name',
                    surname: 'Last name',
                    companyName: 'Company name',
                    imAcompany: "I am a company",
                    imAperson: "I am a natural person",
                    nationality: 'Nationality',
                    birthDate: 'Date of birth',
                    birthDatePlaceHolder: 'DD/MM/YYYY',
                    vatId: 'VAT number',
                    taxId: 'Fiscal code',
                    email: 'Email',
                    phone: 'Mobile phone',
                    phoneSubtext: '<b>Enter a valid phone number</b><br/>You will need it to issue the policy and receive the documentation. We will not disturb you for commercial actions.',
                    address: {
                        residence: 'Are you resident in Italy?',
                        autocomplete: {
                            label: 'Address',
                            subText: 'Start typing your address'
                        },
                        street: 'Street',
                        number: 'Street number',
                        city: 'City',
                        postalCode: 'ZIP code',
                        province: 'Province',
                        country: 'Country',
                        buttons: {
                            searchAgain: "Search for the address again"
                        }
                    },
                    maritalStatus: 'Marital status',
                    profession: 'Job',
                    buttons: {
                        companySelection1: 'I am a company',
                        companySelection2: 'I am a natural person',
                        reloadAddress: 'Type the address again',
                        checkout: 'Continue'
                    },
                    errors: {
                        generic: {
                            required: 'This field is required',
                            minLength2Char: 'It must be at least 2 characters long',
                            noSpecialCharsOrNumbers: 'It must not contain numbers or special characters',
                            invalidFormat: 'Invalid format'
                        },
                        surname: {
                            unique: 'Please, check that your first and last name are different'
                        },
                        fiscalCode: {
                            differentFromOtherRegistries: 'The fiscal code must be different from the one used in the other registry',
                            nameNotCorresponding: 'The fiscal code does not match the name',
                            surnameNotCorresponding: 'The fiscal code does not match the last name',

                        },
                        province: {
                            mustMatchProvince: 'Non an italian province'
                        },
                        email: {
                            alreadyTaken: 'The email address is already linked with another user'
                        },
                        phone: {
                            invalidFormat: 'Invalid format, please add international prefix e.g. (+44)',
                            addPlusThreeNine: 'Insert the phone number with the country code (+44)',
                            alreadyTaken: 'The phone number is already linked with another user'

                        },
                        date: {
                            notBefore: 'The start date cannot be higher than the end date'
                        }
                    }
                }
            }
        },
        snowcare: {
            quotation: {
                title: 'Your quote data',
                form: {
                    howMany: {
                        label: 'How many people do you want to insure?',
                    },
                    age: {
                        age1: 'Aged 8+',
                        age2: 'Aged 0 to 7',
                        adult: 'Adult',
                        adults: 'Adults',
                        child:'Child',
                        children:'Children',
                        note: 'Your age at the time of purchase'
                    },
                    policyDuration: {
                        label: 'Select the date range',
                        btn1: 'Policy start date',
                        btn2: 'End date',
                        today: 'Today',
                        alertToday: "The policy will be active 20 minutes after purchase",
                        alertFromToday: "The policy will be active 20 minutes after purchase and will end at",
                        alertNotTodayPre: "The policy will be active from",
                        alertNotTodayAfter: "and will end on",
                        at: "at",
                        errors: {
                            startOlderThanSixty: 'The coverage start date cannot be more than 60 days from today',
                            startDateLessThanToday: 'The start date has been updated'
                        }
                    },
                    modal: {
                        day: "day",
                        days: "days",
                        confirm: "Confirm",
                    },
                },
                findOutMoreButton: 'Learn more about Snowcare',
                bundles: {
                    modal: {
                        title: 'Are you sure?',
                        message: 'With the GOLD package, you are also refunded in case of rescue on Italian slopes for a fee (average cost €200).',
                        btn1: ' Taking a chance, I choose SILVER',
                        btn2: 'Keep GOLD'
                    },
                    title: 'Choose the bundle',
                    mostSold: 'Best seller',
                    silver: {
                        name: 'Silver',
                        description: "Includes <b>compulsory TPL</b> + Emergency medical expenses + Ski pass/lesson cancellation + Legal protection and many other guarantees.",
                        guaranteesLabel: 'All guarantees',
                        modal: {
                            title: 'SILVER Guarantees',
                            subTitle: 'In addition to the mandatory Third party liability, you are insured for accidents and injuries during the activity. It does not include rescue on the Italian ski slopes for a fee.',
                            content: TABLE_EN_SILVER
                        }
                    },
                    gold: {
                        name: 'Gold',
                        description: 'Includes <b>compulsory TPL</b> + Emergency medical expenses + Ski pass/lesson cancellation + Legal protection + <b>Slope rescue</b> and many other guarantees.',
                        guaranteesLabel: 'All guarantees',
                        modal: {
                            title: 'GOLD Guarantees',
                            subTitle: 'You will have all the covers of the SILVER package, with the addition of the rescue on the ski slopes for a fee in the participant locations.',
                            content: TABLE_EN_GOLD
                        }
                    }
                },
                buttons: {
                    calculate: 'Calculate',
                    email: 'Receive by email',
                    next: 'Continue'
                },
                emailModal: {
                    title: 'Receive by email',
                    recap: 'Insert your email to receive a summary of your Snowcare quote. Remember that to lock the price and save a purchasable quote, you must click PROCEED by entering all the necessary data.',
                    email: 'email@email.com',
                    consent: 'I consent to the processing of my personal data also for commercial purposes (I can revoke it at any time)',
                    validate: 'Please, insert a valid email address',
                    btn: 'Send',
                    stepMail: "Email sent",
                    btn2: 'Close'
                },
                price: {
                    label: 'Your price'
                }
            },
            sideBar: {
                policy: {
                    title: 'Policy summary',
                    value: 'Policy',
                    individual: 'Individual',
                    group: 'More than one person',
                    adult: 'Adult',
                    kid: 'Child',
                    number: 'Quotation N.',
                    labels: {
                        duration: 'Duration',
                        startDate: 'Start date',
                        endDate: 'End date',
                        total: 'Total',
                        day: 'Day',
                        days: 'Days',
                        today: "Today - 20 min from purchase"
                    }
                },
                customer: {
                    title: 'Policyholder data'
                },
                insuree: {
                    title: 'Insured data'
                },
                group: {
                    title: 'Insured persons data',
                    componentsNumber: 'N° insured persons',
                }
            },
            registry: {
                groups: {
                    title: {
                        singular:"Insured data",
                        plural:"Insured data",
                    },
                    isCustomerAlsoInsuree: {
                        label: 'Are you the person to be insured?',
                        btn1: 'Yes',
                        btn2: 'No',
                    },
                    form: {
                        title: 'Insured persons data',
                        label: 'Contractor',
                        adult: 'Adult',
                        child: 'Child',
                        input1: 'Name and surname',
                        input2: 'Date of birth',
                        link: 'Did you forget someone?',
                        modalTitle: 'Do you want to add an insuree?',
                        name: 'Name',
                        surname: 'Surname',
                        birthDatePlaceHolder: 'DD/MM/YYYY',
                        birthDateInvalidErrors: 'The date is not valid',
                        birthDateKidInvalidErrors: 'The birthdate is not related to a child',
                        birthDateAdultInvalidErrors: 'The birthdate is not related to an adult',

                        recap: 'Your quote'
                    },
                },
                buttons: {
                    next: 'Continue',
                    save:'Save',
                }
            },
            policy: {
                adultPrice: 'Unit price adult',
                childPrice: 'Unit price child',
            },
            discountCode: {
                link: 'Do you have a discount code?',
                input: 'Discount code',
                label: 'Your quote'
            },
            checkout: {
                title: 'Purchase your policy'
            },
            fiscalCode: {
                calculate: "Calculate your fiscal code",
                birthDatePlaceHolder: 'DD/MM/YYYY',
                insertBirthDate: "Insert your birthdate",
                province: "Prov.",
                birthPlace: "Place of birth",
                gender: "Sex",
                genderM: "Male",
                genderF: "Female"
            },
            productComponent: {
                benefits: {
                    title: 'Snowcare is the most popular ski policy with liability in Europe for your one or multi-day snow activities',
                    box1: {
                        title: 'Slope rescue included',
                        description: 'Protect yourself from any extra expenses even in resorts where piste rescue is charged (average cost €200)!',
                        linkText: 'All guarantees'
                    },
                    box2: {
                        title: 'Online or directly with your skipass',
                        description: 'No forms to sign and no extra queues at the ticket office. In case of an accident and/or injury on skis or snowboard the check will be done directly by the slope rescue.',
                        linkText: 'Discover partner ski resorts'
                    },
                    box3: {
                        title: 'Ski pass refund for quarantine',
                        description: "With Snowcare insurance you are covered even if you can't go skiing due to illness or quarantine before the start of your skipass validity.",
                        linkText: 'Discover bundles'
                    },
                    text1: 'The Snowcare policy is also valid for a single day, for you or for a group of people starting from €3.00 per day.',
                    alert: {
                        title: 'This year, liability insurance is mandatory on the ski slopes!',
                        description: `Always keep your skipass: the insurance is tied to your ski pass and is valid only in case of a request for <br />
                        rescue intervention on the slopes at the place where the accident occurred.`,
                    }
                },
                partner: {
                    title: 'Our partners',
                    description: 'You can find us in major ski resorts and we collaborate with top mountain-loving brands.',
                    linkText: 'Discover all partner'
                },
                pacchetti: {
                    title: 'Discover the details of Snowcare coverage',
                    text1: `Snowcare is the <strong>ski and snowboard insurance</strong> that protects more than one million skiers each year.<br/>
                        You can purchase <strong>daily ski pass insurance</strong> starting at €3.00 at checkouts at major ski resorts.`,
                    text2: `Choose the Snowcare guarantee package that best suits your needs to protect you <strong>in the event of an accident and/or injury on skis and snowboards</strong>.`,
                    text3: `In addition to the mandatory Civil Liability, Legal Protection guarantees are already included,<br/> 
                    Slope Rescue, Emergency Medical Expenses, Chauffeur service and many others for comprehensive coverage.`,
                },
                silverBox: {
                    title: 'Silver',
                    description: 'In addition to the mandatory Third party liability, you are insured for accidents and injuries during the activity. It does not include rescue on the Italian ski slopes for a fee.'
                },
                goldBox: {
                    title: 'Gold',
                    description: 'You will have all the covers of the SILVER package, with the addition of the rescue on the ski slopes for a fee in the participant locations.',
                    moreChosen: 'BEST SELLER'
                },
                table: {
                    title1: 'COMPULSORY INSURANCE COVERAGE',
                    // titleModal: '<span class="text-uppercase text-gold">GOLD</span> Guarantees',
                    titleModal: 'Guarantees',
                    titleInternalModal: 'INSURANCE COVERAGE',
                    title2: 'INCLUDED IN THE PRICE',
                    titleMassimali: 'LIMITS',
                    garanzia1: {
                        label: 'Third-party liability for personal injury and property damage',
                        labelModal: 'Third-party liability for personal <br/>injury and property damage',
                        massimaleModal: '150.000€ personal injury<br/><span>10.000€ property damage</span>',
                        massimale1: '<b>150.000€</b> personal injury<br/><span><b>10.000€</b> property damage</span><br/><span>Fixed excess 500€ Deductible 10%</span>',
                        massimale2: '<b>150.000€</b> personal injury<br/><span><b>10.000€</b> property damage</span><br/><span>Fixed excess 500€ Deductible 10%</span>'
                    },
                    garanzia2: {
                        label: 'Legal protection',
                        massimale1: '1.000€',
                        massimale2: '1.000€'
                    },
                    garanzia3: {
                        label: '<span class="font-weight-700">Emergency medical expenses</span><br><span class="small font-weight-400 italic">Referring exclusively to costs incurred in the immediate aftermath of an accident, only in the event that the rescue service is called for assistance at the site of the accident on the slopes',
                        labelModal: 'Emergency medical expenses <br/>',
                        massimaleModal: '500€',
                        massimale1: '<b>500€</b> <br/><small> Exclusively at participating healthcare facilities</small>',
                        massimale2: '<b>500€</b> <br/><small> Exclusively at participating healthcare facilities</small>'
                    },
                    garanzia4: {
                        label: 'Private chauffeur service',
                        massimale1: 'Full price',
                        massimale2: 'Full price'
                    },
                    garanzia5: {
                        label: '<span class="font-weight-700">First aid on Italian slopes</span><br><small>Only in the event that the rescue service is called for assistance at the site of the accident on the slopes</small>',
                        labelModal: 'First aid on Italian slopes',
                        massimaleModal: '250€ on slopes<br/> 5.000€ helicopter</br> transportation',
                        massimale1: '-',
                        massimale2: '<b>250€</b> on slopes<br/><span class="font-weight-400"><b>1.000€</b> helicopter transportation</span>'
                    },
                    garanzia6: {
                        label: '<span class="font-weight-700">First aid on non-Italian slopes</span><br><small class="font-weight-400">Only in the event that the rescue service is called for assistance at the site of the accident on the slopes</small>',
                        labelModal: 'First aid on non-Italian slopes',
                        massimaleModal: '400€ on slopes<br/> 5.000€ helicopter transportation',
                        
                        massimale1: '<b>400€</b> on slopes<br/><span class="font-weight-400"><b>5.000€</b> helicopter transportation</span></td>',
                        massimale2: '<b>400€</b> on slopes<br/><span class="font-weight-400"><b>5.000€</b> helicopter transportation</span></td>',
                    },
                    garanzia7: {
                        label: "<span class='font-weight-700'>Cancellation of skipass not used due to accident, illness or quarantine</span> <br><small>Valid only with skipass bearing user's name or photograph</small>",
                        
                        labelModal: 'Cancellation of skipass not used due</br> to accident, illness or quarantine*',
                        massimale1: 'Unused part',
                        massimale2: 'Unused part'
                    },
                    garanzia8: {
                        label: "<span class='font-weight-700'>Cancellation of skiing lessons and/or ski equipment hire due to accident</span><br><small class='font-weight-400'>Valid only with skipass bearing user's name or photograph</small>",
                        labelModal: 'Cancellation of skiing lessons and/or ski equipment hire due to accident',
                        massimaleModal: '100€/day',
                        massimale1: '<b>100€/day</b><br/><span class="font-weight-400">Fixed excess 50€</span>',
                        massimale2: '<b>100€/day</b><br/><span class="font-weight-400">Fixed excess 50€</span>'
                    },
                    garanzia9: {
                        label: "<span class='font-weight-700'>Medical repatriation</span><br><small class='font-weight-400'>Valid only with skipass bearing user's name or photograph</small>",
                        labelModal: 'Medical repatriation*',
                        labelModalExpl: "*Valid only with skipass bearing user's name or photograph",
                        massimale1: '5.000€',
                        massimale2: '5.000€'
                    },
                },
                supportCopy: `<p class="col-11 top-sm text-center mb-md-5 pb-md-4">
                            Snowcare ski and snowboard policy is available at special conditions <b>for groups</b> (Ski club, Clubs, Associatons etc.). <br/>
                            To request a free insurance quote or request information visit our <a target="_blank" href="https://help.24hassistance.com/hc/it/requests/new">help center</a>.
                        </p><br/>`,
                docs: {
                    title: 'General insurance conditions',
                    doc1: {
                        label: 'Snowcare information set',
                        link: '/download/Assicurazione-sci-snowboard-Set-informativo-ENG_Sogessur.pdf'
                    },
                    doc2: {
                        label: 'DIP Snowcare',
                        link: '/download/DIP_Snowcare.pdf'
                    }
                },
                reviews: {
                    title: 'Other users recommend it',
                    description: 'More than 1 million skiers insured each year and over 90% satisfied customers.',
                    review1: {
                        title: 'No queue at ticket offices',
                        label1: 'Recommended. Allows you to save money on your ski policy while avoiding ticket office queues',
                        label2: 'Greta, 3 mar ',
                    },
                    review2: {
                        title: 'Daily coverage',
                        label1: 'Quick and easy... even for just 1 day',
                        label2: 'Cristina, 2 feb',
                    },
                    review3: {
                        title: 'Instant and easy',
                        label1: 'Excellent policy mode of purchase very simple',
                        label2: 'Chiara, 31 jan',
                    },
                    review4: {
                        title: 'Useful and digital',
                        label1: 'I recommend it for day-trippers to the mountains. Great coverage and very fast checkout!',
                        label2: 'Gianni, 11 jan',
                    }
                }
            }
        },
        privacyComponent: {
            title: 'Privacy consents'
        },
        checkoutComponent: {
            amountToPay: 'Total amount',
            payMethods: 'Payment methods',
            changePayMethod: 'Change payment method',
            saveForNextPurchase: 'Save this method for your next purchases',
            component: {
                iban: 'Wire transfer',
                ibanSubText: 'Coverage will be activated from receipt of payment.',
                credit: 'Partner credit',
            },
            buttons: {
                checkout: 'Buy now'
            }
        },
        thankYouComponent: {
            title: "Thank you for your purchase!",
            brainTree: {
                summary: "Check the episode of the policy you have acquired:",
                weSentYouDocuments1: "You will receive an email with the documents related to your ",
                weSentYouDocuments2: " policy, within 10 minutes, to the email address you entered during the quote process.",
            },
            iban: {
                sendTo: "You have to send the transfer to:",
                amount: "Amount",
                beneficiary: "Made out to",
                reason: "Reason",
                quotationPurchase: "Purchase insurance",
                alert: "Coverage will be active from receipt of payment."
            },
            button: "Back to homepage"
        },
        errors: {
            generic: 'An error occurred, please try again later.',
            quotationNotBuyable: 'This quote is not available for purchase.',
            pageUnauthorized: 'You are not authorized to visit this page.',
            api: {
                QUOTATION_ALREADYBOUGHT: "This quote is already purchased",
                QUOTATION_DELETED: 'Quote deleted',
                QUOTATION_EXPIRED: 'This quote is expired, calculate a new one',
                OTP_CODE_INCORRECT: 'The OTP code is incorrect or has expired',
                OLD_PASSWORD_INCORRECT: 'The old password is incorrect',
                PASSWORD_INCORRECT_FORMAT: 'The password cannot exceed 20 characters.',
                UNMODIFIABLE_DATA: "Non-editable data",
                UNMODIFIABLE_DATA_DATETIME: 'The date is no longer editable',
                INEXISTENT_DATA: 'Non-existing data',
                INVALID_DISCOUNT_CODE: 'The discount code is not valid',
                UNHATHORIZED: 'Unauthorized access',
                PRIVACY_1: 'Consent for processing of sensitive data mandatory',
                PFW_MP_NONUNIVOCO_EMAIL: 'This email is already associated with another person\'s tax code. It is possible that you have already made a quote for another person with this email in the past. You must enter a different email to proceed with the purchase.',
                PFW_MP_NONUNIVOCO_CELLULARE: 'This number is already associated with another person\'s tax code. It is possible that you have already made an estimate for another person with this number in the past. You must enter a different mobile phone number to proceed with the purchase.',
                DUPLICATED_CUSTOMER: 'Error entering master data, if the problem persists please contact the support team.',
                UNDERAGE_CUSTOMER: "The policyholder cannot be a minor.",
                INVALID_EMAIL_FORMAT: 'Invalid email format',
                E00088: 'Payment failed! A payment error occurred: try again or contact your bank to resolve the problem',
                ADDRESS_IS_MANDATORY: 'Insert address to proceed'
            },
            emailUnivocModal: {
                title: `The email address entered is already being used by another profile`,
                body: `
                    To proceed you can:<br/><br/>
                    - use a different email and continue with this purchase<br/>
                    - access the <a href="/Website/Login.aspx" target="_blank">private area</a> of the user with which the phone number you want to use is associated to change it.(<a href="/W_Anagrafica/Anagrafica_RecuperaAccount.aspx" target="_blank">Click here</a> if you forgot the password)<br/>
                    - <a href="https://help.24hassistance.com/hc/it/requests/new" target="_blank">open a customer service request</a> for more details
                `
            },
            cellUnivoccModal: {
                title: 'The phone number entered is already being used by another profile',
                body: `
                    To proceed you can:<br/><br/>
                    - use a different phone number and continue with this purchase<br/>
                    - access the <a href="/Website/Login.aspx" target="_blank">private area</a> of the user with which the email you want to use is associated to change it. (<a href="/W_Anagrafica/Anagrafica_RecuperaAccount.aspx" target="_blank">Click here</a> if you forgot the password)<br/>
                    - <a href="https://help.24hassistance.com/hc/it/requests/new" target="_blank">open a customer service request</a> for more details
                `
            }
        }
    },
    de: {
        global: {
            h1: 'Angebot für Ski- und Snowboardversicherung',
            breadcrumbs: {
                quotation: 'Angebot',
                registry: 'Registrierung',
                checkout: 'Kauf'
            },
            registry: {
                title: {
                    contractor: 'Daten des Auftragnehmers',
                    beneficiary: 'Daten des Versicherten',
                },
                form: {
                    name: 'Vorname',
                    surname: 'Nachname',
                    companyName: 'Firmenname',
                    imAcompany: "Ich bin ein Unternehmen",
                    imAperson: "Ich bin eine natürliche Person",
                    nationality: 'Staatsangehörigkeit',
                    birthDate: 'Geburtsdatum',
                    birthDatePlaceHolder: 'TT/MM/JJJJ',
                    vatId: 'Abgabenordnung',
                    taxId: 'Umsatzsteuer-Identifikationsnummer',
                    email: 'Email',
                    phone: 'Telefon',
                    phoneSubtext: '<b>Geben Sie eine gültige Rufnummer ein</b> <br/>Sie benötigen es, um die Police auszustellen und die Unterlagen zu erhalten. Wir werden Sie nicht für kommerzielle Aktionen stören.',
                    address: {
                        residence: 'Haben Sie Ihren Wohnsitz in Italien?',
                        autocomplete: {
                            label: 'Adresse',
                            subText: 'Fange an deine Adresse einzugeben'
                        },
                        street: 'Straße',
                        number: 'Nummer',
                        city: 'Stadt',
                        postalCode: 'Postleitzahl',
                        province: 'Provinz',
                        country: 'Land',
                        buttons: {
                            searchAgain: "Suchen Sie erneut nach der Adresse"
                        }
                    },
                    maritalStatus: 'Familienstand',
                    profession: 'Beruf',
                    buttons: {
                        companySelection1: 'Ich bin ein Unternehmen',
                        companySelection2: 'Ich bin eine natürliche Person',
                        reloadAddress: 'Geben Sie die Adresse erneut ein',
                        checkout: 'Aufleuchten'
                    },
                    errors: {
                        generic: {
                            required: 'Dieses Feld ist obligatorisch',
                            minLength2Char: 'Er muss mindestens 2 Zeichen lang sein',
                            noSpecialCharsOrNumbers: 'Er darf keine Zahlen oder Sonderzeichen enthalten',
                            invalidFormat: 'Ungültiges Format'
                        },
                        surname: {
                            unique: 'Bitte überprüfen Sie, ob Ihr Vor- und Nachname unterschiedlich sind.'
                        },
                        fiscalCode: {
                            differentFromOtherRegistries: 'Die Steuernummer muss sich von der in dem anderen Register verwendeten unterscheiden.',
                            nameNotCorresponding: 'Die Steuernummer stimmt nicht mit dem Namen überein',
                            surnameNotCorresponding: 'Die Steuernummer stimmt nicht mit dem Nachnamen überein',

                        },
                        province: {
                            mustMatchProvince: 'Non è una provincia italiana'
                        },
                        email: {
                            alreadyTaken: 'Die E-Mail-Adresse ist bereits mit einem anderen Benutzer verknüpft'
                        },
                        phone: {
                            invalidFormat: 'Ungültiges Format, bitte fügen Sie die Landesvorwahl hinzu, z.B. (+44)',
                            addPlusThreeNine: 'Wählen Sie die Nummer mit der internationalen Vorwahl (+44)',
                            alreadyTaken: 'Die Rufnummer ist bereits mit einem anderen Benutzer verknüpft'

                        },
                        date: {
                            notBefore: 'Das Startdatum kann nicht höher sein als das Enddatum'
                        }
                    }
                }
            }
        },
        snowcare: {
            quotation: {
                title: 'Ihre Angebotsdaten',
                form: {
                    howMany: {
                        label: 'Wie viele Personen wollen Sie versichern?',
                    },
                    age: {
                        age1: '8 Jahre und älter',
                        age2: '0 bis 7 Jahre',
                        adult: 'Erwachsene',
                        adults: 'Erwachsene',
                        child:'Kind',
                        children:'Kinder',
                        note: 'Ihr Alter zum Zeitpunkt des Kaufs'
                    },
                    policyDuration: {
                        label: 'Wählen Sie den Datumsbereich',
                        btn1: 'Datum des Versicherungsbeginns',
                        btn2: 'Datum des Endes',
                        today: 'Heute',
                        alertToday: "Die Police wird 20 Minuten nach dem Kauf aktiv",
                        alertFromToday: "Die Police wird 20 Minuten nach dem Kauf aktiv und endet am",
                        alertNotTodayPre: "Die Police ist ab",
                        alertNotTodayAfter: "aktiv und endet am",
                        at: "um",
                        errors: {
                            startOlderThanSixty: 'Das Datum des Versicherungsbeginns darf nicht später als 60 Tage ab dem heutigen Tag liegen.',
                            startDateLessThanToday: 'Das Datum des Inkrafttretens wurde aktualisiert'
                        }
                    },
                    modal: {
                        day: "Tag",
                        days: "Tage",
                        confirm: "Bestätigen",
                    },
                },
                findOutMoreButton: 'Mehr über Snowcare erfahren',
                bundles: {
                    modal: {
                        title: 'Sind Sie sicher?',
                        message: 'Mit dem GOLD-Paket erhalten Sie auch im Falle einer Rettung auf italienischen Pisten gegen eine Gebühr (Durchschnittskosten 200 €) eine Rückerstattung.',
                        btn1: ' Ich gehe das Risiko ein, ich wähle SILVER',
                        btn2: 'Behalten Sie GOLD'
                    },
                    title: 'Wählen Sie das Bundle',
                    mostSold: 'Verkaufsschlager',
                    silver: {
                        name: 'Silver',
                        description: "Inklusive <b>obligatorischer TPL</b> + Medizinische Notfallkosten + Stornierung von Skipässen/Kursen + Rechtsschutz und viele weitere Garantien",
                        guaranteesLabel: 'Alle Garantien',
                        modal: {
                            title: 'SILVER Garantien',
                            subTitle: 'Zusätzlich zur obligatorischen Haftpflichtversicherung sind Sie für Unfälle und Verletzungen während der Aktivität versichert. Sie umfasst nicht die kostenpflichtige Rettung auf den italienischen Skipisten.',
                            content: TABLE_DE_SILVER
                        }
                    },
                    gold: {
                        name: 'Gold',
                        description: 'Inklusive <b>obligatorischer TPL</b> + Medizinische Notfallkosten + Stornierung von Skipässen/Kursen + Rechtsschutz + <b>Pistenrettung</b> und viele weitere Garantien',
                        guaranteesLabel: 'Alle Garantien',
                        modal: {
                            title: 'GOLD Garantien',
                            subTitle: 'Sie erhalten alle Leistungen des SILVER-Pakets, zusätzlich die Pistenrettung gegen Gebühr in den Teilnehmerorten.',
                            content: TABLE_DE_GOLD
                        }
                    }
                },
                buttons: {
                    calculate: 'Berechnen Sie',
                    email: 'Per E-Mail erhalten',
                    next: 'Weiter'
                },
                emailModal: {
                    title: 'Per E-Mail erhalten',
                    recap: 'Geben Sie Ihre E-Mail-Adresse ein, um eine Zusammenfassung Ihres Snowcare-Angebots zu erhalten. Denken Sie daran, dass Sie auf WEITER klicken und alle erforderlichen Daten eingeben müssen, um den Preis festzulegen und ein kaufbares Angebot zu speichern.',
                    email: 'email@email.com',
                    // @TODO: traduz
                    consent: 'Ich willige in die Verarbeitung meiner personenbezogenen Daten auch zu kommerziellen Zwecken ein (ich kann diese Einwilligung jederzeit widerrufen)',
                    validate: 'Bitte eine gültige E-Mail eingeben',
                    btn: 'Senden',
                    stepMail: "E-Mail gesendet",
                    btn2: 'Schließen Sie'
                },
                price: {
                    label: 'Ihr Preis'
                }
            },
            sideBar: {
                policy: {
                    title: 'Zusammenfassung der Richtlinien',
                    value: 'Versicherung',
                    individual: 'Einzelperson',
                    group: 'Mehr als eine Person',
                    adult: 'Erwachsene',
                    kid: 'Kind',
                    number: 'N. Präventiv',
                    labels: {
                        duration: 'Dauer',
                        startDate: 'Datum des Beginns',
                        endDate: 'Enddatum',
                        total: 'Total',
                        day: 'Tag',
                        days: 'Tage',
                        today: "Heute - 20 min ab Kauf"
                    }
                },
                customer: {
                    title: 'Auftragnehmer'
                },
                insuree: {
                    title: 'Versichert'
                },
                group: {
                    title: 'Daten der versicherten Personen',
                    componentsNumber: 'Anzahl der versicherten Personen',
                }
            },
            registry: {
                groups: {
                    title: {
                        singular:"Daten des Versicherten’",
                        plural:"Daten des Versicherten’",
                    },
                    isCustomerAlsoInsuree: {
                        label: 'Sind Sie die zu versichernde Person?',
                        btn1: 'Ja',
                        btn2: 'Nein',
                    },
                    form: {
                        title: 'Daten der versicherten Personen',
                        label: 'Auftragnehmer',
                        adult: 'Erwachsene',
                        child: 'Kind',
                        input1: 'Vorname and Nachname',
                        input2: 'Geburtsdatum',
                        link: 'Haben Sie jemanden vergessen?',
                        modalTitle: 'Möchten Sie eine versicherte Person hinzufügen?',
                        name: 'Vorname',
                        surname: 'Nachname',
                        birthDatePlaceHolder: 'TT/MM/JJJJ',
                        birthDateInvalidErrors: 'Das Datum ist nicht gültig',
                        // @TODO: trad
                        birthDateKidInvalidErrors: 'Das Geburtsdatum bezieht sich nicht auf ein Kind',
                        birthDateAdultInvalidErrors: 'Das Geburtsdatum bezieht sich nicht auf ein Erwachsene',
                        recap: 'Ihr Zitat'
                    },
                },
                buttons: {
                    next: 'Weiter',
                    save:'Speichern',
                }
            },
            policy: {
                adultPrice: 'Einheitspreis für Erwachsene',
                childPrice: 'Einzelpreis Kind',
            },
            discountCode: {
                link: 'Haben Sie einen Rabattcode?',
                input: 'Rabatt-Code',
                label: 'Ihr Angebot'
            },
            checkout: {
                title: 'Versicherung kaufen'
            },
            fiscalCode: {
                calculate: "Steuerkennzeichen berechnen",
                birthDatePlaceHolder: 'TT/MM/JJJJ',
                insertBirthDate: "Eingabe des Geburtsdatums",
                province: "Prov.",
                birthPlace: "Geburtsort",
                // validation: "Hausnummer eingeben",
                gender: "Sex",
                genderM: "Mann",
                genderF: "Frau"
            },
            productComponent: {
                benefits: {
                    title: 'Snowcare ist die beliebteste Ski-Haftpflichtversicherung in Europa für Ihre ein- oder mehrtägigen Schneeaktivitäten',
                    box1: {
                        title: 'Pistenrettung inklusive',
                        description: 'Schützen Sie sich vor zusätzlichen Kosten, auch in Skigebieten, in denen die Pistenrettung kostenpflichtig ist (Durchschnittskosten 200 €)!',
                        linkText: 'Alle Garantien'
                    },
                    box2: {
                        title: 'Online oder direkt mit Ihrem Skipass',
                        description: 'Sie müssen keine Formulare unterschreiben und müssen nicht extra an der Kauf anstehen. Im Falle eines Unfalls und/oder einer Verletzung auf den Skiern oder dem Snowboard wird die Kontrolle direkt von der Pistenrettung durchgeführt.',
                        linkText: 'Entdecken Sie Partnerskigebiete'
                    },
                    box3: {
                        title: 'Skipass-Rückerstattung bei Quarantäne',
                        description: 'Mit der Snowcare-Versicherung sind Sie auch dann abgesichert, wenn Sie aufgrund von Krankheit oder Quarantäne vor Beginn der Gültigkeit Ihres Skipasses nicht Skifahren können.',
                        linkText: 'Pakete entdecken'
                    },
                    text1: 'Der Snowcare-Vertrag gilt auch für einen einzelnen Tag, für Sie oder eine Gruppe ab 3,00 € pro Tag.',
                    alert: {
                        title: 'Dieses Jahr ist die Haftpflichtversicherung auf den Skipisten Pflicht!',
                        description: `Bewahren Sie immer Ihren Skipass auf: Die Versicherung ist an Ihren Skipass gebunden und gilt nur für den Fall,<br/> dass ein Rettungseinsatz auf der Piste am Ort des Unfalls angefordert wird.`,
                    }
                },
                partner: {
                    title: 'Unsere Partner',
                    description: 'Sie finden uns in den großen Skigebieten und wir arbeiten mit den besten Marken zusammen, die die Berge lieben.',
                    linkText: 'Alle Partner entdecken'
                },
                pacchetti: {
                    title: 'Entdecken Sie die Details zum Snowcare-Schutz',
                    text1: `Snowcare ist die <strong>Ski- und Snowboardversicherung</strong>, die jedes Jahr mehr als eine Million Skifahrer schützt.<br/>
                        Sie können eine <strong>Tages-Skipassversicherung</strong> ab 3,00 € an den Kassen der großen Skigebiete abschließen.`,
                    text2: `Wählen Sie das Snowcare-Garantiepaket, das am besten zu Ihren Bedürfnissen passt, <strong>um Sie im Falle eines Unfalls und/oder einer Verletzung auf Skiern und Snowboards zu schützen</strong>.`,
                    text3: `Neben der obligatorischen Haftpflicht sind bereits Rechtsschutzgarantien, Pistenrettung, medizinische Notfallkosten,<br/>
                    Fahrer zur verfügung und viele andere für einen umfassenden Schutz enthalten.`,
                },
                silverBox: {
                    title: 'Silver',
                    description: 'Zusätzlich zur obligatorischen Haftpflichtversicherung sind Sie für Unfälle und Verletzungen während der Aktivität versichert. Sie umfasst nicht die kostenpflichtige Rettung auf den italienischen Skipisten.'
                },
                goldBox: {
                    title: 'Gold',
                    description: 'Sie erhalten alle Leistungen des SILVER-Pakets, zusätzlich die Pistenrettung gegen Gebühr in den Teilnehmerorten.',
                    moreChosen: 'Verkaufsschlager'
                },
                table: {
                    title1: 'HAFTPLICHT VERSICHERUNGSSCHUTZ',
                    // titleModal: '<span class="text-uppercase text-gold">GOLD</span> Garantien',
                    titleModal: 'Garantien',
                    titleInternalModal: 'VERSICHERUNGSSCHUTZ',
                    title2: 'Bereits im Preis inbegriffen',
                    titleMassimali: 'LIMITS',
                    garanzia1: {
                        label: 'Haftpflichtversicherung für Personen- und Sachschäden',
                        labelModal: 'Haftpflichtversicherung für <br/>Personen- und Sachschäden',
                        massimaleModal: '150.000€ Personenschäden</br> <span>10.000€ Sachschäden</span>',
                        
                        massimale1: '<b>150.000€</b> Personenschäden<br/><span><b>10.000€</b> Sachschäden</span><br/><span>Pauschaler Selbstbehalt 500€ Selbstbeteiligung 10%</span>',
                        massimale2: '<b>150.000€</b> Personenschäden<br/><span><b>10.000€</b> Sachschäden</span><br/><span>Pauschaler Selbstbehalt 500€ Selbstbeteiligung 10%</span>'
                    },
                    garanzia2: {
                        label: 'Rechtsschutz',
                        massimale1: '1.000€',
                        massimale2: '1.000€'
                    },
                    garanzia3: {
                        label: '<span class="font-weight-700">Notarztkosten</span><br><span class="small font-weight-400 italic"> Hierzu gehören nur die Kosten, die unmittelbar nach dem Unfall entstanden sind, und zwar nur im Falle eines Einsatzes des Pistenrettungsdienstes am Unfallort',
                        labelModal: 'Notarztkosten',
                        massimaleModal: '500€',
                        massimale1: '<b>500€</b> <br/><small> nur in angeschlossenen Gesundheitseinrichtungen </small>',
                        massimale2: '<b>500€</b> <br/><small> nur in angeschlossenen Gesundheitseinrichtungen </small>'
                    },
                    garanzia4: {
                        label: 'Fahrer steht zu Verfügung',
                        massimale1: 'Gesamte Kosten',
                        massimale2: 'Gesamte Kosten'
                    },
                    garanzia5: {
                        label: '<span class="font-weight-700">Rettung auf italienischen Pisten</span><br><small>Nur im Falle eines Einsatzes des Pistenrettungsdienstes am Unfallort</small>',
                        labelModal: 'Rettung auf italienischen Pisten',
                        massimaleModal: '250€ auf der Piste<br/> 5.000€ im Hubschrauber',
                        massimale1: '-',
                        massimale2: '<b>250€</b> auf der Piste<br/><span class="font-weight-400"><b>1.000€</b> im Hubschrauber</span>'
                    },
                    garanzia6: {
                        label: '<span class="font-weight-700">Rettung auf ausländischen Pisten</span><br><small class="font-weight-400">Nur im Falle eines Einsatzes des Pistenrettungsdienstes am Unfallort</small>',
                        labelModal: 'Rettung auf ausländischen Pisten',
                        massimaleModal: '400€ auf der Piste<br/> 5.000€ im Hubschrauber',
                        massimale1: '<b>400€</b> auf der Piste<br/><span class="font-weight-400"><b>5.000€</b> im Hubschrauber</span></td>',
                        massimale2: '<b>400€</b> auf der Piste<br/><span class="font-weight-400"><b>5.000€</b> im Hubschrauber</span></td>'
                    },
                    garanzia7: {
                        label: "<span class='font-weight-700'>Rückerstattung eines nicht benutzten Skipasses aufgrund von Unfall, Krankheit oder Quarantäne",
                        labelModal: 'Rückerstattung eines nicht benutzten Skipasses aufgrund von Unfall, Krankheit <br/>oder Quarantäne*',

                        massimale1: 'Nicht genutzter Anteil',
                        massimale2: 'Nicht genutzter Anteil'
                    },
                    garanzia8: {
                        label: "<span class='font-weight-700'>Stornierung von Unterricht und/oder Verleih von Skiausrüstung aufgrund eines Unfalls</span><br><small class='font-weight-400'>Gültig nur in Verbindung mit einem Skipass mit Namen oder Foto</small>",
                        labelModal: 'Stornierung von Unterricht und/oder Verleih<br/> von Skiausrüstung aufgrund eines Unfalls*',
                        massimaleModal: '100€/Tag',
                        massimale1: '<b>100€/Tag</b><br/><span class="font-weight-400">Pauschaler Selbstbehalt 50€</span>',
                        massimale2: '<b>100€/Tag</b><br/><span class="font-weight-400">Pauschaler Selbstbehalt 50€</span>'
                    },
                    garanzia9: {
                        label: '<span class="font-weight-700">Medical repatriation</span><br><small class="font-weight-400">Gültig nur in Verbindung mit einem Skipass mit Namen oder Foto</small>',
                        labelModal: 'Medical repatriation*',
                        labelModalExpl: "*Gültig nur in Verbindung mit einem Skipass mit Namen oder Foto",
                        massimale1: '5.000€',
                        massimale2: '5.000€'
                    },
                },
                supportCopy: `<p class="col-11 top-sm text-center mb-md-5 pb-md-4">
                            Die Snowcare Ski- und Snowboard-Police gibt es zu Sonderkonditionen <b>für Gruppen</b> (Skiclub, Verbände etc.). <br/>
                            Um einen kostenlosen Kostenvoranschlag anzufordern oder Informationen anzufordern, besuchen Sie unsere <a target="_blank" href="https://help.24hassistance.com/hc/it/requests/new">help center</a>.
                        </p><br/>`,
                docs: {
                    title: 'Dokumentation',
                    doc1: {
                        label: 'Informationspaket zur Snowcare',
                        link: '/download/Assicurazione-sci-snowboard-Set-informativo-ENG_Sogessur.pdf'
                    },
                    doc2: {
                        label: 'DIP Snowcare',
                        link: '/download/DIP_Snowcare.pdf'
                    }
                },
                reviews: {
                    title: 'Andere Benutzer empfehlen es',
                    description: 'Mehr als 1 Million versicherte Skifahrer pro Jahr und über 90 Prozent zufriedene Kunden.',
                    review1: {
                        title: 'Keine Warteschlange an der Kauf',
                        label1: 'Empfohlen! Sparen Sie Geld bei der Skiversicherung, indem Sie die Warteschlangen an der Kauf vermeiden',
                        label2: 'Greta, 3 März ',
                    },
                    review2: {
                        title: 'Tägliche Abdeckung',
                        label1: 'Schnell und einfach... sogar für einen Tag',
                        label2: 'Cristina, 2 Feb',
                    },
                    review3: {
                        title: 'Einfach und sofort',
                        label1: 'Ausgezeichnete Versicherung Sehr einfache Kaufmethode',
                        label2: 'Chiara, 31 Jan',
                    },
                    review4: {
                        title: 'Nützlich und digital',
                        label1: 'Ich empfehle es für Tagesskifahrer. Hervorragende Abdeckung und sehr schneller Ausgabedienst!',
                        label2: 'Gianni, 11 Jan',
                    }
                }
            }
        },
        privacyComponent: {
            title: 'Datenschutz'
        },
        checkoutComponent: {
            amountToPay: 'Gesamtbetrag',
            payMethods: 'Zahlungsmöglichkeiten',
            changePayMethod: 'Zahlungsmethode ändern',
            saveForNextPurchase: 'Diese Karte für zukünftige Einkäufe aufheben',
            component: {
                iban: 'Banküberweisung',
                ibanSubText: 'Der Versicherungsschutz wird nach Zahlungseingang aktiviert.',
                credit: 'Partner-Kredit',
            },
            buttons: {
                checkout: 'Kaufen'
            }
        },
        thankYouComponent: {
            title: "Vielen Dank für Ihren Kauf!",
            brainTree: {
                summary: "Überprüfen Sie die Episode der von Ihnen erworbenen Police:",
                weSentYouDocuments1: "Sie erhalten innerhalb von 10 Minuten eine E-Mail mit den Unterlagen zu Ihrer ",
                weSentYouDocuments2: "-Police an die E-Mail-Adresse, die Sie bei der Angebotserstellung angegeben haben.",
            },
            iban: {
                sendTo: "Die Überweisung ist auszustellen auf:",
                amount: "Betrag",
                beneficiary: "Halter",
                reason: "Grund der Überweisung",
                quotationPurchase: "Zahlung der Versicherung",
                alert: "Der Versicherungsschutz wird nach Zahlungseingang aktiviert."
            },
            button: "Zurück zur Startseite"
        },
        errors: {
            generic: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal.',
            quotationNotBuyable: 'Dieses Angebot ist nicht käuflich zu erwerben.',
            pageUnauthorized: 'Sie sind nicht berechtigt, diese Seite zu besuchen.',
            api: {
                QUOTATION_ALREADYBOUGHT: "Dieses Angebot ist bereits gekauft",
                QUOTATION_DELETED: 'Angebot gelöscht',
                QUOTATION_EXPIRED: 'Dieses Angebot ist abgelaufen, berechnen Sie ein neues',
                OTP_CODE_INCORRECT: 'Der OTP-Code ist falsch oder abgelaufen',
                OLD_PASSWORD_INCORRECT: 'Das alte Passwort ist falsch',
                PASSWORD_INCORRECT_FORMAT: 'Das Passwort darf nicht länger als 20 Zeichen sein.',
                UNMODIFIABLE_DATA: "Nicht editierbare Daten",
                UNMODIFIABLE_DATA_DATETIME: 'Das Datum ist nicht mehr editierbar',
                INEXISTENT_DATA: 'Nicht vorhandene Daten',
                INVALID_DISCOUNT_CODE: 'Der Rabattcode ist nicht gültig',
                UNHATHORIZED: 'Unbefugter Zugang',
                PRIVACY_1: 'Einwilligung in die Verarbeitung sensibler Daten obligatorisch',
                PFW_MP_NONUNIVOCO_EMAIL: 'Diese E-Mail ist bereits mit der Steuernummer einer anderen Person verknüpft. Es ist möglich, dass Sie in der Vergangenheit bereits ein Angebot für eine andere Person mit dieser E-Mail-Adresse gemacht haben. Sie müssen eine andere E-Mail-Adresse eingeben, um mit dem Kauf fortzufahren.',
                PFW_MP_NONUNIVOCO_CELLULARE: 'Diese Nummer ist bereits mit der Steuernummer einer anderen Person verknüpft. Es ist möglich, dass Sie in der Vergangenheit bereits einen Kostenvoranschlag für eine andere Person mit dieser Nummer gemacht haben. Sie müssen eine andere Mobiltelefonnummer eingeben, um mit dem Kauf fortzufahren.',
                DUPLICATED_CUSTOMER: 'Fehler bei der Eingabe von Stammdaten. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an das Support-Team.',
                UNDERAGE_CUSTOMER: "Der Versicherungsnehmer darf nicht minderjährig sein.",
                INVALID_EMAIL_FORMAT: 'Ungültiges E-Mail-Format',
                E00088: 'Zahlung fehlgeschlagen! Es ist ein Zahlungsfehler aufgetreten: Versuchen Sie es erneut oder kontaktieren Sie Ihre Bank, um das Problem zu lösen.',
                ADDRESS_IS_MANDATORY: 'Geben Sie die Adresse ein, um fortzufahren'
            },
            emailUnivocModal: {
                title: `Die eingegebene E-Mail-Adresse wird bereits von einem anderen Profil verwendet`,
                body: `
                    Um fortzufahren, können Sie:<br/><br/>
                    - eine andere E-Mail-Adresse verwenden und mit diesem Kauf fortfahren<br/>
                    - auf den <a href="/Website/Login.aspx" target="_blank">privaten Bereich des Benutzers</a> zugreifen, mit dem die E-Mail-Adresse, die Sie verwenden möchten, verknüpft ist, um sie zu ändern. (<a href="/W_Anagrafica/Anagrafica_RecuperaAccount.aspx" target="_blank">Klicken Sie hier</a> wenn Sie das Passwort vergessen haben)<br/>
                    - <a href="https://help.24hassistance.com/hc/it/requests/new" target="_blank">eine Anfrage an den Kundendienst stellen</a>, um weitere Informationen zu erhalten
                `
            },
            cellUnivoccModal: {
                title: 'Die eingegebene Rufnummer wird bereits von einem anderen Profil verwendet',
                body: `
                    Um fortzufahren, können Sie:<br/><br/>
                    - eine andere Telefonnummer verwenden und mit diesem Kauf fortfahren<br/>
                    - auf den <a href="/Website/Login.aspx" target="_blank">privaten Bereich des Benutzers</a> zugreifen, dem die Telefonnummer, die Sie verwenden möchten, zugeordnet ist, um sie zu ändern. (<a href="/W_Anagrafica/Anagrafica_RecuperaAccount.aspx" target="_blank">Klicken Sie hier</a> wenn Sie das Passwort vergessen haben)<br/>
                    - <a href="https://help.24hassistance.com/hc/it/requests/new" target="_blank">eine Anfrage an den Kundendienst stellen</a>, um weitere Informationen zu erhalten
                `
            }
        }
    },
}

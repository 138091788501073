import ArQuotationsApiService from "@/AreaRiservata/Components/ArQuotations/quotations.service";

const _getDefaultState = () => {
    return {
        results: {
            quotations:[],
            web:[]
        }
    }
};

const defineQuotationKind = (el)=>{
    const code = el.substring(4, 7)
    switch (code) {
        case 'WEB':
            return 'Nuova polizza'
        case 'RIN':
            return 'Rinnovo'
        case 'REQ':
            return 'Requoting'
        case 'ADG':
            return 'Aggiunta garanzie'
        case 'CMZ':
            return 'Cambio mezzo'
        case 'DRB':
            return 'Cambio proprietario'
        default:
            return ''

    }
}


export default {
    state: _getDefaultState(),
    actions: {
        // Recupera i preventivi
        async AR_QUOTATIONS_GET({commit, state}, _request) {
            try {
                const response = await ArQuotationsApiService.getUserQuotations(_request)
                _request.start === 0 ? commit('AR_QUOTATIONS_SETQUOTATIONS', response.data.quotations) : commit('AR_QUOTATIONS_PUSHQUOTATIONS', response.data.quotations)
            } catch (e) {
                throw e
            }
        },

        // Recupera le quotazioni web
        async AR_QUOTATIONS_SEARCH({commit, state}, _request) {
            try {
                const response = await ArQuotationsApiService.getOmniQuotations(_request)
                _request.start === 0 ? commit('AR_QUOTATIONS_SETWEB', response.data.quotations) : commit('AR_QUOTATIONS_PUSHWEB', response.data.quotations)
            } catch (e) {
                throw e
            }
        },

        // Setta la proprietà del preventivo
        async AR_QUOTATIONS_OWN({commit, state}, _request) {
            try {
                return await ArQuotationsApiService.ownOmniQuotation(_request)
            } catch (e) {
                throw e
            }
        },

    },

    getters: {},
    mutations: {
        AR_QUOTATIONS_PUSHQUOTATIONS(state, payload) {
            state.results.quotations.push(payload.map((el)=>{
                // Aggiungo all'oggetto il tipo di preventivo
                return {
                    ...(el),
                    kind: defineQuotationKind(el.quotationNumber)
                }
            }));
        },
        AR_QUOTATIONS_SETQUOTATIONS(state, payload) {
            state.results.quotations = payload.map((el)=>{
                // Aggiungo all'oggetto il tipo di preventivo
                return {
                    ...(el),
                    kind: defineQuotationKind(el.quotationNumber)
                }
            })
        },

        AR_QUOTATIONS_PUSHWEB(state, payload) {
            state.results.web.push(payload.map((el)=>{
                // Aggiungo all'oggetto il tipo di preventivo
                return {
                    ...(el),
                    kind: defineQuotationKind(el.quotationNumber)
                }
            }));
        },
        AR_QUOTATIONS_SETWEB(state, payload) {
            state.results.web = payload.map((el)=>{
                // Aggiungo all'oggetto il tipo di preventivo
                return {
                    ...(el),
                    kind: defineQuotationKind(el.quotationNumber)
                }
            })
        },

        AR_QUOTATIONS_RESETQUOTATIONS(state) {
            state.results.quotations = []
        },
        AR_QUOTATIONS_RESET_SEARCH(state) {
            state.results.web = []
        },
        AR_QUOTATIONS_RESET_STATE(state) {
            Object.assign(state, _getDefaultState());
        }
    }
}


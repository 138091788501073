<template>
    <div>
        <div class="spinner-backdrop" v-if="true">
            <div class="d-flex vh-100 vw-100 justify-content-center align-items-center">
                <i class="fal fa-circle-notch fa-spin fa-8x text-primary"></i>
            </div>
        </div>
        <div class="progress-bar-container">
            <progress-bar size="small" bg-color="#fff" bar-color="#ffc820" :val="increasing_pct"></progress-bar>
            <iframe style="position: fixed; width:100vw; border:0; top:0;left:0; height: 100vh; z-index:99999999999;"></iframe>
        </div>
    </div>
</template>

<script>
    import ProgressBar from 'vue-simple-progress'

    export default {
        name: 'generic-loading',
        components: {ProgressBar},
        data() {
            return {
                increasing_pct: 0,
                showSpinner: false
            }
        },
        mounted() {
            setInterval(() => {
                if (this.is_paused)
                    return;
                this.increasing_pct = Math.min(this.increasing_pct + 13, 100);
            }, 65);

            setTimeout(() => {
                this.showSpinner = true;
            }, 1000)
        },
    }
</script>

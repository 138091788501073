import Vue from 'vue';
import VueRouter from 'vue-router';
import SelligentPusher from "../Common/selligentPusher";

//@NOTE: Nuovo import
const Motoplatinum = () => import('../Preventivo/Motoplatinum/MotoplatinumApp')
const MotoplatinumStart = () => import('../Preventivo/Motoplatinum/MotoplatinumStart')
const MotoplatinumQuotation = () => import('../Preventivo/Motoplatinum/MotoplatinumQuotation')
const MotoplatinumQuotationAddons = () => import('../Preventivo/Motoplatinum/MotoplatinumQuotationAddons')
const MotoplatinumQuotationBundles = () => import('../Preventivo/Motoplatinum/MotoplatinumQuotationBundles')
const MotoplatinumQuotationFastquote = () => import('../Preventivo/Motoplatinum/MotoplatinumQuotationFastquote')
const MotoplatinumQuotationOptions = () => import('../Preventivo/Motoplatinum/MotoplatinumQuotationOptions')
const MotoplatinumQuotationPrint = () => import('../Preventivo/Motoplatinum/MotoplatinumQuotationPrint')
const MotoplatinumVehicle = () => import('../Preventivo/Motoplatinum/MotoplatinumVehicle')
const MotoplatinumVehicleDetails = () => import('../Preventivo/Motoplatinum/MotoplatinumVehicleDetails')
const MotoplatinumVehicleInsurance = () => import('../Preventivo/Motoplatinum/MotoplatinumVehicleInsurance')
const MotoplatinumVehicleOwner = () => import('../Preventivo/Motoplatinum/MotoplatinumVehicleOwner')
const MotoplatinumVehicleSummary = () => import('../Preventivo/Motoplatinum/MotoplatinumVehicleSummary')
const MotoplatinumRegistry = () => import('../Preventivo/Motoplatinum/MotoplatinumRegistry')
const MotoplatinumRegistryContractor = () => import('../Preventivo/Motoplatinum/MotoplatinumRegistryContractor')
const MotoplatinumRegistryOwner = () => import('../Preventivo/Motoplatinum/MotoplatinumRegistryOwner')
const MotoplatinumDocuments = () => import('../Preventivo/Motoplatinum/MotoplatinumDocuments')
const MotoplatinumCheckout = () => import('../Preventivo/Motoplatinum/MotoplatinumCheckout')
const MotoplatinumOtp = () => import('../Preventivo/Motoplatinum/MotoplatinumOtp')
const MotoplatinumThankYou = () => import('../Preventivo/Motoplatinum/MotoplatinumThankYou')

Vue.use(VueRouter);

/** Scrolls the element into view
 * Manually created since Safari does not support the native one inside an iframe
 */
const scrollElementIntoView = (element, behavior) => {

    let scrollTop = window.scrollY || element.scrollTop

    const finalOffset = element.getBoundingClientRect().top + scrollTop

    window.parent.scrollTo({
        top: finalOffset,
        behavior: behavior || 'auto'
    })
}

const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: Motoplatinum,
            meta: {
                title: 'Preventivo',
                backButtonEnabled: true
            },
            children: [
                //@NOTE: NUOVE ROUTES
                {
                    path: 'start',
                    name: 'start',
                    component: MotoplatinumStart,
                    meta: {
                        title: `Preventivo`,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                    }
                },
                {
                    path: 'quotation',
                    name: 'quotation',
                    component: MotoplatinumQuotation,
                    meta: {
                        title: `Preventivo`,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                    },
                    children: [
                        {
                            path: 'options',
                            name: 'options',
                            component: MotoplatinumQuotationOptions,
                            meta: {
                                title: 'Preventivo',
                                backButtonEnabled: false,
                                quotationIdRequired: false
                            }
                        },
                        {
                            path: 'bundles',
                            name: 'bundles',
                            component: MotoplatinumQuotationBundles,
                            meta: {
                                title: `Preventivo`,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                        {
                            path: 'fastquote',
                            name: 'fastquote',
                            component: MotoplatinumQuotationFastquote,
                            meta: {
                                title: `Preventivo`,
                                backButtonEnabled: false,
                                quotationIdRequired: false,
                            },
                        },
                        {
                            path: 'addons',
                            name: 'addons',
                            component: MotoplatinumQuotationAddons,
                            meta: {
                                title: `Preventivo`,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                        {
                            path: 'print',
                            name: 'print',
                            component: MotoplatinumQuotationPrint,
                            meta: {
                                title: `Riepilogo preventivo`,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                       
                    ]
                },
                {
                    path: 'vehicle',
                    name: 'vehicle',
                    component: MotoplatinumVehicle,
                    meta: {
                        title: ``,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                    },
                    children: [
                        {
                            path: 'details',
                            name: 'details',
                            component: MotoplatinumVehicleDetails,
                            meta: {
                                title: '',
                                backButtonEnabled: true,
                                quotationIdRequired: false
                            }
                        },
                        {
                            path: 'insurance',
                            name: 'insurance',
                            component: MotoplatinumVehicleInsurance,
                            meta: {
                                title: ``,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                        {
                            path: 'owner',
                            name: 'ownar',
                            component: MotoplatinumVehicleOwner,
                            meta: {
                                title: ``,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                        {
                            path: 'summary',
                            name: 'summary',
                            component: MotoplatinumVehicleSummary,
                            meta: {
                                title: ``,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                       
                    ]
                },
                {
                    path: 'registry',
                    name: 'registry',
                    component: MotoplatinumRegistry,
                    meta: {
                        title: ``,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                    },
                    children: [
                        {
                            path: 'contractor',
                            name: 'contractor',
                            component: MotoplatinumRegistryContractor,
                            meta: {
                                title: '',
                                backButtonEnabled: true,
                                quotationIdRequired: false
                            }
                        },
                        {
                            path: 'owner',
                            name: 'owner',
                            component: MotoplatinumRegistryOwner,
                            meta: {
                                title: ``,
                                backButtonEnabled: true,
                                quotationIdRequired: false,
                            },
                        },
                       
                    ]
                },
                {
                    path: 'documents',
                    name: 'documents',
                    component: MotoplatinumDocuments,
                    meta: {
                        title: ``,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                    },
                },
                {
                    path: 'checkout',
                    name: 'checkout',
                    component: MotoplatinumCheckout,
                    meta: {
                        title: ``,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                    },
                },
                {
                    path: 'otp',
                    name: 'otp',
                    component: MotoplatinumOtp,
                    meta: {
                        title: ``,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                    },
                },
                {
                    path: 'thank-you',
                    name: 'thankyou',
                    component: MotoplatinumThankYou,
                    meta: {
                        title: ``,
                        backButtonEnabled: true,
                        quotationIdRequired: false,
                    },
                },
                

            ]
        },
        {
            path: '*',
            component: Motoplatinum,
        }
    ],

});

// NUOVA LOGICA

// redirect se punta a root del preventivatore
router.beforeEach((to, from, next) => {
    if (to.path === '/' || to.path === '/quotation') {
        next({
            path: '/quotation/fastquote',
            query: to.query
        })
    } else {
        next()
    }
})


// Verifico che se url diverso da quello iniziale ci sia il weborderid nell'url
/* router.beforeEach((to, from, next) => {
    if (to.path !== '/quotation/fastquote' && !to.query.weborderid) {
        next('/quotation/fastquote')
    } else {
        next()
    }
}) */
// Verifico che se url diverso da quello iniziale ci sia il weborderid nell'url
/* router.beforeEach((to, from, next) => {
    if (to.path !== '/quotation/fastquote' && !to.query.weborderid) {
        next('/quotation/fastquote')
    } else {
        next()
    }
}) */

router.afterEach((to, from) => {
    if(to.path === from.path) return
    // element which needs to be scrolled to
    const element = document.querySelector("#scrollReference")
// scroll to element
    scrollElementIntoView(element)
})

// Segnalo a selligent ogni cambio di pagina
router.afterEach((to, from) => {
    try {
        let pusher = new SelligentPusher()
        pusher.add('page', window.location.pathname + '/#' + to.fullPath)
        pusher.push()
    } catch (e) {
    }
})


//VECCHIA LOGICA

// Imposto il titolo della pagina e l'impostazione del pulsante indietro e se ci troviamo in AR oppure no
/* router.beforeEach((to, from, next) => {
    store.dispatch('SET_VIEW_TITLE', to.meta.title);
    store.dispatch('SET_BACK_BUTTON_STATE', to.meta.backButtonEnabled);
    store.dispatch('SET_CURRENT_PRODUCT', 'motoplatinum');
    store.dispatch('SET_AR_STATE', to.path.includes('/auth/'));
    next();
}); */

// Controlla che l'utente non possa fare danni passando da una route a un'altra,
// fa in modo che esca un avviso se la navigazione puo causare un ricalcolo della tariffa
/* router.beforeEach((to, from, next) => {
    const routes = router.options.routes[0].children
    const quotationRoutes = routes.find(route => {
        return route.name === 'quotation'
    }).children.map(r => r.name)

    const anagraficaRoutes = routes.find(route => {
        return route.name === 'anagraficaParent'
    }).children.map(r => r.name)

    if (anagraficaRoutes.includes(from.name) && quotationRoutes.includes(to.name) || from.name === 'acquista' && quotationRoutes.includes(to.name)) {
        let confirm = window.confirm('Attenzione! Abbandonando questa pagina, il tuo prezzo finale potrebbe cambiare.')
        if (confirm) next()
    } else next();
}); */


// Controllo se l'utente puo entrare o meno in determinate pagine e da li faccio i redirect necessari
/* router.beforeEach((to, from, next) => {
    const _vuexStore = JSON.parse(window.sessionStorage.getItem('vuex'));
    if (to.meta.quotationIdRequired &&
        _vuexStore && !_vuexStore.quotation.quotationData.quotationId && !to.query.q) {
        next('/quotazione/fastquote');
    } else {
        next();
    }
}); */

// Segnalo a selligent ogni cambio di pagina
/* router.afterEach((to, from) => {
    try {
        let pusher = new SelligentPusher()
        pusher.add('page', window.location.pathname + '/#' + to.fullPath)
        pusher.push()
    } catch (e) {
    }
}) */

/* router.afterEach((to, from) => {
    if (to.path === from.path) return
    const element = document.querySelector("#scrollReference");

    scrollElementIntoView(element)
}) */


export {router}

<template>
    <div class="m-2">

        <div class="d-flex justify-content-between align-items-start py-2 form-row">
            <div class="mr-4">
                <p>
                    <span class="font-weight-bold">ADULTI</span><br/>
                    Da 8 anni in su
                </p>
            </div>
            <div class="">
                <button class="btn btn-outline-secondary rounded-circle text-black text-uppercase"
                        @click="onElementRemove('ADULT')">
                    -
                </button>

                <span class="font-weight-bold px-1">{{ adultsNumber }}</span>
                <button class="btn btn-outline-secondary rounded-circle text-black text-uppercase"
                        @click="onElementAdd('ADULT')">
                    +
                </button>

            </div>
        </div>
        <div class="d-flex justify-content-between align-items-start form-row">
            <div class="mr-4">
                <p class="mb-0">
                    <span class="font-weight-bold">BAMBINI</span><br/>
                    Età 0-7 anni
                </p>
            </div>
            <div class="">
                <button class="btn btn-outline-secondary rounded-circle text-black text-uppercase"
                        @click="onElementRemove('KID')">
                    -
                </button>
                <span class="font-weight-bold px-1">{{ kidsNumber }}</span>
                <button class="btn btn-outline-secondary rounded-circle text-black text-uppercase"
                        @click="onElementAdd('KID')">
                    +
                </button>

            </div>
        </div>

        <div class="form-row mt-3" style="line-height: 1;">
            <i>Indica l'età effettiva al momento dell'acquisto</i>
        </div>

    </div>

</template>

<script>

export default {
    name: "SnowcareFastquoteInsuree",
     data() {
        return {
            adultsNumber: 0,
            kidsNumber: 0
        }
    },
    props:{
        adults:{
            type: Number,
            required: false
        },
        kids:{
            type: Number,
            required: false
        }
    },

    mounted(){
      if(this.adults){this.adultsNumber = this.adults}
      if(this.kids){this.kidsNumber = this.kids}
    },

    computed: {
        totalNumber() {
            return this.kidsNumber + this.adultsNumber
        }
    },
    methods: {
        // Aggiunge il numero di assicurati sia KID o ADULT
        onElementAdd(age) {

            if(age === 'ADULT'){
                this.adultsNumber++
                this.$emit('onAdultsChange', this.adultsNumber)
            }

            if(age === 'KID'){
                this.kidsNumber++
                this.$emit('onKidsChange', this.kidsNumber)
            }

        },

        // Rimuove il numero di assicurati sia KID o ADULT
        onElementRemove(age) {

            if (age === 'ADULT') {
                if (this.adultsNumber < 1) return
                this.adultsNumber--
                this.$emit('onAdultsChange', this.adultsNumber)
            }

            if (age === 'KID') {

                if (this.kidsNumber < 1) return
                this.kidsNumber--
                this.$emit('onKidsChange', this.kidsNumber)
            }

        },
    }
}
</script>


<style lang="scss" scoped>
.btn {
    line-height: 1.2 !important;
}




.margin-0 {
    margin: 0 !important;
}

</style>

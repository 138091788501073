<template>
    <div class="container-fluid m-0 p-0 pb-5">
        <the-progressbar-main></the-progressbar-main>

        <!-- class: overflow-hidden-desktop -->
        <div class="container p-0 mt-0 mt-md-3" :class="{'min-vh-100 pb-5' : !isframed}">
            <div class="row no-gutters" :class="{'mb-5 pb-5' : isframed}">
                <div class="col-12">
                    <transition
                            name="slideLeft"
                            :duration="200"
                            mode="out-in">
                        <router-view/>
                    </transition>

                </div>
            </div>
        </div>
    </div> 

</template>

<script>

    import * as moment from "moment";
    import TheProgressbarMain from "./Components/TheProgressbarMain"
    import {useMotoplatinumStore} from "@/stores/motoplatinum.store"
    import {mapWritableState, mapActions} from 'pinia'
    import { MP_ITEM, UPADATE_PROPERTY_MP_ITEM } from '@/Common/constants';
    import motoplatinumService from '../../services/motoplatinum.service';
    import axios from "axios";

    export default {
        name: "MotoplatinumApp",
        components: {
            TheProgressbarMain,
        },

        data() {
            return {
                currentProduct: 'motoplatinum',
                isframed: false
            }
        },
        mounted() {
            this.init()
        },
        computed: {
            ...mapWritableState(useMotoplatinumStore, ['mpWebOrder', 'cvtWebOrder', 'currentWebOrder']),
        },
        methods: {
            ...mapActions(useMotoplatinumStore, ['getMpWebOrder', 'getCvtWebOrder','createMpWebOrder','createCvtWebOrder','setVehicleData','setOwnerData','setInsuranceData']), 
            async init(){
                
                if(window.location.search){
                    const urlParams = new URLSearchParams(window.location.search);
                    const urlProdValue = urlParams.get('urlProd');
                    axios.defaults.headers.urlProd = urlProdValue
                }
               
              
                // se non sono sulla pagina start creo un nuovo token
                //E verifica se che non ci sia il Usertokenid
                if(!this.$route.path.includes('/start') && !window.globalConfigVariables.token){
                        const AUTH = await this.getAuth()
                        UPADATE_PROPERTY_MP_ITEM('token', AUTH.data)
                }

                //in caso si arrivi dal vecchio sito si hanno già webOrderId e productType
                if(this.$route.query.w && this.$route.query.type){
                    if(this.$route.query.type === 'MP') {
                        this.currentWebOrder = await this.getMpWebOrder(this.$route.query.w)
                        const params = {
                            plate: this.currentWebOrder.properties.plate,
                            birthDate: moment(this.currentWebOrder.registries.find(el => el.isInsuree === true).birthDate).format('YYYYMMDD')
                        }

                        await this.createCvtWebOrder(params)
                    }
                    else {
                        this.currentWebOrder = await this.getCvtWebOrder(this.$route.query.w)
                        const params = {
                            plate: this.currentWebOrder.properties.plate,
                            birthDate: moment(this.currentWebOrder.registries.find(el => el.isInsuree === true).birthDate).format('YYYYMMDD'),
                        }
                        await this.createMpWebOrder(params)
                    }
                    UPADATE_PROPERTY_MP_ITEM('mpWebOrderID', this.mpWebOrder.webOrderID)
                    UPADATE_PROPERTY_MP_ITEM('cvtWebOrderID', this.cvtWebOrder.webOrderID)
                    await this.setVehicleData()
                    await this.setOwnerData()
                    await this.setInsuranceData()
                }
                else if( !this.mpWebOrder && !this.cvtWebOrder){
                    if(this.$route.path.includes('/start') || this.$route.path.includes('/fastquote')) return false
                    if(Object.keys(MP_ITEM).length === 0) return false
                    MP_ITEM.mpWebOrderID && await this.getMpWebOrder(MP_ITEM.mpWebOrderID)
                    MP_ITEM.cvtWebOrderID && await this.getCvtWebOrder(MP_ITEM.cvtWebOrderID)
                    //al caricamento se è stato salvato il tipo di currentWebOrder lo carica
                    if(MP_ITEM.currentWebOrderProductType) this.currentWebOrder =  MP_ITEM.currentWebOrderProductType === 'MP' ? this.mpWebOrder : this.cvtWebOrder
                }
            },
            async getAuth(){
               const res =  await motoplatinumService.createAnonymousBeare()
               return res
            }

        }
    }


</script>



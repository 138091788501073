<template>
    <div>
      <template>
        <div class="container">
            <div class="container-reclami">
                <div class="row justify-content-center">

                    <div class="col-sm-8 text-center">
                        <div class="container-form" v-if="step === 0">
                            <p>
                                Compila i dati richiesti nel form di seguito per inviare il tuo reclamo formale a B2C Innovation S.p.A. L'ufficio reclami provvederà a fornirti una risposta entro 45 giorni dalla data di ricezione.<br/>
                                <br/>
                                Se invece hai un problema con i servizi digitali forniti da B2C Innovation S.p.A., consulta il centro assistenza dove puoi anche inviare una richiesta: <a href="https://help.moto.app/hc/it" target="_blank">clicca qui</a> per MOTO.APP - livello Adventure e <a href="https://help.cicloregistro.it/hc/it" target="_blank">clicca qui</a> per Ciclo Registro.<br/>
                                
                            </p>
                            <form class="form reclami-form py-4" accept-charset="UTF-8" method="post">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h3 class="mb-3">I tuoi dati</h3>
                                        <p class="form-lead">Indica chi è il reclamante e inserisci i dati di contatto</p>
                                        
                                        <div class="form-group form-group-select">
                                            <multiselect
                                                id="reclamante"
                                                name="reclamante"
                                                :max-height="250"
                                                :options="reclamanteList"
                                                label="label"
                                                
                                                v-model="form.reclamante"
                                                placeholder="Seleziona il reclamante..."
                                                track-by="label"
                                                :searchable="true"
                                                :showNoResults="false"
                                                :class="{'is-invalid' : $v.form.reclamante.$invalid && $v.form.reclamante.$error}"
                                                @blur="$v.form.reclamante.$touch()"
                                                selected-label=" "
                                                select-label=" "
                                                deselectLabel=" "
                                                ref="reclamanteID"></multiselect>
                                        </div>

                                        <div class="form-row" v-if="form.reclamante">
                                            <div class="form-group col-md-6" v-if="isRequired.name">
                                        
                                                <input type="text"
                                                    class="form-control"
                                                    id="Nome"
                                                    name="Name"
                                                    v-model="form.name"
                                                    :class="{'is-invalid' : $v.form.name.$invalid && $v.form.name.$error}"
                                                   
                                                    @blur="$v.form.name.$touch();"
                                                    ref="name"
                                                    placeholder="Nome"
                                                    v-capitalize>
                                        
                                            </div>

                                            <div class="form-group col-md-6" v-if="isRequired.cognome">
                                                
                                                <input type="text"
                                                    class="form-control"
                                                    id="cognome"
                                                    name="cognome"
                                                    v-model="form.cognome"
                                                    :class="{'is-invalid' : $v.form.cognome.$invalid && $v.form.cognome.$error}"
                                                   
                                                    @blur="$v.form.cognome.$touch();"
                                                    ref="cognomeRef"
                                                    placeholder="Cognome"
                                                    v-capitalize>
                                        
                                            </div>       

                                            <div class="form-group col-md-6" v-if="isRequired.fiscalCode">
                                                
                                                <input type="text"
                                                    class="form-control"
                                                    id="fiscalCode"
                                                    name="fiscalCode"
                                                    v-model="form.fiscalCode"
                                                    :class="{'is-invalid' : $v.form.fiscalCode.$invalid && $v.form.fiscalCode.$error}"
                                                    @blur="$v.form.fiscalCode.$touch();"
                                                    ref="fiscalCode"
                                                    placeholder="Codice fiscale/P.IVA"
                                                    v-uppercase>
                                        
                                            </div>

                                             <div class="form-group col-md-6" v-if="isRequired.fiscalCode">
                                        
                                                <input type="text"
                                                    class="form-control"
                                                    id="nomeSoc"
                                                    name="nomeSoc"
                                                    v-model="form.nomeSoc"
                                                    ref="nomeSoc"
                                                    placeholder="Nome società in caso di P.IVA">
                                        
                                            </div>

                                            <div class="form-group col-md-6" v-if="isRequired.recapito">
                                        
                                                <input type="text"
                                                    class="form-control"
                                                    id="recapito"
                                                    name="recapito"
                                                    v-model="form.recapito"
                                                    :class="{'is-invalid' : $v.form.recapito.$invalid && $v.form.recapito.$error}"
                                                   
                                                    @blur="$v.form.recapito.$touch();"
                                                    ref="recapito"
                                                    placeholder="Cellulare"
                                                    v-capitalize>
                                            
                                            </div>

                                            <div class="form-group col-md-6" v-if="isRequired.email">
                                                <input type="text"
                                                class="form-control"
                                                id="email"
                                                name="email"
                                                v-model="form.email"
                                                :class="{'is-invalid' : $v.form.email.$invalid && $v.form.email.$error}"
                                                @blur="$v.form.email.$touch();"
                                                ref="mail"
                                                placeholder="Email"
                                                >

                                            </div>

                                            <div class="form-group col-md-6" v-if="isRequired.pec">
                                                <input type="text"
                                                    class="form-control"
                                                    id="pec"
                                                    name="pec"
                                                    v-model="form.pec"
                                                    ref="pec"
                                                    placeholder="PEC"
                                                    >

                                            </div>

                                        </div>
            
                                        <h3 class="mb-3">La tua richiesta</h3>
            
                                        <p class="form-lead">Seleziona a chi è rivolto il tuo reclamo, il prodotto di riferimento e indica brevemente cosa è successo</p>
                                        
                                        <div class="form-group form-group-select">
                                            <multiselect
                                                id="destinatario"
                                                name="destinatario"
                                                :max-height="250"
                                                :options="destinatarioList"
                                                label="label"
                                                
                                                v-model="form.destinatario"
                                                placeholder="Seleziona a chi è rivolto il tuo reclamo"
                                                track-by="label"
                                                :searchable="true"
                                                :showNoResults="false"
                                                :class="{'is-invalid' : $v.form.destinatario.$invalid && $v.form.destinatario.$error}"
                                                @blur="$v.form.destinatario.$touch()"
                                                selected-label=" "
                                                select-label=" "
                                                deselectLabel=" "
                                                ref="destinatarioID"></multiselect>
                                        </div>

                                        <div class="form-group form-group-select">
                                            <multiselect
                                                id="prodotto"
                                                name="prodotto"
                                                :max-height="250"
                                                :options="prodottiList"
                                                label="label"
                                                
                                                v-model="form.prodotto"
                                                placeholder="Seleziona un prodotto..."
                                                track-by="label"
                                                :searchable="true"
                                                :showNoResults="false"
                                                :class="{'is-invalid' : $v.form.prodotto.$invalid && $v.form.prodotto.$error}"
                                                @blur="$v.prodotto.prodotto.$touch()"
                                                selected-label=" "
                                                select-label=" "
                                                deselectLabel=" "
                                                ref="prodottoID"></multiselect>
                                            
                                        </div>

                                        <div class="form-group form-group-select" v-if="form.prodotto">
                                            <multiselect
                                                id="firstContent"
                                                name="firstContent"
                                                :max-height="250"
                                                :options="tagList"
                                                label="label"
                                                
                                                v-model="form.category"
                                                placeholder="Seleziona la motivazione del reclamo..."
                                                track-by="label"
                                                :searchable="true"
                                                :showNoResults="false"
                                                selected-label=" "
                                                select-label=" "
                                                deselectLabel=" "
                                                ref="firstContentID"></multiselect>
                                            
                                        </div>
                                        
                                        <div class="form-row" v-if="form.category">
                                            <p class="form-lead text-center w-100">Inserisci il numero di preventivo/polizza se conosciuto<span v-if="isMotoplatinum"> e la targa del mezzo</span></p>
                                            <div class="form-group col-md-6">
                                                
                                                <input type="text"
                                                    class="form-control"
                                                    id="numeroPolizzaPreventivo"
                                                    name="numeroPolizzaPreventivo"
                                                    v-model="form.numeroPolizzaPreventivo"
                                                    ref="numeroPolizzaPreventivo"
                                                    placeholder="Numero di preventivo/polizza"
                                                    v-capitalize>
                                        
                                            </div>

                                            <div class="form-group col-md-6" v-if="isMotoplatinum">
                                                
                                                <input type="text"
                                                    class="form-control"
                                                    id="targa"
                                                    name="targa"
                                                    v-model="form.targa"
                                                    :class="{'is-invalid' : $v.form.targa.$invalid && $v.form.targa.$error}"
                                                    @blur="$v.form.targa.$touch();"
                                                    ref="targa"
                                                    placeholder="Targa">
                                        
                                            </div>
                                        </div>
            
                                        <div class="form-group">
                                            <textarea 
                                                class="form-control" 
                                                placeholder="Descrivi brevemente cosa è successo" 
                                                id="comment"
                                                name="comment"
                                                v-model="form.comment"
                                                :class="{'is-invalid' : $v.form.comment.$invalid && $v.form.comment.$error}"
                                                @blur="$v.form.comment.$touch();"
                                                style="min-height: 120px;"
                                                ref="comment">
                                            </textarea>
                                        </div>

                                        <div id="document-box">
                                            <h3 class="mb-3">I tuoi documenti</h3>
                                            <p class="form-lead">Puoi allegare dei file alla tua richiesta. I formati supportati sono: jpg, png, jpeg, pdf, doc, xls.
                                                RICORDA: il peso di ogni singolo file non deve superare i 18MB.</p>
                                        </div>
                                        

                                        <div id="dragdrop" class="form-group form-group-input py-4" @click="$refs.input_file.click();">
                                            <a id="BtninputFile"><p>
                                                Trascina i tuoi documenti<br> o <br>Clicca qui
                                            </p></a>
                                            <input class="inputFile d-none" type="file" id="myFile" name="input_file" multiple=""
                                                @change="handleFileSelect($event)" 
                                                ref="input_file">
                                        </div>
                                    <!--  {{$v.f}} -->
                                        <div class="file-uploaded" v-if="filesUpdated.length > 0">
                                            <div class="row list-file">

                                                <div class="col-sm-4 puls-del animated bounceIn" 
                                                    v-for="(file, index) in filesUpdated" :key="index">
                                                    <div class="panel panel-file-elm">
                                                        <img v-if="isImg(file)" :src="file.thumbnails[0].content_url" :alt="file.file_name" style="height: 64px;">
                                                        
                                                        <i v-if="!isImg(file)" class="fal fa-file-alt fa-2x mt-4 mb-2" :class="setClass(file)"  aria-hidden="true"></i>
                                                        <p class="pt-2">{{ file.file_name }}</p>
                                                        <div class="cov" @click="removeFile(index);">
                                                            <div class="cir">
                                                                <i class="far fa-trash-alt fa-2x" aria-hidden="true"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                       
                                                
                                            </div>
                                        
                                            
                                        </div>

                                        <div class="checkbox checkbox-hover mt-3 text-left">
                                            <label class="form-lead">
                                                <input type="checkbox" v-model="checked"> Inviando la richiesta accetti la nostra informativa sulla <a href="/privacy-policy"  target="_blank">Privacy</a>.
                                            </label>
                                        </div>

                                        <button id="sub_button" type="button" name="submit_form" class="btn sub-btn px-4" rows="4" cols="50" @click.prevent="onRequestSendClick()" :disabled="!checked || $v.form.$invalid">INVIA</button>

                                    </div>
                                </div>
                            </form>
            
                        </div>

                        <div class="loader-container" v-if="loading">
                            <div class="loader-shadow reclami"></div>
                            <div class="loader"></div>
                        </div>

                        <div class="container-error mt-5" v-if="step === 1">
                            <div class="panel-error">
                                <i class="fa fa-5x fa-times animated shake" aria-hidden="true"></i>
                                <p>
                                    Ops, si è verificato un errore imprevisto.
                                </p>
                                <p>Ci dispiace ma al momento non è possibile inviare la tua richiesta, ti preghiamo di riprovare più tardi.</p>
                                <a href="/">Torna alla home page</a>
                            </div>
                        </div>

                        <div class="container-success mt-5" v-if="step === 2">
                            <div class="panel-success">
                                <i class="fa fa-5x fa-check animated bounceIn" aria-hidden="true"></i>
                                <p>
                                    Grazie,<br />
                                    l'Ufficio Reclami provvederà a fornirti una risposta nel rispetto dei termini previsti dalla normativa di settore
                                </p>

                                <a href="/">Torna alla home page</a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
      </template>
    </div>
  </template>

  <script>
  import {
    required,
    email,
    alpha,
    minLength,
    maxLength,
    requiredIf,
    numeric
  } from 'vuelidate/lib/validators';
  import Multiselect from "vue-multiselect";
  import Noty from 'noty';
  import axios, { AxiosRequestConfig } from "axios";
  
  import forEach from 'lodash/forEach';
  import find from 'lodash/find';
  import indexOf from 'lodash/indexOf';

  const _validatePlate = (plate) => {
    if (!plate) return false
    return (
      /[XY]{1}[BCDFGHJKLMNPRSTVWXYZ23456789]{5}$/.test(plate.toUpperCase()) ||
      /^[a-zA-Z]{2}[0-9]{5,6}$/.test(plate.toUpperCase())
    )
  }
  export default {
    name: "TheReclamiForm",
    components: { Multiselect },
    data() {
      return {
        domain: 'https://help.24hassistance.com/api/v2',
        step: 0,
        loading: false,
        success: false,
        filesUpdated: [],
        ListTokenFiles: [],
        checked: false,
        form: {
            reclamante: "",
            name: "",
            cognome: "",
            fiscalCode: "",
            nomeSoc:"",
            recapito: "",
            email: "",
            pec: "",
            
            
            //destinatario
            destinatario: "",
            prodotto: "",
            numeroPolizzaPreventivo: "",
            targa: "",
            files: [],
            filesToken: null,
            comment: "",
            category: null
        },
        reclamanteList: [
            { 
                value: "rec_dir_int", 
                label: "Diretto interessato"
            },
            { 
                value: "rec_leg", 
                label: "Legale"
            },
            { 
                value: "rec_cons", 
                label: "Consulente"
            },
            { 
                value: "rec_ass_cons", 
                label: "Associazione consumatori"
            },
            { 
                value: "rec_int_coll", 
                label: "Portatore di interessi collettivi"
            },
            { 
                value: "rec_altro_sogg",
                label: "Altro soggetto"
            },
            
        ],
        destinatarioList: [
            { 
                value: "rec_dest_inter", 
                label: "Intermediario / B2C Innovation Spa"
            },
            { 
                value: "rec_dest_coll", 
                label: "Partner dell'intermediario / B2C Innovation Spa"
            },
        ],
        prodottiList:[
            { 
                value: "rec_prod_motoplatinum", 
                label: "Motoplatinum"
            },
            { 
                value: "rec_prod_dottordog", 
                label: "Dottordog" ,
               
            },
            { 
                value: "rec_prod_bikeplatinum", 
                label: "Bikeplatinum" ,
            },
            { 
                value: "rec_prod_multimobility", 
                label: "Multimobility",
            },
            { 
                value: "rec_prod_snowcare", 
                label: "Snowcare",
            },
            { 
                value: "rec_prod_multisport", 
                label: "Multisport" ,
            },
            { 
                value: "rec_prod_altro", 
                label: "Altro" ,
            },
        ],

        categoryOptions: [
            { 
                value: "_fr001", 
                label: "Non trovo chiare le condizioni contrattuali e i set informativi" ,
            },
            { 
                value: "_fr002", 
                label: "Ho avuto problemi con il servizio clienti" ,
            },
            { 
                value: "_fr003", 
                label: "Ho problemi nel comprendere il preventivo" ,
            },
            { 
                value: "_fr004", 
                label: "Ho problemi nel comprendere come esercitare il diritto di ripensamento-recesso-annullamento" ,
            },
            { 
                value: "_fr005", 
                label: "Vorrei avere informazioni sul mio rimborso" ,
            },
            { 
                value: "_fr006", 
                label: "Ho problemi nel comprendere l'importo del premio di polizza/preventivo e/o eventuali integrazioni" ,
            },
            { 
                value: "_fr007", 
                label: "Ho avuto problemi con il flusso di acquisto e/o richiesta caricamento dei documenti" ,
            },
            { 
                value: "_fr008", 
                label: "Ho avuto problemi con le operazioni post acquisto polizza" ,
            },
            { 
                value: "_fr009", 
                label: "Ho problemi con la RC Familiare e/o la relativa applicazione" ,
            },
            { 
                value: "_fr010", 
                label: "Ho problemi nel comprendere i dati del mio attestato di rischio (ATRC)" ,
            },
            { 
                value: "_fr011", 
                label: "Privacy - tutela dati personali - esercizio diritti GDPR" ,
            },
            { 
                value: "_fr012", 
                label: "Vorrei segnalare un caso di possibile truffa" ,
            },
            { 
                value: "_fr013", 
                label: "Altro" ,
            }
            
        ],
      }
    },
    computed: {

        tagList(){
            if (!this.form.prodotto) return undefined;
            return this.categoryOptions;

        },
        isMotoplatinum(){
            if(!this.form.prodotto ) return false;
            return this.form.prodotto.value === "rec_prod_motoplatinum";    
        },
        isRequired() {
            const content = {
                name: false,
                cognome: false,
                fiscalCode: false,
                nomeSoc:false,
                recapito: false,
                email: false,
                pec: false
            }
            if(!this.form.reclamante ) return content;

            if( this.form.reclamante.value === "rec_dir_int" ){
                Object.keys(content).forEach((item) => {
                    content[item] = true;
                })
            }
            else{
                content.name = true;
                content.cognome = true;
                content.fiscalCode = true;
                content.recapito = true;
                content.email = true;
                content.pec = true;
            }

            return content
        }
        
    },
    watch: {
        tagList() {
            this.form.category = null;
        },
    },
    mounted() {},
    methods: {
      async onRequestSendClick() {

        const option1 = `\n\nNome e Cognome: ${this.form.name} ${this.form.cognome}\n\n
                        Codice fiscale/P.IVA: ${this.form.fiscalCode}\n
                        Nome Socetà: ${this.form.nomeSoc}\n
                        Numero di polizza/preventivo: ${this.form.numeroPolizzaPreventivo}\n
                        Targa: ${this.form.targa}\n
                        PEC: ${this.form.pec}
                        Mail inviata da ${this.form.email} \n-------------------\n  ${this.form.comment}`

        const option2 =  `\n\Nome e Cognome: ${this.form.name} ${this.form.cognome}\n\n
                        Cellulare:  ${this.form.recapito}\n
                        Numero di polizza/preventivo: ${this.form.numeroPolizzaPreventivo}\n
                        Targa: ${this.form.targa}\n
                        PEC: ${this.form.pec}
                        Mail inviata da ${this.form.email} \n-------------------\n  ${this.form.comment}`

        const requestBody = this.form.reclamante.value === "rec_dir_int" ? option1 : option2
        
        let _subject =  `Reclamo - ${this.form.reclamante.label} - ${this.form.category.label}`; 

        const request = {
          request: {
            subject: _subject,
            custom_fields: [
              /*{
                // Nome e Cognome
                id: 45252889,
                value: this.form.name,
              },
              {
                // Codice fiscale
                id: 360020181719,
                value: this.form.fiscalCode,
              },*/
              {
                // Numero di polizza/preventivo
                id: 360020055720,
                value: this.form.numeroPolizzaPreventivo,
              },
              {
                // targa
                id: 45254625,
                value: this.form.targa,
              },
              {
                // Numero di polizza/preventivo
                id: 360020181799,
                value: this.form.numeroPolizzaPreventivo,
              },
              {
                // Categoria
                id: 13882000113810,
                value: this.form.category.value,
              },
              {
                // Product
                id: 13881139691410,
                value: this.form.prodotto.value,
              },
              {
                // Reclamante
                id: 13880833345426,
                value: this.form.reclamante.value,
              },
              {
                // Destinatario
                id: 13881612900114,
                value: this.form.destinatario.value,
              }
            ],
            comment: {
              body: requestBody,
              uploads: this.ListTokenFiles,
            },
            requester: {
              name: this.form.name,
              email: this.form.email,
            },
          },
        }
        
        this.loading = true;
        axios.post( `${this.domain}/requests.json`, request).then( (res) => {
                this.loading = false
                this.step = 2;
        })
        .catch(( err ) =>{
            this.loading = false
            this.step = 1;   
            new Noty({
                text: 'Ops si è verificato un errore!.',
                type: 'error'
            }).show();           
        })
      },

      handleFileSelect(evt){
            let files = evt.target.files;

            if (files) {
                        
                let reader = new FileReader();

                forEach( files, ( file ) =>{
                    let exce = '';
                    let isValid = this.validateFile(file);
                    let namefile = file.name;
                    let n = strEndsWith(namefile, '.xlsx');
                    let m = strEndsWith(namefile, '.xls');

                function strEndsWith(namefile, suffix) {
                            return namefile.match(suffix+'$')==suffix;
                        }

                if (isValid == true && n == false ) {
                    this.sendFile(file,exce);
                } else if(n == true || m == true){
                    exce = 'application/vnd.ms-excel';
                    this.sendFile(file,exce);
                }else{
                    new Noty({
                        text: '<span>File non valido!</span><br>Controlla il formato o la dimensione del file.',
                        type: 'error'
                    }).show();
                }
                
                })
        
            }
        },

        validateFile( fileup ){
            const sizeLimit = 20000000; //20MB
            const format = ['image/png','image/jpeg','application/msword','application/pdf','application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            
            let type = fileup.type;
            let size = fileup.size;


            if( indexOf(format, type ) != -1 && size <= sizeLimit){
                return true;
            } else{
                return false;
            }
        },
        isImg( file ){
            return file.content_type == 'image/png' || file.content_type =='image/jpeg' ? true : false;
        },

        setClass( file ){
            switch (file.content_type) {
                case 'application/pdf':
                    return 'text-red'
                    break;

                case 'application/msword':
                    return 'text-blue'
                    break;
                
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return 'text-blue'
                    break;
                
                case 'application/vnd.ms-excel':
                    return 'text-green'
                    break;
                
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel':
                    return 'text-green'
                    break;

                default:
                    return 'text-black'
                    break;
            }
        },

        removeFile( index ){
            this.filesUpdated = [...this.filesUpdated.slice(0, index), ...this.filesUpdated.slice( index + 1 )];
            this.ListTokenFiles = [...this.ListTokenFiles.slice(0, index), ...this.ListTokenFiles.slice( index + 1 )]
        },

        async updateFile( file, exce ){
            
            const config = {
                headers: {
                    'Content-Type': file.type + exce,
                }
            }

            return axios.post( `${this.domain}/uploads.json?filename=${ file.name }`, file, config ).catch( (err)  => {
                throw new Error(err);
            })
        },

        sendFile( file , exce){
            this.loading = true
            this.updateFile( file, exce ).then( ( res) => {
                const file = res.data;
                this.filesUpdated = [ ...this.filesUpdated, file.upload.attachment];
                this.ListTokenFiles = [ ...this.ListTokenFiles, file.upload.token];
                this.loading = false           
            })
            .catch(( err ) =>{
                this.loading = false
                new Noty({
                            text: 'Ops!. Si è verificato un errore, prova più tardi.',
                            type: 'error'
                        }).show();                
            })
        }
    },
    validations: {
      selected: {
        first: {
          required,
        },
        second: {
          required,
        },
      },
      form: {
        
        reclamante: {
            required
        },
        
        name: {
            required: requiredIf(function () {
                return this.isRequired.name
            }),
            minLength: minLength(3),
            maxLength: maxLength(30),
        },
        cognome: {
            required: requiredIf(function () {
                return this.isRequired.cognome
            }),
            minLength: minLength(3),
            maxLength: maxLength(30),
        },
        fiscalCode: {
            required: requiredIf(function () {
                return this.isRequired.fiscalCode
            }),
            minLength: minLength(11),
        },
        recapito:{
            required: requiredIf(function () {
                return this.isRequired.recapito
            }),
            minLength: minLength(3),
            maxLength: maxLength(30),
        },
        email: {
            email,
            required: requiredIf(function () {
                return this.isRequired.email
            }),
        },
        destinatario: {
            required
        },
        prodotto: {
            required
        },
        category:{
            required
        },
        targa: {
            required: requiredIf(function () {
                    return this.isMotoplatinum
                }),
        },
        comment: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(400),
        },
      },
    },
  }
  </script>

<style type="text/css">
.reclami-form input[type='text'],
.reclami-form select,
.reclami-form textarea,
.reclami-form .multiselect .multiselect__tags {
    color: #515151;
    min-height: 50px;
    -webkit-appearance: none;
    border-radius: 0;
    border-color: #0661AC!important;
    padding-right: 30px!important;
}

.reclami-form input[type='text']{
    font-weight: 600;
}
.reclami-form input[type='text']::placeholder{
    font-weight: 400;
}

.reclami-form .is-invalid,
.reclami-form input[type='text'].is-invalid{
    border-color: #dc3545!important;
}

.reclami-form .form-lead {
    font-style: italic;
    color: #898989;
    font-size: 13px;
    margin-bottom: 15px;
}

.reclami-form  #dragdrop {
    border: 1px dashed #C4C4C4;
}

.reclami-form  #dragdrop p {
    cursor: pointer;
    text-align: center;
    line-height: 26px;
    color: #C4C4C4;
}

.reclami-form .sub-btn {
    margin-top: 30px;
    border: 0;
    border-radius: 0;
    height: 40px;
    background: #0661AC;
    color: #fff;
    float: right;
}
.reclami-form .multiselect__single {
  font-weight: 600;
}

.container-error, .container-success {
    padding: 18px 30px 40px 30px;
    background-color: #F9F9F9;
}

.container-success i {
    color: #7ED321;
}

.container-error i {
    color: #CB011B;
}

.container-error p, .container-success p {
    margin-top: 20px;
}
.loader-container .loader-shadow.reclami {
    left: 0px;
}

.file-uploaded .panel {
	 padding: 28px 10px 10px 10px;
	 text-align: center;
	 position: relative;
	 height: 140px;
}
 .file-uploaded p {
	 font-size: 11px;
	 color: gray;
	 word-wrap: break-word;
}
 .file-uploaded .cov {
	 height: 140px;
	 opacity: 0;
	 background-color: rgba(81, 81, 81, 0.8);
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 z-index: 999;
	 -webkit-transition: opacity 0.3s ease-in;
	 -moz-transition: opacity 0.3s ease-in;
	 -o-transition: opacity 0.3s ease-in;
	 transition: opacity 0.3s ease-in;
	 cursor: pointer;
}
 .file-uploaded .cov:hover {
	 opacity: 1;
}
 .file-uploaded .cir {
	 margin: auto;
	 width: 60px;
	 height: 60px;
	 background: #fff;
	 border-radius: 30px;
	 margin-top: 40px;
	 padding: 13px 10px 10px 10px;
	 color: #515151;
}
 .panel-error, .panel-success {
	 text-align: center;
}
 .panel-error p, .panel-success p {
	 margin-top: 20px;
}
 


</style>
  
import * as Cookies from 'js-cookie';
import * as u from 'umbrellajs';
import {API_URL} from './constants';
import axios from "axios";


export default function () {

    // Configuro il comportamento del menu mobile
    u('.mobileNavToggle').on('click', () => {
        u('#mobileNavContainer').toggleClass('h-100');
        u('body').toggleClass('fixed');
    });
    u('.mobileNavArToggle').on('click', () => {
        u('#mobileNavArContainer').toggleClass('h-100');
        u('body').toggleClass('fixed');
    });

    // Se l'utente è loggato
    // if (Cookies.get('24hname')) {
    //     // Mostro la dropdown dell'area riservata nel menu desktop
    //     u('.header-ar-menu').removeClass('sr-only');
    //     // Mostro il pulsante che apre il menu mobile dell'area riservata
    //      u('.mobileNavArToggle').removeClass('sr-only');
    //     // Nascondo i pulsanti che linkano alla pagina di login
    //      u('.login-btn').addClass('sr-only');
//
    //      // Aggiungo il nome dell'utente al menu ar mobile e desktop
    //      u('.userNameArLabel').text(Cookies.get('24hname'));
    //  }

    const loggedUserKind = Cookies.get('24huserkind');
    if (loggedUserKind === 'P') u('.menuAnagrafica').addClass('sr-only');
    else {
        u('.menuContattaci').addClass('sr-only');
        u('.menuCentroAssistenza').removeClass('sr-only');
    }


    async function _pendingOperationsCheck() {

        const _token = window.globalConfigVariables.token
        if (_token) {
           await axios.get(`${API_URL}/motoplatinum/getPendingOperations`, {
                headers: {
                    userTokenId: _token
                },
            }).then((res) => {
                if (_token && res && res.data.details.length > 0) u('.pendingOperationsSign').removeClass('sr-only');
            })
        }

    }

    try {
        _pendingOperationsCheck()
    } catch (e) {
        console.error(e)
    }

    
}

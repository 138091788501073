<template>
    <div class="d-flex flex-column">
        <the-ar-header :container="'container'"/>
        <div class="p-0 m-0 min-vh-100 bg-light d-flex flex-grow-1">
            <router-view></router-view>
        </div>
    </div>
</template>


<script>
    import TheArHeader from "@/AreaRiservata/Components/Common/TheArHeader";
    export default {
        name: "AgenteBase",
        components: {TheArHeader},
        created() {
      }
    }
</script>

<style>

</style>

import {ZENDESK_WIDGET_CONFIG} from "./constants";


export const loadZendesk = (isHuman) => {
    return new Promise(function (resolve, reject) {
        if (window.zE) {
            return resolve(window.zE)
        }

        if(isHuman) {
            const container = document.getElementById('iframe-container');
            container.style.display = 'block';
            return false;
        }

        const src = 'https://static.zdassets.com/ekr/snippet.js?key=264821be-6112-4815-973f-e7592b3ee723'
        /*  isHuman ? 'https://static.zdassets.com/ekr/snippet.js?key=264821be-6112-4815-973f-e7592b3ee723' : 
       
        'https://static.zdassets.com/ekr/snippet.js?key=b1d6de11-a59f-492f-aefc-ab176f9d1c2d' */
        const script = document.createElement('script')
        script.id = 'ze-snippet'
        script.src = src
        script.async = true
        script.addEventListener(
            'load',
            function () {
                const settings = ZENDESK_WIDGET_CONFIG
                if (!isHuman) Object.assign(settings.webWidget.chat, {
                        departments: {
                            select: 'Chatbot'
                        }
                })
                if (isHuman) Object.assign(settings.webWidget.chat, {
                    departments: {
                        select: '24h | Direct'
                    }
                })

                window.zESettings = {
                    ...(settings)
                };

                return resolve(window.zE)
            },
            false
        )
        script.addEventListener(
            'error',
            function () {
                return reject('Error loading Zendesk JS SDK from '.concat(src))
            },
            false
        )

        
        

        /* const iframe = document.createElement('iframe');
        const srcSA = "https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=green&size=standard&radius=4px&label=Mandaci un messaggio su WhatsApp&number=393666514726"
        iframe.src = srcSA
        iframe.width = "250"
        iframe.height = "50"
        iframe.style.border = 'none' */

        const sibling = document.getElementsByTagName('script')[0]
        sibling.parentNode.insertBefore(script, sibling)

        const container = document.getElementById('iframe-container');
        if (container) container.appendChild(iframe);
    })

}

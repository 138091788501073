import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueOffline from 'vue-offline';
import store from '../Store'
import ButtonLoadingSpinner from '../Preventivo/_Common/Components/ButtonLoadingSpinner';
import 'iframe-resizer';
import AxiosConfiguration from '../Preventivo/_Common/Services/AxiosConfiguration.service';
import {VBPopoverPlugin} from 'bootstrap-vue';
import {router as AgenteRouter} from './Agente.router';
import VuePromiseBtn from 'vue-promise-btn';
import AgenteBase from "./AgenteBase";


if (document.getElementById("agenteContainer")) {
    AxiosConfiguration();

    Vue.use(VBPopoverPlugin);
    Vue.use(Vuelidate);
    Vue.use(VueOffline, {
        // Fa si che il mixin non sia
        // iniettato automaticamente in tutti i componenti
        mixin: false

    });
    Vue.use(VuePromiseBtn);

    Vue.component('button-loading-spinner', ButtonLoadingSpinner);

    let app = new Vue({
        el: '#agenteContainer',
        router: AgenteRouter,
        store,
        template: '<transition name="slideLeft"  mode="out-in" appear><router-view></router-view></transition>',
        components: {AgenteBase}
    });


}


















